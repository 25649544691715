import { makeRequest } from "./Requests";

export const FetchPatientByName = async ({token, data}) => {
    const headers = {
        Authorization: `bearer ${token}`,
    };

    return makeRequest({
        method: 'POST', 
        url: 'patients/patientByName', 
        data,
        config:{headers: headers}
    }).then(response => response.data )
}

export const FetchPatientDocs = async ({token, data}) => {
    const headers = {
        Authorization: `bearer ${token}`,
    };

    return makeRequest({
        method: 'POST', 
        url: 'patients/fetchPatientDocs', 
        data,
        config:{headers: headers}
    }).then(response => response.data )
}