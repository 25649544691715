import React, { useEffect, useState } from "react";
import TimeStartToApprovalWithIntervals from "./TimeStartToApprovalWithIntervals.js";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import { Info } from "../../config.js";
import ApprovedPerDayWithIntervals from "./ApprovedPerDayWithIntervals";
import NurseReturnMetrics from "./NurseReturnMetrics";
import Paper from "@material-ui/core/Paper";
import IndividualNurseMetrics from "./IndividualNurseMetrics";
import WeeklyApprovalMetrics from "./Weekly";
import NurseTotalReports from "./NurseTotalReports";
import NurseRosterByWeek from "./NurseRosterByWeek";
import InfusionVisitsByWeek from "./InfusionVisitsByWeek";

const domain = Info.domain;

const PerformanceMetrics = (props) => {
  const grabBasicNumbers = async () => {
    let res = await axios({
      method: "get", // you can set what request you want to be
      url: `https://${domain}/analytics/basicNumbers`,
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });

    return res;
  };
  //Can probably be deleted
  // const grabAnalyticsData = async () => {
  //   let res = await axios({
  //     method: "get", // you can set what request you want to be
  //     url: `https://${domain}/analytics`,
  //     headers: {
  //       Authorization: `bearer ${props.token}`,
  //     },
  //   });
  //   return res;
  // };

  //let [genData, updateGenData] = useState([]);
  let [basicNums, updateBasicNums] = useState([]);

  useEffect(() => {
    // grabAnalyticsData().then((res) => {
    //   updateGenData(res.data);
    // });

    grabBasicNumbers().then((res) => {
      updateBasicNums(res.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        margin: "auto",
        maxWidth: "80%",
      }}
    >
      <BasicNumbers basicNums={basicNums} />
      <IndividualNurseMetrics domain={domain} api={"analytics/NurseList"} />
      <NurseTotalReports {...props} />
      <NurseReturnMetrics {...props} />
      <ApprovedPerDayWithIntervals {...props} />
      <TimeStartToApprovalWithIntervals {...props} />
      <NurseRosterByWeek />
      <InfusionVisitsByWeek />
      <WeeklyApprovalMetrics {...props} />
    </div>
  );
};

const BasicNumbers = (props) => {
  let data = props.basicNums;
  return (
    <Paper>
      <div style={{ margin: 30 }}>
        Overall Nursing Report Numbers
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {Object.keys(data).map((key) => {
            return <BasicNumbersChild key={key} objKey={key} {...props} />;
          })}
        </div>
      </div>
    </Paper>
  );
};

const BasicNumbersChild = (props) => {
  //console.log(props);
  let key = props.objKey;
  return (
    <div style={{ alignItems: "center", margin: 20 }}>
      <div>{key}</div>
      <div style={{ textAlign: "center" }}>{props.basicNums[key]}</div>
    </div>
  );
};

export default ConsumerHOC(PerformanceMetrics);
