import React, { useState } from "react";
import { Info } from "../../config.js";
import { PatientList } from "./PatientList.js";
import { PatientInfoScreen } from "./PatientInfoScreen.js";
import { FetchPatientByName, FetchPatientDocs } from "../../network/Patients_api.js";

const domain = Info.domain;

const Patients = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {userInfo} = props;
  
  const handleSearch = async (e) => {
    e.preventDefault();
    if(firstname.length === 0 && lastname.length === 0){
      return;
    }
    setLoading(true);
    const results = await FetchPatientByName({token: props.token, data: { firstname, lastname }})
    setLoading(false);
    setPatients(results);
  };

  const fetchPatientDocs = async (patient, page = 1, pageSize = 5) => {
    const { MRN } = patient;
    setLoading(true);

    try {
      const results = await FetchPatientDocs({token: props.token, data: { MRN: MRN, page: page, pageSize: pageSize }})
      let patientInfo = {
        ...patient,
        DOCS: results.data, // Paginated documents
        totalPages: results.totalPages // Total number of pages
      };
      setPatient(patientInfo);
    } catch (error) {
      console.error("Error fetching patient documents:", error);
    } finally {
      setLoading(false);
      setShowInfoScreen(true);
      return true;
    }
    // const results = {
    //   data: undefined,
    // };
  };

  return (
    <div style={style.container}>
      <div style={style.header}>
        <div style={{ textAlign: "center" }}> Search Patient</div>
      </div>
      <div>
        {showInfoScreen ? (
          <PatientInfoScreen
            patient={patient}
            setShowInfoScreen={setShowInfoScreen}
            loading={loading}
            fetchPatientDocs={fetchPatientDocs}
            userInfo={userInfo}
          />
        ) : (
          <PatientList
            patients={patients}
            setFirstname={setFirstname}
            setLastname={setLastname}
            handleSearch={handleSearch}
            fetchPatientDocs={fetchPatientDocs}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default Patients;

const style = {
  container: {
    padding: 20,
  },
  header: {
    backgroundColor: "#15794F",
    height: "5%",
    flexDirection: "row",
    padding: 20,
    color: "white",
    borderRadius: 5,
    fontSize: 30,
    fontWeight: "500",
  },
};
