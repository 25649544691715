import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./RefillSchedule.css";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    backgroundColor: "#d1f7e7",
    marginTop: "10px",
  },
}));

const PCFAdminWeekTabs = (props) => {
  // console.log(props);
  let { toggleWeek, weekNum, moveEntireDownloadToDisplay } = props;

  const buildWeekRange = () => {
    let weekNumbers = [];
    const beg = weekNum - 2;
    const end = weekNum + 2;
    for (let count = beg; count <= end; count++) {
      weekNumbers.push(count);
    }

    return weekNumbers;
  };

  const classes = useStyles();

  let [weekRange, updateWeekRange] = useState(buildWeekRange());

  const handleChange = (event, newValue) => {
    // console.log(event, newValue);
    moveEntireDownloadToDisplay();

    toggleWeek(newValue);
  };

  //allow user to change date range to view
  const moveRange = (dir = null) => {
    if (dir === "left") {
      let newRange = [...weekRange];

      updateWeekRange([
        newRange[0] - 1,
        ...newRange.slice(0, newRange.length - 1),
      ]);
    } else {
      let newRange = [...weekRange];

      updateWeekRange([
        ...newRange.slice(1, newRange.length),
        newRange[newRange.length - 1] + 1,
      ]);
    }
  };

  return (
    <Paper className={classes.root}>
      <div className="WeekGridParent">
        <BiLeftArrow
          className="WeekGridArrows"
          onClick={() => {
            moveRange("left");
          }}
        />
        <Tabs
          value={weekNum}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {/* {" "}
          {weekRange.map((week, idx) => {
            return <Tab key={idx} label={weekToLabel(week)} value={week} />;
          })} */}{" "}
          {weekRange.map((week, idx) => {
            return <Tab key={idx} label={weekToLabel(week)} value={week} />;
          })}
        </Tabs>{" "}
        <BiRightArrow
          className="WeekGridArrows"
          onClick={() => {
            moveRange();
          }}
        />
      </div>
    </Paper>
  );
};

const weekToLabel = (num) => {
  let startDate = moment().day("Monday").isoWeeks(num);
  let strStartDate = startDate.format("MMM D, YYYY");

  let endDate = startDate.add(6, "days");
  let strEndDate = endDate.format("MMM D, YYYY");
  return `${strStartDate} - ${strEndDate}`;
  // return `${moment()
  //   .day("Monday")
  //   .isoWeeks(num)
  //   .format("MMM D, YYYY")} - ${moment()
  //   .day("Sunday")
  //   .isoWeeks(num)
  //   .format("MMM D, YYYY")}`;
};
export default PCFAdminWeekTabs;
