import React, { useState } from "react";
import Tab from "./Tab";
import FallAssessments from "./FallAssessments";
import PatientAlertList from './PatientAlertList';
import Tools  from "./Tools";
import "./Dashboard.css"; // Import your CSS file here

const Dashboard = (props) => {
  const [activeTab, setActiveTab] = useState();
  const permissions = props?.userInfo?.permissions;

  return (
    <div className="App">
      <div className="sidebar">
        <Tab
          label="Patient Alerts"
          onClick={() => setActiveTab('Patient Alerts')}
          activeTab={activeTab}
          allowedPermission={['billing' , 'admin']}
          permissions= {permissions}
        />
        <Tab
          label="Fall Assessments"
          onClick={() => setActiveTab('Fall Assessments')}
          activeTab={activeTab}
          allowedPermission={['callcenter' , 'admin']}
          permissions= {permissions}
        />
        <Tab
          label="Oxygen Assessments"
          onClick={() => setActiveTab('Oxygen Assessments')}
          activeTab={activeTab}
          allowedPermission={['callcenter' ,' admin']}
          permissions= {permissions}
        />
        <Tab
          label="Tools"
          onClick={() => setActiveTab('Tools')}
          activeTab={activeTab}
          allowedPermission={['callcenter' , 'admin']}
          permissions= {permissions}
        />
        {/* Add more tabs as needed */}
      </div>
      <div className="content">
        {activeTab === "Fall Assessments" && <FallAssessments {...props} />}
        {activeTab === "Patient Alerts" && <PatientAlertList {...props} />}
        {activeTab === "Tools" && <Tools {...props} />}
      </div>
    </div>
  );
};

export default Dashboard;
