import React, { useState, useEffect } from "react";

import Typography from "@material-ui/core/Typography";
//import { Typography } from "@material-ui/system";

import moment from "moment";
import ConsumerHOC from "../context/ConsumerHOC";
import { MdEdit } from "react-icons/md";
import DatePicker from "./MatUIDatePicker";
import reportConfig from "../ReportConfig.js";
import styled from "styled-components";
import Styles from "../ParentStyles.json";

const EditDate = (props) => {
  //console.log("editDate", props);

  let { keyName, initialDate, title } = props;
  const permission = props.userInfo.permissions;

  //determine whether user has right to edit
  let editLevel = reportConfig[permission].edit;

  let [edit, toggleEdit] = useState(false);

  // initialDate = props.data[props.keyName];
  //console.log("intial date", initialDate);
  // let testDate = new Date(initialDate).toISOString();
  // let testDate2 = testDate.split(".")[0];

  // //console.log(testDate);
  // //console.log(testDate2);

  let [date, toggleDate] = useState(initialDate);

  useEffect(() => {
    if (date !== initialDate) {
      toggleDate(initialDate);
    }
  }, [date, initialDate]);

  let updateDate = async (newDate) => {
    await toggleDate(newDate);
    //console.log("what is new date", newDate);

    //avoid crashing here
    if (!newDate) {
      //console.log("no date, don't doanything");
    } else if (props.keyName === "visitStart") {
      props.currentReport.visitStart = newDate;
      props.currentReport.visitStartEdited = true;
      await props.changeCurrentReport(props.currentReport);
    } else {
      props.editReportData(title, keyName, newDate);
    }
  };

  if (!edit && editLevel) {
    //console.log("date up here", date);
    return (
      <Typography component={"span"} style={{ color: "black", fontSize: 15 }}>
        {props.dateOnly
          ? moment.utc(date).format("MMMM Do YYYY")
          : moment(date).format("MMMM Do YYYY, h:mm a")}

        <HoverText>
          {" "}
          <MdEdit
            onClick={() => {
              toggleEdit(true);
            }}
          />
        </HoverText>
      </Typography>
    );
  } else if (edit && editLevel) {
    return (
      <div
        onBlur={() => {
          toggleEdit(false);
        }}
      >
        <DatePicker
          header={keyName ? keyName : "Modified Visit Start"}
          initialDate={date}
          updateDate={updateDate}
          dateOnly={props.dateOnly}
        />
        {/* <div
         
        >
          Save
        </div> */}
      </div>
    );
  } else {
    return (
      <Typography component={"span"} style={{ color: "black", fontSize: 15 }}>
        {props.dateOnly
          ? moment.utc(date).format("MMMM Do YYYY")
          : moment(date).format("MMMM Do YYYY, h:mm a")}
      </Typography>
    );
  }
};

const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 20px;
  textalign: left;
  padding: 10;
  margin: 20%;
  margintop: 20px;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

export default ConsumerHOC(EditDate);
