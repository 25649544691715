import React from "react";
import "./RefillSchedule.css";
import moment from "moment";

const PCFAdminDayTabs = (props) => {
  let { weekNum, handleUpdateFilter } = props;

  // let [selectedDay, toggleSelectedDay] = useState(false);

  // //remove selected day if change occurs
  // useEffect(() => {
  //   if (
  //     workingCopiesToList &&
  //     workingCopiesToList.length &&
  //     workingCopiesToList[0].Day !== selectedDay &&
  //     workingCopiesToList[workingCopiesToList.length - 1].Day !== selectedDay
  //   ) {
  //     toggleSelectedDay(false);
  //   }
  // }, [workingCopiesToList, selectedDay]);

  let weekDayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  //updates state to reflect filter by day
  const filterByDay = (dayNum) => {
    // console.log("hit filter by day!!", dayNum);
    console.log("filterByDay");
    console.log("dayNum coming in: ", dayNum);
    dayNum = dayNum + 1;
    console.log("daynum after: ", dayNum);

    //in order to get sunday --- FIX THIS!!******
    //Best way to fix is on the serverside by editing the query to assign Monday to 1 - Sunday to 7. - if possible
    if (dayNum === 8) {
      dayNum = 1;
    }

    handleUpdateFilter("Day", dayNum);

    // const filteredList = workingCopiesToList.filter((visit) => {
    //   return visit.Day === dayNum;
    // });

    // updateDisplayList(filteredList);
  };

  return (
    <div className="horizontalNav">
      {weekDayNames.map((day, id) => {
        //use id to select day of week, must be on scale of 1-7
        id = id + 1;
        return (
          <div
            key={day}
            onClick={() => {
              filterByDay(id);
              
            }}
            className="horizontalNavNotSelected"
            // className={
            //   id + 1 === selectedDay
            //     ? "horizontalNavSelected"
            //     : "horizontalNavNotSelected"
            // }
          >
            <span>{day} </span>
            <span className="date">
              {" "}
              {weekNum
                ? moment().isoWeeks(weekNum).day(id).format("- MMM DD, YYYY")
                : null}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default PCFAdminDayTabs;
