import MyContext from "./Context";
import React, { Component } from "react";

class MyProvider extends Component {
  state = {
    newProgressNoteText: "Blank Progress Note",
    recipientEmails: [],
  };

  render() {
    return (
      <MyContext.Provider
        value={{
          newProgressNoteText: this.state.newProgressNoteText,

          updateNewProgressNoteText: async (newText) => {
            await this.setState({ newProgressNoteText: newText });
          },
          recipientEmails: this.state.recipientEmails,
          updateEmails: async (emails) => {
            let emailsArr = [];
            Object.keys(emails).forEach((x) => {
              if (emails[x] === true) {
                emailsArr.push(x);
              }
            });

            await this.setState({ recipientEmails: emailsArr });
          },
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default MyProvider;
