import React, { useState, useEffect } from "react";

import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
//import domain from "../../config.js";
import { Info } from "../../config";

//const Info = domain.Info;
const domain = Info.domain;

const Census = (props) => {
  let { token } = props;
  let [patientList, updatePatientList] = useState({});
  let firstName = "Marissa"; //This needs to be changed to be whatever first name and last name of the currently logged in nurse.
  let lastName = "Amari";

  useEffect(() => {
    const getPatients = async (domain) => {
      try {
        let results = await axios({
          method: "post",
          url: `https://${domain}/analytics/patientCensus`,
          data: { firstName, lastName },
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log(results.data);
        updatePatientList(results);
      } catch (err) {
        console.log(err);
        return err;
      }
    };
    getPatients(domain);
  }, [firstName, lastName, token, updatePatientList]);

  return (
    <div>
      {Object.keys(patientList).map((result, idx) => {
        return (
          <React.Fragment key={idx}>
            <div>{result.LAST_NAME}</div>
            <div>{result.FIRST_NAME}</div>
            <div></div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ConsumerHOC(Census);
