import React, { useState, useEffect } from "react";
import axios from "axios";
import { FetchPatientByName } from "../../network/Patients_api.js";
import { Info } from "../../config.js";
import { Container, Typography, List, ListItem, ListItemText, Button, Paper, CircularProgress, TextField, Box , Pagination } from '@mui/material';


const domain = Info.domain;
const alertTypesOptions = [
  '',
  'Prialt',
  'Pharmacy Alert',
  'Hospice Alert',
];
const PatientAlertList = (props) => {
    const [patientList, setPatientList] = useState();
    const [alertType, setAlertType] = useState("")
    const [resultsSearchTerm, setResultsSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(6);

    const fetchPatientAlertList = async () => {
        try {
            const response = await axios.get(`https://${domain}/patients/PatientAlertList`, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            });
            setPatientList(response.data); // Assuming the response data is the patient list you want
        } catch (error) {
            console.error("Failed to fetch patient alert list:", error);
            // Handle the error appropriately
        }
    };

    const TogglePatientAlert = async ({token, data}) => {
        const {type, MRN} = data;
        try {
            const response = await axios.post(`https://${domain}/patients/TogglePatientAlert`, {
                type,
                MRN
            },{
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            });
            setPatientList(response.data); // Assuming the response data is the patient list you want
        } catch (error) {
            console.error("Failed to fetch patient alert list:", error);
            // Handle the error appropriately
        }
    };

    const updateAlertReason = async ({token, data}) => {
      const {Reason, MRN} = data;
      if(!Reason || Reason?.length === 0) return;
      try {
        const response = await axios.post(`https://${domain}/patients/UpdatePatientAlert`, {
            MRN,
            Reason,
        },{
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
        });
        setPatientList(response.data); // Assuming the response data is the patient list you want
        setAlertType("")
      } catch (error) {
          console.error("Failed to fetch patient alert list:", error);
          // Handle the error appropriately
      }
    }

    useEffect(() => {
        fetchPatientAlertList();
    }, []); // Empty dependency array to run only once on mount

    const actions = async (type, MRN, Reason) => {
        let payload;
        switch (type) {
            case "removePatientAlert":
                 payload = {
                    type: 'remove',
                    MRN,
                }
                try {
                    return await TogglePatientAlert({ token: props.token, data: payload });
                } catch (error) {
                    console.error(error);
                    return null; // Return null or appropriate error handling
                }
        
            case "addPatientAlert":
                 payload = {
                    type: 'add',
                    MRN,
                }
                try {
                    return await TogglePatientAlert({ token: props.token, data: payload });
                } catch (error) {
                    console.error(error);
                    return null; // Return null or appropriate error handling
                }

                        
            case "updateAlertReason":
               payload = {
                  MRN,
                  Reason,
              }
              try {
                  return await updateAlertReason({ token: props.token, data: payload });
              } catch (error) {
                  console.error(error);
                  return null; // Return null or appropriate error handling
              }
            break;
        default:
          break;
      }
  };

      // Filter patients based on the search term
      // const filteredPatients = resultsSearchTerm
      // ? patientList.filter((patient) =>
      //     patient.LAST_NAME.toLowerCase().includes(
      //       resultsSearchTerm.toLowerCase()
      //     )
      //   )
      // : patientList;
          // Filter patients based on the search term
    const filteredPatients = resultsSearchTerm
    ? patientList.filter(patient =>
        patient.LAST_NAME.toLowerCase().includes(resultsSearchTerm.toLowerCase())
    )
    : patientList;

    // Calculate total pages
    const pageCount = filteredPatients && Math.ceil(filteredPatients.length / itemsPerPage);

    // Get current page of patients
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPatients = filteredPatients && filteredPatients.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
  return (
    <div style={{display: 'flex',justifyContent: 'flex-start'}}>
       <Container maxWidth="md" sx={{ my: 4}}>
            <TextField
              helperText="Search by lastname"
              id="demo-helper-text-aligned"
              label="Search Results"
              size="small"
              value={resultsSearchTerm}
              onChange={(e) => setResultsSearchTerm(e.target.value)}
            />
            <List>
                {currentPatients && currentPatients.map((patient) => (
                    <Paper key={patient.MRN} elevation={2} sx={{ my: 2, p: 2, height: '120px' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={`${patient.FIRST_NAME} ${patient.LAST_NAME}`}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="text.primary">
                                            DOB: {patient.DOB.split('T')[0]}
                                        </Typography>
                                        {` — MRN: ${patient.MRN}`}
                                    </>
                                }
                            />
                            <Button
                                variant="outlined"
                                color={patient.AlertStatus ? "error" : "primary"}
                                onClick={() => actions('removePatientAlert', patient.MRN)}
                            >
                                {patient.AlertStatus ? 'Disable Alert' : 'Enable Alert'}
                            </Button>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={`Alert Status: ${patient.AlertStatus}`}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="text.primary">
                                            Alert Type: {patient.AlertReason}
                                        </Typography>
                                    </>
                                }
                            />
                                <div style={{
                                    display: 'flex',
                                    width: '50%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>
                                    <label htmlFor="cars" style={{ fontSize: '12px',marginRight: '10px' }}>Update Type:</label>
                                    <select
                                        name="cars"
                                        id="cars"
                                        style={{
                                            height: '40px',
                                            padding: '0 11px',  // Adjusted padding for better UI
                                            borderRadius: '5px', // Use 'px' or 'em' for borderRadius for consistency
                                            width: '200px'      // Optional: Define a width for the select box
                                        }}
                                        onChange={e => setAlertType(e.target.value)}
                                    >
                                        {alertTypesOptions.map(alert => (
                                            <option
                                                key={alert} // Always use a unique 'key' prop for list items
                                                value={alert}
                                                selected={alert === patient.AlertReason}  // Set 'selected' based on a condition
                                            >
                                                {alert}
                                            </option>
                                        ))}
                                    </select>
                                    <Button onClick={() => actions('updateAlertReason', patient.MRN, alertType)}>Update</Button>
                                </div>
                        </ListItem>
                    </Paper>
                ))}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Box>
        </Container>
        <section style={{display: 'flex', flexDirection: 'column',margin: '5vh 0'}}>
            <h1>Search patents</h1>
            <PatientList
                actions={actions}
                props={props}
            />
        </section>
    </div>
  );
};

export default PatientAlertList;



export const PatientList = ({
    props,
    actions
  }) => {
    const [resultsSearchTerm, setResultsSearchTerm] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();
        if(firstname.length === 0 && lastname.length === 0){
          return;
        }
        setLoading(true);
        const results = await FetchPatientByName({token: props.token, data: { firstname, lastname }})
        setLoading(false);
        setPatients(results);
    };
    // Filter patients based on the search term
    const filteredPatients = resultsSearchTerm
      ? patients.filter((patient) =>
          patient.FIRST_NAME.toLowerCase().includes(
            resultsSearchTerm.toLowerCase()
          )
        )
      : patients;

    return (
      <div style={{ padding: "10px", fontFamily: "Arial, sans-serif" }}>
        <section style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <form onSubmit={handleSearch}>
            <Box
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="outlined-basic" size="small" label="First Name" variant="outlined"             onChange={(e) => {
                    const value = e.target.value;
                    setFirstname(value.charAt(0).toUpperCase() + value.slice(1));
                    }}/>
                <TextField id="outlined-basic" size="small" label="Last Name" variant="outlined"            onChange={(e) => {
                    const value = e.target.value;
                    setLastname(value.charAt(0).toUpperCase() + value.slice(1));
                }}/>
                <button type="submit" >
                    Search
                </button>
            </Box>
          </form>
        </section>
        <aside
          key="resultsContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            margin: "0 auto",
          }}
        >
          {loading ? (
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <CircularProgress />
            </section>
          ) : (
            <>
              {patients.length > 0 && (
                <div>
                  <TextField
                    helperText="Search by firstname"
                    id="demo-helper-text-aligned"
                    label="Search Results"
                    size="small"
                    value={resultsSearchTerm}
                    onChange={(e) => setResultsSearchTerm(e.target.value)}
                  />
                  {filteredPatients.map((patient, i) => {
                    return (
                      <PatientTile
                        key={i}
                        patient={patient}
                        actions={actions}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </aside>
      </div>
    );
  };

  const PatientTile = ({ patient, actions }) => {
    const { FIRST_NAME, LAST_NAME, DOB, MRN } = patient;
  
    return (
      <div
        style={{
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "15px",
          borderRadius: "4px",
          backgroundColor: "rgb(226 226 226)",
          cursor: "pointer",
          margin: '0 0 10px 0'
        }}
      >
        <section style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <h3 style={{ margin: "0", color: "#333", fontSize: "18px" }}>
            {FIRST_NAME} {LAST_NAME}
            </h3>
            <Button variant="contained" color="success" onClick={() => actions('addPatientAlert', MRN)}>ENABLE ALERT</Button>
        </section>
        <p style={{ margin: "5px 0", fontSize: "14px" }}>
          <b style={{ fontSize: "14px" }}>DOB:</b> {DOB.split(" ")[0]}
        </p>
      </div>
    );
  };