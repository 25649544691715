import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { MdEdit } from "react-icons/md";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: 10,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

export default function DateAndTimePickers(props) {
  let { startend, note } = props;
  let [isError, toggleError] = useState(null);
  let helperTextNote = note ? note : "Note: Date must be greater than 2018-1-1";
  const classes = useStyles();
  let initialDate = props.initialDate
    ? props.initialDate.split(".")[0]
    : new Date().toISOString().split(".")[0];
  //console.log(initialDate);
  //'type' and default value differ depending on component's use
  return (
    <form className={classes.container} noValidate>
      {startend ? `${startend} of Interval` : null}
      <TextField
        error={isError}
        helperText={isError ? isError : helperTextNote}
        id={props.dateOnly ? "date" : "datetime-local"}
        label={props.header}
        type={props.dateOnly ? "date" : "datetime-local"}
        //defaultValue = {"2019-12-19T20:45:05.125Z"}
        //defaultValue={"2017-05-24T10:30"}
        defaultValue={
          props.dateOnly
            ? initialDate
            : moment.utc(initialDate).format("MMMM Do YYYY, h:mm a")
        }
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          //console.log("changed!", new Date(e.target.value));

          if (
            new Date(e.target.value) < new Date("2018-1-1") ||
            new Date(e.target.value) > new Date("2099-12-31")
          ) {
            toggleError(
              "Invalid Date: Must set a date greater than 2018-1-1 and less than 2099-12-31"
            );
          } else {
            toggleError(null);
            props.updateDate(e.target.value, startend);
          }
        }}
      />
    </form>
  );
}
