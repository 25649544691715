import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

export default function TransitionsModal({open, pageWidth, handleClose, selectedDoc, renderFileContent}) {
    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: pageWidth,
        maxHeight: '90vh', 
        overflowY: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <sectioon style={{display: 'flex', justifyContent: 'space-between', width: '80%'}}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                {selectedDoc && selectedDoc.Name}
                </Typography>
                <button onClick={() => handleClose()}>
                    Close
                </button>
            </sectioon>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
             {open && renderFileContent(selectedDoc)}
            </Typography>
          </Box>
        </Fade>
      </Modal>
  );

}
