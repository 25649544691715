import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Legend,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import DatePicker from "../MatUIDatePicker";
import Button from "../Button";
import { Info } from "../../config.js";
const domain = Info.domain;

const WeeklyApprovalMetrics = (props) => {
  //console.log("metrics props", props);

  let [open, toggleOpen] = useState(false);
  let [weeklyStats, updateWeeklyStats] = useState([]);
  let [startDate, updateStartDate] = useState("10/1/2019");
  let [endDate, updateEndDate] = useState(null);
  // console.log(weeklyStats);

  const grabWeeklyStats = async () => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/analytics/weeklystats`,
      data: {
        startdate: startDate,
        enddate: endDate,
      },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });
    //console.log(res.data);
    return res;
  };

  const updateData = (date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  };

  useEffect(() => {
    grabWeeklyStats().then((x) => {
      updateWeeklyStats(x.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let reconfigureData = [];
  let totalLength;

  //Probably can delete this function
  // const getAvg = async () => {
  //   let TotalReports = 0;
  //   reconfigureData.map((report) => {
  //     TotalReports += report["Total Report"];
  //   });
  //   return TotalReports / reconfigureData.length;
  // };

  if (Array.isArray(weeklyStats)) {
    weeklyStats.forEach((dp) => {
      reconfigureData.push({
        Name: `${dp["First Name"]} ${dp["Last Name"]}`,
        "Total Reports": dp["TotalReports"],
      });
    });
    //console.log("what is reconfigured data", reconfigureData);
    totalLength = reconfigureData.length;
  }

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Total Number of Reports Approved by Weekly's Admin in Set Time Frame (click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Total Number of Reports Approved by Weekly's Admin in Set Time Frame (click to close)"
            func={toggleOpen}
            data={false}
          />
          <div style={{ width: "30%" }}>
            <DatePicker
              updateDate={updateData}
              dateOnly={true}
              startend={"start"}
            />
            <DatePicker
              updateDate={updateData}
              dateOnly={true}
              startend={"end"}
            />
          </div>
          <ComposedChart
            width={1000}
            height={(totalLength + 2) * 65}
            data={reconfigureData}
            margin={{ top: 50, right: 20, bottom: 100, left: 40 }}
            barGap={10}
            layout="vertical"
          >
            <Bar dataKey="Total Reports" barSize={20} fill={"gray"} />

            <CartesianGrid stroke="#ccc" />
            <YAxis dataKey="Name" interval={0} type="category"></YAxis>

            <XAxis padding={{ top: 12 }} type="number">
              <Label
                value={`Number of Reports Approved by Weekly's Admins since ${startDate}`}
                position="insideBottom"
                offset={-10}
              />
            </XAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" height={36} />
          </ComposedChart>
        </div>
      </Paper>
    );
  }
};
const CustomTooltip = (props) => {
  let active = props.active;
  let label = props.label;
  let data =
    props.payload && props.payload[0] ? props.payload[0].payload : null;
  if (active && props.payload) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${label}`}</div>
        <div className="intro">{`Total Approved: ${
          data["Total Reports"] ? data["Total Reports"] : "N/A"
        }`}</div>
      </div>
    );
  } else {
    return (
      <div className="custom-tooltip">
        <p className="desc">Data problems...</p>
      </div>
    );
  }
};

export default WeeklyApprovalMetrics;
