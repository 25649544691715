import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "./Button";
import { withRouter } from "react-router-dom";
import { createPDF } from "../PDFConversion/pdfConversion.js";
import ConsumerHOC from "../context/ConsumerHOC";
import Upload from "./Upload";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import Styles from "../ParentStyles.json";
import axios from "axios";
import { Info } from "../config.js";
const domain = Info.domain;

const useStyles = makeStyles({
  card: {
    flex: 1,
    minWidth: 300,
    minHeight: 500,
    maxWidth: 500,
    margin: 20,
    padding: 20,
    //alignContent: "center",
  },

  title: {
    fontSize: 14,
  },
  cardContent: {
    // padding: 5,
    // width: "50%",
    // alignItems: "center",
    //margin: "auto",
  },
});

// const submitToServer = props => {
//   //delete old id because we want a new insertion
//   //create priorID
//   props.reportData["priorID"] = props.reportData["_id"];
//   delete props.reportData["_id"];
//   props.userInfo["RevisionUpdatedAt"] = moment().format(
//     "MMMM Do YYYY, h:mm:ss a"
//   );
//   props.reportData["updaterInfo"] = props.userInfo;

//   props.submitUpdate(props.token, props.reportData, props.status);

//   if (props.status === "finalized" || props.status === "Finalized") {
//     createPDF(props.reportData);
//   }
//   goBack(props);
// };

const goBack = (props) => {
  props.history.goBack();
};

const pullFileList = async (visitID, token) => {
  //console.log("in pull files from server");
  const results = await axios({
    method: "post", // you can set what request you want to be
    url: `https://${domain}/review/retrieveFileList`,
    data: { visitID },
    headers: {
      Authorization: `bearer ${token}`,
    },
  });

  let headerOpts = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };

  return results.data;
};

function UploadComp(props) {
  let { isProgressNote } = props;
  const classes = useStyles();

  let [data, alterData] = useState([]);
  let [identifier, updateID] = useState(null);

  useEffect(() => {
    if (data !== props.data) {
      alterData(props.data);
    }
    //do something depending on what kind of component we're passing props from.
    if (
      props.location.state &&
      props.location.state.data &&
      props.location.state.data.ReportID &&
      identifier !== props.location.state.data.ReportID
    ) {
      updateID(props.location.state.data.ReportID);
    } else if (
      props.data &&
      props.data.ReportID &&
      identifier !== props.data.ReportID
    ) {
      updateID(props.data.ReportID);
    } else if (
      props.data &&
      props.data.visitID &&
      identifier !== props.data.visitID
    ) {
      updateID(props.data.visitID);
    }
  }, [
    data,
    identifier,
    props.data,
    props.location.state,
    props.location.state.data,
    props.telemAttached,
  ]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContentTitle}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{
            backgroundColor: "#15794F",
            padding: 20,
            margin: 0,
            borderRadius: 5,
            color: "white",
          }}
        >
          {/* section title here */}
          File Uploads
        </Typography>
      </CardContent>

      <CardContent className={classes.cardContent}>
        {!isProgressNote ? <TelemetryStatusUpdate {...props} /> : null}
        <Upload
          visitID={identifier ? identifier : null}
          pullFilesFromServer={props.pullFilesFromServer}
          pullFileList={pullFileList}
          //didSendFiles={props.didSendFiles}
        />
      </CardContent>
    </Card>
  );
}

export default ConsumerHOC(withRouter(UploadComp));

const confirmTelem = (uploadedFiles) => {
  let includesPDF = false;

  if (Array.isArray(uploadedFiles)) {
    uploadedFiles.forEach((file) => {
      file.endsWith(".pdf") ? (includesPDF = true) : console.log("nope");
    });
  }
  return includesPDF;
};

const TelemetryStatusUpdate = (props) => {
  // console.log("props in here", props);
  let uploadedFiles = props.uploadedFiles;

  let [telemStatus, changeTelemStatus] = useState(props.telemAttached);
  let [explainBox, changeExplainBox] = useState(true);

  useEffect(() => {
    if (
      props.telemAttached !== telemStatus ||
      confirmTelem(uploadedFiles) !== telemStatus
    ) {
      // console.log("inside useEffect", props);
      let currStatus = confirmTelem(uploadedFiles);
      changeTelemStatus(currStatus);
      props.updateTelem(currStatus);
    }
  }, [explainBox, props, telemStatus, uploadedFiles]);
  // console.log({ telemStatus });
  // console.log({ explainBox });
  if (telemStatus) {
    return null;
  } else if (
    explainBox &&
    (props.userInfo.permissions === "ipmservices" ||
      props.userInfo.permissions === "nurse")
  ) {
    return <NoTelemInputExplaination {...props} toggleBox={changeExplainBox} />;
  } else {
    // console.log("here");
    return (
      <NoTelemReadComp
        {...props}
        toggleBox={changeExplainBox}
        boxstatus={explainBox}
      />
    );
  }
};

const NoTelemInputExplaination = (props) => {
  let [text, updateText] = useState(
    props.currentReport.TelemExplained
      ? props.currentReport.TelemExplained
      : "Reason Telemetry has not yet been submitted"
  );

  const handleChange = (e) => {
    let newText = e.target.value;

    updateText(newText);
  };

  const explained = async () => {
    props.currentReport.TelemExplained = text;
    props.changeCurrentReport(props.currentReport);

    props.toggleBox(false);
  };

  const textboxstyle = {
    color: "black",
    width: "80%",
    height: 100,
    alignContent: "center",
  };

  return (
    <>
      <div
        style={{
          margin: 10,
          padding: 15,
          borderColor: "black",
          borderWidth: 1,
          borderStyle: "solid",
          backgroundColor: "pink",
        }}
      >
        <div>
          Telemetry has not been detected, please either upload a telemetry
          report or explain in the text box below.
        </div>

        <textarea onChange={handleChange} value={text} style={textboxstyle} />
        <Button label="Save Explanation" func={explained} />
      </div>
    </>
  );
};

const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 20px;
  textalign: left;
  padding: 10;
  margin: 10%;
  margintop: 20px;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

const NoTelemReadComp = (props) => {
  const openEditBox = () => {
    //console.log("open box");
    props.toggleBox(!props.boxstatus);
    //console.log(props.boxstatus);
  };
  return (
    <>
      <div style={{ margin: 15, display: "flex", flexDirection: "row" }}>
        <div style={{ paddingRight: 20 }}>
          <HoverText
            onClick={() => {
              openEditBox();
            }}
          >
            <MdEdit />
          </HoverText>
        </div>
        <div> No Telemetry - Reason: {props.currentReport.TelemExplained}</div>
      </div>
    </>
  );
};
