import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
//import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import IndividualNurseMetricsTable from "./IndividualNurseMetricTable.js";
import Button from "../Button";

// const CallApi = async (domain, token, api, nurse = null) => {
//   let res = await axios({
//     method: "post", // you can set what request you want to be
//     url: `https://${domain}/${api}`,
//     data: {
//       nurse: nurse,
//     },
//     headers: {
//       Authorization: `bearer ${token}`,
//     },
//   });
//   return res;
// };

const IndividualNurseMetrics = (props) => {
  let { domain, token } = props;
  // let [nurseList, updateNurseList] = useState([]);
  let [pickedNurse, updatePickedNurse] = useState([]);
  let [open, toggleOpen] = useState(false);

  let nurseList = [];
  //let [showAllNurses, toggleShowAllNurses] = useState(false);

  //Can probably delete
  // const changePickedNurse = (nurse = null) => {
  //   updatePickedNurse([...pickedNurse, nurse]);
  // };

  //Can probably delete
  // function compare(a, b) {
  //   // Use toUpperCase() to ignore character casing
  //   const markerA = a.label.toUpperCase();
  //   const markerB = b.label.toUpperCase();

  //   let comparison = 0;
  //   if (markerA > markerB) {
  //     comparison = 1;
  //   } else if (markerA < markerB) {
  //     comparison = -1;
  //   }
  //   return comparison;
  // }

  //Can probably delete
  // const ShowAllNurses = () => {
  //   if (showAllNurses) {
  //     toggleShowAllNurses(false);
  //     updatePickedNurse([]);
  //   } else {
  //     toggleShowAllNurses(true);
  //     let revisedNurseList = convertList(nurseList);

  //     updatePickedNurse(revisedNurseList);
  //   }
  // };

  //Can probably delete
  // const convertList = (list) => {
  //   let revisedlist = [];
  //   list.forEach((val) => {
  //     if (val.status) {
  //       revisedlist.push(val);
  //     } else if (val.email) {
  //       revisedlist.push({
  //         label: `${val.lastName}, ${val.firstName}`,
  //         value: val.email,
  //         status: true,
  //       });
  //     }
  //   });
  //   return revisedlist;
  // };

  const childStyle = {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    backgroundColor: "gray",
  };

  const headCells = [
    { label: "Last Name", id: "LastName", numeric: false },
    { label: "First Name", id: "FirstName", numeric: false },
    {
      label: "Reports Currently Returned",
      id: "ReportsCurrentlyReturned",
      numeric: true,
    },

    {
      label: "Reports Currently in Nurse's Work Folder",
      id: "ReportsInWorkFolder",
      numeric: true,
    },

    {
      label: "Nurse's Reports Currently with Weeklys",
      id: "ReportsWithWeeklys",
      numeric: true,
    },
    {
      label: "Total Reports Approved",
      id: "ReportsApproved",
      numeric: true,
    },
    { label: "Number of Returns", id: "TotalReturns", numeric: true },
    { label: "Return Percentage", id: "ReturnPercent", numeric: true },

    {
      label: "Average Time from Visit Start to Visit Upload",
      id: "TimeStartToUpload",
      numeric: true,
    },

    {
      label: "Average Time from Visit Start to Visit End",
      id: "TimeStartToVisitEnd",
      numeric: true,
    },

    {
      label: "Average Time from Visit Start to Report Approval By Weeklys",
      id: "TimeStartToApproval",
      numeric: true,
    },
  ];

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Individual Nurse Metrics Table (click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Individual Nurse Metrics Table (click to close)"
            func={toggleOpen}
            data={false}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <IndividualNurseMetricsTable
            headCells={headCells}
            pickedNurse={pickedNurse}
            nurseList={nurseList}
            api={"analytics/NurseMetrics"}
            domain={domain}
            token={token}
            style={childStyle}
          />
        </div>
      </Paper>
    );
  }
};
//Can probably delete
// const NurseMetricChild = (props) => {
//   let { label, value, domain, token, api, childStyle } = props;

//   let [nurseData, updateNursesData] = useState([]);
//   //console.log(nurseData);

//   useEffect(() => {
//     CallApi(domain, token, api, value).then((res) => {
//       //console.log(res.data);
//       updateNursesData(res.data);
//     });
//   }, [api, domain, token, value]);

//   return (
//     <>
//       <div style={childStyle}>{label}</div>
//       <div style={childStyle}>{nurseData["currentReturned"]}</div>
//       <div style={childStyle}>{nurseData["currentWorkingCopy"]}</div>
//       <div style={childStyle}>{nurseData["currentMC"]}</div>
//       <div style={childStyle}>{nurseData["Finalized"]}</div>
//       <div style={childStyle}>{nurseData["totalTimesReturned"]}</div>
//       <div style={childStyle}>{nurseData["ReturnPercentage"] + "%"}</div>
//       <div style={childStyle}>
//         {Math.round((nurseData["startToUploadTime"] * 100) / 100) + "hrs"}
//       </div>
//       <div style={childStyle}>
//         {Math.round(nurseData["startToEndTime"] * 100) / 100 + "hrs"}
//       </div>
//       <div style={childStyle}>
//         {Math.round(nurseData["startToApprovalTime"] * 100) / 100 + "hrs"}
//       </div>
//     </>
//   );
// };

export default ConsumerHOC(IndividualNurseMetrics);
