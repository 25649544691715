import React, { useState, useCallback } from "react";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC.js";
import { Info } from "../../config.js";
import DatePicker from "../MatUIDatePicker";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";

const domain = Info.domain;

const NewView = ({ updateData, grabBasicNumbers }) => {
  return (
    <div
      style={{
        display: "flex",
        height: "15%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <section style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"start"}
          />
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"end"}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button onClick={grabBasicNumbers}>Search</button>
        </div>
      </section>
    </div>
  );
};

const RenderVisits = ({ visits, startDate, endDate }) => {
  let count = visits ? visits.length : [];
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <p>There are {count} visits in this batch!</p>
      <CSVLink data={visits} filename={`${startDate}-${endDate}-Visits`}>
        {" "}
        <MdFileDownload className="download-button" />{" "}
      </CSVLink>
    </div>
  );
};

const AdmissionReport = (props) => {
  const date = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const [startDate, updateStartDate] = useState("09/1/2022");
  const [endDate, updateEndDate] = useState(
    date.toLocaleDateString("en-US", options).replace(/\//g, "/")
  );
  const [visits, setVisits] = useState();

  const grabVisitsByDate = useCallback(async () => {
    axios
      .post(
        `https://${domain}/analytics/retriveVisitsByDate`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        let newRes = res.data.map((item) => {
          const { submitterData, visitData, ...data } = item;
          let { email, id } = submitterData;
          let refill = visitData["Medtronic Refill Details"]
            ? visitData["Medtronic Refill Details"].data["Pump refill"]
            : visitData["Flowonix Refill Details"]
            ? visitData["Flowonix Refill Details"].data["Pump refill"]
            : visitData["Non-Programmable Pump Refill"].data["Pump refill"];

          data["Nurse Email"] = email;
          data["Nurse ID"] = id;
          data["Pump Refill"] = refill;
          data["Next Refill Visit"] =
            visitData["End of Visit Note"].data["Next refill visit"];
          return data;
        });
        setVisits(newRes);
      });
  }, [startDate, endDate, props.token]);

  const updateData = useCallback((date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  }, []);

  return (
    <div
      style={{
        margin: "auto",
        maxWidth: "80%",
      }}
    >
      <NewView updateData={updateData} grabBasicNumbers={grabVisitsByDate} />
      <section>
        {visits && (
          <RenderVisits
            visits={visits}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </section>
    </div>
  );
};

export default ConsumerHOC(AdmissionReport);
