import React, { useState, useEffect } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import PCFAdminTable from "./PCFAdminTable";
import PCFIndividualReport from "./PCFIndividualReport";
import axios from "axios";
import { Info } from "../../config";

import PCFAdminFilter from "./PCFAdminFilter";
import socketIOClient from "socket.io-client";

const PCFAdminView = (props) => {
  let { token, workingCopies, updateWorkingCopiesFromServer } = props;
  // console.log("what is props", props);

  const updateBillingStatus = async (data) => {
    try {
      await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/pharmacyRefill/`,
        data: { ...data, headerID: "BillingStatus" },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      // console.log("we're good");
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  let [viewReport, toggleViewReport] = useState(false);
  let [workingCopiesToList, updateWorkingCopiesToList] = useState([]);

  //*********************************
  //begin experimental socket

  useEffect(() => {
    //download data from server
    //downloaded data stored in workingCopiesToList
    //default pushes all into displayList for viewing, but displayList can be
    //modified based on filtering
    const getData = async () => {
      try {
        let results = await axios({
          method: "get", // you can set what request you want to be
          url: `https://${Info.domain}/pcf/admin`,

          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log("what is results.data", results.data);
        updateWorkingCopiesFromServer(results.data);
      } catch (err) {
        console.error(err);
      }
    };

    //getData();

    const socket = socketIOClient.connect(`https://${Info.domain}`, {
      query: { token },
    });

    socket.on("update pharmacy board", (data) => {
      // console.log("hit the update thing!");
      getData();
    });

    socket.on("FromAPI", (data) => {
      // console.log("Socket.io API connnected", data);
      //mark status as connected
      //updateStatus(true);
    });

    socket.on("disconnect", () => {
      console.log("Socket has disconnected");
      //updateStatus(false);
    });

    return () => {
      socket.disconnect();
      //updateStatus(false);
      console.log("Socket has disconnected");
    };
  }, [token, updateWorkingCopiesFromServer]);

  //end experimental socket
  //*************************************

  if (!viewReport) {
    return (
      <div>
        <PCFAdminFilter
          workingCopiesToList={workingCopiesToList}
          updateWorkingCopiesToList={updateWorkingCopiesToList}
          workingCopies={workingCopies}
        />

        <PCFAdminTable
          toggleViewReport={toggleViewReport}
          updateBillingStatus={updateBillingStatus}
          workingCopiesToView={workingCopiesToList}
        />
      </div>
    );
  } else if (viewReport) {
    return (
      <PCFIndividualReport
        toggleViewReport={toggleViewReport}
        viewReport={viewReport}
      />
    );
  }
};

export default ConsumerHOC(PCFAdminView);
