import React, { useState, input } from "react";
import ConsumerHOC from "../context/ConsumerHOC";

const Toggler = (props) => {
  let [status, toggleStatus] = useState(false);

  return (
    <div
      onClick={() => {
        if (props.isEditable) {
          toggleStatus((status = true));
        }
      }}
      onBlur={() => {
        toggleStatus((status = false));
      }}
    >
      {status ? (
        <InputComponent {...props} />
      ) : (
        <div> {props.falseComponent ? props.falseComponent : "?"} </div>
      )}
    </div>
  );
};

const InputComponent = (props) => {
  let [string, changeString] = useState(props.falseComponent);
  //console.log("input comp props", props);
  const SaveStuff = () => {
    props.editReportData(props.title, props.identifier, string, "edit");
  };
  return (
    <input
      style={{ maxWidth: 200 }}
      autoFocus
      placeholder={props.falseComponent ? props.falseComponent : "?"}
      onBlur={() => SaveStuff()}
      onChange={(e) => changeString(e.target.value)}
    />
  );
};

export default ConsumerHOC(Toggler);
