import React from "react";

const ModifyScheduleDenial = (props) => {
  return (
    <div style={{ padding: "20px", margin: "20px" }}>
      The Pharmacy Department has locked the scheduled visit date if you must
      reschedule please call the pharmacy department.{" "}
    </div>
  );
};

export default ModifyScheduleDenial;
