import Typography from "@material-ui/core/Typography";
import React from "react";

const ProgressNoteRow = props => {
  return (
    <Typography color="primary" component={"span"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "noWrap"
        }}
      >
        <div
          style={{
            margin: 10,
            padding: 10,
            flexGrow: 2,
            flexBasis: "30%",
            alignSelf: "flexStart",
            backgroundColor: "#d8d8d8",
            borderRadius: 10,
            fontWeight: "900",
            fontSize: 15
          }}
        >
          {props.header}
        </div>
        <div
          style={{
            fontWeight: "400",
            fontSize: 15,
            backgroundColor: "#d8d8d8",
            borderRadius: 10,
            margin: 10,
            padding: 10,
            flexGrow: 3,
            alignSelf: "flexStart",
            flexBasis: "50%",
            borderWidth: 2
          }}
        >
          {props.details}
        </div>
        <div style={{ alignSelf: "center", fontSize: 12 }} />
      </div>
    </Typography>
  );
};

export default ProgressNoteRow;
