export function sortClosure(list, updateState, dir, updateDir) {
  // let sortDir = "desc";

  const sortFunc = (e) => {
    const sortBy = e.currentTarget.title;
    let newlyOrderedList = list.sort((a, b) => {
      if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        if (dir === "desc") {
          return 1;
        } else {
          return -1;
        }
      } else if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        if (dir === "desc") {
          return -1;
        } else {
          return 1;
        }
      }
    });

    if (dir === "desc") {
      updateDir("asc");
    } else {
      updateDir("desc");
    }

    updateState([...newlyOrderedList]);
  };
  return sortFunc;
}
