import React, { useState, useEffect } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import RefillScheduleTable from "./RefillScheduleTable";
import PCFIndividualReport from "./PCFIndividualReport";
import axios from "axios";
import moment from "moment";
import RefillScheduleFilter from "./RefillScheduleFilter";
import socketIOClient from "socket.io-client";
import { Info } from "../../config";
import ReportGenerators from "./ReportGenerators";
import { filteringFunction } from "./refillScheduleFilterMech";
import "./RefillSchedule.css";
import RowHighLightsKey from "./RowHighlightsKey";

const domain = Info.domain;

const PCFAdminView = (props) => {
  // console.log("inside ppcf admin view yo......", props);
  let { token, userInfo } = props;

  let [viewReport, toggleViewReport] = useState(false);
  let [workingCopiesToList, updateWorkingCopiesToList] = useState([]);
  let [weekNum, toggleWeek] = useState(moment().isoWeeks());
  let [displayList, updateDisplayList] = useState([]);
  let [filters, updateFilters] = useState([]);
  // console.log(displayList);
  const handleUpdateFilter = (field, value) => {
    let newFilters = [];
    //no filters yet
    if (filters.length === 0) {
      newFilters = [[field, value]];
    } else {
      newFilters = [...filters];
      let didMatch = false;
      for (let i = 0; i < filters.length; i++) {
        //if blank then remove
        if (filters[i][0] === field && (value === "" || !value)) {
          newFilters.splice(i, 1);

          didMatch = true;
          break;
        }
        //if field matches
        else if (filters[i][0] === field) {
          newFilters[i][1] = value;
          didMatch = true;
          break;
        }
      }

      if (!didMatch) {
        //otherwise add new field/value to the end
        newFilters.push([field, value]);
      }
    }

    updateFilters(newFilters);

    let filteredResults = filteringFunction(newFilters, workingCopiesToList);
    updateDisplayList(filteredResults);
  };

  // let [modDialog, toggleModDialog] = useState(false);

  //controls status circle at top of page indicating whether socket connection is live or not.
  let [status, updateStatus] = useState(false);

  useEffect(() => {
    //download data from server
    //downloaded data stored in workingCopiesToList
    //default pushes all into displayList for viewing, but displayList can be
    //modified based on filtering
    const getData = async (data) => {
      console.log("data === ");
      let { weekNum } = data;
      try {
        let results = await axios({
          method: "get", // you can set what request you want to be
          url: `https://${domain}/pharmacyRefill/${weekNum}`,

          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        updateWorkingCopiesToList(results.data);
        let filteredResults = filteringFunction(filters, results.data);
        updateDisplayList(filteredResults);
      } catch (err) {
        // console.log(err);
      }
    };

    getData({ weekNum });

    const socket = socketIOClient.connect(`https://${domain}`, {
      query: { token },
    });

    socket.on("update pharmacy board", (data) => {
      getData({ weekNum });
    });

    socket.on("FromAPI", (data) => {
      // console.log("Socket.io API connnected", data);

      //mark status as connected
      updateStatus(true);
    });

    socket.on("disconnect", () => {
      // console.log("Socket has disconnected");
      updateStatus(false);
    });

    return () => {
      socket.disconnect();
      updateStatus(false);
      // console.log("Socket has disconnected");
    };
  }, [filters, token, weekNum]);

  const moveEntireDownloadToDisplay = () => {
    //updateDisplayList(workingCopiesToList);
    //disconnected for now
  };

  if (!viewReport) {
    // console.log("what is userInfo", userInfo);
    return (
      <div>
        <div className="topOfPageLayout">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="liveConnGrid">
              <div> Pharmacy Board Live Connection: </div>
              <div
                className={status ? "liveconnection" : "disconnection"}
              ></div>
            </div>
            <RowHighLightsKey />
            {userInfo.permissions !== "ipmservices" &&
            userInfo.permissions !== "nurse" ? (
              <ReportGenerators token={token} />
            ) : null}
          </div>

          <RefillScheduleFilter
            workingCopiesToList={workingCopiesToList}
            displayList={displayList}
            updateDisplayList={updateDisplayList}
            weekNum={weekNum}
            toggleWeek={toggleWeek}
            moveEntireDownloadToDisplay={moveEntireDownloadToDisplay}
            handleUpdateFilter={handleUpdateFilter}
            updateFilters={updateFilters}
          />
        </div>
        <RefillScheduleTable
          toggleViewReport={toggleViewReport}
          workingCopiesToView={displayList}
          updateWorkingCopiesToList={updateWorkingCopiesToList}
        />
      </div>
    );
  } else if (viewReport) {
    return (
      <PCFIndividualReport
        toggleViewReport={toggleViewReport}
        viewReport={viewReport}
      />
    );
  }
};

export default ConsumerHOC(PCFAdminView);
