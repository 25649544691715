import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import RefillScheduleTableConfig from "./RefillScheduleTableConfig";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "93%",
    padding: 40,
  },
  tableHead: {
    fontWeight: 600,
  },
}));

function PrintableScheduleFunction(props) {
  let { type, workingCopiesToView } = props;
  let setupFuncs = RefillScheduleTableConfig;
  let { rowMapper, colorRow } = setupFuncs;
  const rows = rowMapper(workingCopiesToView);
  const classes = useStyles();

  // function stableSort(array, cmp) {
  //   const stabilizedThis = array.map((el, index) => [el, index]);

  //   stabilizedThis.sort((a, b) => {
  //     const order = cmp(a[0], b[0]);

  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });

  //   return stabilizedThis.map((el) => el[0]);
  // }

  let headCells = [
    {
      id: "NurseName",
      numeric: false,
      disablePadding: false,
      label: "Nurse Name",
    },
    {
      id: "VisitDate",
      date: true,
      numeric: true,
      disablePadding: false,
      label: "Visit Date",
      button: true,
    },
    {
      id: "PatientName",
      numeric: false,
      disablePadding: false,
      label: "Patient Name",
    },
    {
      id: "VisitType",
      numeric: false,
      disablePadding: false,
      label: "Visit Type",
    },
  ];

  return (
    <div>
      <br />
      &nbsp; &nbsp; &nbsp; &nbsp; Pharmacy Refill Schedule
      <div className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} className={classes.tableHead}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {stableSort(rows, 1).*/}
            {rows.map((row, index) => {
              let color = null;

              if (colorRow) {
                color = colorRow(type, row);
              }
              return (
                <TableRow
                  hover
                  row={row}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.name || index}
                  style={{
                    backgroundColor: color,
                  }}
                >
                  {headCells.map((header, id) => {
                    return (
                      <TableCell
                        key={id + header.id}
                        component="th"
                        scope="row"
                      >
                        {header.date
                          ? moment(row[header.id]).format("MMMM Do YYYY")
                          : row[header.id].toString()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

class PrintableSchedule extends React.Component {
  //Needs to be class component to be able to print
  render() {
    const { type, workingCopiesToView } = this.props;
    return (
      <PrintableScheduleFunction
        type={type}
        workingCopiesToView={workingCopiesToView}
      />
    );
  }
}

export default PrintableSchedule;
