import React, { Component } from "react";
import logo from "../assets/webLogo.png";
import { Redirect } from "react-router-dom";
import Spin from "./Spin.js";
import axios from "axios";
import Button from "./Button";
import Input from "./Input";
import ConsumerHOC from "../context/ConsumerHOC";
import { Info } from "../config.js";
import AdminModalParent from "./AdminModalParent.js";
import AdminPick from "./AdminPick";

const domain = Info.domain;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitLogin: false,
      loggedIn: false,
      Name: null,
      Password: null,
      redirectToReferrer: false,
      loginError: false,
      adminModal: false,
    };
    this.props.location.state = { name: "Login" };
  }

  closeModal = () => {
    this.setState({ adminModal: false });
    //console.log("what is props", this.props);
  };

  AdminLoginHelper = async (results) => {
    //console.log("check results", results);
    if (results.status === 200) {
      await this.setState({ loggedIn: true });

      //await localStorage.setItem("token", isAuthorized.data.token);
      await this.props.updateToken(results.data.token);

      this.setState({ redirectToReferrer: true });
    }
  };

  login = async (e) => {
    e.preventDefault();

    await this.setState({ awaitLogin: true });
    try {
      let isAuthorized = await axios.post(`https://${domain}/login`, {
        email: this.state.Name.trim().toLowerCase(),
        password: this.state.Password.trim(),
      });

      if (isAuthorized.data.message === "TempToken") {
        await this.setState({ awaitLogin: false });
        await this.props.updateToken(isAuthorized.data.token);
        await this.setState({ adminModal: true });
      } else if (isAuthorized.status === 200) {
        await this.setState({ awaitLogin: false });
        await this.setState({ loggedIn: true });

        //await localStorage.setItem("token", isAuthorized.data.token);
        await this.props.updateToken(isAuthorized.data.token);

        this.setState({ redirectToReferrer: true });
      }
    } catch (err) {
      await this.setState({ awaitLogin: false });
      //console.log("what is err", err);
      await this.setState({ loginError: true });
      return this.state.loggedIn;
    } finally {
      //console.log("this.state", this.state);
    }
  };

  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    await this.setState({ loginError: false });
  };

  render() {
    let { from } = this.props.location.state || {
      from: { pathname: "/menu" },
    };
    let { redirectToReferrer } = this.state;
    if (this.state.adminModal) {
      return (
        <AdminModalParent modalState={true}>
          <AdminPick
            closeModal={this.closeModal}
            AdminLoginHelper={this.AdminLoginHelper}
          />
        </AdminModalParent>
      );
    } else if (redirectToReferrer) return <Redirect to={from || "/menu"} />;
    else if (!this.state.awaitLogin) {
      return (
        <div>
          <div className="SignLogin ">
            {domain === "rnexpress.net:88" ? (
              <div>Currently Communicating with: {domain}</div>
            ) : null}
            <form className="classForm" onSubmit={(e) => this.login(e)}>
              <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
              <Input
                label="Name"
                onChange={this.handleChange}
                value={this.state.Name}
                error={this.state.loginError}
              />
              <Input
                label="Password"
                type="password"
                onChange={this.handleChange}
                value={this.state.Password}
                error={this.state.loginError}
                helperText={
                  this.state.loginError ? "Login Error, please try again" : null
                }
              />

              <div>
                <Button
                  type="submit"
                  label="Login"
                  disabled={!this.state.Name || !this.state.Password}
                />
              </div>
            </form>
          </div>
        </div>
      );
    } else if (this.state.awaitLogin) {
      return (
        <div>
          <div className="SignLogin ">
            <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
            <Spin />
          </div>
        </div>
      );
    }
  }
}

export default ConsumerHOC(Login);
