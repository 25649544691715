import React from "react";
import FormWizard from "../FormWizard/FormWizard";
import "./marketing.css";

import marketingUpdateNarrativeQuestionaire from "./marketingUpdateNarrativeQuestionaire.json";

const MarketingNarrativeUpdate = (props) => {
  let { SubmitComponent, pickedAccount, close } = props;
  // console.log(props);

  return (
    <div className="initialProspectParent">
      <div className="ReviewTitle">Marketing: Update Narrative</div>

      <FormWizard
        inputQuestionaire={marketingUpdateNarrativeQuestionaire}
        goBack={() => {
          close();
        }}
        SubmitComponent={SubmitComponent}
        submitAPI="marketing/Narrative"
        relevantData={pickedAccount}
      />
    </div>
  );
};

export default MarketingNarrativeUpdate;
