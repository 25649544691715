import React from "react";
import "./styles.css";

const RowHighLightsKey = () => {
  return (
    <div className="RowHighlightsKeyParent">
      <div className="highlightsKeyTitle"> Row Hightlights Key</div>
      <div className="lowRiskSy">Low and Medium Risk Syringes</div>
      <div className="highRiskSy">High Risk Syringes</div>
      <div className="outsidePharm">Compounded by an Outside Pharmacy</div>
    </div>
  );
};

export default RowHighLightsKey;
