import MyContext from "./Context";
import React from "react";

const Wrapper = (ChildComp) => (props) => (
  <MyContext.Consumer>
    {(context) => {
      return (
        <ChildComp
          {...props}
          token={context.token}
          updateToken={context.updateToken}
          workingCopies={context.workingCopies}
          updateWorkingCopiesFromClient={context.updateWorkingCopiesFromClient}
          updateWorkingCopiesFromServer={context.updateWorkingCopiesFromServer}
          userInfo={context.userInfo}
          updateFiles={context.updateFiles}
          uploadedFiles={context.uploadedFiles}
          //editWorkingCopy={context.editWorkingCopy}
          isValidTokenInLocalStorage={context.isValidTokenInLocalStorage}
          submitUpdate={context.submitUpdate}
          currentReport={context.currentReport}
          changeCurrentReport={context.changeCurrentReport}
          wipeFileList={context.wipeFileList}
          wipeProvider={context.wipeProvider}
          updateTelem={context.updateTelem}
          telemAttached={context.telemAttached}
          updateUserInfo={context.updateUserInfo}
          serverRequest={context.serverRequest}
        />
      );
    }}
  </MyContext.Consumer>
);

export default Wrapper;
