import React, { useState, useEffect } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import ParentStyles from "../ParentStyles.json";
import { cloneDeep } from "lodash";
import { drugArray } from "./RowDataTypes.js";

const gridCells = {
  backgroundColor: "#d8d8d8",
  borderRadius: 5,
  padding: 5,
  margin: 3,
};

const AddToDrugList = (props) => {
  let { closeInputBox } = props;
  let listArray = [];
  let listKey = "";
  //console.log("what is props.data", props.data);
  let [isData, changeisData] = useState(false);

  Object.keys(props.data).forEach((key) => {
    if (drugArray.includes(key)) {
      listArray = cloneDeep(props.data[key]);

      listKey = key;
      if (!isData) {
        changeisData(true);
      }
    }
  });

  //insert this default key to handle cases in which there is no pre-existing drugs
  //Without default being on "drugArray" list, this would create an error.
  if (!isData) {
    listKey = "Therapy";
  }

  let [drugName, toggleDrugName] = useState("");

  useEffect(() => {
    toggleDrugName(drugName);
    if (listArray) {
      listArray.push(drugName);
    }
  }, [drugName, listArray]);

  return (
    <div
      style={{
        //gridColumn: "1 / span 5",
        width: "100%",
        //display: "grid",
        //gridTemplateColumns: "100 100 100 100",

        borderRadius: 10,
        margin: "10, 5 10 5",
        padding: 10,
        color: ParentStyles["darkColor"],
      }}
    >
      <div
        style={gridCells}
        onBlur={async () => {
          if (drugName) {
            //console.log("what is drugName", drugName);
            //console.log("here??", props.title, listKey, listArray);
            await props.editReportData(props.title, listKey, listArray, "edit");
            closeInputBox();
          }
        }}
      >
        <input
          autoFocus
          placeholder="New Medication Name"
          style={{ width: "85%", margin: 5, padding: 5 }}
          onChange={(e) => {
            toggleDrugName(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default ConsumerHOC(AddToDrugList);
