import React, { useState, useEffect } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Card from "@material-ui/core/Card";
import Styles from "../ParentStyles.json";
import Button from "./Button";
import axios from "axios";
import { Info } from "../config.js";
import { EditableYesNo, AddRow } from "./mileageReportWorksheet";
import ModificationRequest from "./ModificationRequest";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";

const MileageReport = (props) => {
  // console.log(props);
  let { modalData, workingCopies } = props;

  let [currentReport, updateCurrentReport] = useState({});

  useEffect(() => {
    if (Object.keys(currentReport).length <= 0) {
      let id = modalData.ID;
      workingCopies.forEach((report) => {
        if (report["_id"] === id) {
          updateCurrentReport(report);
        }
      });
    } else if (modalData.ID !== currentReport["_id"]) {
      let id = modalData.ID;
      workingCopies.forEach((report) => {
        if (report["_id"] === id) {
          updateCurrentReport(report);
        }
      });
    }
  }, [currentReport, modalData.ID, workingCopies, modalData]);

  if (Object.keys(currentReport).length > 0) {
    return (
      <DisplayMileageReport
        {...props}
        currentReport={currentReport}
        updateCurrentReport={updateCurrentReport}
      />
    );
  } else {
    return null;
  }
};

export const DisplayMileageReport = (props) => {
  let {
    currentReport,
    token,
    userInfo,
    modState,
    toggleModState,
    updateWorkingCopiesFromServer,
    workingCopies,
    updateCurrentReport,
    modalData,
    reportSubject,
  } = props;

  // console.log("props", props);
  //used to prevent crash when user begins new report vs import in-progress
  if (!currentReport || !Object.keys(currentReport).length) {
    // console.log("fire in here?");

    currentReport = {
      "Mileage Report Status": "Admin Review",
      mileageReport: {
        TotalTravel: 0,
        reimburseableMileage: 0,
        processedTravelData: [
          {
            reimburseableStart: false,
            rentalCar: false,
            coords: {
              latitude: 40.95892630000001,
              longitude: -74.22666449999999,
            },
            timestamp: new Date(),
            updateType: "start",
          },
        ],
      },
      user: {
        email: reportSubject.email,
        firstName: reportSubject.FNAME,
        lastName: reportSubject.LNAME,
      },
      date: new Date().toISOString(),
    };
  }

  //canEdit dictates whether user can edit a given data point
  let [canEdit, toggleCanEdit] = useState(
    userInfo.permissions === "admin" || userInfo.permissions === "mc"
      ? true
      : false
  );
  let [localReport, updateLocalReport] = useState(currentReport);

  useEffect(() => {
    if (localReport !== currentReport) {
      updateLocalReport(currentReport);
    }
  }, [localReport, currentReport, modalData]);

  let submissionInfo = [
    { label: "Approve", status: "Approved" },
    { label: "Deny", status: "Denied" },
  ];
  if (
    props.userInfo.permissions === "ipmservices" ||
    props.userInfo.permissions === "nurse"
  ) {
    submissionInfo = [{ label: "Submit for Review", status: "Admin Review" }];
  }

  let { reimburseableStart, rentalCar } =
    localReport.mileageReport.processedTravelData[0];

  // console.log("what is reimburseable start", reimburseableStart);
  const reconcileWorkingCopiesAndModify = async (id, workingCopiesArr) => {
    let newArr = [];
    newArr = await workingCopiesArr.filter((report) => id !== report["_id"]);
    //updateParentWorkingCopies(newArr);
    updateWorkingCopiesFromServer(newArr);
  };

  const integrateNewRow = async (newRow, idx = false) => {
    if (newRow === "delete") {
      let newAddyArr = [];
      localReport.mileageReport.processedTravelData.forEach((val, id) => {
        if (id !== idx) {
          newAddyArr.push(val);
        }
      });

      localReport.mileageReport.processedTravelData = newAddyArr;
    } else {
      let formattedCoords = {
        latitude: newRow["coords"].lat,
        longitude: newRow["coords"].lng,
      };

      let timeZone = localReport.timeZone;
      let incomingTimestamp = newRow.time;

      //convert time to milliseconds since epoch
      let timeInMilli = moment.tz(incomingTimestamp, timeZone).valueOf();

      newRow = {
        updateType: newRow.type,
        GPSStamp: { coords: formattedCoords, timestamp: timeInMilli },
      };
      localReport.mileageReport.processedTravelData.push(newRow);

      //must sort all stops by time before sending to server

      let arrOfLocations = localReport.mileageReport.processedTravelData;

      // console.log(localReport);
      if (arrOfLocations.length > 1) {
        arrOfLocations.sort((a, b) => {
          return (
            new Date(a.GPSStamp.timestamp) - new Date(b.GPSStamp.timestamp)
          );
        });
      }

      let newObjectForMileageReport = {
        ...localReport.mileagReport,
        processedTravelData: arrOfLocations,
      };
      let newObjectForLocalReport = {
        ...localReport,
        mileageReport: newObjectForMileageReport,
      };

      updateLocalReport(newObjectForLocalReport);
    }

    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/daily/mileage`,
        data: {
          report: localReport,
          noSave: true,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.data === "err") {
        //console.log("err?");
        throw new Error("Error on submission");
      } else {
        //updateLocalReport(results.data);
        //passDataToModal(results.data);
        updateCurrentReport(results.data);
      }
    } catch (err) {
      //console.log("what is err", err);
      return err;
    }
  };

  let reimburseableMileage;

  if (localReport.mileageReport.processedTravelData[0].rentalCar) {
    reimburseableMileage = 0;
  } else if (!reimburseableStart) {
    reimburseableMileage =
      localReport.mileageReport.totalTravel - 50 < 0
        ? 0
        : localReport.mileageReport.totalTravel - 50;
  } else {
    reimburseableMileage = localReport.mileageReport.totalTravel;
  }

  const handleSubmit = async (status) => {
    // console.log("what is status", status);
    localReport.status = status;
    // console.log("localReport", localReport);

    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/daily/revisedMileagereports`,
        data: {
          localReport,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.data === "err") {
        //console.log("err?");
        throw new Error("Error on submission");
      } else {
        //console.log("here???");
        reconcileWorkingCopiesAndModify(localReport["_id"], workingCopies);
        props.toggleModState(false);
      }
    } catch (err) {
      //console.log("hit an error in submitting update", err);
    }
  };

  return (
    <Modal open={modState} style={{ overflow: "scroll" }}>
      <MileageUpdateWorkSheet
        localReport={localReport}
        reimburseableStart={reimburseableStart}
        canEdit={canEdit}
        updateLocalReport={updateLocalReport}
        updateCurrentReport={updateCurrentReport}
        rentalCar={rentalCar}
        reimburseableMileage={reimburseableMileage}
        integrateNewRow={integrateNewRow}
        currentReport={currentReport}
        submissionInfo={submissionInfo}
        handleSubmit={handleSubmit}
        toggleModState={toggleModState}
      />
    </Modal>
  );
};

export const MileageUpdateWorkSheet = (props) => {
  let {
    localReport,
    reimburseableStart,
    canEdit,
    updateLocalReport,
    updateCurrentReport,
    rentalCar,
    reimburseableMileage,
    integrateNewRow,
    currentReport,
    submissionInfo,
    handleSubmit,
    toggleModState,
  } = props;

  const buttonStyle = { margin: "20px" };
  const section = { margin: 20, padding: 10, textAlign: "center" };
  const headers = {
    fontSize: 15,
    fontWeight: "bold",
    backgroundColor: Styles["bgGray"],
    padding: 5,
    margin: 10,
    borderRadius: 10,
  };
  return (
    <Card style={{ margin: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
        <div
          style={{
            ...section,
            fontWeight: "bold",
            backgroundColor: Styles["darkColor"],
            fontSize: 30,
            color: "white",
            borderRadius: 10,
          }}
        >
          Mileage Report
        </div>
        <div
          className="reportDetails"
          style={{
            ...section,
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto",
          }}
        >
          <div>
            <div style={headers}>Name:</div> {localReport.user.lastName},{" "}
            {localReport.user.firstName}
          </div>
          <div>
            <div style={headers}>Date:</div>{" "}
            {localReport.date.split("T")[0]
              ? localReport.date.split("T")[0]
              : "n/a"}
          </div>
          <div>
            <div style={headers}>Report Processing Status: </div>
            {localReport["Mileage Report Status"]}
          </div>

          <div>
            <div style={headers}>Start From Reimburseable Location:</div>{" "}
            <EditableYesNo
              canEdit={canEdit}
              data={reimburseableStart}
              update={() => {
                let cloneLocalReport = cloneDeep(localReport);
                cloneLocalReport.mileageReport.processedTravelData[0].reimburseableStart =
                  !cloneLocalReport.mileageReport.processedTravelData[0]
                    .reimburseableStart;

                updateCurrentReport(cloneLocalReport);
              }}
              report={localReport}
            />
          </div>
          <div>
            <div style={headers}>Rental Car:</div>
            <EditableYesNo
              canEdit={canEdit}
              data={rentalCar}
              update={() => {
                let cloneLocalReport = cloneDeep(localReport);
                cloneLocalReport.mileageReport.processedTravelData[0].rentalCar =
                  !cloneLocalReport.mileageReport.processedTravelData[0]
                    .rentalCar;

                updateCurrentReport(cloneLocalReport);
                ////console.log("what is local report", localReport);
              }}
              report={localReport}
            />
          </div>
          <div>
            <div style={headers}>Total Mileage:</div>{" "}
            {Math.round(10 * localReport.mileageReport.totalTravel) / 10}
          </div>

          <div>
            <div style={headers}> Reimburseable Mileage:</div>

            {Math.round(10 * reimburseableMileage) / 10}
          </div>
          <div>
            <div style={headers}> Was Report Modified?:</div>

            {localReport.isModified ? "Yes" : "No"}
          </div>
          <div>
            <div style={headers}>Report Modification Requested By Nurse: </div>
            {localReport.user["modificationRequest"] ? "Yes" : "No"}
          </div>

          <div>
            <div style={headers}>Paid: </div>
            <EditableYesNo
              canEdit={canEdit}
              data={localReport.Paid}
              update={() => {
                let cloneLocalReport = cloneDeep(localReport);
                cloneLocalReport.Paid = !cloneLocalReport.Paid;

                updateCurrentReport(cloneLocalReport);
              }}
              report={localReport}
            />
          </div>
        </div>
        <div style={section}>
          <VisitBreakDownChart
            localReport={localReport.mileageReport.processedTravelData}
            timeZone={localReport.timeZone}
            integrateNewRow={integrateNewRow}
            canEdit={canEdit}
          />
        </div>
        <div>
          <ModificationRequest
            section={section}
            currentReport={currentReport}
            updateLocalReport={updateLocalReport}
            canEdit={canEdit}
          />
        </div>

        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {submissionInfo.map((obj, idx) => {
            // if (localReport["Mileage Report Status"] !== obj.status) {
            return (
              <Button
                key={idx}
                label={obj.label}
                style={buttonStyle}
                func={handleSubmit}
                data={obj.status}
              />
            );
            // }
          })}
          <Button
            label={"Cancel"}
            style={buttonStyle}
            func={() => {
              toggleModState(false);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

const VisitBreakDownChart = (props) => {
  let { localReport, timeZone, token, integrateNewRow, canEdit } = props;
  //can edit prop dictates if you user can act on a given feature.

  // console.log("what is props?", props);
  let headerStyle = {
    fontWeight: "bold",
    fontSize: 20,
    color: "white",
    padding: 10,
    margin: 10,
    borderRadius: 5,
    backgroundColor: Styles["darkColor"],
  };

  let cellStyle = {
    fontSize: 15,
    color: Styles["darkColor"],
    padding: 10,
    margin: 10,
    borderRadius: 5,
    backgroundColor: "white",
  };
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto 4% ",
        }}
      >
        <div style={headerStyle}>Type</div> <div style={headerStyle}>Time</div>{" "}
        <div style={headerStyle}>Address</div>
        <div style={headerStyle}>Distance To Location (miles)</div>
        <div> </div>
        {/* ensure that this is an existing report and not a newly created one.  */}
        {localReport.length > 1
          ? localReport.map((stop, key) => {
              let capturedTime = stop.GPSStamp.timestamp;

              let UTCToTZ = moment.tz(capturedTime, timeZone);
              let formattedTime = UTCToTZ.format("MMMM Do YYYY, h:mm A");
              return (
                <React.Fragment key={key}>
                  <div style={cellStyle}>{stop.updateType}</div>
                  <div style={cellStyle}>{formattedTime}</div>
                  <div style={cellStyle}>{stop.locationAddress}</div>
                  <div style={cellStyle}>
                    {stop.distanceTo
                      ? Math.round(10 * stop.distanceTo) / 10
                      : 0}{" "}
                  </div>
                  <div style={cellStyle}>
                    {canEdit ? (
                      <HoverDelRow
                        onClick={() => {
                          //console.log("hit here???");
                          integrateNewRow("delete", key);
                        }}
                      >
                        <TiDelete />
                      </HoverDelRow>
                    ) : null}
                  </div>
                </React.Fragment>
              );
            })
          : null}
        <div style={{ gridColumn: "1 / -1" }}>
          {canEdit ? (
            <AddRow token={token} integrateNewRow={integrateNewRow} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const HoverDelRow = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 40px;
  textalign: left;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

export default ConsumerHOC(MileageReport);
