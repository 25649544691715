import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Login from "./components/Login";
import decode from "jwt-decode";
import NavBar from "./components/NavBar";
import Menu from "./components/Menu";
import Reports from "./components/Reports";
import ViewReport from "./components/ViewReport";
import ConsumerHOC from "./context/ConsumerHOC";
import ViewProgressNote from "./components/ViewProgressNote";
import AddProgressReport from "./components/ProgressReportComponents/AddProgressReport";
import ReportsTable from "./components/ReportsTable";
import PerformanceMetrics from "./components/AnalyticsPanel/PerformanceMetrics";
import AdmissionReport from "./components/AnalyticsPanel/AdmissionReport";
import ClinicalMetrics from "./components/AnalyticsPanel/ClinicalMetrics";
import SystemTools from "./components/SystemTools/SystemTools";
import mileageReport from "./components/mileageReport";
import DemographicsMap from "./components/DemographicMapping/DemographicMap";
import MileageTrackerControls from "./components/MileageTrackerControls/MileageTrackerControlsParent";
import Marketing from "../src/components/Marketing/MarketingParent";
import VerbalOrder from "./components/VOConfirmation/VOConfirmationParent";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import Census from "./components/AnalyticsPanel/PatientCensus";
import Dashboard from "./components/Dashboard/Dashboard_cc";
import Patients from "./components/Patients/Patients";

//check whether we're authenticated
const checkAuth = async (props) => {
  let { token, isValidTokenInLocalStorage, updateToken } = props;
  //must decode token and determine if its expired
  try {
    if (!token) {
      token = await isValidTokenInLocalStorage();

      //need to place Local Storage Token into state as token
      if (token) {
        await updateToken(token);
      } else {
        return false;
      }
    }

    const { exp } = await decode(token);

    //if expired return false
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }

  return true;
};

const NoMatch = () => <h3> 404: Resource not found </h3>;

const Public = () => <h3>Public</h3>;
const Protected = () => <h3>Protected</h3>;
const OtherProtected = () => <h3>OtherProtected</h3>;



// const AuthRoute = ({ component: Component, context, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       checkAuth(context) ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/login",
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );
const AuthRoute = ({ component: Component, context, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth(context) ? (
          <Component {...props} {...context} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
function AuthExample(props) {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        {/* <Route
          path="/confirmations/*"
          render={(props) => (
            <UpcomingVisitConfirmReject {...props} title="Visit Confirmation" />
          )}
        />
        <Route
          path="/reschedule/*"
          render={(props) => (
            <UpcomingVisitConfirmReject
              {...props}
              title="Visit Reschedule Request"
            />
          )}
        /> */}
        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route exact path="/login" render={(props) => <Login {...props} />} />

        <AuthRoute exact path="/menu" component={Menu} context={props} />
        <AuthRoute
          exact
          path="/ReportsTable"
          component={ReportsTable}
          context={props}
        />
        <AuthRoute exact path="/Reports" component={Reports} context={props} />
        <AuthRoute
          exact
          path="/MileageTrackerControls"
          component={MileageTrackerControls}
          context={props}
        />
        <AuthRoute
          exact
          path="/Marketing"
          component={Marketing}
          context={props}
        />

        <AuthRoute
          exact
          path="/PerformanceMetrics"
          component={PerformanceMetrics}
          context={props}
        />
        <AuthRoute
          exact
          path="/AdmissionReport"
          component={AdmissionReport}
          context={props}
        />
        <AuthRoute
          exact
          path="/ClinicalMetrics"
          component={ClinicalMetrics}
          context={props}
        />
        <AuthRoute
          exact
          path="/SystemTools"
          component={SystemTools}
          context={props}
        />
        <AuthRoute
          exact
          path="/OpenProgressNotes"
          component={Reports}
          context={props}
        />
        <AuthRoute
          exact
          path="/ViewReport"
          component={ViewReport}
          context={props}
        />
        <AuthRoute
          exact
          path="/mileageReport"
          component={mileageReport}
          context={props}
        />
        <AuthRoute
          exact
          path="/VerbalOrder"
          component={VerbalOrder}
          context={props}
        />
        <AuthRoute
          exact
          path="/AddProgressReport"
          component={AddProgressReport}
          context={props}
        />

        <AuthRoute
          exact
          path="/ViewProgressNote"
          component={ViewProgressNote}
          context={props}
        />
        <AuthRoute
          exact
          path="/patients"
          component={Patients}
          context={props}
          />
        <AuthRoute
          exact
          path="/DemographicsMap"
          component={DemographicsMap}
          context={props}
        />
        <AuthRoute
          exact
          path="/AdminPanel"
          component={AdminPanel}
          context={props}
        />
        <AuthRoute exact path="/Census" component={Census} context={props} />
        <AuthRoute
          exact
          path="/dashboard"
          component={Dashboard}
          context={props}
        />
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );
}

export default ConsumerHOC(AuthExample);
