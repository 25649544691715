import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "./Styling.css";
import ConsumerHOC from "../../context/ConsumerHOC";
import ModifyScheduleTableParent from "./ModifyScheduleTableParent";

const NursingScheduleCalendar = (props) => {
  let [Val, changeVal] = useState(new Date());
  //let { workingCopies } = props;
  // console.log(props);

  let [workingCopies, updateWorkingCopies] = useState(props.workingCopies);

  //entire list of upcoming visits
  let [upcomingVisits, updateUpcomingVisits] = useState({});

  let [ViewState, toggleViewState] = useState(false);

  const clickHandler = (e) => {
    changeVal(e);
    let date = new Date(e).toISOString().split("T")[0];

    toggleViewState(upcomingVisits[date]);
  };

  //displays values in each calendar date
  const tileContent = ({ date, view }) => {
    let dateString = new Date(date).toISOString().split("T")[0];
    let AllDaysVisits = upcomingVisits[dateString];

    let totalVisitsNotAlarm = 0;
    let totalVisitsNotAlarmModified = 0;
    let totalAlarm = 0;
    let totalAlarmModified = 0;

    let visitCountStyle = {
      fontWeight: 100,
    };

    if (view === "month" && Array.isArray(AllDaysVisits)) {
      //count each modified visit
      AllDaysVisits.forEach((visit) => {
        if (
          visit.TEXT_ !== "Alarm Date" &&
          visit.TEXT_ !== "Potential Alarm Date" &&
          visit.Note
        ) {
          totalVisitsNotAlarmModified += 1;
        } else if (
          visit.TEXT_ !== "Alarm Date" &&
          visit.TEXT_ !== "Potential Alarm Date" &&
          !visit.Note
        ) {
          totalVisitsNotAlarm += 1;
        } else if (
          (visit.TEXT_ === "Alarm Date" ||
            visit.TEXT_ === "Potential Alarm Date") &&
          visit.Note
        ) {
          totalAlarmModified += 1;
        } else {
          totalAlarm += 1;
        }
      });
      return (
        <div className="modified_grid_counter">
          <div>Visits</div>
          <div
            style={{
              ...visitCountStyle,
              // backgroundColor: ParentStyles["darkColor"],
            }}
          >
            {totalVisitsNotAlarm}
          </div>

          <div
            style={{
              ...visitCountStyle,
              // backgroundColor: ParentStyles["lightColor"],
            }}
          >
            {totalVisitsNotAlarmModified}
          </div>
          <div>Alarms</div>
          <div
            style={{
              ...visitCountStyle,
              // backgroundColor: ParentStyles["darkColor"],
            }}
          >
            {totalAlarm}
          </div>
          <div
            style={{
              ...visitCountStyle,
              // backgroundColor: ParentStyles["darkColor"],
            }}
          >
            {totalAlarmModified}
          </div>
        </div>
      );
    } else {
      return <p>0</p>;
    }
  };

  useEffect(() => {
    let groupedByDate = groupByDate(workingCopies);
    let groupedByDateLength = Object.keys(groupedByDate);

    if (groupedByDateLength.length !== Object.keys(upcomingVisits).length) {
      groupedByDate = groupByDate(workingCopies);
      updateUpcomingVisits(groupedByDate);
    }

    //done to ensure rerender on change to date, there is likely a cleaner way to do this.
    if (props.workingCopies !== workingCopies) {
      // console.log("change!!");
      updateWorkingCopies(props.workingCopies);
      groupedByDate = groupByDate(props.workingCopies);
      updateUpcomingVisits(groupedByDate);
    }
  }, [
    workingCopies,
    upcomingVisits.length,
    upcomingVisits,
    props.workingCopies,
  ]);

  if (!ViewState) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexBasis: "auto",
          justifyContent: "center",
          alignItems: "flexStart",
        }}
      >
        <Calendar
          onChange={(e) => clickHandler(e)}
          value={Val}
          tileContent={tileContent}
          calendarType={"US"}
        />
      </div>
    );
  } else if (ViewState) {
    return (
      <ModifyScheduleTableParent
        ViewState={ViewState}
        toggleViewState={toggleViewState}
      />
    );
  }
};

export default ConsumerHOC(NursingScheduleCalendar);

const groupByDate = (listOfVisits) => {
  if (!Array.isArray(listOfVisits)) {
    return {};
  }
  let objOfDates = {};
  listOfVisits.forEach((visit) => {
    let date = visit.DUEDATE.split("T")[0];
    if (objOfDates[date]) {
      objOfDates[date].push(visit);
    } else {
      objOfDates[date] = [visit];
    }
  });

  return objOfDates;
};
