import React, { useEffect, useState } from "react";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import { Info } from "../../config.js";

import Paper from "@material-ui/core/Paper";

const domain = Info.domain;

const ClinicalMetrics = (props) => {
  let [avgFlowRateErr, updateAvgFlowRateErr] = useState("No Data");
  let [oxygenNums, updateOxygenNums] = useState("No Data");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const getFlowRateErrAvg = async (dateRange) => {
    const { startDate, endDate } = dateRange;
    const config = {
      method: "get",
      url: `https://${domain}/analytics/FlowRateErrAvg`,
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    };

    if (startDate && endDate) {
      config.params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    }

    let res = await axios(config);
    console.log("REPSONSE FlowRateErrAvg", res);
    return res;
  };

  const getOxygenNumbers = async (dateRange) => {
    const { startDate, endDate } = dateRange;
    const config = {
      method: "get",
      url: `https://${domain}/analytics/oxygen`,
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    };

    if (startDate && endDate) {
      config.params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    }

    let res = await axios(config);
    console.log("RESPONSE oxygen", res);
    return res;
  };

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getFlowRateErrAvg(dateRange).then((res) => {
        updateAvgFlowRateErr(res.data);
      });

      getOxygenNumbers(dateRange).then((res) => {
        updateOxygenNums(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <>
      <DateRangeInput setDateRange={setDateRange} />
      <BasicNumsParent parentData={avgFlowRateErr} title="Flow Rate Numbers" />
      <BasicNumsParent parentData={oxygenNums} title="Oxygen Usage" />
    </>
  );
};

const BasicNumbersChild = (props) => {
  //console.log(props);
  let key = props.objKey;
  return (
    <div style={{ alignItems: "center", margin: 20 }}>
      <div>{key}</div>
      <div style={{ textAlign: "center" }}>{props.parentData[key]}</div>
    </div>
  );
};

const BasicNumsParent = (props) => {
  let { parentData, title } = props;
  console.log("PARENT DATE: ", parentData);
  return (
    <Paper>
      <div style={{ margin: 30 }}>
        {title}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {Object.keys(parentData).map((key) => {
            return (
              <BasicNumbersChild
                key={key}
                objKey={key}
                {...props}
                parentData={parentData}
              />
            );
          })}
        </div>
      </div>
    </Paper>
  );
};
const DateRangeInput = ({ setDateRange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const isValid = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  const handleSubmit = () => {
    if (isValid(startDate) && isValid(endDate)) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      setDateRange({ startDate: startDateObj, endDate: endDateObj });
    } else {
      alert("Please enter valid dates in the format YYYY-MM-DD");
    }
  };

  return (
    <div style={style.dateInputContainer}>
      <input
        style={style.dateInputField}
        type="text"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        placeholder="YYYY-MM-DD"
      />
      <input
        style={style.dateInputField}
        type="text"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        placeholder="YYYY-MM-DD"
      />
      <button
        style={style.submitButton}
        onMouseEnter={(e) =>
          (e.target.style.backgroundColor =
            style.submitButtonHover.backgroundColor)
        }
        onMouseLeave={(e) =>
          (e.target.style.backgroundColor = style.submitButton.backgroundColor)
        }
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default ConsumerHOC(ClinicalMetrics);

const style = {
  dateInputContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  dateInputField: {
    margin: "0 5px",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "14px",
  },
  submitButton: {
    marginLeft: "10px",
    padding: "8px 12px",
    backgroundColor: "#1976d2",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "14px",
    cursor: "pointer",
  },
  submitButtonHover: {
    backgroundColor: "#1565c0",
  },
};
