import MyContext from "./Context";
import React, { Component } from "react";
import decode from "jwt-decode";

import { cloneDeep } from "lodash";
import axios from "axios";
import { Info } from "../config.js";
const domain = Info.domain;

const initialState = {
  token: null,
  workingCopies: [],
  userInfo: {},
  uploadedFiles: [],
  currentReport: {},
  telemAttached: false,
};

class MyProvider extends Component {
  state = initialState;

  render() {
    return (
      <MyContext.Provider
        value={{
          uploadedFiles: this.state.uploadedFiles,
          token: this.state.token,
          userInfo: this.state.userInfo,
          workingCopies: this.state.workingCopies,
          currentReport: this.state.currentReport,
          telemAttached: this.state.telemAttached,
          updateTelem: async (val) => {
            await this.setState({ telemAttached: val });
          },

          wipeProvider: async () => {
            await this.setState(initialState);
          },

          changeCurrentReport: async (newReport) => {
            // console.log("what is new report", newReport);
            //console.log("in provider", newReport);
            await this.setState({ currentReport: newReport });
            //console.log("what is currebnt report", this.state.currentReport);
            return newReport;
          },
          updateWorkingCopiesFromServer: async (newCopy) => {
            //console.log("what is newcopy", newCopy);
            await this.setState({ workingCopies: newCopy });
          },

          //updates working copy on client side by replacing old array index with new one
          updateWorkingCopiesFromClient: async (entry, id, status = null) => {
            let newWorkingCopy = cloneDeep(this.state.workingCopies);
            newWorkingCopy.forEach((origEntry, id) => {
              if (origEntry._id === id) {
                newWorkingCopy[id] = entry;
              }
            });
            await this.setState({ workingCopies: newWorkingCopy });
            // console.log("new working copies", newWorkingCopy);
          },
          //update UserInfo
          updateUserInfo: async (newUserInfo) => {
            this.setState({ userInfo: newUserInfo });
          },

          isValidTokenInLocalStorage: async () => {
            let token = await localStorage.getItem("token");
            let decoded = decode(token);

            if (token && decoded.exp > new Date().getTime() / 1000) {
              this.setState({ userInfo: decode(token) });
              return token;
            } else {
              // console.log("token is no good!");
              return false;
            }
          },

          //updates token and also decodes token for user info
          updateToken: async (newToken) => {
            localStorage.setItem("token", newToken);
            await this.setState({ token: newToken });
            let decoded = await decode(this.state.token);
            //console.log(decoded);
            await this.setState({ userInfo: decoded });
          },

          updateFiles: async (files) => {
            //console.log("what is files", files);
            // let arr = [...this.state.uploadedFiles, ...files];
            // await this.setState({
            //   uploadedFiles: [...new Set(arr)]
            // });
            this.setState({ uploadedFiles: files });
          },

          wipeFileList: async () => {
            await this.setState({ uploadedFiles: [] });
          },
          //updates data on server
          submitUpdate: async (
            data,
            status = null,
            isProgressNote = false,
            initialSubmitToWeeklys = false,
            isSave = false
          ) => {
            if (initialSubmitToWeeklys) {
              data["InitialSubmitToWeeklys"] = "grabTime";
            }
            if (status !== null) {
              data["status"] = status;
            }

            data["isProgressNote"] = isProgressNote;

            try {
              //isSave demonstrates if submission is simply saving updates
              //will not allow server to send email if this is the case
              let results = await axios({
                method: "post", // you can set what request you want to be
                url: `https://${domain}/submission/submitRevision`,
                data: {
                  isSave,
                  data,
                },
                headers: {
                  Authorization: `bearer ${this.state.token}`,
                },
              });

              return results;
            } catch (err) {
              // console.log("hit an error in submitting update", err);
            }
          },
          serverRequest: async (data, api, method) => {
            try {
              let results = await axios({
                method: method, // you can set what request you want to be
                url: `https://${domain}/${api}`,
                data: { ...data },
                headers: {
                  Authorization: `bearer ${this.state.token}`,
                },
              });

              return results;
            } catch (err) {
              //console.log("hit an error in submitting update", err);
              return err;
            }
          },
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    );
  }
}

export default MyProvider;
