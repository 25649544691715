import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "./Button";
import { withRouter } from "react-router-dom";
import ConsumerHOC from "../context/ConsumerHOC";
import Input from "./Input";
import DropDown from "./DropDown";
import { Info } from "../config.js";
import DatePicker from "./MatUIDatePicker";
import Styles from "../ParentStyles.json";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";
import "./SearchCompressedUI.css";

const domain = Info.domain;

const HoverText = styled.span`
  font-size: 45px;
  color: ${Styles["darkColor"]};

  :hover {
    color: ${Styles["intermediateColor"]};

    cursor: pointer;
  }
`;
//Can probably delete
// const useStyles = makeStyles({
//   card: {
//     flexBasis: "25%",
//     flex: 1,
//     margin: 5,
//     padding: 5,
//     alignItems: "center",
//   },

//   title: {
//     fontSize: 9,
//   },
//   cardContent: {
//     padding: 1,
//     width: "75%",
//     alignItems: "center",
//     margin: "auto",
//   },
//   button: {
//     margin: "auto",
//     padding: 20,
//     "&:hover": {
//       //you want this to be the same as the backgroundColor above
//       backgroundColor: "#439372",
//       //backgroundColor: "red"
//     },
//   },
//   input: {
//     display: "none",
//   },
// });

function SearchCompressedUI(props) {
  //console.log(props);
  let {
    boxes,
    options,
    editSearchTerms,
    addBox,
    searchStatus,
    handleSubmit,
    DeleteFromSearchTerms,
    type,
  } = props;

  return (
    <>
      <Card className="card">
        <div className="FlexSearchCardParent">
          <div className="FlexTitle" style={{ color: Styles["darkColor"] }}>
            Search Reports
          </div>

          <div className="ChildFlexSearchRow">
            {Array.isArray(boxes)
              ? boxes.map((x, idx) => {
                  return (
                    <SearchRow
                      key={idx}
                      editSearchTerms={editSearchTerms}
                      id={idx}
                      options={options}
                      boxes={boxes}
                      DeleteFromSearchTerms={DeleteFromSearchTerms}
                      type={type}
                    />
                  );
                })
              : null}
          </div>
          <div className="ChildFlexButtons">
            <div className="ChildOfChildFlexButtons">
              {" "}
              <Button
                label="     Search     "
                func={() => handleSubmit()}
                disabled={!searchStatus}
              />{" "}
            </div>

            <div
              className="ChildOfChildFlexButtons"
              onClick={() => {
                let clonebox = [...boxes];
                clonebox.push({ "Please select": null });
                addBox(clonebox);
              }}
            >
              <Button label="+ Add Search Term" />
            </div>
          </div>
        </div>
      </Card>
      <div style={{ fontSize: 11 }}>
        Note: All searches are case insensitive, and will return results from
        partial inputs.
      </div>
    </>
  );
}

const SearchRow = (props) => {
  //console.log(props);
  let { DeleteFromSearchTerms, id, type, boxes } = props;
  let dates = [
    "visitStartAfter",
    "visitStartBefore",
    "Uploaded Before Date",
    "Uploaded After Date",
    "DayStartBefore",
    "DayStartAfter",
  ];

  let [category, newCategory] = useState(null);
  let [searchTerm, newSearchTerm] = useState(null);

  const changeCategory = async (input) => {
    await newCategory(input.value);
    props.editSearchTerms(input.value, searchTerm, props.id, type);
  };
  const changeSearchTerm = async (input) => {
    if (input.label) {
      input = input.value;
    } else if (input.target && input.target.value) {
      input = input.target.value;
    }

    await newSearchTerm(input);
    props.editSearchTerms(category, input, props.id, type);
  };

  // useEffect();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "3% 40% 40%",
        justifyContent: "center",
        gridColumnGap: "3%",
        alignContent: "start",
        width: "100%",
      }}
    >
      <div style={{ alignItems: "center" }}>
        {boxes.length === 1 && Object.keys(boxes[0]).length === 0 ? null : (
          <HoverText>
            {" "}
            <TiDelete onClick={() => DeleteFromSearchTerms(id)} />
          </HoverText>
        )}
      </div>

      <div>
        <DropDown
          list={props.options}
          onChange={changeCategory}
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <SearchTermComponents
          dates={dates}
          category={category}
          changeSearchTerm={changeSearchTerm}
          changeCategory={changeCategory}
        />
      </div>
    </div>
  );
};

const DatePick = (props) => {
  return <DatePicker {...props} />;
};

const SearchTermComponents = (props) => {
  //console.log("what is props here", props);
  let dates = props.dates;
  let category = props.category;
  let changeSearchTerm = props.changeSearchTerm;

  //console.log("category", category);
  let statuses = [
    {
      status: "working copy",
      value: "working copy",
      label: "Nurse Account: Not yet submitted to Weekly's ('working copy')",
    },
    {
      status: "Revised",
      value: "Revised",
      label: "Returned to Weekly's after secondary changes by Nurse (Revised)",
    },
    {
      status: "OpenMC",
      value: "OpenMC",
      label: "Submitted to Weekly's (OpenMC)",
    },
    {
      status: "Finalized",
      value: "Finalized",
      label: "Approved ('Finalized')",
    },
    {
      status: "Returned",
      value: "Returned",
      label: "Returned to Nurse for requested changes ('Returned')",
    },
    {
      status: "Discard",
      value: "Discard",
      label: "Report was deemed unnecessary and removed from pool ('Discard') ",
    },
  ];

  const mileageStatuses = [
    {
      status: "unreviewed",
      value: "unreviewed",
      label: "unreviewed",
    },
    {
      status: "Nurse Review",
      value: "Nurse Review",
      label: "Nurse Review",
    },
    {
      status: "Admin Review",
      value: "Admin Review",
      label: "Admin Review",
    },
    {
      status: "Denied",
      value: "Denied",
      label: "Denied",
    },
    {
      status: "Approved",
      value: "Approved",
      label: "Approved",
    },
  ];

  const departments = [
    {
      status: "monitoringcenter@basichi.com",
      value: "monitoringcenter@basichi.com",
      label: "Monitoring Center",
    },
    {
      status: "scheduling@basichi.com",
      value: "scheduling@basichi.com",
      label: "Scheduling",
    },
    {
      status: "weeklys@basichi.com",
      value: "weeklys@basichi.com",
      label: "Weeklys",
    },
    {
      status: "intake@basichi.com",
      value: "intake@basichi.com",
      label: "Intake",
    },
    {
      status: "pharmacyalert@basichi.com",
      value: "pharmacyalert@basichi.com",
      label: "Pharmacy",
    },
    {
      status: "callcenter@basichi.com",
      value: "callcenter@basichi.com",
      label: "Call Center",
    },
  ];

  // "monitoringcenter@basichi.com",
  // "scheduling@basichi.com",
  // "weeklys@basichi.com",
  // "intake@basichi.com",
  // "pharmacyalert@basichi.com",

  //console.log("what is category", category);
  if (dates.includes(category)) {
    return <DatePick updateDate={changeSearchTerm} />;
  } else if (category === "Mileage Report Status") {
    return (
      <DropDown
        list={mileageStatuses}
        onChange={changeSearchTerm}
        style={{ width: "100%" }}
      />
    );
  } else if (category === "status") {
    return (
      <DropDown
        list={statuses}
        onChange={changeSearchTerm}
        style={{ width: "100%" }}
      />
    );
  } else if (category === "Email") {
    return (
      <DropDown
        list={departments}
        onChange={changeSearchTerm}
        style={{ width: "100%" }}
      />
    );
  } else {
    return (
      <Input
        onChange={changeSearchTerm}
        fullWidth={false}
        style={{ width: "100%", marginBottom: 20 }}
      />
    );
  }
};

export default ConsumerHOC(withRouter(SearchCompressedUI));
