import React, { useState } from "react";
import { cloneDeep } from "lodash";
import Button from "../Button";
import TableCell from "@material-ui/core/TableCell";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";

const ButtonCell = (props) => {
  // console.log(props);
  let { row, id, header, viewReport, updateBillingStatus } = props;
  let { BillingStatus } = row;

  let [billStat, updateBillStat] = useState(BillingStatus);
  let [isError, toggleIsError] = useState(false);
  let [isSuccess, toggleIsSuccess] = useState(false);

  const modifyBillingStatus = async (data) => {
    // console.log(data);
    data = cloneDeep(data);
    if (data.RescheduleLock) {
      toggleIsError(
        "Pharmacy Dept. has set Compounding Lock: You must remove Compounding Lock first"
      );
      return;
    }
    //prepare data to send to db
    data.BillingStatus = !billStat;
    data.value = data.BillingStatus;
    data.visitID = data.VisitNum;
    // console.log("What is data", data);
    let results = await updateBillingStatus(data);

    if (results) {
      // console.log("we have results?", results);
      updateBillStat(!billStat);
      toggleIsSuccess(true);
    } else {
      // console.log("toggling error!!");
      toggleIsError(
        "An error occured when submitting your update, please contact IT"
      );
    }
  };

  return (
    <TableCell
      key={id + header.id}
      component="th"
      // /id={labelId}
      scope="row"
      //padding="none"
    >
      <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
        {row.reportStatus === "complete" ? (
          <div className="ViewReportBtn">
            <Button label={"View Report"} data={row} func={viewReport} />
          </div>
        ) : (
          <div>PCF Report Status: {row.reportStatus}</div>
        )}

        <div className="toggleBillingBtn">
          {isError ? (
            <div style={{ backgroundColor: "red", color: "white" }}>
              {isError}
            </div>
          ) : null}

          <div
            style={
              isSuccess ? { backgroundColor: "green", color: "white" } : null
            }
          >
            {billStat
              ? "Refill approved for compounding"
              : "Refill NOT Approved"}
          </div>
          <div>
            {billStat ? (
              <FaRegCheckCircle
                className="clickToUnapprove"
                onClick={() => {
                  modifyBillingStatus(row);
                }}
              />
            ) : (
              <FaRegCircle
                className="clickToApprove"
                onClick={() => {
                  modifyBillingStatus(row);
                }}
              />
            )}
          </div>
          {/* <Button
            label={billStat ? "Click to Unapprove" : "Click to Approve"}
            data={row}
            func={modifyBillingStatus}
            // disabled={btnStatus ? false : true}
          /> */}
        </div>
      </div>
    </TableCell>
  );
};

export default ButtonCell;
