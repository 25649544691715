//const moment = require("moment");

const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Report Status",
  },
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  {
    id: "UploadDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
  { id: "VisitID", numeric: true, disablePadding: false, label: "Visit ID" },
  {
    id: "VisitStart",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Start",
  },
  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },
];

const createData = function (
  PatientName,
  Status,
  NurseName,
  UploadDate,
  VisitID,
  VisitStart,
  ID,
  VisitType
) {
  // console.log(
  //   PatientName,
  //   Status,
  //   NurseName,
  //   UploadDate,
  //   VisitID,
  //   VisitStart,
  //   ID,
  //   VisitType
  // );
  return {
    PatientName,
    Status,
    NurseName,
    UploadDate,
    VisitID,
    VisitStart,
    ID,
    VisitType,
  };
};

const rowMapper = (incoming) => {
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let fullName;
  let nurseName;
  let uploadDate;
  let visitID;
  let visitStart;
  let ID;
  let Status;
  let visitType;

  incoming.forEach((row, id) => {
    try {
      let patientInformation;
      let firstName;
      let lastName;
      if (
        row.visitData &&
        row.visitData["Patient Information"].data["Demographics"].data
      ) {
        patientInformation =
          row.visitData["Patient Information"].data["Demographics"].data;
        firstName = patientInformation[0].FIRST_NAME;
        lastName = patientInformation[1].LAST_NAME;
      } else if (row.visitData) {
        patientInformation =
          row.visitData["Patient Information"].data["Demographics"];
        firstName = patientInformation.FIRST_NAME;
        lastName = patientInformation.LAST_NAME;
      }

      fullName = lastName + ", " + firstName;
      let nurseFirstName = row.submitterData.firstName;
      let nurseLastName = row.submitterData.lastName;
      nurseName = nurseLastName + ", " + nurseFirstName;
      Status = row.status;

      uploadDate = row.InitialUploadAt;
      visitID = row.visitID;
      visitStart = row.visitStart;
      ID = row["_id"];
      visitType = row.visitData["Visit Type"].data["Visit Type"];
    } catch (err) {
      //console.log("what is err", err);
    } finally {
      let rowObj = createData(
        fullName ? fullName : "err",
        Status ? Status : "err",
        nurseName ? nurseName : "err",
        uploadDate ? uploadDate : "err",
        visitID ? visitID : "err",
        visitStart ? visitStart : "err",
        ID ? ID : "err",
        visitType ? visitType : "err"
      );
      returnArr.push(rowObj);
    }
  });

  return returnArr;
};

const colorRow = (type, row) => {
  //console.log(type, row);
  if (type === "PatientReports") {
    if (row.Status === "Returned") {
      return "#94b8b8";
    } else if (row.Status === "Revised") {
      return "#40bf40";
    } else return null;
  }
};

module.exports = { createData, headCells, rowMapper, colorRow };
