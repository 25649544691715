import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withRouter } from "react-router-dom";
import Btn from "./LogoutBtn";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "./MenuIcon.js";
import BackImg from "@material-ui/icons/ArrowBackIos";
import ConsumerHOC from "../context/ConsumerHOC";
import domain from "../config.js";
import "./NavBar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  connectionStatus: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

const NavBar = (props) => {
  let [viewName, setViewName] = useState("Basic Home Infusion");

  useEffect(() => {
    if (props.location.state) {
      //console.log("have it");
      setViewName(
        (viewName =
          (props.location.state.btn && props.location.state.btn.title) ||
          props.location.state.name ||
          props.location.state.title)
      );
    }
  });

  const logOut = async (props) => {
    // console.log("hit logout!!");
    props.history.push({
      pathname: "/login",
      //state: { from: props.location }
    });
    await localStorage.setItem("token", null);
    await props.wipeProvider();

    //force user to reload upon logging out
    window.location.reload();
  };

  const goBack = () => {
    // console.log("props on go back", props);
    // //console.log(
    //   "check this - history location path",
    //   props.history.location.pathname
    // );
    // //console.log(
    //   "check this - history location state name",
    //   props.history.location.state.name
    // );
    // //console.log("check this - location path", props.location.pathname);

    if (props.location.pathname === "/Reports") {
      //console.log("yeah we gonna delete yo!");
      props.updateWorkingCopiesFromServer([]);
    }

    props.history.goBack();
  };

  const classes = useStyles();

  const isLoggedIn = (props) => {
    if (
      /^\/$|\/confirmations$|\/confirmations\/|\/confirmations|\/reschedule$|\/reschedule\/|\/reschedule|\/login/.test(
        props.location.pathname
      )
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {isLoggedIn(props) ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          <div className={classes.title}>
            {viewName !== undefined ? `  ${viewName}` : null}
          </div>

          <div className={classes.connectionStatus}>
            {domain.Info.domain === "rnexpress.net:88" ? "DEV" : "LIVE"}
          </div>

          {isLoggedIn(props) ? (
            <>
              {
                //prevents 'goBack' button from showing in Menu
                props.location &&
                props.location.state &&
                props.location.state.name !== "Menu" ? (
                  <Btn
                    func={goBack}
                    txt="Back"
                    dataProps={props}
                    img={BackImg}
                  />
                ) : null
              }

              <Btn func={logOut} txt="Logout" dataProps={props} />
              <img
                src={require("../assets/webLogo.png")}
                alt="BHI Administrative Portal"
                // style={{
                //   height: "5%",
                //   width: "5%",

                //   textAlign: "center",
                //   margin: 15,
                // }}
                className="icon"
              />
            </>
          ) : (
            <img
              src={require("../assets/webLogo.png")}
              alt="BHI Administrative Portal"
              style={{
                margin: 15,
              }}
              className="icon"
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ConsumerHOC(withRouter(NavBar));
