import React, { useState } from "react";
import Styles from "../ParentStyles.json";
import Button from "./Button";
import Input from "./Input";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";

const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 20px;
  textalign: left;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

const EditIcon = (props) => {
  let { onClick } = props;
  return (
    <HoverText
      onClick={() => {
        onClick();
      }}
    >
      <MdEdit />
    </HoverText>
  );
};

const MileageModificationRequest = (props) => {
  //console.log("wat is props", props);
  //if !canEdit then we know this is a nurse and therefore they CAN request modification
  let { section, currentReport, updateLocalReport, canEdit } = props;
  let status = currentReport["Mileage Report Status"];
  let [isModifying, toggleIsModifying] = useState(false);
  let [text, modText] = useState(currentReport.user.modificationRequest);

  const consolidateInfo = () => {
    let report = currentReport;
    report.user.modificationRequest = text;
    //console.log(report);
    return report;
  };

  return (
    <>
      <div>
        <ModificationHeader section={section} />

        {!canEdit && (status === "Nurse Review" || status === "unreviewed") ? (
          <InputButtonOrDisplay
            toggleIsModifying={toggleIsModifying}
            updateLocalReport={updateLocalReport}
            consolidateInfo={consolidateInfo}
            isModifying={isModifying}
            modText={modText}
            canEdit={canEdit}
            text={text}
          />
        ) : (
          <UneditableModRequestView currentReport={currentReport} />
        )}
      </div>
    </>
  );
};

const ModificationHeader = (props) => {
  let { section } = props;
  return (
    <div>
      <div
        style={{
          ...section,

          backgroundColor: Styles["darkColor"],
          fontSize: 20,
          color: "white",
          borderRadius: 10,
          width: "25%",
        }}
      >
        Report Modification Request
      </div>
      <div style={{ width: "35%", margin: "20px" }}>
        Note: If you find that Saskatoon is listed as a city which you visited,
        please note that there was an error processing your location at that
        moment. Please request modification for that stop to appropriately
        reflect your trip.{" "}
      </div>
    </div>
  );
};

const InputButtonOrDisplay = (props) => {
  let {
    toggleIsModifying,
    updateLocalReport,
    consolidateInfo,
    isModifying,
    modText,
    canEdit,
    text,
  } = props;

  if (isModifying) {
    return (
      <Input
        style={{ width: "45%" }}
        multiline={true}
        onChange={(e) => modText(e.target.value)}
        defaultValue={"Request modification to your mileage report"}
        onBlur={() => {
          consolidateInfo();
          toggleIsModifying(!isModifying);
        }}
      />
    );
  } else {
    return (
      <div>
        {text ? text : "Request modification to your mileage report"}
        <EditIcon
          onClick={() => {
            toggleIsModifying(!isModifying);
          }}
        />
      </div>
    );
  }
};

//view if admin or non-editable state
const UneditableModRequestView = (props) => {
  let { currentReport } = props;
  if (currentReport.user.modificationRequest) {
    return <div>{currentReport.user.modificationRequest} </div>;
  } else {
    return <div>No Modification Requested</div>;
  }
};

export default MileageModificationRequest;
