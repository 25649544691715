import React, { useState } from "react";
import ReviewHeader from "./ReviewHeader";
import Switch from "@material-ui/core/Switch";
import IsChecked from "../CheckIcon";
import Button from "../Button";
import { cloneDeep } from "lodash";
import axios from "axios";
import { Info } from "../../config";
import ConsumerHOC from "../../context/ConsumerHOC";

const domain = Info.domain;

//allows user to: toggle Lock, set compounding status level (high/low)
const CompoundingLock = (props) => {
  // console.log(props);
  let { dataToPass, handleDialogClose, token, updateWorkingCopiesFromServer } =
    props;
  let { NurseName, PatientName, VisitType, VisitDate, VisitNum } = dataToPass;

  //api only accepts visitID so hardcoded that variable
  let clonedData = cloneDeep({ ...dataToPass, visitID: VisitNum });

  let [componentState, updateComponentState] = useState(clonedData);
  let [error, toggleError] = useState(false);
  let [reconciledData, updateReconciledData] = useState([]);

  const DBPost = async (input = {}, method = "post") => {
    try {
      let workingCopies = await axios({
        method,
        url: `https://${Info.domain}/PharmacyRefill/`,
        data: input,
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      // console.log(workingCopies.data);
      return workingCopies.data;
    } catch (err) {
      throw err;
    }
  };

  const submitToServer = async (newState) => {
    try {
      await DBPost(newState);
      //download most up to date data
      let results = await DBPost({}, "get");
      //reconcile updated data
      updateReconciledData(results);
    } catch (err) {
      console.error(err);
      toggleError(true);
    }
  };

  const onCloseWrapper = () => {
    updateWorkingCopiesFromServer(reconciledData);
    handleDialogClose();
  };

  return (
    <div className="CompoundLockParent">
      <ReviewHeader
        NurseName={NurseName}
        PatientName={PatientName}
        VisitType={VisitType}
        VisitDate={VisitDate}
      />
      {error ? (
        <div className="onError">
          An Error Occured on submitting updates to server, please contact IT{" "}
        </div>
      ) : null}
      <div className="CompoundLockTitle">
        Modify Order Status / Schedule Locking
      </div>

      <div className="CompoundLockSection">
        {" "}
        <span>
          <span className="CompoundLockPrompt">
            {" "}
            Do you wish to{" "}
            {componentState.MDOrderStatus
              ? "place order on hold/unsign?"
              : "declare order signed?"}
          </span>
          <Switch
            checked={componentState.MDOrderStatus}
            color="primary"
            disabled={componentState.PharmacyReschedulingLockSet}
            onChange={() => {
              let newState = {
                ...componentState,
                headerID: "MDOrderStatus",
                MDOrderStatus: !componentState.MDOrderStatus,
              };
              updateComponentState(newState);
              submitToServer(newState);
            }}
          />{" "}
          {componentState.MDOrderStatus ? "Signed" : "Unsigned"}
        </span>
      </div>

      <div className="CompoundLockSection">
        {" "}
        <span>
          <span className="CompoundLockPrompt">
            {" "}
            Do you wish to{" "}
            {componentState.PharmacyReschedulingLockSet
              ? "Unlock scheduling/compounding for this visit?"
              : "Lock scheduling/compounding for this visit?"}
          </span>
          <Switch
            checked={componentState.PharmacyReschedulingLockSet}
            color="primary"
            onChange={() => {
              let newState = {
                ...componentState,
                headerID: "PharmacyReschedulingLockSet",
                PharmacyReschedulingLockSet:
                  !componentState.PharmacyReschedulingLockSet,
              };
              updateComponentState(newState);
              submitToServer(newState);
            }}
          />{" "}
          {componentState.PharmacyReschedulingLockSet ? "Locked" : "Unlocked"}
        </span>
      </div>

      {componentState.PharmacyReschedulingLockSet ? (
        <div className="CompoundLockSection">
          <span className="CompoundLockPrompt">
            Please select the compounding status level for this refill visit:
          </span>
          <span>
            {" "}
            <IsChecked
              label="high"
              checkedClass="CompoundStatusChecked"
              unCheckedClass="CompoundStatusUnchecked"
              checked={componentState.CompoundStatus === "high" ? true : false}
              onClick={() => {
                let newState = {
                  ...componentState,
                  headerID: "PharmacyReschedulingLockSet",
                  CompoundStatus: "high",
                };
                updateComponentState(newState);
                submitToServer(newState);
              }}
            />
            <IsChecked
              label="low"
              checkedClass="CompoundStatusChecked"
              unCheckedClass="CompoundStatusUnchecked"
              checked={componentState.CompoundStatus === "low" ? true : false}
              onClick={() => {
                let newState = {
                  ...componentState,
                  headerID: "PharmacyReschedulingLockSet",
                  CompoundStatus: "low",
                };
                updateComponentState(newState);
                submitToServer(newState);
              }}
            />
            <IsChecked
              label="Compounded By Third Party Pharmacy"
              checkedClass="CompoundStatusChecked"
              unCheckedClass="CompoundStatusUnchecked"
              checked={
                componentState.CompoundStatus === "third party pharmacy"
                  ? true
                  : false
              }
              onClick={() => {
                let newState = {
                  ...componentState,
                  headerID: "PharmacyReschedulingLockSet",
                  CompoundStatus: "third party pharmacy",
                };
                updateComponentState(newState);
                submitToServer(newState);
              }}
            />
          </span>
        </div>
      ) : null}

      <div>
        <Button
          label="Accept Changes and Close"
          func={() => onCloseWrapper()}
        />
      </div>
    </div>
  );
};

export default ConsumerHOC(CompoundingLock);
