import React from "react";
import FormWizardConsumerHOC from "./FormWizardConsumerHOC";
import "./Wizard.css";

const ReviewSubmission = (props) => {
  let { answers, questionaire } = props;
  // console.log(props);

  //list of headers in order
  let answerKeys = Object.keys(questionaire);

  return (
    <>
      <div className="ReviewTitle">Review</div>{" "}
      <div className="ReviewSubmissionParent">
        {" "}
        {answerKeys.map((section, idx) => {
          return (
            <ReviewSection
              section={section}
              answers={answers[section]}
              key={idx}
            />
          );
        })}
      </div>
    </>
  );
};

const ReviewSection = (props) => {
  let { section, answers } = props;

  return (
    <div className="ReviewSection">
      <div className="ReviewTitle">{section}</div>
      <div>
        <RecursiveMap answers={answers} />
      </div>
    </div>
  );
};

const RecursiveMap = (props) => {
  let { answers } = props;
  // console.log("what is answers....", answers);
  // console.log(typeof answers);

  if (!answers) {
    return <div>N/A</div>;
  } else if (Array.isArray(answers)) {
    return answers.map((ans) => {
      return <RecursiveMap answers={ans} />;
    });
  } else if (typeof answers === "object") {
    if (answers["INSURANCE"] && !answers["remove"]) {
      // console.log("kaboom");
      return <div> </div>;
    }
    return (
      <div>
        {Object.keys(answers).map((ans, idx) => {
          if (ans !== "remove" && ans !== "MRN") {
            return (
              <div className="ReviewGrid" key={idx}>
                <div class="insuranceReview">{ans}</div>
                <div class="insuranceReviewBody">
                  <RecursiveMap
                    answers={
                      ans === "START"
                        ? answers[ans].split("T")[0]
                        : answers[ans]
                    }
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  } else if (typeof answers === "string") {
    return <div className="ReviewGridAnswer">{answers}</div>;
  } else {
    // console.log("WTF is answers", answers);
    return <div className="ReviewGridAnswer">''</div>;
  }
};

export default FormWizardConsumerHOC(ReviewSubmission);
