import React from "react";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

//import BackImg from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#439372",
    },
  },
}));

const Logout = (props) => {
  const classes = useStyles();
  const { func, txt, dataProps } = props;

  return (
    <Button
      className={classes.button}
      style={{
        margin: 20,
      }}
      variant="contained"
      color="primary"
      onClick={() => {
        func(dataProps);
      }}
    >
      {props.img ? <props.img /> : null}
      {txt}
    </Button>
  );
};

export default Logout;
