import React, { useEffect, useState } from "react";

import "./DemographicMap.css";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import domain from "../../config.js";
import SideBar from "./Sidebar";
import PatientsByStateDisplay from "./PatientsByStateDisplay";
import MapParent from "./MapParent";
import ServiceData from "./ServiceData";

const Info = domain.Info;

const DemographicsMap = (props) => {
  let { token } = props;

  let [inputData, updateInput] = useState({ employees: [], patients: [] });

  let [viewNurses, toggleViewNurses] = useState(true);
  let [viewPatients, toggleViewPatients] = useState(true);

  //view map or graph of service
  let [mainComponent, updateMainComponent] = useState("graph");

  //dictates who is showing in side bar
  //could be a patient or a nurse
  //along with name.
  let [sideBarView, updateSideBarView] = useState({
    type: null,
    id: null,
    data: null,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        let results = await axios({
          method: "get", // you can set what request you want to be
          url: `https://${Info.domain}/geoMap`,

          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log(results.data);
        updateInput(results.data);
      } catch (err) {
        console.error(err);
        return err;
      }
    };
    if (inputData.employees.length === 0) {
      getData();
    }
  }, [inputData.employees.length, token]);

  return (
    <div className="top">
      <div className="MapParent">
        <div className="MapStats">
          <PatientsByStateDisplay input={inputData.patientStats} />
        </div>
        <div className="MapControlColumn">
          <SideBar
            mainComponent={mainComponent}
            updateMainComponent={updateMainComponent}
            toggleViewNurses={toggleViewNurses}
            toggleViewPatients={toggleViewPatients}
            viewNurses={viewNurses}
            viewPatients={viewPatients}
            sideBarView={sideBarView}
            updateSideBarView={updateSideBarView}
            inputData={inputData}
          />
        </div>
        <div className="map">
          {mainComponent === "map" ? (
            <MapParent
              inputData={inputData}
              viewNurses={viewNurses}
              viewPatients={viewPatients}
              sideBarView={sideBarView}
              updateSideBarView={updateSideBarView}
            />
          ) : (
            <ServiceData token={token} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsumerHOC(DemographicsMap);
