import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import React, { useState } from "react";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: "auto",
    padding: 20,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#439372",
      //backgroundColor: "red"
    },
  },
  input: {
    display: "none",
  },
}));

export default function ContainedButtons(props) {
  //console.log("props", props);
  const classes = useStyles();
  let disabled;

  props.disabled ? (disabled = true) : (disabled = false);

  let [modalState, toggleModal] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        style={props.style}
        variant="contained"
        color="primary"
        className={classes.button}
        fullWidth={true}
        type={props.type}
        onClick={async () => {
          if (props.modal) {
            toggleModal(!modalState);
          } else if (props.func) {
            await props.func(props.data);
          }
        }}
      >
        {props.label}
      </Button>
    </>
  );
}
