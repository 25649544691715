import React from "react";
//import ReviewSubmission from "../FormWizard/ReviewSubmission";
import questionaire from "../PCF/PCFQuestionaire";
// import { PatientInfoDisplay } from "./PCFTable";
import Button from "../Button";
import "./pcf.css";

const PCFIndividualReport = (props) => {
  let { viewReport, toggleViewReport } = props;
  // console.log(viewReport);
  return (
    <div>
      <PatientDataDisplay
        {...viewReport}
        returnToPrior={() => {
          toggleViewReport(false);
        }}
      />
      <ReviewSubmission {...viewReport.PCFReport} questionaire={questionaire} />
    </div>
  );
};

export default PCFIndividualReport;

const ReviewSubmission = (props) => {
  let { answers, questionaire } = props;
  // console.log(props);

  //list of headers in order
  let answerKeys = Object.keys(questionaire);

  return (
    <div className="ReviewParent">
      <div className="ReviewTitle">Review</div>{" "}
      <div className="ReviewSubmissionParent">
        {" "}
        {answerKeys.map((section, idx) => {
          return (
            <ReviewSection
              section={section}
              answers={answers[section]}
              key={idx}
            />
          );
        })}
      </div>
    </div>
  );
};

const ReviewSection = (props) => {
  let { section, answers } = props;
  // console.log(props);
  return (
    <div className="ReviewSection">
      <div className="ReviewTitle">{section}</div>
      <div>
        <RecursiveMap answers={answers} />
      </div>
    </div>
  );
};

const RecursiveMap = (props) => {
  let { answers } = props;
  // console.log("what is answers....", answers);
  // console.log(typeof answers);

  if (!answers) {
    return <div>N/A</div>;
  } else if (Array.isArray(answers)) {
    return answers.map((ans) => {
      return <RecursiveMap answers={ans} />;
    });
  } else if (typeof answers === "object") {
    if (answers["INSURANCE"] && !answers["remove"]) {
      // console.log("kaboom");
      return <div> </div>;
    }
    return (
      <div>
        {Object.keys(answers).map((ans, idx) => {
          return (
            <div className="ReviewGrid" key={idx}>
              <div>{ans}</div>
              <div>
                <RecursiveMap answers={answers[ans]} />
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (typeof answers === "string") {
    return <div className="ReviewGridAnswer">{answers}</div>;
  } else {
    // console.log("WTF is answers", answers);
    return <div className="ReviewGridAnswer">''</div>;
  }
};

const PatientDataDisplay = (props) => {
  // console.log(props);
  let { returnToPrior, PatientName, VisitType, VisitDate, reportStatus } =
    props;

  return (
    <div className="PatientInfo">
      <div className="PatientInfoParent">
        <div className="PatientInfoSubsection">
          {" "}
          <Button label="Go Back" func={returnToPrior} />
        </div>
        <div className="PatientInfoTitle">Patient Coordination Form </div>
        <div className="PatientInfoSubsection">
          <div className="PatientInfoHeader">Patient Name:</div>
          <div>{PatientName}</div>
        </div>
        <div className="PatientInfoSubsection">
          <div className="PatientInfoHeader">Visit Date:</div>
          <div>{VisitDate}</div>
        </div>

        <div className="PatientInfoSubsection">
          <div className="PatientInfoHeader">Visit Type:</div>
          <div>{VisitType}</div>
        </div>
        <div className="PatientInfoSubsection">
          <div className="PatientInfoHeader">Report Status:</div>
          <div>{reportStatus ? reportStatus : "Begun"}</div>
        </div>
        {/* <div className="PatientInfoSubsection">
          <div className="PatientInfoHeader">Next Alarm Date:</div>
          <div>{nextAlarm ? nextAlarm : "N/A"}</div>
        </div> */}
      </div>
    </div>
  );
};
