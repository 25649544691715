import React, { useState } from "react";
import PickPhysicianDialogue from "./PickPhysicianDialogue";

const PickPhysician = (props) => {
  let {
    providers,
    error,
    onChange,
    onBlur,
    value,
    label,
    helperText,
    formik,
  } = props;

  let [isDialogueOpen, toggleDialogueOpen] = useState(false);

  return (
    <>
      <div
        className={error ? "openDialogueError" : "openDialogueBtn"}
        onClick={() => {
          toggleDialogueOpen(!isDialogueOpen);
        }}
      >
        {label} {formik.values[label] ? formik.values[label].label : null}
      </div>
      <div style={error ? { color: "red" } : null}>
        {error ? "Required" : null}
      </div>

      {isDialogueOpen ? (
        <PickPhysicianDialogue
          open={isDialogueOpen}
          setOpen={toggleDialogueOpen}
          {...props}
        />
      ) : null}
    </>
  );
};

export default PickPhysician;
