import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: "none",
    overflow: "scroll",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "85%",
    width: "65%",
  };
}

const Mod = (props) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  let { modState, toggleMod } = props;
  // console.log("props here", props);

  return (
    <>
      <Modal open={modState}>
        <div style={modalStyle} className={classes.paper}>
          {props.children}
          <div onClick={() => toggleMod(false)} style={{ marginBottom: 10 }}>
            <Button label="Click to Close" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Mod;
