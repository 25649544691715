import MyContext from "./Context";
import React from "react";

const ProgressNoteWrapper = ChildComp => props => (
  <MyContext.Consumer>
    {context => {
      return (
        <ChildComp
          {...props}
          newProgressNoteText={context.newProgressNoteText}
          updateNewProgressNoteText={context.updateNewProgressNoteText}
          recipientEmails={context.recipientEmails}
          updateEmails={context.updateEmails}
        />
      );
    }}
  </MyContext.Consumer>
);

export default ProgressNoteWrapper;
