import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import axios from "axios";

const CallApi = async (domain, token, api, nurse = null) => {
  let res = await axios({
    method: "post", // you can set what request you want to be
    url: `https://${domain}/${api}`,
    data: {
      nurse: [...nurse],
    },
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
  return res;
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let toReturn = stabilizedThis.map((el) => el[0]);

  return toReturn;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  //console.log("table head props", props);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Typography className={classes.title} variant="h6" id="tableTitle">
      Metrics Table
    </Typography>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "98%",
    margin: 10,
    padding: 10,

    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {
  let { headCells, pickedNurse, domain, token, api, nurseList } = props;

  // console.log("whatis props", props);
  let newpickedNurse = pickedNurse.map((obj) => {
    return obj.value;
  });

  let [rows, updateRows] = React.useState(newpickedNurse);
  let [dataRows, updateDataRows] = React.useState([]);

  useEffect(() => {
    if (rows.length !== newpickedNurse.length) {
      updateRows(newpickedNurse);
    }
  }, [newpickedNurse, rows]);

  useEffect(() => {
    CallApi(domain, token, api, rows).then((res) => {
      //Can probably delete, if can figure out what it's supposed to do
      res.data.forEach((row) => {
        let email = row["email"];
        for (let nurse of nurseList) {
          //console.log(nurse);
          if (nurse["email"] === email) {
            row["last name"] = nurse["lastName"];
            row["first name"] = nurse["firstName"];
          }
        }
      });
      updateDataRows(res.data);
      setRowsPerPage(res.data.length);
    });
  }, [api, domain, nurseList, rows, token]);

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("LastName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rows && rows.length
      ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
      : 0;

  return (
    <div
      className={classes.root}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: 5,
      }}
    >
      <div style={{ flex: 1 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows && rows.length ? rows.length : 0}
            />
            <TableBody>
              {stableSort(dataRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row["last name"]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let color;
                  if (row.Status === "Returned") {
                    color = "#94b8b8";
                  } else if (row.Status === "Revised") {
                    color = "#40bf40";
                  } else {
                    color = null;
                  }

                  return (
                    <TableRow
                      hover
                      row={dataRows}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name || index}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      <TableCell
                        align="right"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row["LastName"]}
                      </TableCell>
                      <TableCell align="right">{row["FirstName"]}</TableCell>
                      <TableCell align="right">
                        {row["ReportsCurrentlyReturned"]}
                      </TableCell>
                      <TableCell align="right">
                        {row["ReportsInWorkFolder"]}
                      </TableCell>
                      <TableCell align="right">
                        {row["ReportsWithWeeklys"]}
                      </TableCell>
                      <TableCell align="right">
                        {row["ReportsApproved"]}
                      </TableCell>
                      <TableCell align="right">{row["TotalReturns"]}</TableCell>
                      <TableCell align="right">
                        {row["ReturnPercent"] + "%"}
                      </TableCell>
                      <TableCell align="right">
                        {Math.round(row["TimeStartToUpload"] * 100) / 100 +
                          " hrs"}
                      </TableCell>
                      <TableCell align="right">
                        {Math.round(row["TimeStartToVisitEnd"] * 100) / 100 +
                          " hrs"}
                      </TableCell>
                      <TableCell align="right">
                        {Math.round(row["TimeStartToApproval"] * 100) / 100 +
                          " hrs"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default withRouter(EnhancedTable);
