import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { pdfjs } from 'react-pdf';
import { RenderDocumentsView } from "./RenderDocumentsView";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function extractPhoneNumbers(phoneNumbersString) {
  const phoneNumbers = {};
  if (!phoneNumbersString) return phoneNumbers;
  const entries = phoneNumbersString && phoneNumbersString.split(", ");

  entries.forEach((entry) => {
    const [title, number] = entry.split(": ").map((str) => str.trim());
    if (number) {
      phoneNumbers[title] = number;
    }
  });

  return phoneNumbers;
}
function extractEmergencyContacts(contactString) {
  let contacts = [];
  if (!contactString) return contacts;
  const contactSegments = contactString.split(", ");
  let contact = [];
  contactSegments.map((segment, i) => {
    const segments = segment.split("- ");
    let segmentObject = {};
    segments.forEach((segment) => {
      const [key, value] = segment.split(":").map((str) => str.trim());
      segmentObject[key] = value;
    });
    contact.push(segmentObject);
  });
  return contact;
}

export const PatientInfoScreen = ({ patient, setShowInfoScreen, fetchPatientDocs, userInfo }) => {
  const [showEmergencyContacts, setShowEmergencyContacts] = useState(false);
  const [showMedication, setShowMedication] = useState(false);
  const {email} = userInfo;
  const {
    FIRST_NAME,
    LAST_NAME,
    DOB,
    PHONE_NUMBERS,
    EMERGENCY_CONTACT,
    DOCS,
    ADDRESS,
    CITY,
    STATE,
    ZIP,
    PHYSICIAN,
    MRN,
    EMAIL,
    PRIMARY_RN,
    DIABETIC,
    ALLERGIES,
    Medication,
    totalPages,
  } = patient;
  console.log(patient);
  const addressSting = `${ADDRESS} ${CITY} ${STATE}, ${ZIP}`;
  const phoneNumbers = extractPhoneNumbers(PHONE_NUMBERS);
  const emergencyContacts = extractEmergencyContacts(EMERGENCY_CONTACT);
  const { Home, Mobile, Work } = phoneNumbers;
  
  const EmailDisplay = ({ EMAIL }) => {
    const isEmptyOrComma = email => {
      return !email || email.trim() === ',' || email.trim() === '';
    };
  
    const renderEmails = email => {
      // Check if the email is effectively empty
      if (isEmptyOrComma(email)) {
        return <p>No email on file</p>;
      }
      const emails = email.split(',').filter(email => !isEmptyOrComma(email));
  
      return (
        <>
          {emails.map((email, index) => (
            <p key={index}>{email.trim()}</p>
          ))}
        </>
      );
    };
  
    return (
      <div>
        {renderEmails(EMAIL)}
      </div>
    );
  };
  return (
    <div style={{ padding: "10px", fontFamily: "Arial, sans-serif" }}>
      <section style={styles.backSection}>
        <button
          onClick={() => setShowInfoScreen(false)}
          style={styles.backButton}
        >
          Back
        </button>
      </section>
      <section style={styles.generalSection}>
        <h2 style={{ fontSize: "20px" }}>Patient Information</h2>
        <section
          style={{
            backgroundColor: "rgb(248 248 248)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            borderRadius: "8px",
            margin: "20px 0",
          }}
        >
          <p style={{ fontSize: "14px" }}>
            <b>Patient Name: </b>
            {FIRST_NAME} {LAST_NAME}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Medical Record No: </b>
            {MRN}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>DOB: </b>
            {formatDate(DOB)}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Mobile Phone Number: </b>
            {Mobile}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Home Phone Number: </b>
            {Home}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Work Phone Number: </b>
            {Work}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Email: </b>
            {EmailDisplay({EMAIL})}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Address: </b>
            {addressSting}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Primany RN: </b>
            {PRIMARY_RN}
          </p>
          <p style={{ fontSize: "14px" }}>
            <b>Physician: </b>
            {PHYSICIAN}
          </p>
        </section>
      </section>
      <section style={styles.generalSection}>
        <aside
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: "20px" }}>Emergency Contacts</h2>
          <Button
            variant="contained"
            color="success"
            size="small"
            key={'emergency'}
            onClick={() => setShowEmergencyContacts(!showEmergencyContacts)}
          >
            {showEmergencyContacts ? "Collapse" : "Show"}
          </Button>
        </aside>
        <aside
          style={{
            display: showEmergencyContacts ? "flex" : "none",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {emergencyContacts &&
            emergencyContacts.map((contact, i) => {
              const { FIRSTNAME, LASTNAME, RELATIONSHIP, PHONE } = contact;
              return (
                <section
                  key={i}
                  style={{
                    backgroundColor: "rgb(248 248 248)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    borderRadius: "8px",
                    margin: "20px 0",
                    width: "25%",
                  }}
                >
                  <p style={{ fontSize: "14px" }}>
                    <b>Name: </b>
                    {FIRSTNAME} {LASTNAME}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>Relatiionship: </b>
                    {RELATIONSHIP}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>Phone Number: </b>
                    {PHONE}
                  </p>
                </section>
              );
            })}
        </aside>
      </section>
      <section style={styles.generalSection}>
        <h2 style={{ fontSize: "20px" }}>Medical Information</h2>
        <p style={{ fontSize: "16px" }}>
          <b>Diabetic: </b>
          {DIABETIC}
        </p>
        <p style={{ fontSize: "16px" }}>
          <b>Allergies: </b>
          {ALLERGIES}
        </p>
      </section>
      <section style={styles.generalSection}>
        <aside
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: "20px" }}>Medications</h2>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => setShowMedication(!showMedication)}
          >
            {showMedication ? "Collapse" : "Show"}
          </Button>
        </aside>
        <aside
          style={{
            display: showMedication ? "flex" : "none",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {Medication &&
            Medication.map((medication, i) => {
              const { Description, Strength, ROUTE, Frequency } = medication;
              return (
                <section
                key={i}
                  style={{
                    backgroundColor: "rgb(248 248 248)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    borderRadius: "8px",
                    margin: "20px 0",
                    width: "40%",
                  }}
                >
                  <p style={{ fontSize: "14px" }}>
                    <b>Description: </b>
                    {Description}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>Strength: </b>
                    {Strength}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>Route: </b>
                    {ROUTE}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    <b>Frequency: </b>
                    {Frequency}
                  </p>
                </section>
              );
            })}
        </aside>
      </section>
      <RenderDocumentsView patient={patient} totalPages={totalPages} fetchPatientDocs={fetchPatientDocs} DOCS={DOCS} /> 
    </div>
  );
};

const styles = {
  backButton: {
    padding: "10px 15px",
    backgroundColor: "rgb(67, 147, 114",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  section: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  generalSection: {
    backgroundColor: "rgb(227 227 227)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    borderRadius: "8px",
    margin: "20px 0",
    width: "80%",
  },
  backSection: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  },
};

export default PatientInfoScreen;