import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    flexBasis: "25%",
    flex: 1,
    // minWidth: "30%",
    // minHeight: 500,
    // maxWidth: "40%",
    margin: "1%",
    padding: 20,
  },

  title: {
    fontSize: 14,
  },
  cardContent: { padding: 5 },
});

export default function SimpleCard(props) {
  const classes = useStyles();
  //console.log("props in datacard peeps", props);

  let [header, updateHeader] = useState(null);
  let [details, updateDetails] = useState(null);
  let [isError, toggleError] = useState(null);

  return (
    <CardContent className={classes.cardContent}>
      <Typography color="primary" component={"span"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
          }}
        >
          <div
            style={{
              margin: 10,
              padding: 15,
              flexGrow: 2,
              flexBasis: "30%",
              alignSelf: "flexStart",
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              fontWeight: "900",
              fontSize: 15,
            }}
          >
            <TextField
              error={isError}
              helperText={isError}
              id="name"
              value={header}
              label="header"
              multiline
              margin="normal"
              style={{ width: "100%", padding: 0 }}
              onChange={(e) => {
                updateHeader(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              fontWeight: "400",
              fontSize: 10,
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              margin: 10,
              padding: 10,
              flexGrow: 3,
              alignSelf: "flexStart",
              flexBasis: "50%",
            }}
          >
            <Typography
              component={"span"}
              style={{ color: "black", fontSize: 15 }}
            >
              <TextField
                error={isError}
                helperText={isError}
                value={details}
                id="standard-name"
                label="details"
                multiline
                margin="normal"
                fullWidth
                onChange={(e) => {
                  updateDetails(e.target.value);
                }}
                onBlur={() => {
                  if (!header || !details) {
                    toggleError("Fields cannot be blank");
                  } else {
                    props.addEntry(header, details, true);
                  }
                }}
              />
            </Typography>
          </div>
          {/* <div
            style={{ alignSelf: "center" }}
            onClick={() => {
              if (!header || !details) {
                toggleError("Fields cannot be blank");
              } else {
                props.addEntry(header, details, true);
              }
            }}
          >
            Save
          </div> */}
        </div>
      </Typography>
    </CardContent>
  );
}
