import React from "react";
import FormWizard from "../FormWizard/FormWizard";
import ReviewHeader from "./ReviewHeader";
import SyringeWasteSubmitComponent from "./SyringeWasteSubmitComponent";

const SyringeWasteQuestionaire = (props) => {
  // console.log(props);
  let { dataToPass } = props;
  let { handleDialogClose } = props;
  return (
    <>
      <ReviewHeader {...dataToPass} />
      <FormWizard
        inputQuestionaire={Questionaire}
        SubmitComponent={SyringeWasteSubmitComponent}
        goBack={handleDialogClose}
        relevantData={dataToPass}
      />
      ;
    </>
  );
};

export default SyringeWasteQuestionaire;

const Questionaire = {
  "RX Number": {
    type: "text",
    question: "RX Number",
    mandatory: true,
  },
  "Compounded By": {
    type: "YesNo",
    question: "Compounded by:",
    mandatory: true,
    choices: [
      { choice: "BHI" },
      { choice: "Medicine Shoppe" },
      { choice: "ICS" },
    ],
  },
  "Compound Date:": {
    type: "UncontrolledDatePicker",
    question: "Compound Date",
    mandatory: true,
  },
  "Cost of waste:": {
    type: "text",
    question: "Cost of waste",
    mandatory: true,
  },
  "Reason for return": {
    type: "YesNo",
    question: "Reason for return:",
    mandatory: true,
    choices: [
      { choice: "Orders changed after compounding" },
      { choice: "PT rescheduled" },
      { choice: "RN rescheduled" },
      { choice: "PT no longer on service" },
      { choice: "problem with syringe" },
    ],
  },
  "Waste Avoidance": {
    type: "YesNo",
    question: "How could waste have been avoided? (pick best option):",
    mandatory: true,
    choices: [
      { choice: "Rescheduling of refill date within BUD" },
      { choice: "Confirming refill date with patient before compounding" },
      { choice: "RN adhering to scheduled refill date" },
      {
        choice:
          "RN informing the pharmacy of patient issue before compounding date",
      },
    ],
  },
};
