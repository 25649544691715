import React, { useState } from "react";
import Button from "./Button";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const ReturnToNurseAction = (props) => {
  let label = props.label;
  let [text, editText] = useState(false);

  let date = new Date();
  let formatDate = moment(date).format("MMMM Do YYYY, h:mm a");

  const onSubmitReturn = async () => {
    await props
      .submitUpdate(
        { ...props.currentReport, ReturnReason: text, ReturnDate: formatDate },
        "Returned"
      )
      .then(props.history.push({ pathname: "/menu" }));
  };

  return (
    <div>
      <div style={{ margin: 20, marginTop: 30 }}>{label}</div>
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Note to Nurse"
          multiline
          rows="10"
          defaultValue="Please make the following changes ... "
          variant="outlined"
          fullWidth={true}
          margin="normal"
          onChange={(e) => {
            editText(e.target.value);
          }}
        />
      </div>
      <div style={{ margin: 50 }}>
        <Button label={"Submit"} func={onSubmitReturn} />
      </div>
    </div>
  );
};

export default ReturnToNurseAction;
