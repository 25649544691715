import React, { useState, useEffect } from "react";
import axios from "axios";
import { Info } from "../../config.js";
import moment from "moment";
import "./marketing.css";
import { MdAddCircle } from "react-icons/md";
import MarketingDialog from "./MarketingDialog";
import MarketingNarrativeUpdate from "./MarketingNarrativeUpdate";
import SubmitComponent from "../FormWizard/SubmitReviewButton";

const ViewNarratives = (props) => {
  let { token, pickedAccount, toggleCurrentView, updatePickedAccount } = props;
  let [narratives, updateNarratives] = useState([]);
  let [enterNewNarrative, toggleEnterNewNarrative] = useState(false);

  // console.log(props);
  useEffect(() => {
    const DBGet = async () => {
      try {
        let workingCopies = await axios({
          method: "get",
          url: `https://${Info.domain}/marketing/Narrative/${pickedAccount}`,
          data: {},
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        updateNarratives(workingCopies.data);
      } catch (err) {
        throw err;
      }
    };

    if (!narratives.length && narratives !== "No Narratives Found") {
      DBGet();
    }
  });

  return (
    <div className="NarrativeGrid">
      {Array.isArray(narratives) && narratives.length > 0 ? (
        <>
          <div className="narrativeGridHeader">Contact Name</div>
          <div className="narrativeGridHeader">Date of Contact</div>
          <div className="narrativeGridHeader">Narrative</div>
          <div className="narrativeGridHeader">Written by</div>
        </>
      ) : (
        <div style={{ gridRow: "1/1" }}>
          There is no narrative data currently available for this contact, would
          you like to add some?
        </div>
      )}
      {Array.isArray(narratives)
        ? narratives.map((narrative, id) => {
            return (
              <React.Fragment key={id}>
                <div className="narrativeGridBody">{narrative.ContactName}</div>
                <div className="narrativeGridBody">
                  {moment(narrative.ContactDate.split("T")[0]).format(
                    "MMMM DD, YYYY"
                  )}
                </div>
                <div className="narrativeGridBody">{narrative.Narrative}</div>
                <div className="narrativeGridBody">
                  {narrative.updaterFirstName} {narrative.updaterLastName}
                </div>
              </React.Fragment>
            );
          })
        : null}
      <div
        className="narrativeGridBody narrativeGridAddButton"
        onClick={() => {
          toggleEnterNewNarrative(true);
        }}
      >
        <MdAddCircle />
      </div>
      {enterNewNarrative ? (
        <MarketingDialog
          toggleDialogue={toggleEnterNewNarrative}
          dialogueStatus={enterNewNarrative}
        >
          <MarketingNarrativeUpdate
            pickedAccount={pickedAccount}
            token={token}
            SubmitComponent={SubmitComponent}
            close={() => {
              updatePickedAccount(null);
              toggleEnterNewNarrative(false);
            }}
          />
        </MarketingDialog>
      ) : null}
    </div>
  );
};

export default ViewNarratives;
