import React, { useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Button from "./Button";
import axios from "axios";
import { Info } from "../config.js";
import ReportConfig from "../ReportConfig.js";
const domain = Info.domain;

const AdminPick = (props) => {
  let [permissionLvl, updatePermissionLvl] = useState(null);

  //prevent displaying permissions that do not have corresponding configuration for RN Express
  const parsePermissions = (input) => {
    const permissionObj = {};
    const configuredPermissions = [];
    Object.keys(ReportConfig).forEach((perm) => {
      permissionObj[perm] = true;
    });

    input.forEach((perm) => {
      if (permissionObj[perm]) {
        configuredPermissions.push(perm);
      }
    });

    return configuredPermissions;
  };

  const submitPermission = async (permission) => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/login/tempTokenReturn`,
      data: { permission },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });

    return res;
  };

  if (!Array.isArray(props.userInfo.permissions)) {
    props.closeModal();
    return <div>Nothing To display</div>;
  } else {
    let displayPermissions = parsePermissions(props.userInfo.permissions);

    return (
      <div>
        <div style={{ margin: 10 }}> Please Pick One: </div>
        {displayPermissions.map((permLvl, idx) => {
          return (
            <div style={{ margin: 10 }} key={permLvl + idx}>
              <ToSelect
                userInfo={props.userInfo}
                permission={permLvl}
                selected={permissionLvl}
                select={updatePermissionLvl}
                key={idx}
                updateUserInfo={props.updateUserInfo}
                submitPermission={submitPermission}
                AdminLoginHelper={props.AdminLoginHelper}
                close={props.closeModal}
              />
            </div>
          );
        })}
      </div>
    );
  }
};

export default ConsumerHOC(AdminPick);

const ToSelect = (props) => {
  let {
    permission,
    selected,
    select,
    submitPermission,
    AdminLoginHelper,
    close,
  } = props;

  const notSelectedProp = {
    backgroundColor: "white",
    borderColor: "black",
    borderWidth: 2,
    color: "green",
  };

  const selectedProp = {};

  let styleProp;
  if (selected === permission) {
    styleProp = selectedProp;
  } else {
    styleProp = notSelectedProp;
  }

  return (
    <div
      onClick={async () => {
        //console.log("props in here", props);
        await select(permission);
        let results = await submitPermission(permission);
        await AdminLoginHelper(results);
        close();
      }}
    >
      <Button label={permission} style={styleProp} />
    </div>
  );
};
