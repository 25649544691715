import React, { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { FaUserNurse } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import useSupercluster from "use-supercluster";
import {
  ListOfPatientsAssignedToNurse,
  xClosestPatientsToNurse,
  xClosestNursesToPatient,
} from "./helpers";

const Marker = ({ children }) => children;

const MapParent = (props) => {
  let { inputData, viewNurses, viewPatients, sideBarView, updateSideBarView } =
    props;
  const mapRef = useRef();
  let [mapsTools, updateMapsTools] = useState({});
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  const handleApiLoaded = (map, maps) => {
    updateMapsTools(maps);
  };

  const points = inputData.patients.map((patient) => ({
    type: "Feature",
    properties: {
      cluster: false,
      patientId: patient.MRNID,
      FNAME: patient.First_Name,
      LNAME: patient.Last_Name,
      Address: patient.Address,
      State: patient.State,
      City: patient.City,
      nurse: patient.Primary_RN,
      nurseID: patient.NurseID,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(patient.Longitude),
        parseFloat(patient.Latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <GoogleMapReact
      onClick={(e) => {
        // console.log("grabby", e);
      }}
      bootstrapURLKeys={{
        key: "AIzaSyCH01rqcaeE45QbfpVG3mCAxAP6XBiexdg",
      }}
      defaultCenter={{ lat: 35.254879, lng: -95.126602 }}
      defaultZoom={5}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        mapRef.current = map;
        handleApiLoaded(map, maps);
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}
    >
      {viewPatients
        ? clusters.map((cluster, idx) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker key={`cluster-${idx}`} lat={latitude} lng={longitude}>
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({
                        lat: latitude,
                        lng: longitude,
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <PatientMarker
                mapsTools={mapsTools}
                {...cluster}
                key={`patient-${idx}`}
                lat={latitude}
                lng={longitude}
                sideBarView={sideBarView}
                onClick={() => {
                  updateSideBarView({
                    type: "patient",
                    id: cluster.properties.patientId,
                    data: cluster,
                    closestNurses: xClosestNursesToPatient(cluster, inputData),
                  });
                }}
              />
            );
          })
        : null}
      {/* display nurse markers */}
      {viewNurses
        ? inputData.employees.map((coords, idx) => {
            return (
              <NurseMarker
                onClick={() => {
                  updateSideBarView({
                    type: "nurse",
                    id: coords.NO,
                    data: coords,
                    patientList: ListOfPatientsAssignedToNurse(
                      coords.NO,
                      coords.Latitude,
                      coords.Longitude,
                      inputData
                    ),
                    closestPats: xClosestPatientsToNurse(
                      coords.Latitude,
                      coords.Longitude,
                      inputData
                    ),
                  });
                }}
                sideBarView={sideBarView}
                key={"nurse" + idx}
                Data={coords}
                lat={parseFloat(coords.Latitude)}
                lng={parseFloat(coords.Longitude)}
              />
            );
          })
        : null}
    </GoogleMapReact>
  );
};

const NurseMarker = (props) => {
  let { sideBarView } = props;
  let { FNAME, LNAME, Address, CITY, STATE, NO } = props.Data;

  return (
    <div
      className={sideBarView.id === NO ? "nurseMarkerSelected" : "nurseMarker"}
      onClick={() => props.onClick()}
    >
      <FaUserNurse />
      {props.$hover ? (
        <div className="nurseHover">
          <div>Nurse: {FNAME + " " + LNAME}</div>
          <div>{Address + " - " + CITY + ", " + STATE}</div>
        </div>
      ) : null}
    </div>
  );
};

const PatientMarker = (props) => {
  // console.log(props);
  let { sideBarView } = props;
  let { LNAME, FNAME, Address, City, State, patientId, nurse } =
    props.properties;

  return (
    <div
      className={
        sideBarView.id === patientId ? "PatientMarkerSelected" : "PatientMarker"
      }
      onClick={() => props.onClick()}
    >
      <MdPerson />
      {props.$hover ? (
        <div className="patientHover">
          <div>Patient: {FNAME + " " + LNAME}</div>
          <div>{Address + " - " + City + ", " + State}</div>
          <div>Primary RN: {nurse}</div>
        </div>
      ) : null}
    </div>
  );
};
export default MapParent;
