import React, { useState, useEffect } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import "./MileageTrackerControls.css";
import { MdEdit } from "react-icons/md";
import Dialogue from "./dialogue";
import axios from "axios";
import { Info } from "../../config";
import { cloneDeep } from "lodash";

const domain = Info.domain;

const MileageTrackerControlsParent = (props) => {
  // console.log(props);
  let { workingCopies, token, updateWorkingCopiesFromServer } = props;

  let [localWorkingCopies, updateLocalWorkingCopies] = useState(workingCopies);
  //let [results, updateResults] = useState(localWorkingCopies || []);
  let [openDialog, toggleDialog] = useState(false);
  let [serverError, updateServerError] = useState(false);

  useEffect(() => {
    // console.log(localWorkingCopies);
    if (workingCopies !== localWorkingCopies) {
      updateLocalWorkingCopies(workingCopies);
    }
  }, [workingCopies, localWorkingCopies]);

  const submitUpdateToServer = async (UserID, isExempt, originalIDX) => {
    // console.log(UserID, isExempt, originalIDX);
    try {
      let result = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${domain}/daily/mileage/status`,
        data: {
          UserID,
          isExempt: isExempt,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      //updateResults([]);
      //update working copies
      let initialWorkingCopy = cloneDeep(localWorkingCopies);
      let copyOriginalIDX = initialWorkingCopy[originalIDX];

      copyOriginalIDX["isExempt"] = isExempt;
      updateWorkingCopiesFromServer(initialWorkingCopy);
      updateServerError(false);
      return true;
    } catch (err) {
      // console.log("what is error", err);
      updateServerError(true);
      return false;
    }
  };

  return (
    <div className="parent">
      <Title />

      <ResultsBox results={localWorkingCopies} toggleDialog={toggleDialog} />
      <Dialogue
        dialogueStatus={openDialog}
        toggleDialogue={toggleDialog}
        onConfirmAction={submitUpdateToServer}
      >
        <DialogueChild report={openDialog} serverError={serverError} />
      </Dialogue>
    </div>
  );
};

const ResultsBox = (props) => {
  // console.log(props);
  let { results, toggleDialog } = props;
  if (!results.length) {
    return <div>No Results Found</div>;
  } else {
    return (
      <div className="resultsGrid">
        <div className="resultsGridHeader">Last Name</div>
        <div className="resultsGridHeader">First Name</div>
        <div className="resultsGridHeader">Status</div>
        <div className="resultsGridHeader">Modify</div>
        {results.map((result, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className={result.isExempt ? "exempt" : "notExempt"}>
                {result.LNAME}
              </div>
              <div className={result.isExempt ? "exempt" : "notExempt"}>
                {result.FNAME}
              </div>
              <div className={result.isExempt ? "exempt" : "notExempt"}>
                {result.isExempt ? "Exempt" : "Not Exempt"}
              </div>
              <div className={result.isExempt ? "exempt" : "notExempt"}>
                <MdEdit
                  className="editStatusBtn"
                  onClick={() => {
                    toggleDialog({ ...result, idx });
                  }}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
};

const Title = (props) => {
  return <div className="title">Mileage Tracker Enable/Disable</div>;
};

const DialogueChild = (props) => {
  let { report, serverError } = props;
  let { FNAME, LNAME, isExempt } = report;
  // console.log(report);
  let prompt = `Are you sure you wish to ${
    isExempt
      ? "place the RN Express Mileage Tracker on"
      : "remove the RN Express Mileage Tracker from "
  }  ${FNAME} ${LNAME}'s phone app?`;
  return (
    <div className="dialoguePromptText">
      {prompt}
      {serverError ? (
        <div className="dialogError">An error occured, please try again</div>
      ) : null}
    </div>
  );
};

export default ConsumerHOC(MileageTrackerControlsParent);
