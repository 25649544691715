import axios from 'axios';

const production = {
    server: "production server",
    domain: "rnexpress.net",
};

const development = {
    server: "development server yo",
    domain: "bhiuniv.com",
  };

export const makeRequest = async ({
    method,
    url,
    data = null,
    config = {}
  }) => {
    try {
      // Directly return the axios call which returns a promise
      const response = await axios({
        method: method,
        url: `https://${production.domain}/${url}`,
        data: data,
        ...config
      }, );
      return response;
    } catch (error) {
      // If an error occurs, throw it so the caller can handle it
      throw error;
    }
};