let answers = {
  "Date of Patient Contact": {
    "Were you able to make contact with the patient?": "Yes",
    "When did you reach the patient?": "2020-11-18",
  },
  "Reschedule Request": {
    "Would you like to reschedule the visit?": "No",
  },
  "Added Insurance Policy": {
    "Have you added a new health insurance policy?": "Yes",
    "Rx Group#": "dfgdf",
    "Rx PCN#": "dfgdfg",
    "RxBin#": "fg",
    "Effective date": "gdfg",
    "Group#": "dfgd",
    "ID#": "dfg",
    "Phone number for providers": "gdfg",
    "Name of insurance company": "fd",
  },
  "Removed Insurance Policy": {
    "Have you removed a health insurance policy?": "No",
  },
  "Hospice Plans": {
    "Are you on hospice/any plans to be on hospice?": "No",
  },
  "Last MD Visit": {
    "Have you seen your doctor since your LAST refill with BHI?": "Yes",
    "Did your doctor make any dose changes?": "Yes",
    "Did your doctor refill your pump?": "Yes",
  },
  "Next MD Visit": {
    "Will you see your doctor BEFORE your scheduled refill with BHI?": "Yes",
    "Is your doctor planning to make any dose changes at this appointment?":
      "Yes",
    "Is your doctor planning to refill your pump at this appointment?": "Yes",
  },
  "Recent Hospitalizations": {
    "Have you had any ER visits or hospital admissions since your last appointment? ":
      "No",
  },
  "Covid-19 Status": {
    "Do you currently have any flu-like symptoms including but not limited to: fever, shortness of breath or coughing?":
      "No",
  },
  "Additional Information": {
    "Would you like to share additional information with your nurse?": "No",
  },
};

// let questionaire = require("./PCFQuestionaire");

class PCFProcessor {
  //toEmail includes PCF data that needs to be sent to Call Center
  //toHighlight includes PCF data that needs to be highlighted for billing review
  constructor(answers, questionaire) {
    this.toEmail = {};
    this.toHighlight = [];
    this.answers = answers;
    this.questionaire = questionaire;
    this.emailTriggers = this.findEmailTriggers();
    this.highlightTriggers = this.findHighlightTriggers();
  }

  findEmailTriggers = () => {
    let triggerList = [];
    try {
      if (!this.questionaire) {
        throw new Error("No questionaire");
      }
      Object.keys(this.questionaire).forEach((header) => {
        if (this.questionaire[header].Notify) {
          triggerList.push(this.questionaire[header].question);
        }
      });
      return triggerList;
    } catch (err) {
      return null;
    }
  };

  findHighlightTriggers = () => {
    let triggerList = [];
    try {
      if (!this.questionaire) {
        throw new Error("No questionaire");
      }
      Object.keys(this.questionaire).forEach((header) => {
        if (this.questionaire[header].highlight) {
          triggerList.push(this.questionaire[header].question);
        }
      });
      return triggerList;
    } catch (err) {
      return null;
    }
  };

  findHighlights = () => {
    Object.keys(this.answers).forEach((header) => {
      // console.log(this.questionaire);
      // console.log(header);
      // console.log(this.answers[header]);
      let questionaireHeaderHighlightTrigger =
        this.questionaire[header].highlight;
      for (let i of this.highlightTriggers) {
        // console.log(this.answers[header][i]);
        if (
          this.answers[header][i] &&
          this.answers[header][i].includes(questionaireHeaderHighlightTrigger)
        ) {
          this.toHighlight.push(header);
        }

        // else if (
        //   this.answers[header] &&
        //   this.answers[header] &&
        //   this.answers[header][i] &&
        //   this.answers[header][i].includes("Yes")
        // ) {
        //   this.toHighlight.push(header);
        // }
      }
    });
  };

  findEmailerData = () => {
    Object.keys(this.answers).forEach((header) => {
      for (let i of this.emailTriggers) {
        if (this.answers[header][i] && this.answers[header][i] !== "No") {
          this.toEmail = { ...this.toEmail, [header]: this.answers[header] };
        }
      }
    });
  };

  grabEmailAndHighlightData = async () => {
    // console.log("inside grab email and highlight");

    await this.findEmailerData();
    await this.findHighlights();
    // console.log({ email: this.toEmail, highlight: this.toHighlight });
    return { email: this.toEmail, highlight: this.toHighlight };
  };
}

// (async () => {
//   let processed = await new PCFProcessor(
//     answers,
//     questionaire
//   ).grabEmailAndHighlightData();
//   console.log(processed);
// })();

export default PCFProcessor;
