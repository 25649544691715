import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Text,
} from "recharts";
import Styles from "../../ParentStyles.json";
import Paper from "@material-ui/core/Paper";
import avgApprovedPerInterval from "./avgReportsApprovedPerInterval.js";
import axios from "axios";
import Button from "../Button";
import DatePicker from "../MatUIDatePicker";
import { Info } from "../../config.js";
const domain = Info.domain;

const ApprovedPerDay = (props) => {
  const grabAvgReports = async () => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/analytics/ReportsApprovedPerDay`,
      data: {
        startInterval: startDate,
        endInterval: endDate,
      },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });

    return res;
  };

  const updateData = (date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  };
  let [open, toggleOpen] = useState(false);
  let [avgReports, updateAvgReports] = useState([]);
  let [startDate, updateStartDate] = useState("10/1/2019");
  let [endDate, updateEndDate] = useState(Date.now());
  useEffect(() => {
    grabAvgReports().then((res) => {
      updateAvgReports(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let reconfigureData = avgApprovedPerInterval(avgReports);
  //console.log("what is reconfigure data", reconfigureData);

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Reports Approved per Day with Running Average (click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Reports Approved per Day with Running Average (click to close)"
            func={toggleOpen}
            data={false}
          />

          <div style={{ width: "25%" }}>
            <DatePicker
              updateDate={updateData}
              dateOnly={true}
              startend={"start"}
            />
            <DatePicker
              updateDate={updateData}
              dateOnly={true}
              startend={"end"}
            />
          </div>
          <ComposedChart
            width={1000}
            height={400}
            data={reconfigureData}
            margin={{ top: 5, right: 20, bottom: 20, left: 20 }}
            barGap={10}
          >
            <Bar dataKey="NumberOfReports" barSize={20} fill={"gray"} />
            <Line
              type="monotone"
              dataKey="avgNumReportsPerDay"
              stroke={Styles["darkColor"]}
              fill={Styles["darkColor"]}
              dot={false}
              strokeWidth={4}
            />
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey="Date"
              tick={{ fontSize: 10, fill: Styles["darkColor"] }}
            >
              <Label value="Date" offset={-10} position="insideBottom" />
            </XAxis>

            <YAxis
              padding={{ top: 12 }}
              //type="number"
              //domain={[0, "dataMax + 2"]}
              //yAxisId="hours"
              orientation="left"
              label={
                <Text x={0} y={0} dx={50} dy={300} offset={0} angle={-90}>
                  Reports Approved per Day
                </Text>
              }
            />
            <Tooltip />
          </ComposedChart>
        </div>
      </Paper>
    );
  }
};

export default ApprovedPerDay;
