var jsPDF = require("jspdf");
require("jspdf-autotable");

const progNotePDF = (data) => {
  //console.log("data", data)
  let y = 10;
  let x = 10;
  let i = "";
  const doc = new jsPDF("p", "mm", "A4");
  doc.setFontSize(13);

  i = `Patient Name: ${data.firstName} ${data.lastName}`;
  doc.text(i, 14, (y += 15));

  i = `Uploaded At: ${data.uploadedAt}`;
  doc.text(i, 14, (y += 15));

  i = `Uploaded By: ${data.submitterData.firstName} ${data.submitterData.lastName}`;
  doc.text(i, 14, (y += 15));

  i = `Note: ${data.Note}`;
  doc.text(i, 14, (y += 15));

  doc.save("jsPDF.pdf");
  let output = doc.output("arraybuffer");
  let toReturn = Buffer.from(output, "binary").toString("base64");

  return toReturn;
};

module.exports = { progNotePDF };
