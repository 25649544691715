import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClinicianVisitCountCheck } from "../AdminPanel/ClinicianVisitCountCheck";
import { FetchPatientByName } from "../../network/Patients_api.js";
import { GenerateCarePlanByName, GenerateCarePlanByState } from "../../network/Caretend_api.js";
import { CarePlans } from "../AdminPanel/CarePlans"; 
import { Info } from "../../config.js";
const domain = Info.domain;

const Tools = (props) => {
    const [selectedState, setSelectedState] = useState("");
    const [state, setState] = useState("");

    const fetchvisitCount = async ({startDate, endDate}) => {
        return await axios
           .post(`https://${domain}/nurses/nursingScheduleDateRange`, {
             startDate,
             endDate
           },{
             headers: {
               Authorization: `bearer ${props.token}`,
             },
           })
    }

    const generateCareplan = async (type = "default", data) => {
        let results;
        console.log(data);
        switch (type) {
            case "state":
                try {
                    return await GenerateCarePlanByState({ token: props.token, data });
                } catch (error) {
                    console.error(error);
                    return null; // Return null or appropriate error handling
                }
        
            case "name":
                console.log(data);
                try {
                    return await GenerateCarePlanByName({ token: props.token, data });
                } catch (error) {
                    return null; // Return null or appropriate error handling
                }
            case "searchPatient":
                console.log(data);
                if (data.firstname || data.lastname) {
                    let patientData = {
                        firstname: data.firstname.trim(),
                        lastname: data.lastname.trim(),
                    };
                    console.log(patientData);
                    // Adjusted to use a separate variable for patient data
                    results = await FetchPatientByName({ token: props.token, data: patientData });
                    return results;
                }
            break;
        default:
          break;
      }
  };
  return (
    <div>
        <section style={{display: 'flex', margin: '5vh 0'}}>
            <ClinicianVisitCountCheck fetchvisitCount={fetchvisitCount}/>
        </section>
        <section style={{display: 'flex', margin: '5vh 0'}}>
            <CarePlans tate={state}  setState={setState} generateCareplan={generateCareplan}/>
        </section>
    </div>
  );
};

export default Tools;
