import React, { useState } from "react";
import { FiRotateCcw, FiRotateCw } from "react-icons/fi";
import Styles from "../ParentStyles.json";

//component to render images such as signature
const ViewImage = (imgStr) => {
  let [rotation, updateRotation] = useState(null);

  return (
    <div
      style={{
        height: "85%",
        width: "80%",
        maxHeight: 1200,
        maxWidth: 1200,
        overflow: "scroll",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <FiRotateCcw
          style={{ color: Styles["darkColor"], fontSize: 30, margin: 20 }}
          onClick={() => {
            updateRotation((rotation -= 90));
          }}
        />{" "}
        <FiRotateCw
          style={{ color: Styles["darkColor"], fontSize: 30, margin: 20 }}
          onClick={() => {
            updateRotation((rotation += 90));
          }}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          src={`data:image/png;base64, ${imgStr.imgStr}`}
          style={{
            maxHeight: 1500,
            maxWidth: 2000,
            transform: `rotate(${rotation}deg)`,
          }}
          alt="img"
        />
      </div>
    </div>
  );
};

export default ViewImage;
