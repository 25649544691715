import React, { Component } from "react";
import axios from "axios";
import ReportsTable from "./ReportsTable";
import MileageReportsParent from "./MileageReportsParent";
import SearchOptions from "./SearchOptions";
import ConsumerHOC from "../context/ConsumerHOC";
import { Info } from "../config.js";
import MDInstSearch from "./DoctorTransmissionPolicy/MDTransmissionSearch.js";
import Spin from "./Spin.js";
import SystemTools from "./SystemTools/SystemTools";
import NursingScheduleCalendar from "./Schedule/NursingScheduleCalendar";
import PharmacyUpdates from "./Schedule/PharmacyUpdates";
import PCF from "./PCF/PCFTable.js";
import CarePlan from "./CarePlan/CarePlanParent.js";
import PharmacyRefillSchedule from "./RefillSchedule/RefillScheduleParent";
import PCFAdmin from "./PCF/PCFAdminView";
import NurseDocumentationAndCredentials from "./DocumentationAndCredentials/NurseDocumentationAndCredentials";

import MileageTrackerControls from "./MileageTrackerControls/MileageTrackerControlsParent";
const domain = Info.domain;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workingCopy: [],
      title: null,
      status: null,
      isSearching: false,
    };
    this.props.location.state = {
      ...this.props.location.state,
      name: "Reports",
    };
  }

  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    //console.log("what is state", this.state);
  };

  getWorkingCopies = async (status) => {
    let urlStr;
    let httpMethod;
    let api = this.props.location.state.btn.api;
    if (!api) {
      return;
    }
    if (api === "confirmations/retrieveConfirmationData") {
      urlStr = `https://${domain}/${api}`;
      httpMethod = "post";
    } else if (api === "daily/retrieveMileageReports/") {
      urlStr = `https://${domain}/${api}`;
      httpMethod = "post";
    } else if (
      api === "scheduleReconciliation/" ||
      api === "scheduleReconciliation/pharmacyView/" ||
      api === "pcf/" ||
      api === "pcf/admin/" ||
      api === "docsandcredentials/" ||
      api === "daily/mileage/status/" ||
      api === "PharmacyRefill/"
    ) {
      urlStr = `https://${domain}/${api}`;
      httpMethod = "get";
    } else {
      urlStr = `https://${domain}/review/${api}`;
      httpMethod = "post";
    }

    try {
      // if (this.props.location.state["type"] === "Progress Notes - Open") {
      //   api = "reviewProgressNotes";
      // } else if (this.props.location.state["type"] === "Visit Forms - Open") {
      //   api = "Reports";
      // }

      let workingCopies = await axios({
        method: httpMethod,
        url: urlStr,
        data: {
          field: "status",
          value: status,
        },
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
      });
      // console.log("what happened here??", urlStr, httpMethod);
      //console.log("This is working copies" + workingCopies.data);
      return workingCopies.data;
    } catch (err) {
      //console.log("hit an error in getWorkingCopies", err);
    }
  };

  getWorkingCopiesAndUpdate = async (status) => {
    let workingCopies = await this.getWorkingCopies(status);
    //console.log("what is working copies here", workingCopies);

    if (!Array.isArray(workingCopies) || workingCopies.length === 0) {
      await this.setState({ workingCopy: "No Reports to Show" });
    } else {
      await this.setState({
        workingCopy: workingCopies,
      });
      //console.log("working copie?", workingCopies);
      await this.props.updateWorkingCopiesFromServer(workingCopies);
    }
  };

  //loads pre-existing rows first, then checks for updates from server.
  async componentDidMount() {
    // console.log("mounted first time");
    // if (this.props.workingCopies) {
    //   await this.setState({ workingCopy: this.props.workingCopies });
    // }

    await this.setState({ title: this.props.location.state.btn.title });
    await this.setState({ status: this.props.location.state.btn.status });
    await this.getWorkingCopiesAndUpdate(this.state.status);

    //should trigger a render and download every 5 minutes
    this.interval = setInterval(async () => {
      this.setState({ time: Date.now() });
      this.getWorkingCopiesAndUpdate(this.state.status);
    }, 1000 * 60 * 60);

    //const token = this.props.token;
    //await this.props.updateToken(token);
    // let workingCopies = await this.getWorkingCopies();
    // this.props.updateWorkingCopiesFromServer(workingCopies);
    // await this.setState({ workingCopy: this.props.workingCopies });
  }

  async componentDidUpdate() {
    //console.log("component updated");
    if (this.state.title !== this.props.location.state.btn.title) {
      await this.setState({ title: this.props.location.state.btn.title });
      await this.getWorkingCopiesAndUpdate(this.state.status);
    }
    if (this.state.status !== this.props.location.state.btn.status) {
      await this.setState({ status: this.props.location.state.btn.status });
      await this.getWorkingCopiesAndUpdate(this.state.status);
    }
  }

  // async componentDidUpdate() {
  //   if (this.props.workingCopies.length !== this.state.workingCopy.length) {
  //     await this.getWorkingCopiesAndUpdate(this.status);
  //   }
  // }

  render() {
    // console.log("what is this.sttat", this.state);
    //console.log("tes tes test", this.state.title);
    //checks this.props.location.state.type in order to determine which variation of page to display (reports or progressnotes)
    //willuse progress table or reports table to decide what to display
    let returnComp;
    if (this.state.title === "System Tools") {
      //console.log("hit sys tools");
      returnComp = <SystemTools />;
    } else if (this.state.title === "Mileage Tracker Enable/Disable") {
      returnComp = <MileageTrackerControls />;
    } else if (
      Array.isArray(this.state.workingCopy) &&
      this.state.workingCopy.length < 1
    ) {
      returnComp = <Spin />;
    } else if (this.state.title === "Patient Reports") {
      returnComp = <ReportsTable type={"PatientReports"} />;
    } else if (this.state.title === "View Progress Notes") {
      returnComp = <ReportsTable type={"ProgressNotes"} />;
    } else if (this.state.title === "Searchable Patient Reports") {
      returnComp = <SearchOptions />;
    } else if (this.state.title === "Mileage") {
      //console.log("here??");
      returnComp = <MileageReportsParent type={"mileage"} />;
    } else if (this.state.title === "Edit Documentation Sharing Instructions") {
      returnComp = <MDInstSearch rows={this.state.workingCopy} />;
    } else if (this.state.title === "Nursing Schedule") {
      returnComp = <NursingScheduleCalendar type={"NursingSchedule"} />;
    } else if (this.state.title === "Pharmacy Updates") {
      returnComp = <PharmacyUpdates rows={this.state.workingCopy} />;
    } else if (this.state.title === "PCF") {
      returnComp = <PCF rows={this.state.workingCopy} />;
    } else if (this.state.title === "Care Plan") {
      returnComp = <CarePlan />;
    } else if (this.state.title === "PCF Admin") {
      returnComp = <PCFAdmin rows={this.state.workingCopy} />;
    } else if (this.state.title === "Pharmacy Refill Schedule") {
      returnComp = <PharmacyRefillSchedule rows={this.state.workingCopy} />;
    } else if (this.state.title === "Credentials and Documentation") {
      returnComp = <NurseDocumentationAndCredentials rows={"test"} />;
    } else if (this.state.workingCopy === "No Reports to Show") {
      returnComp = <NoResults test={this.state} />;
    }
    return <div style={{ margin: 20 }}> {returnComp} </div>;
  }
}

const NoResults = (props) => {
  // console.log(props);
  return <div>No Reports Found</div>;
};

export default ConsumerHOC(Reports);
