import React from "react";
import Button from "./Button";
import TableCell from "@material-ui/core/TableCell";

const TableButton = (props) => {
  let {
    row,
    id,
    header,
    status,
    canToggle,
    label,
    func,
    error,
    isReady,
    wasRescheduled,
  } = props;

  if (wasRescheduled) {
    isReady = false;
  }
  const readyColor = "#bbd1ed";
  const alertColor = "#f7807c";

  return (
    <TableCell
      key={id + header.id}
      component="th"
      // /id={labelId}
      scope="row"
      //padding="none"
    >
      <div
        style={{
          padding: "5px",

          borderColor: error ? "red" : null,
          backgroundColor: isReady ? readyColor : alertColor,
        }}
      >
        {error ? (
          <div
            style={{ backgroundColor: "red", color: "white", padding: "10px" }}
          >
            An error occured on this operation
          </div>
        ) : null}
        <div>{status}</div>
        <Button
          label={label}
          data={row}
          func={() => {
            func();
          }}
          disabled={!canToggle}
          fullWidth={false}
        />
      </div>
    </TableCell>
  );
};

export default TableButton;
