import React from "react";
import "./VO.css";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const PickOne = (props) => {
  let { choices, label, error, value, setValues, formikValues } = props;

  const handleChange = (e) => {
    // console.log(e.target.value);
    // console.log(label);
    setValues({ ...formikValues, [label]: e.target.value });
  };

  return (
    <div className={error ? "pickOneParent" : null}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          aria-label={label}
          name={label}
          value={value}
          onChange={handleChange}
        >
          {choices.map((options) => {
            return (
              <FormControlLabel
                key={options.choice}
                value={options.choice}
                control={<Radio color="primary" />}
                label={options.choice}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default PickOne;
