import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
export default function CustomizedTables({DOCS, handleViewClick}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
            <TableRow >
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="left">Filename</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(DOCS) && DOCS.map((doc, index) => {
            const date = doc.CreatedDate ? doc.CreatedDate : 'N/A';
            return (
                <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                        {date === 'N/A' ? date : formatDate(date)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{doc.Name}</StyledTableCell>
                    <StyledTableCell align="right">
                        <Button variant="text" onClick={() => handleViewClick(doc)}>View</Button>
                    </StyledTableCell>
                </StyledTableRow>
            )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
