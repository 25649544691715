import React from "react";
import "./RefillSchedule.css";

const ReviewHeader = (props) => {
  // console.log(props);
  let { NurseName, PatientName, VisitType, VisitDate } = props;
  return (
    <div className="PCFReviewHeader">
      <div>
        {" "}
        <div className="PCFReviewHeaderKey">Patient</div>
        <div className="PCFReviewHeaderValue">{PatientName}</div>
      </div>
      <div>
        <div className="PCFReviewHeaderKey">Nurse</div>
        <div className="PCFReviewHeaderValue">{NurseName}</div>
      </div>
      <div>
        {" "}
        <div className="PCFReviewHeaderKey">Visit Date</div>
        <div className="PCFReviewHeaderValue">{VisitDate}</div>
      </div>
      <div>
        <div className="PCFReviewHeaderKey">Visit Type</div>
        <div className="PCFReviewHeaderValue">{VisitType}</div>
      </div>
    </div>
  );
};

export default ReviewHeader;
