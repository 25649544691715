import React from "react";
import "./pharmacyBoard.css";

const PharmacyBoardStatus = (props) => {
  let { status } = props;

  return (
    <div>
      Connection Status: <ConnectionStatusDisplay status={status} />
    </div>
  );
};

const ConnectionStatusDisplay = (props) => {
  let { status } = props;

  if (status) {
    return (
      <>
        <span className="live">Live</span> <span className="liveCircle"></span>
      </>
    );
  } else {
    return (
      <>
        <span className="offline">Offline </span>{" "}
        <span className="offlineCircle"></span>
        <div>
          (Data may be stale, please refresh page for latest information.)
        </div>
      </>
    );
  }
};
export default PharmacyBoardStatus;
