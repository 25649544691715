import React, {useState} from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CircularProgress } from "@mui/material";

export const ClinicianVisitCountCheck = ({fetchvisitCount}) => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [error, setError] = useState(null)
    const [count, setCount] = useState(0);

    const handleSubmit = async () => {
        if(endDate < startDate) {
            setError('Invalid dates, End < Start');
            return;
        }
        if(!endDate || !startDate) return;
        setError(null)
        setLoading(true)
        const response = await fetchvisitCount({startDate, endDate});
        setCount(response.data);
        setLoading(false)
    }

    return (
        <div>
            {loading ? 
                <section
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                }}
                >
                <CircularProgress />
                </section>
            :(
                <Card variant="outlined" sx={{ maxWidth: '70%' }}>
                    <Box sx={{ p: 2 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography gutterBottom variant="h5" component="div">
                            Clinician Visit Count
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div">
                            {count}
                        </Typography>
                        </Stack>
                        <Typography color="text.secondary" variant="body2">
                            Enter a date range to get the total number of "Confirmed Unbiled", "Unconfirmed" 
                            visits present in RN Express sourced from Caredtend!
                        </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        {error && <TextField error id="standard-error" label={error} defaultValue="Error" variant="standard"/>}
                        <Stack direction="row" spacing={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    onChange={(newValue) => setStartDate(newValue.$d)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    onChange={(newValue) => setEndDate(newValue.$d)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Button variant="contained" color="success" size="large" sx={{width: "200px"}} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    </Box>
                </Card>
            )}
        </div>
    )
}