import React from "react";
import { withRouter } from "react-router-dom";
import DataCard from "./DataCard.js";
import ConsumerHOC from "../context/ConsumerHOC";
import SubmissionData from "./SubmissionDataCard";
import axios from "axios";

import SubmitReportBtns from "./SubmitReportBtns";
import UploadedDocuments from "./UploadedDocuments";

import { emailHeaders } from "./RowDataTypes";
import { shouldSendMCEmail } from "./emailerFunctions";
import { Info } from "../config.js";
const domain = Info.domain;

const pullFilesFromServer = async (fn, visitID, token) => {
  //console.log("in pull files from server");
  const results = await axios({
    method: "post", // you can set what request you want to be
    url: `https://${domain}/review/retrieveFileList`,
    data: { visitID: 123456 },
    headers: {
      Authorization: `bearer ${token}`,
    },
  });

  let headerOpts = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };
  let newData = { visitID: visitID };
  let template = async () => {
    return await axios.post(
      `https://${domain}/review/retrieveFile`,
      newData,
      headerOpts
    );
  };

  let multiReq = [];

  if (results.data.length > 0) {
    try {
      results.data.forEach((file) => {
        newData = { ...newData, fileName: file };
        multiReq.push(template());
      });

      let res = await axios.all(multiReq);

      await fn(res);
      //let res = await axios.post(`https://${domain}/review/retrieveFile`, { visitID: visitID, "fileName": results.data[0] }, headerOpts)
    } catch (err) {
      //console.log(err);
    }
  }
};

class ViewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentReport: {}, sendEmailNotification: {} };
    //this.title = this.props.location.state.btn.title;
    //this.status = this.props.location.state.btn.status;
    this.docID = this.props.location.state.data.ID;
    this.isSearching = false;
    this.api = "retrieveReportByID";
    // this.props.location.state = {
    //   ...this.props.location.state,
    //   name: "Reports"
    // };
  }

  async componentDidMount() {
    await this.props.changeCurrentReport({});

    const patientName = this.props.location.state.data.PatientName;

    this.props.location.state = {
      ...this.props.location.state,
      name: `Visit Report View - Patient:  ${patientName}  - Visit ID: ${this.props.location.state.data.VisitID}`,
    };

    await this.getWorkingCopyAndUpdate(this.docID);

    //emailer check

    //if sendEmail data already collected, then simply add it to another piece of state
    if (this.state.currentReport.sendEmail) {
      await this.setState({
        sendEmailNotification: this.state.currentReport.sendEmail,
      });
    }
    //if no data yet to be emailed, then
    //parse visit data to find information to email
    else if (this.state.currentReport.visitData) {
      let emailData = await shouldSendMCEmail(
        this.props.currentReport.visitData
      );
      await this.setState({ sendEmailNotification: emailData });
      let reportClone = this.state.currentReport;
      reportClone["sendEmail"] = this.state.sendEmailNotification;
      await this.props.changeCurrentReport(reportClone);
    }
  }

  async componentDidUpdate(prevState) {
    if (this.props.currentReport !== this.state.currentReport) {
      await this.setState({ currentReport: this.props.currentReport });
    }
  }

  getCurrentReport = async (id) => {
    try {
      let workingCopy = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${domain}/review/${this.api}`,
        data: {
          field: "_id",
          value: id,
        },
        headers: {
          Authorization: `bearer ${this.props.token}`,
        },
      });

      return workingCopy.data;
    } catch (err) {
      //console.log("hit an error in getCurrentReport", err);
    }
  };

  getWorkingCopyAndUpdate = async (id) => {
    let workingCopy = await this.getCurrentReport(id);
    //console.log("working copy here", workingCopy);
    if (workingCopy && workingCopy.length === 0) {
      await this.setState({ currentReport: "No Report to Show" });
    } else {
      await this.setState({
        currentReport: workingCopy[0],
      });
      await this.props.changeCurrentReport(workingCopy[0]);
    }
  };

  editEntireHeaderReportData = async (sectionHeader, editedObj) => {
    let alterState = this.state.currentReport;

    alterState.visitData[sectionHeader].data = editedObj;

    await this.props.changeCurrentReport(alterState);
  };

  editReportData = async (sectionHeader, Key, Value, toRemove = null) => {
    //console.log("what is value", sectionHeader, Key, Value);
    let alterState = this.state.currentReport;

    let area = alterState.visitData[sectionHeader].data;

    if (toRemove === "delete") {
      delete area[Key];
    } else {
      area[Key] = Value;
    }

    //set up email notifier list

    //should Data be added to email notifier
    if (emailHeaders.includes(sectionHeader)) {
      // console.log("check here??");
      //new input
      let emailData = await shouldSendMCEmail(
        this.props.currentReport.visitData
      );
      // console.log(emailData);
      await this.setState({ sendEmailNotification: emailData });
      let reportClone = this.state.currentReport;
      reportClone["sendEmail"] = this.state.sendEmailNotification;
      await this.props.changeCurrentReport(reportClone);
      //end new input

      //ORIGINAL DO NOT REMOTE !!!!
      // let newList = {
      //   ...this.state.sendEmailNotification,
      //   [sectionHeader]: alterState.visitData[sectionHeader],
      // };
      // await this.setState({ sendEmailNotification: newList });
    }

    //add email data to alterState to be send to provider

    Object.keys(this.state.sendEmailNotification).length > 0
      ? (alterState["sendEmail"] = this.state.sendEmailNotification)
      : (alterState["sendEmail"] = null);

    //console.log("what is alterState here", alterState);
    await this.props.changeCurrentReport(alterState);
  };

  confirmReview = async (sectionHeader) => {
    let alterState = this.state.currentReport;
    let area = alterState.visitData[sectionHeader];

    area["confirmed"]
      ? (area["confirmed"] = false)
      : (area["confirmed"] = true);
    await this.props.changeCurrentReport(alterState);
  };

  //parameters include sectionHeader and (status = true/false)...default is false
  toggleValidation = async (sectionHeader, status = false) => {
    let alterState = this.state.currentReport;
    let area = alterState.visitData[sectionHeader];

    area["confirmed"] = status;

    await this.props.changeCurrentReport(alterState);
  };

  render() {
    return (
      <>
        <div style={{ marginBottom: "5%" }}>
          <div
            style={{
              display: "flex",
              flexBasis: "70%",
              flexDirection: "row",

              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "flexStart",
              alignContent: "space-between",
              margin: "2%",
            }}
          >
            {/* component displays relevant data regarding submission of this report */}
            <SubmissionData data={this.state.currentReport} />

            {/* submit button */}

            <SubmitReportBtns
              token={this.props.token}
              reportData={this.state.currentReport.visitData}
              submitUpdate={this.props.submitUpdate}
              //toggleMessage={toggleSendFunc}
            />

            <UploadedDocuments
              data={this.state.currentReport}
              pullFilesFromServer={pullFilesFromServer}
              //pullFileList={pullFileList}
              //didSendFiles={didSend}
            />

            <div
              style={{
                backgroundColor: "#15794F",
                flexBasis: "100%",
                padding: 10,
                margin: 20,
                borderRadius: 5,
                textAlign: "center",
                color: "white",
                fontWeight: "900",
                fontSize: 30,
              }}
            >
              Visit Report
            </div>
            {!this.state.currentReport.visitData ||
            !this.props.currentReport.visitData ? (
              <div>No Data Available to Display</div>
            ) : (
              Object.keys(this.state.currentReport.visitData).map(
                (keyName, i) => {
                  if (keyName !== null || keyName !== undefined) {
                    return (
                      <DataCard
                        editReportData={this.editReportData}
                        editEntireHeaderReportData={
                          this.editEntireHeaderReportData
                        }
                        key={i}
                        title={keyName}
                        data={this.props.currentReport.visitData[keyName].data}
                        confirmReview={this.confirmReview}
                        toggleValidation={this.toggleValidation}
                      />
                    );
                  }
                }
              )
            )}
            <div>
              <SubmitReportBtns
                token={this.props.token}
                reportData={this.state.currentReport.visitData}
                submitUpdate={this.props.submitUpdate}
                //toggleMessage={toggleSendFunc}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

let ViewReportWithRouter = withRouter(ViewReport);

export default ConsumerHOC(ViewReportWithRouter);
