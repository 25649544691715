import React, { useState } from "react";
import axios from "axios";
import Button from "../Button";
import { Info } from "../../config";
import Dialog from "./Dialog.js";
import Input from "../Input";
import "./styles.css";
const domain = Info.domain;

//component allows user to indicate whether they attempted to contact the patient and were unable
const ReportStatusRow = (props) => {
  let { row, func, token } = props;
  let { reportStatus } = row;

  let [noContactDialog, toggleNoContactDialog] = useState(false);

  const submitStatusUpdate = async (msg) => {
    let data = {
      status: "unable to contact patient",
      visitData: { VisitNum: row.VisitNum },
      msg,
    };

    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/pcf/submitReport`,
      headers: {
        Authorization: `bearer ${token}`,
      },
      data: data,
    });

    if (res.status === 200) {
      row.reportStatus = data.status;
    }
    return res;
  };

  //'incomplete indicates that nurse has not yet worked on this pcf'
  if (reportStatus === "incomplete") {
    return (
      <>
        <div className="buttonLayout">
          <div>
            <Button
              label={"Unable To Contact Patient"}
              data={row}
              func={() => {
                toggleNoContactDialog(true);
              }}
            />
          </div>
          <div>
            <Button
              label={row.reportStatus === "incomplete" ? "Begin Report" : "???"}
              data={row}
              func={func}
            />
          </div>
        </div>
        {noContactDialog ? (
          <Dialog
            dialogueStatus={noContactDialog}
            toggleDialogue={toggleNoContactDialog}
            title={"Unable to contact"}
            onConfirmationAction={submitStatusUpdate}
          >
            <ReasonWhyNoContact
              onSubmit={submitStatusUpdate}
              close={() => {
                toggleNoContactDialog(false);
              }}
            />
          </Dialog>
        ) : null}
      </>
    );
  } else if (reportStatus === "unable to contact patient") {
    return (
      <div>
        <Button label="Begin Report" data={row} func={func} />{" "}
      </div>
    );
  } else {
    return <div>Not Sure What Goes Here {reportStatus}</div>;
  }
};

const ReasonWhyNoContact = (props) => {
  let { onSubmit, close } = props;
  let [noContactMsg, updateNoContactMsg] = useState("");
  let [isError, toggleIsError] = useState(false);

  const submitFunction = async () => {
    try {
      let results = await onSubmit(noContactMsg);
      // console.log("what is results?", results);
      if (results.status === 200) {
        close();
      }
    } catch (err) {
      console.error(err);
      toggleIsError(
        "An error occured upon submission to our servers, please try again later or call the IT department"
      );
    }
  };
  return (
    <span>
      <Input
        onChange={(e) => {
          updateNoContactMsg(e.target.value);
        }}
        multiline={true}
        error={isError}
        helperText={isError}
      />
      <Button func={submitFunction} label="Submit Message" />
    </span>
  );
};
export default ReportStatusRow;
