import React, { useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Card from "@material-ui/core/Card";
import Styles from "../ParentStyles.json";
import Button from "./Button";
import axios from "axios";
import { Info } from "../config.js";
import { EditableYesNo, AddRow } from "./mileageReportWorksheet";
import ModificationRequest from "./ModificationRequest";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";

//component was a quick and dirty way to integrate a blank mileage worksheet for admin

const MileageReport = (props) => {
  let { reportSubject } = props;

  const initialBlankReport = {
    "Mileage Report Status": "Admin Review",
    mileageReport: {
      TotalTravel: 0,
      reimburseableMileage: 0,
      processedTravelData: [
        {
          GPSStamp: {
            timestamp: new Date(),
            coords: {
              latitude: 40.95892630000001,
              longitude: -74.22666449999999,
            },
          },
          reimburseableStart: false,
          rentalCar: false,
          updateType: "start",
        },
      ],
    },
    user: {
      email: reportSubject.EMAIL,
      firstName: reportSubject.FNAME,
      lastName: reportSubject.LNAME,
    },
    date: new Date().toISOString(),
  };

  let [currentReport, updateCurrentReport] = useState(initialBlankReport);

  // console.log("props", props);

  return (
    <DisplayMileageReport
      {...props}
      currentReport={currentReport}
      updateCurrentReport={updateCurrentReport}
    />
  );
};

export const DisplayMileageReport = (props) => {
  let { currentReport, updateCurrentReport, token, toggleModState } = props;

  let submissionInfo = [
    { label: "Approve", status: "Approved" },
    { label: "Deny", status: "Denied" },
  ];
  if (
    props.userInfo.permissions === "ipmservices" ||
    props.userInfo.permissions === "nurse"
  ) {
    submissionInfo = [{ label: "Submit for Review", status: "Admin Review" }];
  }

  let { reimburseableStart, rentalCar } =
    currentReport.mileageReport.processedTravelData[0];

  const integrateNewRow = async (newRow, idx = false) => {
    let newObjectForcurrentReport;
    if (newRow === "delete") {
      let newAddyArr = [];
      currentReport.mileageReport.processedTravelData.forEach((val, id) => {
        if (id !== idx) {
          newAddyArr.push(val);
        }
      });

      currentReport.mileageReport.processedTravelData = newAddyArr;
      newObjectForcurrentReport = currentReport;
    } else {
      let formattedCoords = {
        latitude: newRow["coords"].lat,
        longitude: newRow["coords"].lng,
      };

      let timeZone = currentReport.timeZone;
      let incomingTimestamp = newRow.time;

      //convert time to milliseconds since epoch
      let timeInMilli = moment.tz(incomingTimestamp, timeZone).valueOf();

      newRow = {
        updateType: newRow.type,
        GPSStamp: { coords: formattedCoords, timestamp: timeInMilli },
      };
      currentReport.mileageReport.processedTravelData.push(newRow);

      //must sort all stops by time before sending to server

      let arrOfLocations = currentReport.mileageReport.processedTravelData;

      if (arrOfLocations.length > 1) {
        arrOfLocations.sort((a, b) => {
          return (
            new Date(a.GPSStamp.timestamp) - new Date(b.GPSStamp.timestamp)
          );
        });
      }

      let newObjectForMileageReport = {
        ...currentReport.mileagReport,
        processedTravelData: arrOfLocations,
      };

      let reportDate =
        currentReport.mileageReport.processedTravelData[0].GPSStamp.timestamp;

      let reportDateAsDate = new Date(reportDate).toISOString();
      newObjectForcurrentReport = {
        ...currentReport,
        date: reportDateAsDate,
        mileageReport: newObjectForMileageReport,
      };

      updateCurrentReport(newObjectForcurrentReport);
    }

    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/daily/mileage`,
        data: {
          report: newObjectForcurrentReport,
          noSave: true,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.data === "err") {
        //console.log("err?");
        throw new Error("Error on submission");
      } else {
        //updatecurrentReport(results.data);
        //passDataToModal(results.data);
        updateCurrentReport(results.data);
      }
    } catch (err) {
      //console.log("what is err", err);
      return err;
    }
  };

  let reimburseableMileage;

  if (currentReport.mileageReport.processedTravelData[0].rentalCar) {
    reimburseableMileage = 0;
  } else if (!reimburseableStart) {
    reimburseableMileage =
      currentReport.mileageReport.totalTravel - 50 < 0
        ? 0
        : currentReport.mileageReport.totalTravel - 50;
  } else {
    reimburseableMileage = currentReport.mileageReport.totalTravel;
  }

  const handleSubmit = async (status) => {
    // console.log("hit handleSubmit!");
    currentReport.status = status;

    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/daily/revisedMileagereports`,
        data: {
          currentReport,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.data === "err") {
        //console.log("err?");
        throw new Error("Error on submission");
      } else {
        props.toggleModState(false);
      }
    } catch (err) {}
  };

  return (
    <MileageUpdateWorkSheet
      reimburseableStart={reimburseableStart}
      canEdit={true}
      updateCurrentReport={updateCurrentReport}
      rentalCar={rentalCar}
      reimburseableMileage={reimburseableMileage}
      integrateNewRow={integrateNewRow}
      currentReport={currentReport}
      submissionInfo={submissionInfo}
      handleSubmit={handleSubmit}
      toggleModState={toggleModState}
    />
  );
};

export const MileageUpdateWorkSheet = (props) => {
  let {
    currentReport,
    reimburseableStart,
    canEdit,
    updatecurrentReport,
    updateCurrentReport,
    rentalCar,
    reimburseableMileage,
    integrateNewRow,
    submissionInfo,
    handleSubmit,
    toggleModState,
  } = props;

  // console.log("update worksheet", props);

  const buttonStyle = { margin: "20px" };
  const section = { margin: 20, padding: 10, textAlign: "center" };
  const headers = {
    fontSize: 15,
    fontWeight: "bold",
    backgroundColor: Styles["bgGray"],
    padding: 5,
    margin: 10,
    borderRadius: 10,
  };
  return (
    <Card style={{ margin: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
        <div
          style={{
            ...section,
            fontWeight: "bold",
            backgroundColor: Styles["darkColor"],
            fontSize: 30,
            color: "white",
            borderRadius: 10,
          }}
        >
          Mileage Report
        </div>
        <div
          className="reportDetails"
          style={{
            ...section,
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto",
          }}
        >
          <div>
            <div style={headers}>Name:</div> {currentReport.user.lastName},{" "}
            {currentReport.user.firstName}
          </div>
          <div>
            <div style={headers}>Date:</div>{" "}
            {moment(
              currentReport.mileageReport.processedTravelData[0].GPSStamp
                .timestamp
            ).format("MMMM DD, YYYY")}
          </div>
          <div>
            <div style={headers}>Report Processing Status: </div>
            {currentReport["Mileage Report Status"]}
          </div>

          <div>
            <div style={headers}>Start From Reimburseable Location:</div>{" "}
            <EditableYesNo
              canEdit={canEdit}
              data={reimburseableStart}
              update={() => {
                let clonecurrentReport = cloneDeep(currentReport);
                clonecurrentReport.mileageReport.processedTravelData[0].reimburseableStart =
                  !clonecurrentReport.mileageReport.processedTravelData[0]
                    .reimburseableStart;

                updateCurrentReport(clonecurrentReport);
              }}
              report={currentReport}
            />
          </div>
          <div>
            <div style={headers}>Rental Car:</div>
            <EditableYesNo
              canEdit={canEdit}
              data={rentalCar}
              update={() => {
                let clonecurrentReport = cloneDeep(currentReport);
                clonecurrentReport.mileageReport.processedTravelData[0].rentalCar =
                  !clonecurrentReport.mileageReport.processedTravelData[0]
                    .rentalCar;

                updateCurrentReport(clonecurrentReport);
                ////console.log("what is local report", currentReport);
              }}
              report={currentReport}
            />
          </div>
          <div>
            <div style={headers}>Total Mileage:</div>{" "}
            {Math.round(10 * currentReport.mileageReport.totalTravel) / 10}
          </div>

          <div>
            <div style={headers}> Reimburseable Mileage:</div>

            {Math.round(10 * reimburseableMileage) / 10}
          </div>
          <div>
            <div style={headers}> Was Report Modified?:</div>

            {currentReport.isModified ? "Yes" : "No"}
          </div>
          <div>
            <div style={headers}>Report Modification Requested By Nurse: </div>
            {currentReport.user["modificationRequest"] ? "Yes" : "No"}
          </div>
        </div>
        <div style={section}>
          <VisitBreakDownChart
            currentReport={currentReport.mileageReport.processedTravelData}
            timeZone={currentReport.timeZone}
            integrateNewRow={integrateNewRow}
            canEdit={canEdit}
          />
        </div>
        <div>
          <ModificationRequest
            section={section}
            currentReport={currentReport}
            updatecurrentReport={updatecurrentReport}
            canEdit={canEdit}
          />
        </div>

        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {submissionInfo.map((obj, idx) => {
            if (currentReport["Mileage Report Status"] !== obj.status) {
              return (
                <Button
                  key={idx}
                  label={obj.label}
                  style={buttonStyle}
                  func={handleSubmit}
                  data={obj.status}
                />
              );
            }
          })}
          <Button
            label={"Cancel"}
            style={buttonStyle}
            func={() => {
              toggleModState(false);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

const VisitBreakDownChart = (props) => {
  let { currentReport, timeZone, token, integrateNewRow, canEdit } = props;
  //can edit prop dictates if you user can act on a given feature.

  // console.log("what is props?", props);
  let headerStyle = {
    fontWeight: "bold",
    fontSize: 20,
    color: "white",
    padding: 10,
    margin: 10,
    borderRadius: 5,
    backgroundColor: Styles["darkColor"],
  };

  let cellStyle = {
    fontSize: 15,
    color: Styles["darkColor"],
    padding: 10,
    margin: 10,
    borderRadius: 5,
    backgroundColor: "white",
  };
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto 4% ",
        }}
      >
        <div style={headerStyle}>Type</div> <div style={headerStyle}>Time</div>{" "}
        <div style={headerStyle}>Address</div>
        <div style={headerStyle}>Distance To Location (miles)</div>
        <div> </div>
        {/* ensure that this is an existing report and not a newly created one.  */}
        {currentReport.length > 1
          ? currentReport.map((stop, key) => {
              let capturedTime = stop.GPSStamp.timestamp;

              let UTCToTZ = moment.tz(capturedTime, timeZone);
              let formattedTime = UTCToTZ.format("MMMM Do YYYY, h:mm A");
              return (
                <React.Fragment key={key}>
                  <div style={cellStyle}>{stop.updateType}</div>
                  <div style={cellStyle}>{formattedTime}</div>
                  <div style={cellStyle}>{stop.locationAddress}</div>
                  <div style={cellStyle}>
                    {stop.distanceTo
                      ? Math.round(10 * stop.distanceTo) / 10
                      : 0}{" "}
                  </div>
                  <div style={cellStyle}>
                    {canEdit ? (
                      <HoverDelRow
                        onClick={() => {
                          //console.log("hit here???");
                          integrateNewRow("delete", key);
                        }}
                      >
                        <TiDelete />
                      </HoverDelRow>
                    ) : null}
                  </div>
                </React.Fragment>
              );
            })
          : null}
        <div style={{ gridColumn: "1 / -1" }}>
          {canEdit ? (
            <AddRow token={token} integrateNewRow={integrateNewRow} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const HoverDelRow = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 40px;
  textalign: left;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

export default ConsumerHOC(MileageReport);
