import React, { useState } from "react";
import RefillScheduleWeekTabs from "./RefillScheduleWeekTabs";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../Input";
import Button from "./Button";
import RefillScheduleDayTabs from "./RefillScheduleDayTabs";

const RefillScheduleFilter = (props) => {
  // console.log("props in pcf admin filter", props);
  let {
    workingCopiesToList,
    updateWorkingCopiesToList,
    weekNum,
    toggleWeek,
    moveEntireDownloadToDisplay,
    updateDisplayList,
    displayList,
    handleUpdateFilter,
    updateFilters,
  } = props;

  // which weekNum to view - contains nuumber

  let [day, toggleDay] = useState(null);
  let [name, toggleName] = useState(null);

  let [workingCopiesSortedByWeek, updateworkingCopiesSortedByWeek] = useState(
    {}
  );

  let [workingCopiesSortedByDay, updateWorkingCopiesSortedByDay] = useState({});

  return (
    <div>
      <FilterDash
        weekNum={weekNum}
        workingCopiesSortedByWeek={workingCopiesSortedByWeek}
        displayList={displayList}
        updateDisplayList={updateDisplayList}
        handleUpdateFilter={handleUpdateFilter}
        updateFilters={updateFilters}
      />
      <RefillScheduleWeekTabs
        toggleWeek={toggleWeek}
        weekNum={weekNum}
        //weekNumbers={Object.keys(workingCopiesSortedByWeek)}
        toggleDay={toggleDay}
        moveEntireDownloadToDisplay={moveEntireDownloadToDisplay}
      />
      <RefillScheduleDayTabs
        workingCopiesSortedByDay={workingCopiesSortedByDay}
        weekNum={weekNum}
        updateWorkingCopiesToList={updateWorkingCopiesToList}
        workingCopiesToList={workingCopiesToList}
        toggleDay={toggleDay}
        displayList={displayList}
        updateDisplayList={updateDisplayList}
        handleUpdateFilter={handleUpdateFilter}
      />
    </div>
  );
};

const FilterDash = (props) => {
  let { handleUpdateFilter, updateFilters } = props;

  const FilterFunc = (e) => {
    // console.log(e.target);
    const searchTerm = e.target.value;
    let inputSection = e.target.name;

    handleUpdateFilter(inputSection, searchTerm);
  };

  let bools = [
    { label: "Locked", value: true },
    { label: "Unlocked", value: false || null },
  ];

  let MDOrder = [
    { label: "Signed", value: true },
    { label: "Unsigned", value: false || null },
  ];

  return (
    <div className="FilterRow">
      <div className="FilterTitle">Filter Reports By</div>
      <Input
        label="Patient"
        onChange={FilterFunc}
        fullWidth={false}
        style={{ margin: 0 }}
      />
      <Input
        label="Nurse"
        fullWidth={false}
        onChange={FilterFunc}
        style={{ margin: 0 }}
      />
      <DropDown
        choices={bools}
        title="RescheduleLock"
        FilterFunc={FilterFunc}
      />{" "}
      <DropDown
        choices={MDOrder}
        title="MDOrderStatus"
        FilterFunc={FilterFunc}
      />{" "}
      <div style={{ width: "10%" }}>
        {" "}
        <Button
          func={() => {
            updateFilters([]);
          }}
          label="Reset Filters"
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DropDown = (props) => {
  let { choices, title, FilterFunc } = props;
  const classes = useStyles();
  let [value, updateValue] = useState(null);

  const handleChange = (e) => {
    updateValue(e.target.value);
    e.target.name = title;
    FilterFunc(e);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
        >
          {choices.map((val, id) => {
            if (val.label) {
              return (
                <MenuItem value={val.value} key={id}>
                  {val.label}
                </MenuItem>
              );
            }
            return (
              <MenuItem value={val} key={id}>
                {val}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default RefillScheduleFilter;
