import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import PCFTableActual from "./PCFTableActual";
import FormWizard from "../FormWizard/FormWizard";
import questionaire from "./PCFQuestionaire";
import "./pcf.css";
import moment from "moment";
import Button from "../Button";
import axios from "axios";
import { Info } from "../../config";
import Grid from "@material-ui/core/Grid";
//import { makeStyles } from "@material-ui/core/styles";
import PCFSubmitComponent from "../FormWizard/SubmitReviewButton";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
// }));

const PCFTable = (props) => {
  // console.log(props);
  let { token } = props;
  let [viewPCF, toggleViewPCF] = useState(false);

  const CheckDBForForm = async (data) => {
    let { VisitNum } = data;
    // console.log(VisitNum);
    //toggleViewPCF(data)

    //check db for incomplete form and then import data
    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/pcf/viewReport`,
        data: { VisitNum },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.status === 200) {
        let queryResults = results.data[0];
        toggleViewPCF({ ...data, queryResults });
      }
    } catch (err) {
      // console.log("should be an error here!!!");
    }
  };

  // useEffect(()=>{

  // }, [toggleViewPCF])

  if (!viewPCF) {
    return <PCFTableActual {...props} toggleViewPCF={CheckDBForForm} />;
  } else {
    return (
      <div className="PCFOuter">
        <PatientInfoDisplay
          patientData={viewPCF}
          {...props}
          goBack={() => toggleViewPCF(false)}
        />
        <FormWizard
          {...props}
          relevantData={viewPCF}
          inputQuestionaire={questionaire}
          goBack={() => toggleViewPCF(false)}
          //must pass in a submission component outside of FormWizard library
          SubmitComponent={PCFSubmitComponent}
          submitAPI="pcf/submitReport"
        />
      </div>
    );
  }
};

export const PatientInfoDisplay = (props) => {
  // console.log(props);
  let { patientData, rows, goBack } = props;
  let { PatientName, VisitDate, VisitType, reportStatus, MRN } = patientData;

  //if this stops working, check the sql query, it may be too narrow and not capture an alarm date a little further out.
  const findNextAlarmDate = (mrn, allVisitsList) => {
    let nextAlarmEvent;
    const alarmList = ["Alarm Date", "Potential Alarm Date"];
    allVisitsList.forEach((visit) => {
      if (
        parseInt(visit.MRN) === parseInt(mrn) &&
        alarmList.includes(visit.TEXT_)
      ) {
        let convertedTouchDate = visit.TOUCHDATE
          ? moment(visit.TOUCHDATE.split("T")[0]).format("MM/DD/YYYY")
          : null; 

        if (!nextAlarmEvent) {
          visit.TOUCHDATE = convertedTouchDate;
          nextAlarmEvent = visit;
        } else if (nextAlarmEvent.TOUCHDATE < convertedTouchDate) {
          visit.TOUCHDATE = convertedTouchDate;
          nextAlarmEvent = visit;
        }
      }
    });

    // console.log("what is next alarm event", nextAlarmEvent);
    return nextAlarmEvent
      ? moment(nextAlarmEvent.DUEDATE.split("T")[0]).format("MMMM, DD YYYY")
      : null;
  };

  let [nextAlarm, updateNextAlarm] = useState(
    findNextAlarmDate(parseInt(MRN), rows)
  );

  const returnToPrior = () => {
    goBack(false);
  };

  return (
    <div className="PatientInfo">
      <div className="PatientInfoParent">
        <Grid container spacing={2}>
          <Grid item xs={10} sm={2}>
            <div>
              {" "}
              <Button
                className="goBackBtn"
                label="Go Back (lose all answers)"
                func={returnToPrior}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="PatientInfoTitle">Patient Coordination Form </div>
          </Grid>
          <Grid item xs={5} sm={2}>
            <div className="PatientInfoSubsection">
              <div className="PatientInfoHeader">Patient Name:</div>
              <div>{PatientName}</div>
            </div>
          </Grid>

          <Grid item xs={5} sm={2}>
            <div className="PatientInfoSubsection">
              <div className="PatientInfoHeader">Visit Date:</div>
              <div>{VisitDate}</div>
            </div>
          </Grid>

          <Grid item xs={5} sm={2}>
            <div className="PatientInfoSubsection">
              <div className="PatientInfoHeader">Visit Type:</div>
              <div>{VisitType}</div>
            </div>
          </Grid>

          <Grid item xs={5} sm={2}>
            <div className="PatientInfoSubsection">
              <div className="PatientInfoHeader">Report Status:</div>
              <div>{reportStatus ? reportStatus : "Begun"}</div>
            </div>
          </Grid>

          <Grid item xs={5} sm={2}>
            <div className="PatientInfoSubsection">
              <div className="PatientInfoHeader">Next Alarm Date:</div>
              <div>{nextAlarm ? nextAlarm : "N/A"}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ConsumerHOC(PCFTable);
