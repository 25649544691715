import React from "react";
import MenuList from "./MenuList";
import axios from "axios";
import { FaSearchLocation } from "react-icons/fa";
import Input from "./Input.js";
import Styles from "../ParentStyles.json";
import styled from "styled-components";
import { Info } from "../config.js";

const HoverAddRow = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 50px;
  textalign: left;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

const MileageReportWorkSheetAddressSection = (props) => {
  let {
    addressList,
    updateRowData,
    rowData,
    token,
    updateAddressList,
    dataFormatter,
    selectAddressFromList,
  } = props;

  if (addressList === null) {
    return (
      <AddressSearch
        updateRowData={updateRowData}
        rowData={rowData}
        token={token}
        updateAddressList={updateAddressList}
      />
    );
  } else if (Array.isArray(addressList) && addressList.length === 1) {
    let formattedAddress = dataFormatter(addressList);
    selectAddressFromList(formattedAddress[0]);
    return null;
  } else if (Array.isArray(addressList)) {
    return (
      <MenuList
        dataFormatter={dataFormatter}
        data={addressList}
        func={selectAddressFromList}
      />
    );
  } else {
    return <div>"No Data, please try again"</div>;
  }
};

const AddressSearch = (props) => {
  let { updateRowData, rowData, token, updateAddressList } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Input
        onChange={(e) => {
          updateRowData({ ...rowData, address: e.target.value });
        }}
        default={rowData.address}
      />{" "}
      <HoverAddRow
        onClick={async () => {
          let output = await addressToLatLonList(rowData.address, token);
          if (output.status === "OK") {
            updateAddressList(output.results);
          } else {
            updateAddressList(output.status);
          }
        }}
      >
        <FaSearchLocation style={{ color: Styles["darkColor"], margin: 10 }} />
      </HoverAddRow>
    </div>
  );
};

const addressToLatLonList = async (address, token) => {
  try {
    let addressList = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${Info.domain}/daily/addresstolatlon`,
      data: {
        address,
      },
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    //console.log(addressList.data);
    return addressList.data;
  } catch (err) {
    //console.log("what is err", err);
    return err;
  }
};

export default MileageReportWorkSheetAddressSection;
