import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "../Button";
import { withRouter } from "react-router-dom";
import ConsumerHOC from "../../context/ConsumerHOC";
import Input from "../Input";
import axios from "axios";
import { Info } from "../../config.js";
import { MdEdit } from "react-icons/md";
import ReuseableModal from "../../components/ReuseableModal.js";
import NewInstructions from "./NewInstructions";
import styled from "styled-components";
import ParentStyles from "../../ParentStyles.json";
import Spin from "../Spin";

const domain = Info.domain;

const useStyles = makeStyles({
  card: {
    flexBasis: "25%",
    flex: 1,

    minHeight: 800,

    margin: "1%",
    padding: 20,
    alignItems: "center",
  },

  title: {
    fontSize: 14,
  },
  cardContent: {
    padding: 5,
    width: "75%",
    alignItems: "center",
    margin: "auto",
  },
});

const HoverText = styled.span`
  font-size: 20px;
  color: ${ParentStyles["darkColor"]};

  :hover {
    color: ${ParentStyles["intermediateColor"]};

    cursor: pointer;
  }
`;

function MDInstSearch(props) {
  const classes = useStyles();
  //console.log("search optios props", props);
  const options = [
    {
      value: "ProviderFirstName",
      label: "Provider First Name",
      status: true,
    },
    { value: "ProviderLastName", label: "Provider Last Name", status: true },
  ];

  let [searchObject, editSearchObject] = useState({});
  let [searchStatus, changeSearchStatus] = useState(false);
  let [searchResults, updateSearchResults] = useState([]);
  let [isSearching, updateIsSearching] = useState(false);

  const handleSubmit = async () => {
    //console.log("submitted!", searchObject);
    if (searchStatus) {
      try {
        updateIsSearching(true);
        const results = await axios({
          method: "post", // you can set what request you want to be
          url: `https://${domain}/daily/searchall`,
          data: searchObject,
          headers: {
            Authorization: `bearer ${props.token}`,
          },
        });
        //console.log(results.data);
        updateSearchResults(results.data);
      } catch (err) {
        //console.log("error", err);
      } finally {
        updateIsSearching(false);
      }
    } else {
      return;
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    editSearchObject({ ...searchObject, [name]: value });
  };

  useEffect(() => {
    if (
      searchObject["Provider First Name"] ||
      searchObject["Provider Last Name"]
    ) {
      changeSearchStatus(true);
    }
  }, [searchObject]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentTitle}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{
              backgroundColor: "#15794F",
              padding: 20,
              margin: 0,
              borderRadius: 5,
            }}
          >
            {/* section title here */}
            Search Providers
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            style={{
              padding: 20,
              margin: 0,
              borderRadius: 5,
            }}
          >
            {/* section title here */}
            Note: All searches are case insensitive, and will return results
            from partial inputs.
          </Typography>
        </CardContent>
        <CardContent className={classes.cardContent}>
          {options.map((option, id) => {
            return (
              <Input
                key={id}
                label={option.label}
                name={option.value}
                onChange={handleChange}
              />
            );
          })}
          <Button
            label="Search"
            func={() => handleSubmit()}
            disabled={!searchStatus}
          />
          <div>
            <ResultsUI
              results={searchResults}
              token={props.token}
              isSearching={isSearching}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

const ResultsUI = (props) => {
  let { results, token, isSearching } = props;

  if (isSearching) {
    return <Spin style={{ margin: 50 }} />;
  } else if (results.length < 1) {
    return "No Results";
  } else {
    return <DisplayResults results={results} token={token} />;
  }
};

const DisplayResults = (props) => {
  let { results, token } = props;
  const openModal = (provider) => {
    //console.log("open modal", provider);
    updateModalState(true);
    updateRow(provider);
  };

  const onSubmit = async (data, status) => {
    const results = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/daily/update`,
      data: { id: status.NO, instructionValue: data },
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };

  let [modalState, updateModalState] = useState(false);
  let [row, updateRow] = useState(null);
  let [instructions, updateInstructions] = useState(null);
  let header = {
    fontWeight: "bold",
    margin: 10,
    padding: 5,
    borderRadius: 10,
    borderColor: ParentStyles["darkColor"],
    borderWidth: 3,
    borderStyle: "solid",
  };
  return (
    <React.Fragment>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto",
          margin: 25,
          width: "75%",
        }}
      >
        <div></div>
        <div style={header}>Provider First Name</div>
        <div style={header}>Provider Last Name</div>
        <div style={header}>Instructions</div>
        {results.map((result, id) => {
          return (
            <React.Fragment key={id}>
              <div onClick={() => openModal(result)}>
                <HoverText>
                  <MdEdit />
                </HoverText>
              </div>
              <div>{result.PH_FIRST}</div>
              <div>{result.PH_LAST}</div>
              <div>
                {result.Instructions ? result.Instructions : "Not Applicable"}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <ReuseableModal
        openStatus={modalState}
        close={updateModalState}
        func={onSubmit}
        data={instructions}
        status={row}
      >
        <NewInstructions
          row={row}
          updateInstructions={updateInstructions}
          instructions={instructions}
        />
      </ReuseableModal>
    </React.Fragment>
  );
};

export default ConsumerHOC(withRouter(MDInstSearch));
