import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const ReviewDialog = (props) => {
  let {
    dialogStatus,
    handleDialogClose,
    Component,
    dataToPass,
    visitID,
    token,
  } = props;

  return (
    <Dialog
      maxWidth="lg"
      open={dialogStatus}
      //TransitionComponent={Transition}
      keepMounted
      //onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {Component ? (
            <Component
              token={token}
              dataToPass={dataToPass}
              handleDialogClose={handleDialogClose}
              visitID={visitID}
              //modState is primarily for passing info to ModifyScheduleForm
              modState={dataToPass}
              //placing toggle mod  and toggleViewState so as to not crash component, not actually doing anything
              toggleMod={() => {
                // console.log("running dummy");
              }}
              toggleViewState={() => {
                // console.log("running dummy");
              }}
            />
          ) : (
            "Error Occured"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={() => handleDialogClose()} color="primary">
          Disagree
        </Button> */}
        <Button onClick={() => handleDialogClose()} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewDialog;
