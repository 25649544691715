import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ViewImage from "./ViewImage";
import Modal from "./Modal";
import moment from "moment";
import ConsumerHOC from "../context/ConsumerHOC";
import EditDate from "./EditDate.js";

const useStyles = makeStyles({
  card: {
    flexBasis: "25%",
    flex: 1,
    minWidth: "10%",
    minHeight: 500,
    maxWidth: "40%",
    margin: "1%",
    padding: 20,
  },

  title: {
    fontSize: 14,
  },
  cardContent: { padding: 5 },
});

//function serves to exclude certain keywords from display
const excludeKeys = (keyName) => {
  let arr = [
    "visitData",
    "submitterData",
    "visitLocation",
    "_id",
    "updatedInfo",
    "isProgressNote",
    "endingLocation",
    "sendEmail",
    "visitStartTS",
  ];
  if (arr.includes(keyName)) {
    return false;
  }
  return true;
};

const dataLine = (x, y) => {
  return (
    <div style={{ textAlign: "left" }} key={x + y}>
      {x} : {y}{" "}
    </div>
  );
};

const ifObj = (x) => {
  let toReturn = [];
  if (Array.isArray(x.data)) {
    for (let i of x.data) {
      toReturn.push(dataLine(Object.keys(i)[0], Object.values(i)[0]));
    }

    return toReturn;
  } else if (Array.isArray(x)) {
    return x.map((y) => {
      return <div>{y} </div>;
    });
  } else if (x["coords"]) {
    return (
      <>
        <ul style={{ listStyleType: "none" }}>
          <li>Latitude: {x["coords"]["latitude"]}</li>
          <li>Longitude: {x["coords"]["longitude"]}</li>
        </ul>
      </>
    );
  }
};

const ifSignature = (key, data, i) => {
  if (key === "Signature" || key === "Final Nurse Signature") {
    let image = <ViewImage imgStr={data[key]} />;

    return <Modal props={image} />;
  } else {
    return (
      <>
        <Typography style={{ color: "black" }} key={i} component={"span"}>
          {data[key]}
        </Typography>
      </>
    );
  }
};

function SimpleCard(props) {
  const classes = useStyles();
  //console.log("what are te props", props);
  if (
    props.title === "undefined" ||
    props.title === "next" ||
    props.title === "Final Submission"
  ) {
    return null;
  } else {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContentTitle}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            color="white"
            style={{
              backgroundColor: "#15794F",
              padding: 20,
              margin: 0,
              borderRadius: 5,
              color: "white",
            }}
          >
            {/* section title here */}
            Submission Information
          </Typography>
        </CardContent>

        <CardContent className={classes.cardContent}>
          {Object.keys(props.data).map((keyName, i) => {
            //serves to exclude certain keywords/data from being displayed
            if (excludeKeys(keyName))
              return (
                <Typography color="primary" key={i} component={"span"}>
                  <div
                    component={"span"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "noWrap",
                    }}
                  >
                    <div
                      component={"span"}
                      style={{
                        margin: 10,
                        padding: 10,
                        flexGrow: 2,
                        flexBasis: "30%",
                        alignSelf: "flexStart",
                        backgroundColor: "#d8d8d8",
                        borderRadius: 10,
                        fontWeight: "900",
                        fontSize: 15,
                      }}
                    >
                      {`${keyName}: `}
                    </div>
                    <ValueRender {...props} keyName={keyName} />
                  </div>
                </Typography>
              );
          })}
        </CardContent>
      </Card>
    );
  }
}

const ValueRender = (props) => {
  if (props.keyName !== "visitStart") {
    return (
      <div
        style={{
          fontWeight: "400",
          fontSize: 10,
          backgroundColor: "#d8d8d8",
          borderRadius: 10,
          margin: 10,
          padding: 10,
          flexGrow: 3,
          alignSelf: "flexStart",
          flexBasis: "50%",
          borderWidth: 2,
        }}
      >
        <Typography component={"span"} style={{ color: "black", fontSize: 15 }}>
          {typeof props.data[props.keyName] === "number" ||
          typeof props.data[props.keyName] === "string" ||
          typeof props.data[props.keyName] === "boolean"
            ? formatDateObj(props.keyName, props.data[props.keyName])
            : null}
        </Typography>
      </div>
    );
  }

  if (props.keyName === "visitStart") {
    let date = props.data.visitStart;
    date = new Date(date);

    return (
      <div
        style={{
          fontWeight: "400",
          fontSize: 10,
          backgroundColor: "#d8d8d8",
          borderRadius: 10,
          margin: 10,
          padding: 10,
          flexGrow: 3,
          alignSelf: "flexStart",
          flexBasis: "50%",
          borderWidth: 2,
        }}
      >
        <EditDate {...props} initialDate={props.data.visitStart} />{" "}
      </div>
    );
  }
};

//formats info in submission information column
const formatDateObj = (header, data) => {
  let headerTypes = ["visitStart", "InitialUploadAt", "uploadedAt", "endTime"];
  if (headerTypes.includes(header)) {
    //convert data to new date string
    return moment(data).format("MMMM Do YYYY, h:mm a");
  } else if (typeof data === "boolean") {
    return data.toString();
  } else {
    return data;
  }
};

export default ConsumerHOC(SimpleCard);
