//const moment = require("moment");

const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "New Visit Date",
  },
  {
    id: "originalDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Original Schedule Date",
  },

  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },

  {
    id: "Note",
    numeric: false,
    disablePadding: false,
    label: "Note",
  },
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
];

const createData = function (
  PatientName,
  originalDate,
  VisitType,
  VisitDate,
  NurseName,
  Note,
  ALERT_C,
  VisitNum,
  AlertText,
  updatedBy,
  pharmacyStatus
) {
  return {
    PatientName,
    originalDate,
    VisitType,
    VisitDate,
    NurseName,
    Note,
    ALERT_C,
    VisitNum,
    AlertText,
    updatedBy,
    pharmacyStatus,
  };
};

const rowMapper = (incoming) => {
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let patientName;
  let NurseName;
  let VisitDate;
  let visitNum;
  let visitType;
  let Note;
  let Alerts;
  let AlertText;
  let updatedBy;
  let originalDate;
  let pharmacyStatus;

  incoming.forEach((row, id) => {
    // console.log(row);
    try {
      patientName = row.Patient_First_Name + " " + row.Patient_Last_Name;
      originalDate = row.originalDate;
      visitType = row.TEXT_;
      NurseName = row.Nurse_First_Name + " " + row.Nurse_Last_Name;

      VisitDate = row.DUEDATE;
      Note = row.Note ? row.Note : null;
      Alerts = row.ALERT_C ? "View Alert" : null;
      visitNum = row.Visit_Num;
      AlertText = Alerts ? row.ALERT_C : null;
      updatedBy = row.updaterFNAME
        ? row.updaterFNAME + " " + row.updaterLNAME
        : null;
      pharmacyStatus = row.pharmacyStatus;
    } catch (err) {
      console.error(err);
    } finally {
      let rowObj = createData(
        patientName ? patientName : "err",
        originalDate ? originalDate.split("T")[0] : "err",
        visitType ? visitType : "err",
        //ensure that timezone issues and moment library don't mess up the date.
        VisitDate ? VisitDate.split("T")[0] : "err",
        NurseName ? NurseName : "err",
        Note ? Note : null,
        Alerts ? Alerts : null,
        visitNum ? visitNum : "err",
        AlertText ? AlertText : null,
        updatedBy ? updatedBy : null,
        pharmacyStatus ? pharmacyStatus : null
      );
      returnArr.push(rowObj);
    }
  });
  // console.log(returnArr);
  return returnArr;
};

//determine what color row is.
const colorRow = (type, row) => {
  // console.log(type, row);

  if (row.pharmacyStatus === "unacknowledged") {
    return "#f79292";
  } else if (row.pharmacyStatus === "acknowledged") {
    return null;
  } else return "#f79292";
};

module.exports = { createData, headCells, rowMapper, colorRow };
