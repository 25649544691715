import React from "react";
import "./DemographicMap.css";
import SideBarNurse from "./SideBarNurse";
import SideBarPatient from "./SideBarPatient";

const MapDataDisplay = (props) => {
  let { sideBarView } = props;
  // console.log(props);
  //Pretty sure don't need any of this stuff commented out.
  // let FNAME;
  // let LNAME;
  // let Address;
  // let City;
  // let State;
  // let NO;
  // let nurse;
  // let nurseID;
  // let distance;

  // if (sideBarView.data) {
  //   FNAME = sideBarView.data.FNAME || sideBarView.data.properties.FNAME;
  //   LNAME = sideBarView.data.LNAME || sideBarView.data.properties.LNAME;
  //   Address = sideBarView.data.Address || sideBarView.data.properties.Address;
  //   City = sideBarView.data.CITY || sideBarView.data.properties.City;
  //   State = sideBarView.data.STATE || sideBarView.data.properties.State;
  //   NO = sideBarView.data.NO || sideBarView.data.properties.patientId;
  //   nurse =
  //     sideBarView.type === "patient" && sideBarView.data.properties.nurse
  //       ? sideBarView.data.properties.nurse
  //       : null;
  // }

  if (sideBarView.type === null || sideBarView.id === null) {
    return (
      <div className="projectNote">
        No data point selected, please select either a nurse or a patient in
        order to view data.
      </div>
    );
  } else if (sideBarView.type === "nurse") {
    return <SideBarNurse sideBarView={sideBarView} />;
  } else if (sideBarView.type === "patient") {
    return <SideBarPatient sideBarView={sideBarView} />;
  }
};

export default MapDataDisplay;
