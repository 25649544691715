import React from "react";

const Tab = ({ label, onClick, activeTab, allowedPermission, permissions }) => {
  // Assuming 'permissions' is an array of permissions the current user has,
  // and 'allowedPermission' is the permission required to show this component.

  // Check if the user has the required permission.
  const hasPermission = allowedPermission.some(permission => permissions?.includes(permission));

  // Render button only if user has the required permission.
  if (hasPermission) {
    return (
      <button
        className={activeTab === label ? "tab active" : "tab"}
        onClick={() => onClick(label)}
      >
        {label}
      </button>
    );
  } else {
    // Return null or any other fallback if the user does not have the required permission.
    return null;
  }
};

export default Tab;
