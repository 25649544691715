import React, { useState, useEffect } from "react";
import MarketingContactsTable from "./MarketingContactsTable";
import ContactFilter from "./ContactFilter";
import Spin from "../Spin";

const PickAccountList = (props) => {
  let { accountsList, updatePickedAccount, isSearching } = props;
  // console.log(props);

  let [dataToDisplay, updateDataToDisplay] = useState(accountsList);

  useEffect(() => {
    if (!dataToDisplay.length) {
      updateDataToDisplay(accountsList);
    }
  }, [dataToDisplay.length, accountsList]);

  if (isSearching) {
    return (
      <div>
        <Spin />
      </div>
    );
  }
  return (
    <React.Fragment>
      <ContactFilter
        contacts={dataToDisplay}
        updateParentData={updateDataToDisplay}
        fullAccountsList={accountsList}
      />
      <MarketingContactsTable
        workingCopiesToView={dataToDisplay}
        updatePickedAccount={updatePickedAccount}
      />
    </React.Fragment>
  );
};

export default PickAccountList;
