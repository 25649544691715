//"Comments": "Each object name is permission level
//'edit' value states whether that permission level can edit a report
//'renderAll' can either take true, or an array. It indicates which of the report's questions will be rendered at this permission level.
//'BtnFunctions' key contains an array of objects accessible by that permission level and will pass it in as props. The objects will have a
//key and value corresponding to the button title and the function it corresponds to
//additional information may be contained within the object such as status information to be passed to that function

const config = {
  nurse: {
    edit: true,
    renderAll: true,
    moveOrEditIf: ["working copy", "Returned"],
    BtnFunctions: [
      { title: "Save Changes", fn: "saveChanges" },
      {
        title: "Submit To Weekly's",
        fn: "SubmitToWeeklys",
        status: ["OpenMC", "Revised"],
      },
      {
        title:
          "Discard Report (Report will be removed from your list without submission)",
        fn: "Discard",
        status: "Discard",
      },
    ],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["working copy", "Returned"],
        api: "retrieveOpenReports",
      },
      { title: "Add New Progress Note", pathname: "/AddProgressReport" },
      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      {
        title: "Patients Chart",
        pathname: "patients/",
      },
      {
        title: "Verbal Order",
        pathname: "/VerbalOrder",
      },
      {
        title: "Mileage",
        pathname: "/Reports",
        api: "daily/retrieveMileageReports/",
      },
      {
        title: "Nursing Schedule",
        pathname: "/Reports",
        api: "scheduleReconciliation/",
        permissions: "w",
      },
      // { //Add this back in when work on nurse salary 3 hour thing.
      //   title: "Patient Census",
      //   pathname: "/Census",
      // },

      {
        title: "PCF",
        pathname: "/Reports",
        api: "pcf/",
      },
      // {
      //   title: "Care Plan",
      //   pathname: "/Reports",
      // },

      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
    ],
    searchOptions: {
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },
        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
      Mileage: [
        { value: "Nurse First Name", label: "Nurse First Name", status: true },
        { value: "Nurse Last Name", label: "Nurse Last Name", status: true },
        {
          value: "Mileage Greater Than",
          label: "Mileage Greater Than",
          status: true,
        },
        {
          value: "Mileage Less Than",
          label: "Mileage Less Than",
          status: true,
        },
        {
          value: "Mileage Report Status",
          label: "Report Status",
          status: true,
        },
      ],
    },
  },
  ipmservices: {
    edit: true,
    renderAll: true,
    moveOrEditIf: ["working copy", "Returned", "Discard"],
    BtnFunctions: [
      { title: "Save Changes", fn: "saveChanges" },
      {
        title: "Submit To Weekly's",
        fn: "SubmitToWeeklys",
        status: ["OpenMC", "Revised"],
      },
      {
        title:
          "Discard Report (Report will be removed from your list without submission)",
        fn: "Discard",
        status: "Discard",
      },
    ],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["working copy", "Returned"],
        api: "retrieveOpenReports",
      },
      { title: "Add New Progress Note", pathname: "/AddProgressReport" },
      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      {
        title: "Patients Chart",
        pathname: "patients/",
      },
      {
        title: "Mileage",
        pathname: "/Reports",
        api: "daily/retrieveMileageReports/",
      },
      {
        title: "Nursing Schedule",
        pathname: "/Reports",
        api: "scheduleReconciliation/",
        permissions: "w",
      },
      // { //Add this back in when work on nurse salary 3 hour thing.
      //   title: "Patient Census",
      //   pathname: "/Census",
      // },
      {
        title: "PCF",
        pathname: "/Reports",
        api: "pcf/",
      },
      // {
      //   title: "Care Plan",
      //   pathname: "/Reports",
      // },

      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
      {
        title: "Verbal Order",
        pathname: "/VerbalOrder",
      },
    ],
    searchOptions: {
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },

        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
      Mileage: [
        { value: "Nurse First Name", label: "Nurse First Name", status: true },
        { value: "Nurse Last Name", label: "Nurse Last Name", status: true },
        {
          value: "Mileage Greater Than",
          label: "Mileage Greater Than",
          status: true,
        },
        {
          value: "Mileage Less Than",
          label: "Mileage Less Than",
          status: true,
        },
        {
          value: "Mileage Report Status",
          label: "Report Status",
          status: true,
        },
      ],
    },
  },
  mc: {
    edit: false,
    renderAll: true,
    moveOrEditIf: [
      "OpenMC",
      "Finalized",
      "Revised",
      "working copy",
      "Returned",
    ],
    BtnFunctions: [
      { title: "Approve and File PDF", fn: "Finalize", status: "Finalized" },
      {
        title: "Return To Nurse",
        fn: "WeeklysStatusChange",
        status: "Returned",
      },
      {
        title:
          "Discard Report (Report will be removed from your list without submission)",
        fn: "Discard",
        status: "Discard",
      },
      { title: "Create Daily", fn: "CreateDaily" },
    ],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["OpenMC", "Returned", "Revised"],
        api: "retrieveOpenReports",
      },
      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      {
        title: "Edit Documentation Sharing Instructions",
        pathname: "/Reports",
        api: null,
      },
      { title: "Performance Metrics", pathname: "/PerformanceMetrics" },
      {
        title: "Mileage",
        pathname: "/Reports",
        api: "daily/retrieveMileageReports/",
      },
      {
        title: "Mileage Tracker Enable/Disable",
        pathname: "/Reports",
        api: "daily/mileage/status/",
      },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
    ],
    searchOptions: {
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "Submitter First Name",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "Submitter Last Name",
          label: "Submitter Last Name",
          status: true,
        },
        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
      Mileage: [
        { value: "Nurse First Name", label: "Nurse First Name", status: true },
        { value: "Nurse Last Name", label: "Nurse Last Name", status: true },
        {
          value: "Mileage Greater Than",
          label: "Mileage Greater Than",
          status: true,
        },
        {
          value: "Mileage Less Than",
          label: "Mileage Less Than",
          status: true,
        },
        {
          value: "Mileage Report Status",
          label: "Report Status",
          status: true,
        },
      ],
    },
  },
  billing: {
    edit: false,
    renderAll: ["specific QId's go here"],
    BtnFunctions: [],
    Menu: [
      {
        title: "PCF Admin",
        pathname: "/Reports",
        api: "pcf/admin/",
      },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
      {
        title: "Dashboard",
        pathname: "/dashboard",
      },
    ],
  },
  intake: {
    edit: false,
    renderAll: ["specific QId's go here"],
    BtnFunctions: [],
    Menu: [
      {
        title: "PCF Admin",
        pathname: "/Reports",
        api: "pcf/admin/",
      },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
    ],
  },
  admin: {
    edit: false,
    renderAll: true,
    moveOrEditIf: [
      "OpenMC",
      "Finalized",
      "Revised",
      "Returned",
      "working copy",
    ],
    BtnFunctions: [
      { title: "Approve and File PDF", fn: "Finalize", status: "Finalized" },
      {
        title: "Return To Nurse",
        fn: "WeeklysStatusChange",
        status: "Returned",
      },
      {
        title:
          "Discard Report (Report will be removed from your list without submission)",
        fn: "Discard",
        status: "Discard",
      },
      { title: "Create Daily", fn: "CreateDaily" },
    ],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["OpenMC", "Returned", "Revised"],
        api: "retrieveOpenReports",
      },
      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      { title: "Demographic Map", pathname: "/DemographicsMap" },
      {
        title: "Patients Chart",
        pathname: "patients/",
      },
      { title: "Performance Metrics", pathname: "/PerformanceMetrics" },
      { title: "Clinical Metrics", pathname: "/ClinicalMetrics" },
      {
        title: "Nursing Schedule",
        pathname: "/Reports",
        api: "scheduleReconciliation/",
        permissions: "r",
      },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
      { title: "Marketing", pathname: "/Marketing" },
      {
        title: "Mileage",
        pathname: "/Reports",
        api: "daily/retrieveMileageReports/",
      },
      {
        title: "Edit Documentation Sharing Instructions",
        pathname: "/Reports",
        api: null,
      },
      {
        title: "Mileage Tracker Enable/Disable",
        pathname: "/Reports",
        api: "daily/mileage/status/",
      },
      {
        title: "Admin Panel",
        pathname: "/AdminPanel",
      },
    ],
    searchOptions: {
      NursingSchedule: [{ value: "Test", label: "test", status: true }],
      Mileage: [
        { value: "Nurse First Name", label: "Nurse First Name", status: true },
        { value: "Nurse Last Name", label: "Nurse Last Name", status: true },
        {
          value: "Mileage Greater Than",
          label: "Mileage Greater Than",
          status: true,
        },
        {
          value: "Mileage Less Than",
          label: "Mileage Less Than",
          status: true,
        },
        {
          value: "Mileage Report Status",
          label: "Report Status",
          status: true,
        },
      ],
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "Submitter First Name",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "Submitter Last Name",
          label: "Submitter Last Name",
          status: true,
        },
        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
    },
  },
  pharmacy: {
    edit: false,
    BtnFunctions: [],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["OpenMC", "Returned", "Revised"],
        api: "retrieveOpenReports",
      },

      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        // api: "PharmacyRefill/",
      },
    ],

    searchOptions: {
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "Submitter First Name",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "Submitter Last Name",
          label: "Submitter Last Name",
          status: true,
        },
        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
    },
  },
  callcenter: {
    edit: false,
    renderAll: true,
    moveOrEditIf: [
      "OpenMC",
      "Finalized",
      "Revised",
      "working copy",
      "Returned",
    ],
    BtnFunctions: [
      { title: "Approve and File PDF", fn: "Finalize", status: "Finalized" },
      {
        title: "Return To Nurse",
        fn: "WeeklysStatusChange",
        status: "Returned",
      },
      {
        title:
          "Discard Report (Report will be removed from your list without submission)",
        fn: "Discard",
        status: "Discard",
      },
      { title: "Create Daily", fn: "CreateDaily" },
    ],
    Menu: [
      {
        title: "Patient Reports",
        pathname: "/Reports",
        status: ["OpenMC", "Returned", "Revised"],
        api: "retrieveOpenReports",
      },
      {
        title: "View Progress Notes",
        pathname: "/Reports",
        api: "reviewNotes",
      },
      {
        title: "Edit Documentation Sharing Instructions",
        pathname: "/Reports",
        api: null,
      },
      { title: "Performance Metrics", pathname: "/PerformanceMetrics" },
      { title: "Admission Reports", pathname: "/AdmissionReport" },
      {
        title: "Pharmacy Refill Schedule",
        pathname: "/Reports",
        api: "PharmacyRefill/",
      },
      {
        title: "Dashboard",
        pathname: "/dashboard",
      },
    ],
    searchOptions: {
      Reports: [
        { value: "visitID", label: "Visit ID", status: true },
        {
          value: "Patient Last Name",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "Patient First Name",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "Submitter First Name",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "Submitter Last Name",
          label: "Submitter Last Name",
          status: true,
        },
        { value: "status", label: "Report Status", status: true },
        {
          value: "visitStartAfter",
          label: "Visit Start After Date",
          status: true,
        },
        {
          value: "visitStartBefore",
          label: "Visit Start Before Date",
          status: true,
        },
      ],
      ProgressNotes: [
        {
          value: "lastName",
          label: "Patient Last Name",
          status: true,
        },
        {
          value: "firstName",
          label: "Patient First Name",
          status: true,
        },
        {
          value: "submitterData.firstName",
          label: "Submitter First Name",
          status: true,
        },
        {
          value: "submitterData.lastName",
          label: "Submitter Last Name",
          status: true,
        },
        {
          value: "Uploaded After Date",
          label: "Uploaded After Date",
          status: true,
        },
        {
          value: "Uploaded Before Date",
          label: "Uploaded Before Date",
          status: true,
        },
        { value: "Email", label: "Department", status: true },
      ],
      Mileage: [
        { value: "Nurse First Name", label: "Nurse First Name", status: true },
        { value: "Nurse Last Name", label: "Nurse Last Name", status: true },
        {
          value: "Mileage Greater Than",
          label: "Mileage Greater Than",
          status: true,
        },
        {
          value: "Mileage Less Than",
          label: "Mileage Less Than",
          status: true,
        },
        {
          value: "Mileage Report Status",
          label: "Report Status",
          status: true,
        },
      ],
    },
  },
};

// const PNEmailList = [
//   "monitoringcenter@basichi.com",
//   "scheduling@basichi.com",
//   "weeklys@basichi.com",
//   "intake@basichi.com",
//   "pharmacyalert@basichi.com",
// ];

export default config;

  
