const headCells = [
  {
    id: "PatientName",
    numeric: false,

    disablePadding: false,
    label: "Patient Name",
  },

  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  {
    id: "UploadDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

const createData = function (PatientName, NurseName, UploadDate, ID) {
  return {
    PatientName,
    NurseName,
    UploadDate,
    ID,
  };
};

const rowMapper = (incoming) => {
  let returnArr = [];
  if (!Array.isArray(incoming)) {
    return returnArr;
  }

  incoming.forEach((row, id) => {
    let rowObj = createData(
      `${row.lastName}, ${row.firstName}`,
      `${row.submitterData.lastName}, ${row.submitterData.firstName}`,
      row.uploadedAt,
      row._id
    );

    returnArr.push(rowObj);
  });

  return returnArr;
};

module.exports = { createData, headCells, rowMapper };
