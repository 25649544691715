import React, {useState, useEffect} from 'react';
import TransitionsModal from './DocumentsModal';
import CustomizedTables from './Table';
import { Document, Page } from 'react-pdf';
import { CircularProgress } from "@mui/material";
import { pdfjs } from 'react-pdf';
import './styles.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



export const RenderDocumentsView = ({ patient, totalPages, fetchPatientDocs, DOCS}) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); // Start with the first page
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [pageWidth, setPageWidth] = useState(getResponsiveWidth());
  
    const handleViewClick = (doc) => {
      setSelectedDoc(doc);
      setOpen(true)
    };

   // Function to calculate responsive width
   function getResponsiveWidth() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (screenWidth < 600) {
      return screenWidth - 20; // Subtract some margin
    }
    return 600; // Default width for larger screens
  }

  useEffect(() => {
    // Update page width on window resize
    const handleResize = () => {
      setPageWidth(getResponsiveWidth());
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const renderFileContent = (doc) => {
      const { DocumentImage, FileName } = doc;
  
      const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page when a new document is loaded
      };
    
      const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
      };
    
      const previousPage = () => {
        changePage(-1);
      };
    
      const nextPage = () => {
        changePage(1);
      };
    
      let fileContent;
    
      if (FileName.toLowerCase().endsWith(".pdf") || FileName.toLowerCase().endsWith(".sent") ) {
        const pdfBase64String = `data:application/pdf;base64,${DocumentImage}`;
        fileContent = (
          <>
            <Document
              file={pdfBase64String}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} width={pageWidth}/>
            </Document>
            <div>
              <p>Page {pageNumber} of {numPages}</p>
              <button disabled={pageNumber <= 1} onClick={previousPage}>
                Previous
              </button>
              <button disabled={pageNumber >= numPages} onClick={nextPage}>
                Next
              </button>
            </div>
          </>
        );
      }else if (FileName.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/)) {
        const imageUrl = `data:image/jpeg;base64,${DocumentImage}`;
        fileContent = <img src={imageUrl} alt={FileName} />;
      } else {
        fileContent = <p>No Record to show | Unsupported file format</p>;
      }
    
      return fileContent;
    };
  

    const handlePageChange = async (newPage) => {
      setLoading(true)
      setCurrentPage(newPage); 
      await fetchPatientDocs(patient, newPage)
      setLoading(false)
    };
    return (
      // <section style={{ ...styles.generalSection }}>
      <section className="generalSection">
        <h2 style={{ fontSize: "20px", margin: '0 0 12px 0' }}>Documents</h2>
        {loading ? (
            <section
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <CircularProgress />
            </section>
        ) : (
          <>
            <CustomizedTables DOCS={DOCS} handleViewClick={handleViewClick}/>
            <TransitionsModal open={open} pageWidth={pageWidth} setOpen={setOpen} handleClose={handleClose} selectedDoc={selectedDoc} renderFileContent={renderFileContent}/>
            {/* Pagination Controls */}
            <div style={{ marginTop: "20px" }}>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                <button key={page} onClick={() => handlePageChange(page)} style={page === currentPage ? styles.currentPageStyle : { margin: '0 5px' }}>
                  {page}
                </button>
              ))}
            </div>
          </>
        )}
      </section>
    );
}

const styles = {
    section: {
      padding: "20px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    generalSection: {
      backgroundColor: "rgb(227 227 227)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      borderRadius: "8px",
      margin: "20px 0",
      width: "80%",
    },
    currentPageStyle: {
      margin: '0 5px',
      fontWeight: 'bold',
      backgroundColor: '#4CAF50',
      color: 'white',
    },
    page: {
      width: '85%'
    }
  };