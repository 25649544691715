import React, { useState } from "react";
import Button from "../Button";
import "./Wizard.css";
import ConsumerHOC from "../../context/ConsumerHOC";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Info } from "../../config";
import PCFProcessor from "../PCF/PCFProcessorClass";

const SubmitReviewBtn = (props) => {
  // console.log(props);
  let {
    inReview,
    toggleReview,
    answers,
    questionaire,
    updateMissingInput,
    token,
    updateWorkingCopiesFromServer,
    workingCopies,
    goBack,
    submitAPI,
    relevantData,
    //if true then save button will be present
    saveButton,
  } = props;

  let [isError, toggleError] = useState(false);

  const BeforeReview = async () => {
    let results = await CheckForMandatoryAnswers();

    let missingInputLength = Object.keys(results).length;

    if (missingInputLength === 0) {
      toggleReview(!inReview);
    }
  };

  const CheckForMandatoryAnswers = () => {
    let missingQuestions = {};
    Object.keys(answers).forEach((section) => {
      let subsection = [];

      Object.keys(answers[section]).forEach((subsect) => {
        if (
          !answers[section][subsect] ||
          answers[section][subsect].length < 1 ||
          answers[section][subsect].size < 1
        ) {
          subsection.push(subsect);
        }
      });

      if (subsection.length > 0) {
        missingQuestions[section] = subsection;
      }
    });

    updateMissingInput(missingQuestions);
    return missingQuestions;
  };

  const submitAndLeave = async (status) => {
    // console.log(props);
    const domain = Info.domain;

    let emailHighlight;

    //sort out email and highlight triggers before sending
    if (status === "Admin Review" || status === "complete") {
      emailHighlight = await new PCFProcessor(
        answers,
        questionaire
      ).grabEmailAndHighlightData();
    }

    let email = null;
    let highlight = null;

    if (emailHighlight) {
      email = emailHighlight.email;
      highlight = emailHighlight.highlight;
    }
    let visitData = props.currentReport;

    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${domain}/${submitAPI}`,
        data: {
          answers,
          visitData: {
            PatientName: visitData.PatientName,
            VisitNum: visitData.VisitNum,
            VisitDate: visitData.VisitDate,
            MRN: visitData.MRN,
          },
          relevantData,
          status,
          highlight,
          email,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.status === 200) {
        if (status === "Admin Review" || status === "complete") {
          //function that removes current report from working copies and updates workingCopies
          updateWorkingCopies(visitData.VisitNum);
        }
        goBack(false);
      }
    } catch (err) {
      console.error(err);
      toggleError("Error while sending form, please contact IT");
    }
  };

  const updateWorkingCopies = (visitID) => {
    const newWorkingCopies = workingCopies.filter((arr) => {
      return arr.CPK_CAL !== parseInt(visitID);
    });

    updateWorkingCopiesFromServer(newWorkingCopies);
  };

  return (
    <div className="ReviewBtn">
      <div className="ReviewBtnChild">
        <Button
          label={!inReview ? "Review Your Submission" : "Return to Form"}
          func={BeforeReview}
          data={!inReview}
        />
      </div>
      {saveButton ? (
        <div className="ReviewBtnChild">
          <Button label="Save" func={submitAndLeave} data={"incomplete"} />
        </div>
      ) : null}
      <div className="ReviewBtnChild">
        {inReview ? (
          <Button label="Submit" func={submitAndLeave} data={"complete"} />
        ) : null}
      </div>
      {isError ? <div className="errorOnSubmit">{isError} </div> : null}
    </div>
  );
};

export default ConsumerHOC(withRouter(SubmitReviewBtn));
