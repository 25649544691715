import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const PCFAdminWeekTabs = (props) => {
  let { weekNumbers, toggleWeek } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(3);

  const handleChange = (event, newValue) => {
    // console.log(event, newValue);
    toggleWeek(weekNumbers[newValue]);
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {Array.isArray(weekNumbers) && weekNumbers.length > 0
          ? weekNumbers.map((week, idx) => {
              // console.log(week);
              return <Tab key={idx} label={weekToLabel(week)} value={idx} />;
            })
          : null}
      </Tabs>
    </Paper>
  );
};

const weekToLabel = (num) => {
  let startDate = moment().day("Monday").isoWeeks(num);
  let strStartDate = startDate.format("MMM D, YYYY");

  let endDate = startDate.add(6, "days");
  let strEndDate = endDate.format("MMM D, YYYY");
  return `${strStartDate} - ${strEndDate}`;

  // return `${moment()
  //   .day("Monday")
  //   .isoWeek(num)
  //   .format("MMM D, YYYY")} - ${moment()
  //   .day("Sunday")
  //   .isoWeek(num)
  //   .format("MMM D, YYYY")}`;
};
export default PCFAdminWeekTabs;
