import {
  emailTriggers,
  emailHeaders,
  conditionalEmailConfig,
} from "./RowDataTypes";

//parses incoming report from mobile and creates object of data
//that needs to be sent via email to monitoring center upon submission by nurse
//further refinement of data in server

//updates to this object are made by a second function within viewReport.js
export const shouldSendMCEmail = (incomingReportAns) => {
  let msgToSend = {};

  //check all except Patient Medication and push to msgToSend
  Object.keys(incomingReportAns).forEach((ansHeader) => {
    // console.log("what is msgToSend iterator", msgToSend);
    if (emailHeaders.includes(ansHeader)) {
      // console.log("includes it!", ansHeader);
      for (let subkey in incomingReportAns[ansHeader]["data"]) {
        //particularly checks for flow rate error and ERI months
        //ansHeader = entire section header
        //subkey = the question
        //incomingReportsAns = entire visit data/report answers
        let isConditionalEmail = ConditionalEmailer(
          ansHeader,
          subkey,
          incomingReportAns
        );

        //just checking if fits conditional Email (flow rate or ERI)
        if (isConditionalEmail) {
          msgToSend[ansHeader] = {
            ...msgToSend[ansHeader],
            [subkey]: incomingReportAns[ansHeader]["data"][subkey],
          };
        } else if (emailTriggers.includes(subkey)) {
          // console.log("does incldue subkey", subkey);
          if (
            incomingReportAns[ansHeader]["data"][subkey].includes(
              "Confirmed, No Changes Reported"
            ) ||
            incomingReportAns[ansHeader]["data"][subkey] ===
              "Confirmed, No Changes Reported"
          ) {
            console.log(
              "no changes!!!",
              incomingReportAns[ansHeader]["data"][subkey]
            );
            break;
          } else {
            console.log(
              "We are in the else.....",
              subkey,
              incomingReportAns[ansHeader]["data"][subkey]
            );
            msgToSend[ansHeader] = {
              ...msgToSend[ansHeader],
              [subkey]: incomingReportAns[ansHeader]["data"][subkey],
            };
          }
        }
      }
    }
  });

  //check whether patient medication has been changed
  let patientMedData =
    incomingReportAns["Patient Medication Profile"].data[
      "Patient Medication Information"
    ];

  if (Array.isArray(patientMedData)) {
    for (let row of patientMedData) {
      if ((row.Medication && row.Medication.editStatus) || row.editStatus) {
        msgToSend["Patient Medication Profile"] = patientMedData;
      }
    }
  }

  return msgToSend;
};

//sends email only if a field has a specific value
//ex. flow rate error > 14%
export const ConditionalEmailer = (ansHeader, subkey, incomingReportAns) => {
  let reportValue;
  let conditionalValue;
  let conditionalRule;

  //fires if trigger is not an array or object
  if (
    //if section header is the list, and the trigger is for ex. flow rate error
    conditionalEmailConfig[ansHeader] &&
    conditionalEmailConfig[ansHeader].trigger === subkey
  ) {
    reportValue = incomingReportAns[ansHeader]["data"][subkey];
    conditionalValue = conditionalEmailConfig[ansHeader].condition.value;
    conditionalRule = conditionalEmailConfig[ansHeader].condition.rule;
  }
  //fires if trigger is an object
  else if (
    conditionalEmailConfig[ansHeader] &&
    conditionalEmailConfig[ansHeader].trigger[subkey]
  ) {
    reportValue = incomingReportAns[ansHeader]["data"][subkey];
    conditionalValue =
      conditionalEmailConfig[ansHeader].trigger[subkey].condition;
    conditionalRule = conditionalEmailConfig[ansHeader].trigger[subkey].rule
      ? conditionalEmailConfig[ansHeader].trigger[subkey].rule
      : conditionalEmailConfig[ansHeader].trigger[subkey].condition;
  } else {
    return false;
  }

  //a better way to refactor would be to change "Yes"/"No" to bool in config.json
  //then seek bool rather than this insanely terrible code.
  let surgeonStuff = ["Surgeon Name", "Surgeon Phone", "Surgeon Fax"];
  switch (conditionalRule) {
    case ">":
      if (reportValue > conditionalValue) {
        return conditionalEmailConfig[ansHeader].trigger;
      }
      break;
    case "<=":
      if (reportValue <= conditionalValue) {
        return conditionalEmailConfig[ansHeader].trigger[subkey];
      }
      break;

    case "Yes":
      if (reportValue === conditionalRule) {
        return conditionalEmailConfig[ansHeader].trigger[subkey];
      } else if (surgeonStuff.includes(subkey)) {
        return conditionalEmailConfig[ansHeader].trigger[subkey];
      }
      break;

    case false:
      //return conditionalEmailConfig[ansHeader].trigger[subkey];
      return false;

    case undefined:
      //return conditionalEmailConfig[ansHeader].trigger[subkey];
      return false;

    default:
      //console.log("fire here??", conditionalRule === conditionalValue);
      return false;
  }
};
