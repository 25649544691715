import React, { useState, useEffect } from "react";
import AddTo from "./AddTo";
import AddRow from "./AddRow.js";

export const AddNewRowParent = (props) => {
  let [addedText, updateAddedText] = useState(null);
  let { submitNewRow } = props;

  useEffect(() => {
    //console.log(addedText);
  }, [addedText]);

  const passToParent = async (text) => {
    await updateAddedText(text);
  };
  return (
    <AddTo fn={submitNewRow} params={[null, null, addedText]}>
      <AddRow
        placeholder="Add Medication Here..."
        passToParent={passToParent}
      />
    </AddTo>
  );
};
