const editSearchTermsReports = (key, val, id, clone) => {
  let regval = `^${val}`;
  if (key === "Patient First Name") {
    clone[id] = {
      $or: [
        {
          "visitData.Patient Information.data.Demographics.data.0.FIRST_NAME": {
            $regex: regval,
            $options: "i",
          },
        },
        {
          "visitData.Patient Information.data.Demographics.FIRST_NAME": {
            $regex: regval,
            $options: "i",
          },
        },
      ],
    };
  } else if (key === "Patient Last Name") {
    clone[id] = {
      $or: [
        {
          "visitData.Patient Information.data.Demographics.data.1.LAST_NAME": {
            $regex: regval,
            $options: "i",
          },
        },
        {
          "visitData.Patient Information.data.Demographics.LAST_NAME": {
            $regex: regval,
            $options: "i",
          },
        },
      ],
    };
  } else if (key === "visitStartAfter") {
    clone[id] = { visitStart: { $gte: val } };
  } else if (key === "visitStartBefore") {
    clone[id] = { visitStart: { $lte: val } };
  } else if (key === "Submitter Last Name") {
    clone[id] = { "submitterData.lastName": { $regex: regval, $options: "i" } };
  } else if (key === "Submitter First Name") {
    clone[id] = {
      "submitterData.firstName": { $regex: regval, $options: "i" },
    };
  } else {
    if (!isNaN(val)) {
      val = parseInt(val);
    }

    clone[id] = { [key]: val };
  }

  return clone;
};

const editSearchTermsMileage = (key, val, id, clone) => {
  let regval = `^${val}`;
  if (key === "Nurse First Name") {
    clone[id] = { "user.firstName": { $regex: regval, $options: "i" } };
  } else if (key === "Nurse Last Name") {
    clone[id] = { "user.lastName": { $regex: regval, $options: "i" } };
  } else if (key === "Mileage Greater Than") {
    clone[id] = { "mileageReport.totalTravel": { $gte: parseFloat(val) } };
  } else if (key === "Mileage Less Than") {
    clone[id] = { "mileageReport.totalTravel": { $lte: parseFloat(val) } };
  } else if (key === "DayStartAfter") {
    clone[id] = { date: { $gte: val } };
  } else if (key === "DayStartBefore") {
    clone[id] = { date: { $lte: val } };
  } else {
    if (!isNaN(val)) {
      val = parseInt(val);
    }

    clone[id] = { [key]: val };
  }

  return clone;
};

const editSearchTermsProgressNotes = (key, val, id, clone) => {
  //console.log("what is key", key);
  let regval = `^${val}`;
  if (key === "firstName") {
    clone[id] = { firstName: { $regex: regval, $options: "i" } };
  } else if (key === "lastName") {
    clone[id] = { lastName: { $regex: regval, $options: "i" } };
  } else if (key === "submitterData.firstName") {
    clone[id] = {
      "submitterData.firstName": { $regex: regval, $options: "i" },
    };
  } else if (key === "submitterData.lastName") {
    clone[id] = { "submitterData.lastName": { $regex: regval, $options: "i" } };
  } else if (key === "Uploaded After Date") {
    clone[id] = { uploadedAt: { $gte: val } };
  } else if (key === "Uploaded Before Date") {
    clone[id] = { uploadedAt: { $lte: val } };
  } else if (key === "Email") {
    clone[id] = { Email: val };
  }

  //else if (key === "submitterData.lastName") {
  //     clone[id] = { "submitterData.lastName": { $regex: val, $options: "i" } };
  //   } else if (key === "submitterData.firstName") {
  //     clone[id] = { "submitterData.firstName": { $regex: val, $options: "i" } };
  //   }
  else {
    if (!isNaN(val)) {
      val = parseInt(val);
    }

    clone[id] = { [key]: val };
  }

  return clone;
};

const editSearchTermsNursingSchedule = (key, val, id, clone) => {
  return;
};

export {
  editSearchTermsProgressNotes,
  editSearchTermsReports,
  editSearchTermsMileage,
  editSearchTermsNursingSchedule,
};
