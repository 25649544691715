const production = {
  server: "production server people",
  domain: "rnexpress.net",
};

const development = {
  server: "development server yo",
  domain: "bhiuniv.com",
};

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_ISTESTING) {
  exports.Info = development;
} else if (process.env.NODE_ENV === "development") {
  exports.Info = development;
} else if (process.env.NODE_ENV === "production") {
  exports.Info = production;
}
