import React, { useState, useEffect } from "react";
import PickPatient from "../ProgressReportComponents/PickPatient";
import "./VO.css";
import BasicFormik from "./BasicFormik";
import ConsumerHOC from "../../context/ConsumerHOC";
import httpClient from "../httpClient";
import { Info } from "../../config.js";
import SearchPatient from "./SearchPatient";

const VerbalOrderParent = (props) => {
  let { userInfo, token } = props;

  let [patient, togglePatient] = useState(null);
  let [providers, updateProviders] = useState([]);
  let [isError, toggleError] = useState(false);

  const choosePatient = (pat) => {
    togglePatient(pat);
  };

  const url = "https://" + Info.domain + "/daily/all";
  useEffect(() => {
    httpClient(token, url, "get", {}, updateProviders);
  }, [token, updateProviders, url]);

  if (!patient) {
    // return <Picker choosePatient={choosePatient} patient={patient} />;
    return <SearchPatient choosePatient={choosePatient} patient={patient} />;
  } else {
    return (
      <>
        {isError ? (
          <div className="openDialogueError">
            An error occured, please contact IT
          </div>
        ) : null}
        <VOForm
          patient={patient}
          choosePatient={choosePatient}
          userInfo={userInfo}
          token={token}
          providers={providers}
          toggleError={toggleError}
        />
      </>
    );
  }
};

const Picker = (props) => {
  let { choosePatient } = props;
  return (
    <>
      <div className="VOTitle">Verbal Order Confirmations</div>
      <div className="VOSelectPatParent">
        <div className="VOSelectInstructions">
          Please select from one of your patients in the list below:
        </div>
        <div className="VOSelect">
          <PickPatient choose={choosePatient} VO={"true"} />
        </div>
      </div>
    </>
  );
};

const VOForm = (props) => {
  let {
    choosePatient,
    patient,
    userInfo,
    token,
    providers,
    toggleError,
  } = props;
  let { LAST_NAME, FIRST_NAME, DOB, CITY, STATE, ADDRESS, ALLERGIES } = patient;

  return (
    <div>
      <div
        className="newPatientBtn"
        onClick={() => {
          choosePatient(null);
        }}
      >
        Select a Different Patient
      </div>
      <div className="VOTitle">New Verbal Order</div>
      <div className="patientInfo">
        <div>
          {LAST_NAME}, {FIRST_NAME}{" "}
        </div>
        <div>{DOB.split("T")[0]}</div>
      </div>
      <BasicFormik
        toggleError={toggleError}
        patient={patient}
        userInfo={userInfo}
        token={token}
        providers={providers}
        choosePatient={choosePatient}
      />
    </div>
  );
};

export default ConsumerHOC(VerbalOrderParent);
