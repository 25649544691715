import React from "react";

import "../PCF/PCFAdminFilter.css";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../Input";

const ContactFilter = (props) => {
  // console.log("props", props);
  let { contacts, updateParentData, fullAccountsList } = props;

  return (
    <div>
      <FilterDash
        contacts={contacts}
        updateParentData={updateParentData}
        fullAccountsList={fullAccountsList}
      />
    </div>
  );
};

const FilterDash = (props) => {
  let { contacts, updateParentData, fullAccountsList } = props;
  // console.log(contacts);

  // const resetFilter = () => {
  //   updateParentData(fullAccountsList);
  // };

  const FilterFunc = (e, section) => {
    const searchTerm = e.target.value;

    let inputSection = e.target.name || section;

    let filtered = fullAccountsList.filter((row) => {
      if (row.LastName && inputSection === "Contact Last Name") {
        return (
          row.LastName.toLowerCase().slice(0, searchTerm.length) ===
          searchTerm.trim().toLowerCase()
        );
      }
      if (row.ContactStatus && inputSection === "Contact Status") {
        return (
          row.ContactStatus.toLowerCase().slice(0, searchTerm.length) ===
          searchTerm.trim().toLowerCase()
        );
      }
    });

    updateParentData(filtered);
  };

  // let statusOpts = [
  //   { label: "Not Yet Begun", value: null },
  //   { label: "Incomplete", value: "Incomplete" },
  //   { label: "Admin Review", value: "Admin Review" },
  //   { label: "Completed", value: "Completed" },
  // ];
  // let flags = [
  //   "None",
  //   "Reschedule Request",
  //   "Added Insurance Policy",
  //   "Removed Insurance Policy",
  //   "Hospice Plans",
  //   "Recent Hospitalizations",
  // ];

  return (
    <div className="FilterRow">
      <div className="FilterTitle">Report Filters</div>
      <Input
        label="Contact Last Name"
        onChange={FilterFunc}
        fullWidth={false}
      />
      <Input label="Contact Status" fullWidth={false} onChange={FilterFunc} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// const DropDown = (props) => {
//   let { choices, title, FilterFunc } = props;
//   const classes = useStyles();
//   let [value, updateValue] = useState(null);

//   const handleChange = (e) => {
//     updateValue(e.target.value);
//     FilterFunc(e, title);
//   };

//   return (
//     <div>
//       <FormControl className={classes.formControl}>
//         <InputLabel id="demo-simple-select-label">{title}</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={value}
//           onChange={handleChange}
//         >
//           {choices.map((val) => {
//             if (val.label) {
//               return <MenuItem value={val.value}>{val.label}</MenuItem>;
//             }
//             return <MenuItem value={val}>{val}</MenuItem>;
//           })}
//         </Select>
//       </FormControl>
//     </div>
//   );
// };

export default ContactFilter;
