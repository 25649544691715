// global.window = {
//   document: {
//     createElementNS: () => {
//       return {};
//     }
//   }
// };
// global.navigator = {};
// global.html2pdf = {};
// global.btoa = () => {};

let moment = require("moment");
const {jsPDF} = require("jspdf");
let {
  Images,
  drugChartHeaders,
  drugArray,
} = require("../components/RowDataTypes.js");

require("jspdf-autotable");

let shouldPageBreak = "avoid";

let Styles = "../ParentStyles.json";

const processDetails = (details, header) => {
  //insert visit start time and nurse
  //console.log("details", details);
  //console.log("header", header);
  if (typeof details === "string" || typeof details === "number") {
    return details;
  } else if (header === "Patient Medication Information") {
    let vary;
    if (details.data) {
      vary = details.data;
    } else {
      vary = details;
    }
    let original = "";

    if (!Array.isArray(vary)) {
      return original;
    }

    // console.log("vary", vary);
    vary.forEach((x) => {
      //section put in place to deal with bug that crashed PDF creator.
      //if user adds a header but not a body to medication profile, the program will not print out that row
      if (!x.data) {
        // console.log("not there, return");
        return;
      }
      //end hacky fix here

      if (
        x.isAddition ||
        (x.Medication && x.Medication.isAddition) ||
        x.editStatus === "new entry"
      ) {
        original += ` NEW ADDITION: ${
          x.data || x.Medication.data || "no data entered"
        } \n`;
      } else if (
        x.editStatus === "deleted" ||
        (x.Medication && x.Medication.editStatus === "deleted")
      ) {
        original += ` DELETE: ${
          x.data || x.Medication.data || "no data entered"
        } \n`;
      } else if (
        x.editStatus === "revised" ||
        (x.Medication && x.Medication.editStatus === "revised")
      ) {
        let revData;

        let olddata = `REVISED: ${
          x.data || x.Medication.data || "no data entered"
        }`;
        try {
          revData = `CHANGES/UPDATES: ${
            x.revision || x.Medication.revision || "no data entered"
          }`;
        } catch (err) {
          revData = "CHANGES/UPDATES: Data Not Available";
        }

        original += `${olddata}  \n \t${revData} \n`;
      } else {
        original += `CURRENT MEDICATION: ${
          x.data || x.Medication.data || "no data entered"
        } \n`;
      }
    });
    return original;
  } else if (Array.isArray(details)) {
    let arrToString = "";
    details.forEach((x) => {
      arrToString += `${x} \n`;
    });
    return arrToString;
  }
  //hard codes demographic information to PDF
  else if (header === "Demographics") {
    let DOB = null;
    let firstName = null;
    let lastName = null;
    let Physician = null;
    let Phone = null;

    if (Array.isArray(details.data)) {
      DOB = details.data[2]["DOB"];
      firstName = details.data[0]["FIRST_NAME"];
      lastName = details.data[1]["LAST_NAME"];
      Physician = details.data[3]["PHYSICIAN"];
      details.data && details.data[4] && details.data[4]["PHONE"]
        ? (Phone = details.data[4]["PHONE"])
        : (Phone = null);
    } else {
      DOB = details["DOB"];
      firstName = details["FIRST_NAME"];
      lastName = details["LAST_NAME"];
      Physician = details["PHYSICIAN"];
      details["PHONE"] ? (Phone = details["PHONE"]) : (Phone = "");
    }

    DOB = moment.utc(DOB).format("MMMM Do YYYY");

    return `Patient Name: ${firstName} ${lastName}
     DOB: ${DOB}
     PHYSICIAN: ${Physician}
     ${Phone !== null ? "Patient Phone: " + Phone : null}
        
     `;
  } else if (
    header === "confirm patient info - readonly" ||
    header === "confirm patient insurance - readonly" ||
    header === "confirm patient alerts - readonly" ||
    header === "confirm patient allergies" ||
    header === "Patient Insurance Information" ||
    header === "Patient Allergies" ||
    header === "Patient Personal Information - verify" ||
    header === "Patient Alert" ||
    header === "Contact Information" ||
    header === "Communication Changes" ||
    header === "Communication preferences"
  ) {
    let returnStr = "";
    //console.log("header here", header);

    if (Array.isArray(details["data"])) {
      details["data"].forEach((x) => {
        let data = Object.entries(x);

        if (data.length > 0) {
          returnStr += `${data[0][0]}: ${data[0][1]} \n`;
        }
      });
    } else if (details["arrs"] || details["multipolicies"]) {
      let toIterateOver = details["arrs"]
        ? details["arrs"]
        : details["multipolicies"];
      if (Array.isArray(toIterateOver)) {
        for (let policy of toIterateOver) {
          let row = "";

          Object.keys(policy).forEach((info, idx) => {
            if (info !== "GroupNumber") {
              row += `${info} : ${policy[info]} \n`;
            }
          });
          returnStr += `\n \n ${row}`;
        }
      }
    } else {
      Object.keys(details).forEach((key) => {
        returnStr += `${key}: ${details[key]} \n`;
      });
    }

    return returnStr;
  } else if (header === 'Next refill visit' || header === 'Alarm Date'){
    return details.visitDate
  }
};

export const createPDF = async (data) => {
  let visitDate = moment(data.visitStart).format("MMMM Do YYYY, h:mm a");
  let provider =
    data.submitterData.firstName + " " + data.submitterData.lastName;

  let imageObj = {};
  let y = 10;
  let x = 10;

  const doc = new jsPDF("p", "mm", "A4");

  //remove image adding function for now
  // let img = new Image();
  // img.src = require("../assets/image-full.jpeg");
  // doc.addImage(img, "jpeg", 10, 10);
  // y = 25;

  let visitInfo = data.visitData;

  const pumpMedChart = async (sectionHeader, data) => {
    let usedIDs = {};
    let drugNameArray = [];
    let dataPool = data.visitData[sectionHeader].data;

    let finalObj = {};
    //find array that contains a list of our drugs and name it drugNameArray
    Object.keys(dataPool).forEach((key, idx) => {
      if (drugArray.includes(key)) {
        drugNameArray = dataPool[key];
        usedIDs[key] = true;
      }
    });

    drugNameArray.forEach((name) => {
      for (let k in dataPool) {
        let name2 = k.split(" ")[0];
        let name1 = name.split(" ")[0];
        let datatype = k.split(" - ")[1];

        if (name1 === name2 && datatype) {
          usedIDs[k] = true;

          if (!finalObj[name1]) {
            finalObj[name1] = [];
            finalObj[name1][0] = name;
          }

          //console.log("what is datatype", datatype);
          if (datatype.split(" ")[0] === "unit") {
            finalObj[name1][1] = dataPool[k];
          }
          if (datatype.split(" ")[0] === "concentration") {
            finalObj[name1][2] = dataPool[k];
          }
          if (datatype.split(" ")[0] === "daily") {
            finalObj[name1][3] = dataPool[k];
          }
        }
      }
    });

    let finalArr = [];

    for (let k in finalObj) {
      //merging "other drug" into chart
      if (k === "Other") {
        finalObj[k][0] =
          dataPool["Other drug - pump settings"] ||
          dataPool["Other Drug - pump settings"];
      }
      finalArr.push(finalObj[k]);
    }
    //console.log("what is finalArr", finalArr);

    const findAdditionalData = () => {
      let AdditionalData = [];
      Object.keys(dataPool).forEach((key, idx) => {
        if (!usedIDs[key] && dataPool[key] !== null) {
          AdditionalData[idx] = [key, dataPool[key]];
        } else {
          AdditionalData[idx] = [key, dataPool[key]];
        }
      });
      return AdditionalData;
    };

    let additional = findAdditionalData();

    // console.log("This is additional data: "+additional);
    let startingPT = await additionalData(additional, sectionHeader);

    if (finalArr.length > 0) {
      await doc.autoTable({
        pageBreak: shouldPageBreak,
        rowPageBreak: "always",
        startY: startingPT,
        margin: { left: 30 },

        columnStyles: {
          0: { cellWidth: 30, fontStyle: "bold" },
          1: { cellWidth: 10 },
          2: { cellWidth: 10 },
          3: { cellWidth: 10 },
        },
        styles: {
          overflow: "linebreak",
          minCellWidth: 20,
          halign: "left",
          valign: "top",
        },

        headStyles: { fontStyle: "bold", fillColor: [16, 173, 107] },
        head: [
          ["Name", "Units of Measure", "Concentration per ml", "Daily Dose"],
        ],
        body: finalArr,

        // didDrawCell: HookData => {
        //   HookData.row.height = 60;
        //   for (let i in additional) {
        //     parentY += 50;
        //     doc.text(`${i} - ${additional[i]}`, x, HookData.cell.y + 20);
        //   }
        // }
      });
    }
  };

  const additionalData = async (additional, i) => {
    // console.log("Addiftional: " + additional);
    // console.log("I: " + i);
    let finalY;
    await doc.autoTable({
      pageBreak: shouldPageBreak,
      rowPageBreak: "always",
      startY: y + 5,
      margin: { left: 10 },

      columnStyles: {
        0: { cellWidth: 30, fontStyle: "bold" },
        1: { cellWidth: 100 },
      },
      styles: {
        overflow: "linebreak",
        minCellWidth: 20,
        halign: "left",
        valign: "top",
      },

      headStyles: { fontStyle: "bold", fillColor: [21, 121, 79] },
      head: [[i, ""]],
      body: additional,

      didDrawPage: (data) => {
        finalY = data.table.finalY;
      },
    });
    //console.log("finished with additional data");
    return finalY;
  };

  const normalDraw = (i, toIterate) => {
    let tableBody = [];
    let row = [];

    //goes line by line and populates section header with key/value pairs
    // for (let j in visitInfo[i].data) {
    for (let j in toIterate) {
      row[0] = j;
      let details = toIterate[j];
      details = processDetails(details, j, i);

      if (Images.includes(j)) {
        imageObj[j] = details;
        // hack meant to deal with duplicate signature
        if (j === "Nurse Signature") {
          row[0] = null;
        }
        row[1] = "";
      } else {
        row[1] = details;
      }

      tableBody[tableBody.length] = [...row];
    }

    doc.autoTable({
      pageBreak: shouldPageBreak,
      rowPageBreak: "always",
      startY: y + 5,
      margin: { left: 10 },

      columnStyles: {
        0: { cellWidth: 60, fontStyle: "bold" },
        1: { cellWidth: 210 - 90 },
      },

      headStyles: { fillColor: [21, 121, 79] },

      styles: {
        overflow: "linebreak",
        minCellWidth: 20,
        halign: "left",
        valign: "top",
      },
      head: [[i, ""]],
      body: tableBody,

      willDrawCell: (data) => {
        //console.log("whats this?", data.row.raw[0]);
        if (Images.includes(data.row.raw[0])) {
          data.row.height = 60;
        }
      },
      didDrawCell: (data) => {
        if (Images.includes(data.row.raw[0])) {
          let header = data.row.raw[0];
          let holding = data.row.raw[1];
          data.row.raw[1] = null;
          try {
            if (holding !== null) {
              var base64Img = imageObj[header];
              doc.addImage(
                base64Img,
                "PNG",
                data.cell.x + 110,
                data.cell.y,
                50,
                50
              );
            }
          } catch (err) {
            //console.log("error", err);
          }
        }
      },
    });
  };

  //First put in basic visit Info (nurse and date)
  doc.setFontSize(10);

  let specialdata = { "Nurse Name": provider, "Visit Start": visitDate };
  y = 0;
  await normalDraw("Visit Information", specialdata);

  y = 30;

  for (let i in visitInfo) {
    //i is the section header

    doc.setFontSize(10);

    //check to see if our header is a pump med list
    //will then render specialized chart
    //otherwise hit regular function
    if (
      i === "Final Submission" ||
      i === "next" ||
      i === "status" ||
      i === "updaterInfo"
    ) {
      continue;
    }

    if (drugChartHeaders.includes(i)) {
      await pumpMedChart(i, data);
    } else {
      if (i === "Nurse Signature") {
        let data = visitInfo[i].data;
        data = { ...data, " ": " " };
        await normalDraw(i, data);
      } else if (visitInfo[i]) {
        await normalDraw(i, visitInfo[i].data);
      }
    }

    y = doc.lastAutoTable.finalY;
  }
  // //console.log("what is image obj", imageObj);

  let nonsignatures = [
    "Pump Site Picture",
    "Insurance Card Front",
    "Insurance Card Back",
    "Insurance Card",
    "Skin abnormality site picture",
    "Syringe Label Picture",
    "Catheter Site Picture",
  ];

  Object.keys(imageObj).forEach((img, cnt) => {
    if (nonsignatures.includes(img)) {
      doc.addPage();

      doc.addImage(imageObj[img], "PNG", 10, 10, 165, 165);
    }
  });

  let output = doc.output("arraybuffer");
  // let toReturn = Buffer.from(output, "binary").toString("base64");
  let uint8Array = new Uint8Array(output);
  let binaryString = '';
  for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
  }
  let base64String = btoa(binaryString);
  doc.save("jsPDF.pdf");
  //fs.writeFileSync("./document.pdf", toReturn, "binary");
  // return toReturn;
  return base64String;

};