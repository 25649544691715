import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./VO.css";
import httpClient from "../httpClient";
import { Info } from "../../config.js";
import ConsumerHOC from "../../context/ConsumerHOC";

import moment from "moment";

import ComponentMapper from "./ComponentMapper";

const FormikSchemaOutline = [
  { initialValue: "", label: "Current Settings", type: "text" },
  { initialValue: "", label: "Order Given", type: "text" },
  { initialValue: "", label: "New Settings", type: "text" },
  { initialValue: null, label: "Date of Order", type: "date" },
  {
    initialValue: "No Value Present",
    label: "Primary Provider",
    type: "dropdown",
  },
  {
    initialValue: null,
    label: "Prescribing Provider",
    type: "pickOne",
    choices: [
      { choice: "VORB given by primary provider" },
      {
        choice: "VORB given by alternate practitioner",
        ifSelected: [
          { initialValue: "", label: "Prescriber First Name", type: "text" },
          { initialValue: "", label: "Prescriber Last Name", type: "text" },
          { initialValue: "", label: "Prescriber Credentials", type: "text" },
        ],
      },
    ],
  },
];

const NewVOForm = (props) => {
  let {
    userInfo,
    providers,
    toggleError,
    token,
    choosePatient,
    patient,
  } = props;
  let InitialValues = {};
  let Labels = [];

  let [FormikSchema, updateFormikSchema] = useState([
    { initialValue: "", label: "Primary Provider", type: "text" },
  ]);

  FormikSchema.forEach((obj) => {
    InitialValues = { ...InitialValues, [obj.label]: obj.initialValue };
    Labels.push(obj.label);
  });

  const goBack = (val) => {
    toggleError(false);
    choosePatient(null);
  };

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: Yup.object({
      "Current Settings": Yup.string().required("Required"),
      "Order Given": Yup.string().required("Required"),
      "New Settings": Yup.string().required("Required"),
      "Date of Order": Yup.date()
        .max(new Date(), "Date of Order may not be post-dated")
        .required("Required"),

      "Prescribing Provider": Yup.string().required("Required"),
      "Prescriber First Name": Yup.string().when("Prescribing Provider", {
        is: "VORB given by alternate practitioner",
        then: Yup.string().required("Please provide prescriber first name"),
      }),
      "Prescriber Last Name": Yup.string().when("Prescribing Provider", {
        is: "VORB given by alternate practitioner",
        then: Yup.string().required("Please provide prescriber last name"),
      }),
      "Prescriber Credentials": Yup.string().when("Prescribing Provider", {
        is: "VORB given by alternate practitioner",
        then: Yup.string().required(
          "Please provide prescriber credentials ex. RN, NP, MD etc"
        ),
      }),

      "Primary Provider": Yup.object().required("Required"),
    }),

    onSubmit: (values) => {
      values = { ...values, patient };

      const url = "https://" + Info.domain + "/orders/";
      httpClient(token, url, "post", values, goBack, () => toggleError(true));
    },
  });

  const modifyFormikSchema = () => {
    let currentValues = formik.values;
    let revisedSchema = [];
    FormikSchemaOutline.forEach((question) => {
      revisedSchema.push(question);
      if (question.choices) {
        question.choices.forEach((choice) => {
          if (
            currentValues[question.label] === choice.choice &&
            choice.ifSelected
          ) {
            revisedSchema = [...revisedSchema, ...choice.ifSelected];
          }
        });
      }
    });

    updateFormikSchema(revisedSchema);
  };

  useEffect(
    () => {
      modifyFormikSchema();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="formikFormParent">
        {FormikSchema.map((obj, id) => {
          return (
            <div key={id}>
              <ComponentMapper
                obj={obj}
                id={id}
                formik={formik}
                providers={providers}
              />
            </div>
          );
        })}
        <VORBGiven userInfo={userInfo} formik={formik} />
        <button type="submit" className="formikSubmit">
          Submit
        </button>
      </div>
    </form>
  );
};

const VORBGiven = (props) => {
  let { userInfo, formik } = props;
  let { firstName, lastName, PROFDESIG } = userInfo;
  let { values } = formik;

  let prescriber =
    formik.values["Primary Provider"].label ||
    formik.values["Primary Provider"];

  if (
    formik.values["Prescribing Provider"] ===
    "VORB given by alternate practitioner"
  ) {
    prescriber =
      values["Prescriber First Name"] +
      " " +
      values["Prescriber Last Name"] +
      ", " +
      values["Prescriber Credentials"];
  }

  return (
    <div>
      VORB Given to:{" "}
      {firstName
        ? firstName + " " + lastName + " " + PROFDESIG
        : "RN NAME NOT AVAILABLE"}{" "}
      on{" "}
      {moment(formik.values["Date of Order"]).format("MMMM DD, YYYY - hh:mm A")}{" "}
      by {prescriber}
    </div>
  );
};

export default ConsumerHOC(NewVOForm);
