import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { createPDF } from "../../PDFConversion/pdfConversion.js";
import ConsumerHOC from "../../ProgressNoteContext/ConsumerHOC";
import moment from "moment";
import InputBox from "./InputBox";

const useStyles = makeStyles({
  card: {
    padding: 20,
    margin: 20,
    height: 500,
    width: "100%",
  },

  title: {
    fontSize: 14,
  },
  cardContent: {
    padding: 5,

    alignItems: "center",
    margin: "auto",
  },
});

const submitToServer = (props) => {
  //delete old id because we want a new insertion
  //create priorID
  props.reportData["priorID"] = props.reportData["_id"];
  delete props.reportData["_id"];
  props.userInfo["RevisionUpdatedAt"] = moment().format(
    "MMMM Do YYYY, h:mm:ss a"
  );
  props.reportData["updaterInfo"] = props.userInfo;

  props.submitUpdate(props.token, props.reportData, props.status);

  if (props.status === "finalized") {
    createPDF(props.reportData);
  }
  goBack(props);
};

const goBack = (props) => {
  props.history.goBack();
};

function SubmitReportButtons(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContentTitle}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{
            backgroundColor: "#15794F",
            padding: 20,
            margin: 0,
            borderRadius: 5,
          }}
        >
          {/* section title here */}
          Input
        </Typography>
      </CardContent>

      <CardContent className={classes.cardContent}>
        <InputBox />
      </CardContent>
    </Card>
  );
}

export default ConsumerHOC(withRouter(SubmitReportButtons));
