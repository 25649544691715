//const moment = require("moment");

const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Date",
  },
  {
    id: "ALERT_C",
    numeric: false,
    disablePadding: false,
    label: "Alerts",
  },

  {
    id: "Note",
    numeric: false,
    disablePadding: false,
    label: "Note",
  },
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
];

const createData = function (
  PatientName,
  VisitType,
  VisitDate,
  NurseName,
  Note,
  ALERT_C,
  VisitNum,
  AlertText,
  updatedBy,
  MRN,
  isRescheduleBlocked,
  startTime
) {
  return {
    PatientName,
    VisitType,
    VisitDate,
    NurseName,
    Note,
    ALERT_C,
    VisitNum,
    AlertText,
    updatedBy,
    MRN,
    isRescheduleBlocked,
    startTime,
  };
};

const rowMapper = (incoming) => {
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let fullName;
  let nurseName;
  let visitDate;
  let visitNum;
  let visitType;
  let Note;
  let Alerts;
  let AlertText;
  let updatedBy;
  let MRN;
  let isRescheduleBlocked;
  let startTime;

  incoming.forEach((row, id) => {
    try {
      fullName = row.Patient_Last_Name + ", " + row.Patient_First_Name;
      nurseName = row.Nurse_Last_Name + ", " + row.Nurse_First_Name;
      visitType = row.TEXT_;
      visitDate = row.DUEDATE;
      startTime = row.DUEDATE;
      Note = row.Note ? row.Note : null;
      Alerts = row.ALERT_C ? "View Alert" : null;
      visitNum = row.Visit_Num;
      AlertText = Alerts ? row.ALERT_C : null;
      MRN = row.MRN ? row.MRN : null;
      updatedBy = row.updaterFNAME
        ? row.updaterFNAME + " " + row.updaterLNAME
        : null;
      isRescheduleBlocked = row.isRescheduleBlocked;
    } catch (err) {
      //console.log("what is err", err);
    } finally {
      let rowObj = createData(
        fullName ? fullName : "err",
        visitType ? visitType : "err",
        //ensure that timezone issues and moment library don't mess up the date.
        visitDate ? visitDate.split("T")[0] : "err",
        nurseName ? nurseName : "err",
        Note ? Note : null,
        Alerts ? Alerts : null,
        visitNum ? visitNum : "err",
        AlertText ? AlertText : null,
        updatedBy ? updatedBy : null,
        MRN ? MRN : null,
        isRescheduleBlocked ? isRescheduleBlocked : false,
        startTime ? startTime : "err"
      );
      returnArr.push(rowObj);
    }
  });

  return returnArr;
};

const colorRow = (type, row) => {
  if (row.isRescheduleBlocked) {
    return "#E5E7E9";
  }

  // else if (row.Note) {
  //   return "#94b8b8";
  // }
  else return null;
};

module.exports = { createData, headCells, rowMapper, colorRow };
