import { MdModeEdit } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import React, { useState, useEffect } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Styles from "../ParentStyles.json";
import styled from "styled-components";
import { AddNewRowParent } from "./AddNewRowParent";

const HoverText = styled.span`
  font-size: 20px;
  color: ${Styles["darkColor"]};

  :hover {
    color: ${Styles["intermediateColor"]};
    cursor: pointer;
  }
`;

const descriptor = {
  undefined: "",
  deleted: "DELETED",
  "newly added": "NEW ADDITION",
  "new entry": "NEW ADDITION",
  null: "CURRENT MEDICATION",
  revised: "REVISED PRESCRIPTION",
};

//converts incoming med profile to array containing objects as follows: {data: abc, editStatus: efg, revision: hij}
const MedProfileStructureConverter = (rawMedProfile) => {
  //console.log("what is raw", rawMedProfile);
  let revisedProfile = [];
  if (!Array.isArray(rawMedProfile) || rawMedProfile.length === 0) {
    return revisedProfile;
  }
  rawMedProfile.forEach((line) => {
    if (line.Medication) {
      revisedProfile.push(line.Medication);
    } else {
      revisedProfile.push(line);
    }
  });
  return revisedProfile;
};

//permissionToEdit is indicator of whether permission level allows editing of fields.
//Parent component for medication profile
const MedicationProfileComponent = (props) => {
  //console.log("medication profile component props", props);
  let { permissionToEdit, details } = props;

  let [medProfile, updateMedProfile] = useState(details);

  useEffect(() => {
    //first case exists only if no medications or fields are populated at first
    if (Object.keys(details).length < 1) {
      //console.log("no details??", details);
      updateMedProfile(
        props.currentReport.visitData["Patient Medication Profile"].data[
          "Patient Medication Information"
        ]
      );
    } else if (details !== medProfile) {
      updateMedProfile(details);
    } else console.log("props after effect", props);
    //console.log("what is med profile", medProfile);
  }, [details, medProfile, props]);

  const editMedProfile = (idx = null, id = null, data = null) => {
    //console.log("edit med profile", idx, id, data);
    //console.log(idx, id, data);

    let clone = medProfile;

    try {
      //if we are deleting an entire row accepts index of the row and removes it from array.
      if (id === "deleteRow") {
        clone.splice(idx, 1);
        //updateMedProfile(clone);
      }
      //all other actions
      else {
        //if no meds previously selected
        if (!Array.isArray(clone) && !clone[idx]) {
          clone = [];
          clone[0] = {
            editStatus: "new entry",
            isNew: true,
            data: data,
          };

          //updateMedProfile(clone);
        } else if (!clone[idx]) {
          clone[clone.length] = {
            Medication: {
              editStatus: null,
              data: data,
            },
          };
        } else {
          //console.log("fire here??");
          let obj = clone[idx]["Medication"] || clone[idx];

          obj[id] = data;

          //add blank input if adding a revision
          if (!obj.revision && id === "editStatus" && data === "revised") {
            obj.revision =
              "Please Enter Revised Medication Name, Dose, Route and Frequency";
          }
          clone[idx]["Medication"]
            ? (clone[idx]["Medication"] = obj)
            : (clone[idx] = obj);
        }
      }

      props.editReportData(props.title, props.header, clone);
      //console.log("what is clone", clone);

      //forceUpdate();
    } catch (err) {
      //console.log("error here", err);
    }
  };

  let revisedMedProfile = MedProfileStructureConverter(medProfile);

  return (
    <div
      style={{
        display: "grid",
        //margin: "auto",
        borderRadius: 15,
        backgroundColor: "#d8d8d8",
        gridTemplateColumns: "auto auto",
        gridColumnGap: 10,
        maxWidth: "100%",
      }}
    >
      <Header {...props} />

      <div
        style={{
          paddingLeft: 20,
          margin: 5,
          maxWidth: "100%",
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridColumnGap: 1,
          gridRowGap: 2,
        }}
      >
        {revisedMedProfile.length > 0 ? (
          revisedMedProfile.map((medRow, index) => {
            return (
              <MedicationRow
                key={index + medRow}
                {...medRow}
                editMedProfile={editMedProfile}
                index={index}
                permissionToEdit={permissionToEdit}
              />
            );
          })
        ) : (
          <div>No medications to display</div>
        )}
        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            borderRadius: 5,
          }}
        >
          {permissionToEdit ? (
            <AddNewRowParent submitNewRow={editMedProfile} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const Header = (props) => {
  let { header } = props;
  return (
    <div
      style={{
        color: Styles["darkColor"],
        fontWeight: "900",
        fontSize: 15,
        padding: 10,
        textAlign: "left",
        verticalAlign: "middle",
        margin: "auto",
      }}
    >
      {header}
    </div>
  );
};

const MedicationRow = (props) => {
  //console.log("med row props", props);
  const rowStyle = {
    margin: 1,
    color: Styles["darkColor"],
  };
  const prescription = props.data;
  const status = props.editStatus;
  const { revision, editMedProfile, permissionToEdit, index } = props;

  return (
    <React.Fragment>
      {/* 
      removed delete option from med profile

      <div>
        {permissionToEdit ? (
          <HoverText onClick={() => editMedProfile(index, "deleteRow")}>
            {" "}
            <TiDelete />
          </HoverText>
        ) : null}
      </div> */}
      <div style={{ ...rowStyle }}>
        <MedDescriptor
          status={status}
          permissionToEdit={permissionToEdit}
          editMedProfile={editMedProfile}
          index={index}
        />
      </div>
      <div style={rowStyle}>
        {!revision || status !== "revised" ? (
          <RegularPrescription
            prescription={prescription}
            permissionToEdit={permissionToEdit}
            editMedProfile={editMedProfile}
            index={index}
          />
        ) : (
          <RevisedPrescription
            revision={revision}
            oldprescription={prescription}
            permissionToEdit={permissionToEdit}
            editMedProfile={editMedProfile}
            index={index}
            isRevision={true}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const MedDescriptor = (props) => {
  //console.log("med decriptor props", props);
  //console.log("what is descriptor", descriptor);

  let { status, permissionToEdit, editMedProfile } = props;
  //console.log("what is status", status);
  let [openEdit, toggleOpenEdit] = useState(false);

  if (!openEdit) {
    return (
      <>
        {descriptor[status]}{" "}
        <HoverEdit
          fn={toggleOpenEdit}
          status={true}
          permissionToEdit={permissionToEdit}
        />
      </>
    );
  } else if (openEdit) {
    return (
      <>
        <MedDropDown
          {...props}
          fn={toggleOpenEdit}
          status={false}
          editMedProfile={editMedProfile}
          descriptorStatus={descriptor[status]}
          editStatus={status}
        />
      </>
    );
  }
};

const RegularPrescription = (props) => {
  let { prescription, editMedProfile, permissionToEdit, index } = props;

  //console.log("regular prescription props", props);

  let [openEdit, toggleOpenEdit] = useState(false);
  let [inputText, toggleInputText] = useState(prescription);

  useEffect(() => {
    if (prescription !== inputText) {
      toggleInputText(prescription);
    }
  }, [prescription, inputText]);

  if (!openEdit) {
    return (
      <>
        {inputText}
        <HoverEdit
          fn={toggleOpenEdit}
          status={true}
          permissionToEdit={permissionToEdit}
        />
      </>
    );
  } else if (openEdit) {
    return (
      <MedInput
        fn={toggleOpenEdit}
        status={false}
        defaultValue={inputText}
        index={index}
        editMedProfile={editMedProfile}
      />
    );
  }
};

const MedInput = (props) => {
  //console.log(props);
  let { fn, status, defaultValue, index, editMedProfile, isRevision } = props;
  let [text, toggleText] = useState(defaultValue);
  return (
    <>
      <input
        style={{ width: "60%" }}
        autoFocus
        defaultValue={text}
        onChange={(e) => {
          toggleText(e.target.value);
        }}
      />
      <IoIosSave
        onClick={() => {
          //console.log(index, isRevision, text);
          editMedProfile(index, isRevision ? "revision" : "data", text);
          fn(status);
        }}
      />
    </>
  );
};

const RevisedPrescription = (props) => {
  let { permissionToEdit, revision, editMedProfile, index, isRevision } = props;
  let [openEdit, toggleOpenEdit] = useState(false);
  const rowHeader = { fontWeight: "bold" };

  return (
    <div
      style={{
        maxWidth: "100%",
        display: "grid",
        gridTemplateColumns: "25% 75%",
        gridColumnGap: 5,
      }}
    >
      <div style={rowHeader}>Original Prescription:</div>
      <div>{props.oldprescription}</div>
      <div style={rowHeader}>Newly Revised Prescription:</div>
      <div>
        {openEdit ? (
          <MedInput
            fn={toggleOpenEdit}
            status={false}
            defaultValue={revision}
            index={index}
            editMedProfile={editMedProfile}
            isRevision={isRevision}
          />
        ) : (
          <>
            {revision}
            <HoverEdit
              permissionToEdit={permissionToEdit}
              fn={toggleOpenEdit}
              status={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

const HoverEdit = (props) => {
  let { fn, status, permissionToEdit } = props;
  if (!permissionToEdit) {
    return null;
  }
  return (
    <HoverText
      onClick={() => {
        fn(status);
      }}
    >
      <MdModeEdit />
    </HoverText>
  );
};

const MedDropDown = (props) => {
  //console.log("drop down props", props);
  let { fn, status, editMedProfile, index } = props;

  let [value, editValue] = useState("Select below");

  const DropDown = () => {
    return (
      <>
        <label>
          Choose from the following:
          <select
            value={value}
            onChange={(e) => {
              editValue(e.target.value);

              // if (editStatus !== null) {
              //   editStatus(e.target.value);
              // }

              // fn(e.target.value);
            }}
          >
            {Object.keys(descriptor).map((val, id) => {
              if (descriptor[val] === null) {
                descriptor[val] = "";
              }
              return (
                <option value={descriptor[val]} key={val + id}>
                  {descriptor[val]}
                </option>
              );
            })}
          </select>
        </label>
      </>
    );
  };

  return (
    <>
      <DropDown />
      <IoIosSave
        onClick={async () => {
          await editMedProfile(
            index,
            "editStatus",
            compareValueToDescriptor(value)
          );
          fn(status);
        }}
      />
    </>
  );
};

//retrieve descriptor key for value in order to upload to main med profile object
const compareValueToDescriptor = (value) => {
  let toReturn = null;
  Object.entries(descriptor).forEach((keyVal) => {
    if (keyVal[1] === value) {
      toReturn = keyVal[0];
    }
  });
  return toReturn;
};

export default ConsumerHOC(MedicationProfileComponent);
