//This is an attempt to build a form wizard for react
//project will allow user to use config file to set up nested questionaire.

//purpose will be to take the user configured questionaire and output the answers in an object for rendering.

//user must import configuration file
import React from "react";

import FormWizardProvider from "./FormWizardProvider";
import FormParent from "./FormParent";

const FormWizard = (props) => {
  // console.log("form wiz", props);
  let { inputQuestionaire, relevantData, goBack, SubmitComponent, submitAPI } =
    props;

  return (
    <FormWizardProvider>
      <FormParent
        inputQuestionaire={inputQuestionaire}
        relevantData={relevantData}
        importedAnswers={relevantData ? relevantData.queryResults : null}
        goBackFunc={goBack}
        //user must provide a submit button custom for each form
        SubmitComponent={SubmitComponent}
        submitAPI={submitAPI}
      />
    </FormWizardProvider>
  );
};

export default FormWizard;
