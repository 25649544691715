import React, { useState, useEffect } from "react";
import {
  ReferenceLine,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Legend,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import DatePicker from "../MatUIDatePicker";
import Button from "../Button";
import { Info } from "../../config.js";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
const domain = Info.domain;

const NurseReturnMetrics = (props) => {
  const date = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };

  let [nurseData, updateNurseData] = useState([]);
  let [startDate, updateStartDate] = useState("10/1/2019");
  let [endDate, updateEndDate] = useState(
    date.toLocaleDateString("en-US", options).replace(/\//g, "/")
  );
  let [open, toggleOpen] = useState(false);
  let [overallAvg, updateOverallAvg] = useState(5);

  const grabNurseGenData = async () => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/analytics/basicNurse`,
      data: {
        startdate: startDate,
        enddate: endDate,
      },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });
    //console.log("ho ho ho data", res);
    return res;
  };

  const updateData = (date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  };
  useEffect(() => {
    grabNurseGenData().then((res) => {
      updateNurseData(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let reconfigureData = [];
  let totalLength;

  const getAvg = async () => {
    let holder = [];
    const mapper = async () => {
      holder = await nurseData.map((x) => {
        return x["percentReturned"];
      });

      return holder;
    };

    let arr = await mapper();
    let SumOverallAvg = 0;
    arr.forEach((x) => {
      SumOverallAvg += x;
    });

    updateOverallAvg((SumOverallAvg / totalLength) * 100);

    //console.log(overallAvg);
  };

  if (Array.isArray(nurseData)) {
    nurseData.forEach((dp) => {
      reconfigureData.push({
        Name: `${dp["First Name"]} ${dp["Last Name"]}`,
        "Percent Returned": Math.round(dp["percentReturned"] * 100),
        "Total Number of Reports Submitted": dp["TotalReports"],
        "Number of Returns": dp["TimesReturned"],
      });
    });

    totalLength = reconfigureData.length;
    getAvg();
  }
  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Individual Nurse Return Metrics Table(click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <div className="nurse-roster-container">
            <CSVLink
              data={reconfigureData}
              filename={`${startDate}-${endDate}-Nurse_Metric`}
            >
              {" "}
              <MdFileDownload className="download-button" />{" "}
            </CSVLink>

            <Button
              label="Individual Nurse Return Metrics Table(click to close)s"
              func={toggleOpen}
              data={false}
            />
          </div>
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"start"}
          />
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"end"}
          />
          <ComposedChart
            width={1000}
            height={totalLength * 50}
            data={reconfigureData}
            margin={{ top: 50, right: 20, bottom: 100, left: 40 }}
            barGap={10}
            layout="vertical"
          >
            <Bar dataKey="Percent Returned" barSize={20} fill={"gray"} />
            <ReferenceLine
              x={overallAvg}
              label={`Average Percent Returned - ${
                Math.round(overallAvg * 100) / 100
              }%`}
              stroke="red"
              strokeDasharray="3 3"
            />
            <CartesianGrid stroke="#ccc" />
            <YAxis dataKey="Name" interval={0} type="category"></YAxis>

            <XAxis padding={{ top: 12 }} type="number">
              <Label
                value="Percent Reports Returned to Nurse"
                position="insideBottom"
              />
            </XAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
          </ComposedChart>
        </div>
      </Paper>
    );
  }
};

const CustomTooltip = (props) => {
  let active = props.active;
  let label = props.label;
  let data =
    props.payload && props.payload[0] ? props.payload[0].payload : null;

  if (active && props.payload) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${label}`}</div>
        <div className="intro">{`Percent Returned: ${
          props.payload ? props.payload[0].value : "N/A"
        }%`}</div>
        <div className="desc">
          {`Total Number of Reports Submitted by nurse: ${data["Total Number of Reports Submitted"]}`}
        </div>
        <div className="desc">
          {`Total Number of Returns by Weekly's: ${data["Number of Returns"]}`}
        </div>
      </div>
    );
  } else {
    return (
      <div className="custom-tooltip">
        <p className="desc">Data problems...</p>
      </div>
    );
  }
};

export default NurseReturnMetrics;
