import React from "react";

import TextField from "@material-ui/core/TextField";

export default function Input(props) {
  let { required } = props;
  let fullWidth;
  props.fullWidth !== false ? (fullWidth = true) : (fullWidth = false);

  return (
    <TextField
      required={required}
      defaultValue={props.default ? props.default : null}
      style={props.style ? props.style : { marginBottom: 20 }}
      id={`outlined-${props.label}`}
      label={props.label}
      name={props.label}
      type={props.type}
      //margin="normal"
      variant="outlined"
      fullWidth={fullWidth}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      multiline={props.multiline ? props.multiline : false}
      onBlur={props.onBlur}
    />
  );
}
