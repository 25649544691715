import React, { useState, useEffect } from "react";
import axios from "axios";
import PickAccountList from "./PickAccountList";
import { Info } from "../../config.js";
import { BiArrowBack } from "react-icons/bi";
import "./marketing.css";
import ViewNarratives from "./ViewNarratives";

const MarketingViewNarrativeByAccount = (props) => {
  let {
    toggleCurrentView,
    SubmitComponent,
    token,
    pickedAccount,
    updatePickedAccount,
  } = props;
  // console.log(props);

  let [accountsList, updateAccountsList] = useState([]);
  let [isSearching, toggleIsSearching] = useState(false);

  useEffect(() => {
    const DBGet = async (input = {}) => {
      try {
        toggleIsSearching(true);
        let workingCopies = await axios({
          method: "get",
          url: `https://${Info.domain}/marketing/Contacts`,
          data: input,
          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        toggleIsSearching(false);
        // console.log(workingCopies.data);
        updateAccountsList(workingCopies.data);
      } catch (err) {
        throw err;
      }
    };

    if (!accountsList.length) {
      DBGet();
    }
  });

  return (
    <div className="initialProspectParent">
      {pickedAccount ? (
        <div
          className="backArrow"
          onClick={() => {
            updatePickedAccount(false);
          }}
        >
          <BiArrowBack />
        </div>
      ) : null}
      <div className="ReviewTitle">Marketing: View Narrative By Account</div>
      {!pickedAccount ? (
        <PickAccountList
          accountsList={accountsList}
          updatePickedAccount={updatePickedAccount}
          isSearching={isSearching}
        />
      ) : (
        <ViewNarratives
          pickedAccount={pickedAccount}
          token={token}
          toggleCurrentView={toggleCurrentView}
          updatePickedAccount={updatePickedAccount}
        />
      )}
    </div>
  );
};

export default MarketingViewNarrativeByAccount;
