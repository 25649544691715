//expects an array of objects as input
//will convert and output the same
const avgStartToApproval = (data) => {
  //console.log("input data", data);
  if (!Array.isArray(data)) {
    return [];
  }
  let newRow = [];
  //input should be date, num of reports, amount of time
  //time to process
  //number of reports
  data.forEach((row, id) => {
    if (newRow.length === 0) {
      row["Date"] = row["Date"].split("T")[0];

      row["totalReports"] = row["NumberOfReports"];
      row["totalTime"] = row["TimeToProcess"];
      row["avgTime"] = row["TimeToProcess"] / row["totalReports"];

      newRow.push(row);
    } else {
      row["Date"] = row["Date"].split("T")[0];
      row["totalReports"] =
        row["NumberOfReports"] + data[id - 1]["totalReports"];
      row["totalTime"] = row["TimeToProcess"] + data[id - 1]["totalTime"];
      row["avgTime"] = row["totalTime"] / row["totalReports"];
      newRow.push(row);
    }
  });

  return newRow;
};

export default avgStartToApproval;
