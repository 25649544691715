import axios from "axios";

const httpClient = async (
  token,
  url,
  method,
  data,
  onSuccess = null,
  onError = null
) => {
  try {
    let results = await axios({
      method, // you can set what request you want to be
      url,
      data,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    // console.log(results);
    if (results.data === "err" || results.status !== 200) {
      //console.log("err?");
      // console.log("error????");
      throw new Error("Error on submission");
    } else {
      // console.log(results);
      if (onSuccess) {
        onSuccess(results.data);
      }
      return results;
    }
  } catch (err) {
    // console.log(err);
    err ? onError("An error occured, please contact IT") : console.error(err);
  }
};

export default httpClient;
