//expects an array of objects as input
//will convert and output the same
const avgStartToApproval = (data) => {
  //console.log("report data avg start to approval", data);
  if (!Array.isArray(data)) {
    return [];
  }
  let newRow = [];
  data.forEach((row, id) => {
    if (newRow.length === 0) {
      row["Date"] = row["Date"].split("T")[0];
      row["totalTime"] = row["TimeToProcess"];
      row["totalReports"] = row["NumberOfReports"];
      row["avgNumReportsPerDay"] = row["totalReports"] / 1;
      row["avgTimePerReport"] =
        Math.round((row["totalTime"] / row["totalReports"]) * 100) / 100;
      newRow.push(row);
    } else {
      row["Date"] = row["Date"].split("T")[0];
      row["totalTime"] =
        newRow[newRow.length - 1]["totalTime"] + row["TimeToProcess"];
      row["totalReports"] =
        newRow[newRow.length - 1]["totalReports"] + row["NumberOfReports"];

      row["avgNumReportsPerDay"] = row["totalReports"] / (id + 1);
      row["avgTimePerReport"] = row["totalTime"] / row["totalReports"];
      newRow.push(row);
    }
  });

  return newRow;
};

export default avgStartToApproval;
