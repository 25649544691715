import React, { useState, useEffect } from "react";
import axios from "axios";
import { Info } from "../config";
import Card from "@material-ui/core/Card";
import ConsumerHOC from "../context/ConsumerHOC";
import "./NurseNameGrid.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import ModifiableMileageReport from "./modifiableMileageReport";

const StartBlankMileageReport = (props) => {
  // console.log("props", props);
  let { token, modState, OpenCloseFunc } = props;
  let [userList, updateUserList] = useState([]);

  let [nurse, pickNurse] = useState(false);

  // console.log(props);
  useEffect(() => {
    const grabNurseList = async () => {
      try {
        let results = await axios({
          method: "get", // you can set what request you want to be
          url: `https://${Info.domain}/daily/mileage/status`,
          headers: {
            Authorization: `bearer ${token}`,
          },
        });

        // console.log(results.data);
        updateUserList(results.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (!userList.length) {
      grabNurseList();
    }
  });

  return (
    <Dialog
      open={modState}
      maxWidth="lg"
      // TransitionComponent={Transition}
      keepMounted
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        {!nurse ? (
          <NurseList userList={userList} pickNurse={pickNurse} />
        ) : (
          <ModifiableMileageReport {...props} reportSubject={nurse} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => OpenCloseFunc(!modState)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NurseList = (props) => {
  let { userList, pickNurse } = props;

  return (
    <Card style={{ margin: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
        <div className="instructions">
          {" "}
          Please select the user for which you are creating their report:
        </div>

        <div className="NurseNameGridHeader">
          <div>First Name</div>
          <div>Last Name</div>
          <div></div>
          {userList.map((nurse, id) => {
            return (
              <React.Fragment key={id}>
                <div>{nurse.LNAME}</div>
                <div>{nurse.FNAME}</div>
                <div
                  onClick={() => {
                    pickNurse(nurse);
                  }}
                >
                  SELECT
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default ConsumerHOC(StartBlankMileageReport);
