let mockValuesToFilter = [
  { name: "marvin", occupation: "goofnik", dob: new Date("5/3/1987") },
  { name: "ervin", occupation: "baker", dob: new Date("10/2/1985") },
  { name: "merlin", occupation: "magician", dob: new Date("2/5/1988") },
  { name: "garvin", occupation: "dude", dob: new Date("6/2/1980") },
];

let mockFilterList = [
  ["name", "m"],
  ["occupation", "bak"],
];

export const filteringFunction = (filterList, InfoToFilter) => {
  // console.log(filterList, InfoToFilter);
  if (!filterList.length) {
    return InfoToFilter;
  }

  let toReturn = InfoToFilter;

  filterList.forEach((filt) => {
    let fieldName = filt[0];
    let valueToFilterBy = filt[1];
    if (fieldName === "Schedule Lock") {
      fieldName = "PharmacyReschedulingLockSet";
    }
    if (fieldName === "Schedule Lock" || fieldName === "MDOrderStatus") {
      valueToFilterBy = Boolean(valueToFilterBy);
      toReturn = toReturn.filter((val) => {
        return Boolean(val[fieldName]) === valueToFilterBy;
      });
    } else if (
      typeof valueToFilterBy === "number" ||
      typeof valueToFilterBy === "boolean"
    ) {
      toReturn = toReturn.filter((val) => {
        return val[fieldName] === valueToFilterBy;
      });
    }

    //if value is not a number
    else {
      toReturn = toReturn.filter((val) => {
        return (
          val[fieldName].toLowerCase().substring(0, valueToFilterBy.length) ===
          valueToFilterBy
        );
      });
    }
  });

  return toReturn;
};

// let val = filteringFunction();

// console.log(val);
