import React from "react";
import "./DemographicMap.css";

const PatientsByStateDisplay = (props) => {
  // console.log(props);
  let { input } = props;
  let totalPatientsInService = 0;
  let states = null;
  if (input) {
    totalPatientsInService = input[0][0]["TotalPatientsInService"];
    states = input[1];
  }
  return (
    <div className="StateStatsParent">
      <div className="flexGroup">
        <div className="StateStatsTitle">Patients Per State</div>
      </div>
      <div className="flexGroup">
        <div className="StateStatsHeader">Total</div>{" "}
        <div className="StateStatsBody">{totalPatientsInService}</div>
      </div>
      {states
        ? states.map((stateStats, idx) => {
            return (
              <div key={idx} className="flexGroup">
                <div className="StateStatsHeader">{stateStats.STATE}</div>{" "}
                <div className="StateStatsBody">{stateStats.StateCount}</div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default PatientsByStateDisplay;
