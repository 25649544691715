import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ConsumerHOC from "../context/ConsumerHOC";
import axios from "axios";
import { Info } from "../config.js";
import ReportConfig from "../ReportConfig.js";
import SearchCompressedUI from "./SearchCompressedUI";
let {
  editSearchTermsProgressNotes,
  editSearchTermsReports,
  editSearchTermsMileage,
} = require("./SearchConfigurations");

const domain = Info.domain;

// const ObjToStr = (obj) => {
//   let returnStr = "";
//   obj.forEach((x) => {
//     let key = Object.keys(x);
//     let val = Object.values(x);
//     returnStr += `${key}: ${val}, `;
//   });

//   return returnStr;
// };

const arrOfObjToArrOfKeyOrValues = (arr) => {
  let arrOfKeys = [];
  let arrOfVals = [];
  arr.forEach((obj) => {
    let toInsert = Object.keys(obj)[0];

    //below code is necessary to prevent last name or first from appearing in dropdown after usage

    if (
      Object.keys(obj).length > 0 &&
      obj["$or"] &&
      obj["$or"][0][
        "visitData.Patient Information.data.Demographics.data.0.FIRST_NAME"
      ]
    ) {
      toInsert = "Patient First Name";
    }

    if (
      Object.keys(obj).length > 0 &&
      obj["$or"] &&
      obj["$or"][0][
        "visitData.Patient Information.data.Demographics.data.1.LAST_NAME"
      ]
    ) {
      toInsert = "Patient Last Name";
    }

    arrOfKeys = [...arrOfKeys, toInsert];
  });

  arr.forEach((obj) => {
    arrOfVals = [...arrOfVals, Object.values(obj)[0]];
  });

  return { arrOfKeys, arrOfVals };
};

const mapTwoArrs = (arr1, arr2) => {
  for (let l = 0; l < arr1.length; l++) {
    if (arr1[l] && arr2[l]) {
      return true;
    }
  }
  return false;
};

function SearchLogic(props) {
  let { type } = props;
  let options;

  //list of search options available based on search type (report vs progress note) and permissions
  if (type === "PatientReports") {
    options = ReportConfig[props.userInfo.permissions].searchOptions.Reports;
  } else if (type === "ProgressNotes") {
    options =
      ReportConfig[props.userInfo.permissions].searchOptions.ProgressNotes;
  } else if (type === "mileage") {
    options = ReportConfig[props.userInfo.permissions].searchOptions.Mileage;
  }

  let [boxes, addBox] = useState([{}]);
  let [searchStatus, changeSearchStatus] = useState(false);
  let [searchFail, updateSearchFail] = useState(false);

  const editSearchTerms = (key, val, id, type) => {
    //console.log("run editSearchTerms");
    let clone = [...boxes];
    let newBox;
    if (type === "PatientReports") {
      newBox = editSearchTermsReports(key, val, id, clone);
    }
    if (type === "ProgressNotes") {
      newBox = editSearchTermsProgressNotes(key, val, id, clone);
    }
    if (type === "mileage") {
      newBox = editSearchTermsMileage(key, val, id, clone);
    }

    if (newBox) {
      addBox(newBox);
    }
  };

  //having multiple $or statements in mongo was creating problems, need to place both first and last name $or statements into sincle $and
  const combineNameORStatementsIntoAnd = async (input) => {
    let returnAnd = [];
    let listToReturn = [];
    input.forEach((searchTerm) => {
      if (searchTerm["$or"]) {
        returnAnd.push(searchTerm);
      } else {
        listToReturn.push(searchTerm);
      }
    });

    if (returnAnd.length > 0) {
      listToReturn.push({ $and: returnAnd });
    }
    return listToReturn;
  };

  const DeleteFromSearchTerms = (idx) => {
    let newBox = [];
    boxes.forEach((val, id) => {
      if (id !== idx) {
        newBox.push(val);
      }
    });
    addBox(newBox);
  };

  useEffect(() => {
    let mapping = arrOfObjToArrOfKeyOrValues(boxes);
    //console.log("hit the use effect", boxes);

    let categories = mapping["arrOfKeys"];

    changeSearchStatus(mapTwoArrs(mapping["arrOfKeys"], mapping["arrOfVals"]));

    options.forEach((row) => {
      if (categories.includes(row.value)) {
        row.status = false;
      } else if (!categories.includes(row.value)) {
        row.status = true;
      }
    });
  }, [boxes, options]);

  const handleSubmit = async () => {
    let dataToTransmit = await combineNameORStatementsIntoAnd(boxes);
    //console.log("what is data to trans", dataToTransmit);
    if (searchStatus) {
      try {
        const results = await axios({
          method: "post", // you can set what request you want to be
          url: `https://${domain}/search/${type}`,
          data: dataToTransmit,
          headers: {
            Authorization: `bearer ${props.token}`,
          },
        });

        if (results.data.length > 0) {
          updateSearchFail(false);
          // props.history.push({
          //   pathname: "/ReportsTable",
          //   state: {
          //     type: "PatientReports",
          //     rows: results.data,
          //     title: "Patient Reports: Search Results",
          //   },
          // });
          props.updateWorkingCopiesFromServer(results.data);
        } else {
          //updateSearchFail(true);
          props.updateWorkingCopiesFromServer([]);
          //console.log("hit boxes?", boxes);
        }
      } catch (err) {
        //console.log("error", err);
      }
    } else {
      return;
    }
  };

  return (
    <SearchCompressedUI
      type={type}
      boxes={boxes}
      options={options}
      editSearchTerms={editSearchTerms}
      searchFail={searchFail}
      addBox={addBox}
      searchStatus={searchStatus}
      handleSubmit={handleSubmit}
      DeleteFromSearchTerms={DeleteFromSearchTerms}
    />
  );
}

export default ConsumerHOC(withRouter(SearchLogic));
