import React, { useState, useEffect } from "react";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import { Info } from "../../config";
import ReviewDialog from "./ReviewDialog";
import PCFReview from "./PCFReview";
import SyringeWasteQuestionaire from "./syringeWasteQuestionaire";
import { cloneDeep } from "lodash";
import ModifyScheduleFormWrapper from "./ModifyScheduleFormWrapper";
import moment from "moment";
import ModifyOrderBillingLockModal from "./CompoundingLock";

const TableButtonLogic = (props) => {
  let {
    row,
    id,
    header,
    viewReport,

    WrappedComponent,
    token,
    userInfo,
    workingCopies,
    updateWorkingCopiesFromServer,
    toggleModDialog,
  } = props;

  const workingCopyIndex = row["WCIndex"];
  const headerID = header.id;
  let status;
  let canToggle;
  let label;
  let openDialogOnClick;
  let component;
  let dataToPass;
  let noHttpReqOnClick = false;
  let permission = [];
  let wasRescheduled;

  let [value, updateValue] = useState(row[headerID]);
  let [dialogStatus, toggleDialogStatus] = useState(false);

  const handleDialogClose = async (func = null, funcArgs = null) => {
    if (func) {
      await func(funcArgs);
    }
    toggleDialogStatus(false);
  };

  useEffect(() => {
    if (row[headerID] !== value) {
      updateValue(row[headerID]);
    }
  }, [row, headerID, value]);

  let [error, updateError] = useState(false);

  switch (headerID) {
    case "VisitDate":
      permission = ["pharmacy", "admin"];
      canToggle = true;
      if (!permission.includes(userInfo.permissions)) {
        canToggle = false;
      }
      wasRescheduled = row.WasVisitRescheduled;
      value = row.VisitDate;
      status = moment(value).format("MMMM DD, YYYY");
      label = "Reschedule";
      noHttpReqOnClick = true;
      openDialogOnClick = true;
      component = ModifyScheduleFormWrapper;
      dataToPass = row;
      break;
    case "PharmacyReschedulingLockSet":
      permission = ["pharmacy"];
      canToggle = 1;
      if (!permission.includes(userInfo.permissions)) {
        canToggle = false;
      }
      status = value ? "Locked" : "Not Locked";
      label = status === "Locked" ? "Remove Lock" : "Lock Scheduling";
      openDialogOnClick = true;
      noHttpReqOnClick = true;
      component = ModifyOrderBillingLockModal;
      dataToPass = row;

      break;
    case "BillingStatus":
      permission = ["billing", "intake"];
      canToggle = !row.PharmacyReschedulingLockSet ? true : false;
      if (!permission.includes(userInfo.permissions)) {
        canToggle = false;
      }
      status = value ? "Approved" : "Not approved";
      label = value ? "Unapprove" : "Approve";

      break;
    case "MDOrderStatus":
      permission = ["pharmacy"];

      if (
        permission.includes(userInfo.permissions) &&
        !row.PharmacyRescheduleLockSet
      ) {
        canToggle = !row.PharmacyReschedulingLockSet ? true : false;
      }

      status = row.MDOrderStatus ? "Order Signed" : "Order Unsigned";
      label = "Toggle order status";

      //newly added for modal
      openDialogOnClick = true;
      noHttpReqOnClick = true;
      component = ModifyOrderBillingLockModal;
      dataToPass = row;
      break;
    case "reportStatus":
      permission = ["pharmacy", "admin", "ipmservices", "nurse", "billing"];
      noHttpReqOnClick = true;
      canToggle = value ? true : false;
      if (!permission.includes(userInfo.permissions)) {
        canToggle = false;
      }
      status = value ? "PCF Submitted" : "Awaiting";
      label = value ? "View PCF" : "No PCF to View";
      openDialogOnClick = true;
      component = PCFReview;
      dataToPass = row;

      //in event that nurse is unable to get in contact with patient
      if (value === "unable to contact patient") {
        status = "Unable to contact patient at this time";
        label = "incomplete";
        canToggle = false;
        value = false;
      }

      break;
    case "syringeWasteStatus":
      permission = ["pharmacy", "admin"];
      canToggle = true;
      if (!permission.includes(userInfo.permissions)) {
        canToggle = false;
      }
      status = value ? "Syringe Wasted on this visit" : "No Waste";
      label = "Indicate Waste Syringe";
      openDialogOnClick = true;
      noHttpReqOnClick = true;
      component = SyringeWasteQuestionaire;
      dataToPass = row;

      break;
    default:
      console.log("hit default");
  }

  const onClick = async (input, openDialogOnClick) => {
    if (openDialogOnClick) {
      toggleDialogStatus(true);
    }
    if (!noHttpReqOnClick) {
      try {
        let res = await DBPost(input);

        if (res === "OK") {
          //reconcileChangeWithWorkingCopiesArr(headerID, !value);
        } else if (res !== "OK") {
          // console.log("its an error");
          updateError(true);
        }
      } catch (err) {
        console.error(err);
        updateError(true);
      }
    }
  };

  // const updateComponentState = () => {
  //   updateValue(!value);
  // };

  const DBPost = async (input = {}, urlStr = "") => {
    try {
      let workingCopies = await axios({
        method: "post",
        url: `https://${Info.domain}/PharmacyRefill/${urlStr}`,
        data: input,
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      return workingCopies.data;
    } catch (err) {
      throw err;
    }
  };

  const reconcileChangeWithWorkingCopiesArr = (headerID, newValue) => {
    let clonedWorkingCopies = cloneDeep(workingCopies);
    let workingRow = clonedWorkingCopies[workingCopyIndex];

    if (headerID === "PharmacyReschedulingLockSet") {
      headerID = "RescheduleLock";
    }
    workingRow[headerID] = newValue;
    updateWorkingCopiesFromServer(clonedWorkingCopies);
  };

  return (
    <>
      <WrappedComponent
        wasRescheduled={wasRescheduled}
        isReady={value}
        error={error}
        status={status}
        canToggle={canToggle}
        label={label}
        func={() =>
          onClick(
            { headerID, value: !value, visitID: row["VisitNum"] },
            openDialogOnClick
          )
        }
        id={id}
        header={header}
      />

      {/* conditional in place to limit time to rendering by only loading component if dialog is opened*/}
      {dialogStatus ? (
        <ReviewDialog
          handleDialogClose={handleDialogClose}
          dialogStatus={dialogStatus}
          Component={component}
          dataToPass={{ ...dataToPass, headerID }}
          visitID={row["VisitNum"]}
          token={token}
        />
      ) : null}
    </>
  );
};

export default ConsumerHOC(TableButtonLogic);

//Button Types: PCF, MD Orders, Billing Status, Syringe Waste, Schedule Block

//################Pharmacy
//Status Toggle: MD Orders, Billing Status(disabled), Schedule Block
//Pop-up:
// -PCF: View PCF Report
// -Syringe Waste: Questionaire

//################Billing
//All disabled except for PCF View and Billing Toggle
