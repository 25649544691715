import React from "react";
import "../FormWizard/Wizard.css";
import questionaire from "./PCFQuestionaire";
import "./RefillSchedule.css";
import ReviewHeader from "./ReviewHeader";

const PCFReview = (props) => {
  // console.log(props);
  let { dataToPass } = props;
  const { PCFReport, NurseName, PatientName, VisitType, VisitDate } =
    dataToPass;

  const { answers } = PCFReport;

  if (!PCFReport) {
    return <div>No Reports to view</div>;
  }
  return (
    <>
      <ReviewHeader
        NurseName={NurseName}
        PatientName={PatientName}
        VisitType={VisitType}
        VisitDate={VisitDate}
      />
      {answers ? <ReviewSubmission answers={answers} /> : null}
    </>
  );
};

export default PCFReview;

const ReviewSubmission = (props) => {
  let { answers } = props;

  //list of headers in order
  let answerKeys = Object.keys(questionaire);

  return (
    <>
      <div className="ReviewTitle">PCF Report</div>{" "}
      <div className="ReviewSubmissionParent">
        {" "}
        {answerKeys.map((section, idx) => {
          return (
            <ReviewSection
              section={section}
              answers={answers[section]}
              key={idx}
            />
          );
        })}
      </div>
    </>
  );
};

const ReviewSection = (props) => {
  let { section, answers } = props;

  return (
    <div className="ReviewSection">
      <div className="ReviewTitle">{section}</div>
      <div>
        <RecursiveMap answers={answers} />
      </div>
    </div>
  );
};

const RecursiveMap = (props) => {
  let { answers } = props;
  // console.log("what is answers....", answers);
  // console.log(typeof answers);

  if (!answers) {
    return <div>N/A</div>;
  } else if (Array.isArray(answers)) {
    return answers.map((ans) => {
      return <RecursiveMap answers={ans} />;
    });
  } else if (typeof answers === "object") {
    if (answers["INSURANCE"] && !answers["remove"]) {
      return <div> </div>;
    }
    return (
      <div>
        {Object.keys(answers).map((ans, idx) => {
          return (
            <div className="ReviewGrid" key={idx}>
              <div>{ans}</div>
              <div>
                <RecursiveMap answers={answers[ans]} />
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (typeof answers === "string") {
    return <div className="ReviewGridAnswer">{answers}</div>;
  } else {
    // console.log("WTF is answers", answers);
    return <div className="ReviewGridAnswer">''</div>;
  }
};
