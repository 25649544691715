import React, { useState, useEffect } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Styles from "../ParentStyles.json";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { MdEdit } from "react-icons/md";

//component will only appear if user is nurse, not MC

const WithCalculator = (props) => {
  const { data, editEntireHeaderReportData, title } = props;

  let [dataDetails, updateDataDetails] = useState(data);

  const editDetails = async (header, valChanged) => {
    let clone = dataDetails;
    clone = { ...clone, [header]: valChanged };

    //Medtronic + Flowonix Formula
    //Waste Calculator is Actual Reservoir Vol + Volume wasted to prime + reservoir rinse volume as per M. Amari
    //9_29_2020 as per connie adamek, remove reservoir rinse volume from waste calculator
    //11/23 - re-added as per email from connie and marissa changed name references

    //**** ORiginal code */
    // clone["Waste Calc"] = (
    //   parseFloat(clone["Actual Reservoir Volume (mls)"]) +
    //   parseFloat(clone["Volume wasted to prime filter (mls)"]) +
    //   parseFloat(clone["Reservoir Rinse Volume (mls)"])
    // ).toString();

    clone["Waste Calc"] = (
      parseFloat(clone["Actual Reservoir Volume (mls)"]) +
      parseFloat(clone["Medication wasted to prime filter (mls)"]) +
      parseFloat(clone["Medication wasted to prime pump (mls)"])
    ).toString();

    if (title === "Medtronic Medication Volumes") {
      //Medtronic-only Formula
      clone["Flow Rate Error"] = Math.abs(
        ((parseFloat(clone["Expected Reservoir Volume (mls)"]) -
          parseFloat(clone["Actual Reservoir Volume (mls)"])) /
          (parseFloat(clone["Previous refill volume (mls)"]) -
            parseFloat(clone["Expected Reservoir Volume (mls)"]))) *
          100
      ).toString();
    }

    await updateDataDetails(clone);
    editEntireHeaderReportData(title, clone);
  };

  useEffect(() => {}, [dataDetails, updateDataDetails]);

  return Object.keys(dataDetails).map((header, id) => {
    return (
      <Row
        header={header}
        details={dataDetails[header]}
        allData={dataDetails}
        //update={updateAndCalculate}
        editDetails={editDetails}
        key={header + id}
      />
    );
  });
};

const cannotManuallyEdit = ["Waste Calc", "Flow Rate Error"];

const Row = (props) => {
  let { header, details, editDetails } = props;

  let [isEditing, toggleIsEditing] = useState(false);

  let isAllowedToEdit = true;
  if (cannotManuallyEdit.includes(header)) {
    isAllowedToEdit = false;
  }

  if (isAllowedToEdit) {
    return (
      <Typography color="primary" component={"span"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
          }}
        >
          <div
            style={{
              width: 1500,
              margin: 1,
              padding: 5,
              // flexGrow: 2,
              textAlign: "left",
              flexBasis: "20%",
              alignSelf: "flexStart",
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              fontWeight: "900",
              fontSize: 15,
            }}
          >
            {header}
          </div>
          <div
            field_id={header}
            style={{
              width: "100%",
              fontWeight: "400",
              fontSize: 15,
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              margin: 1,
              padding: 4,
              flexGrow: 3,
              alignSelf: "flexStart",
              flexBasis: "50%",

              //overflow: "auto"
            }}
          >
            {isEditing ? (
              <TextField
                id="details"
                //value={details}
                label={`Original Value: ${details}`}
                multiline
                margin="normal"
                style={{ width: "100%", padding: 0 }}
                onChange={(e) => {
                  editDetails(header, e.target.value);
                }}
                autoFocus
                onBlur={() => {
                  toggleIsEditing(false);
                }}
              />
            ) : (
              <>
                <HoverText
                  onClick={() => {
                    toggleIsEditing(true);
                  }}
                >
                  <MdEdit />
                </HoverText>{" "}
                {details}{" "}
              </>
            )}
          </div>
        </div>
      </Typography>
    );
  } else {
    return (
      <Typography color="primary" component={"span"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
          }}
        >
          <div
            style={{
              width: 1500,
              margin: 1,
              padding: 5,
              // flexGrow: 2,
              textAlign: "left",
              flexBasis: "20%",
              alignSelf: "flexStart",
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              fontWeight: "900",
              fontSize: 15,
            }}
          >
            {header}
          </div>
          <div
            style={{
              width: "100%",
              fontWeight: "400",
              fontSize: 15,
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              margin: 1,
              padding: 4,
              flexGrow: 3,
              alignSelf: "flexStart",
              flexBasis: "50%",

              //overflow: "auto"
            }}
          >
            {details}
          </div>
        </div>
      </Typography>
    );
  }
};

const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 20px;
  textalign: left;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

export default ConsumerHOC(WithCalculator);
