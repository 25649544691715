import React, { useState } from "react";
import Styles from "../ParentStyles.json";
import Typography from "@material-ui/core/Typography";
import { TiDelete } from "react-icons/ti";
import ViewImage from "./ViewImage";
import Modal from "./Modal";
import TextField from "@material-ui/core/TextField";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import { Images } from "./RowDataTypes.js";
import BasicArrayRender from "./BasicArrayRender.js";
import MedicationProfileComponent from "./ProcessMedicationProfile.js";
import EditDate from "./EditDate.js";
import { configCrawler } from "../MobileConfigCopy/configCrawler";

const ReplaceImage = (props) => {
  let { keyName, header, editReportData, editable } = props;

  function onChangeHandler(e) {
    var input = e.target;

    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader.result;

      dataURL = dataURL.split("data:image/jpeg;base64,")[1];
      editReportData(header, keyName, dataURL);
    };
    reader.readAsDataURL(input.files[0]);
  }

  if (editable) {
    return (
      <input
        type="file"
        id="fileinput"
        className="form-control"
        accept="image/*,.pdf"
        multiple
        onChange={onChangeHandler}
        style={{ color: "transparent" }}
      />
    );
  } else {
    return null;
  }
};

const dataLine = (x, y) => {
  return (
    <div style={{ textAlign: "left" }} key={x + y}>
      {x} : {y}{" "}
    </div>
  );
};

//process everything else where details aren't string, image, or patient medication info
const ifObj = (x, isEditable) => {
  let toReturn = [];
  if (x && Array.isArray(x.data)) {
    for (let i of x.data) {
      if (i.Medication) {
        toReturn.push(i.Medication.data);
      } else {
        toReturn.push(dataLine(Object.keys(i)[0], Object.values(i)[0]));
      }
    }

    return toReturn;
  } else if (Array.isArray(x)) {
    return x.map((y, id) => {
      return <div key={id + y}>{y} </div>;
    });
  }
};

const ifImg = (...args) => {
  //console.log(args);
  let key = args[0];
  let data = args[1];
  let header = args[3];
  let editReportData = args[4];
  let editable = args[5];
  let i = args[2];

  const updateData = (newData) => {
    data = newData;
  };

  if (Images.includes(key)) {
    let image = <ViewImage imgStr={data} />;

    return (
      <Modal props={image}>
        <ReplaceImage
          keyName={key}
          header={header}
          editReportData={editReportData}
          updateData={updateData}
          editable={editable}
        />
      </Modal>
    );
  } else {
    return (
      <>
        <Typography style={{ color: "black" }} key={i} component={"span"}>
          {data}
        </Typography>
      </>
    );
  }
};

//Component controls a single row only
const EditableRow = (props) => {
  //console.log("editableRow props", props);
  let { keyName, title } = props;
  let i = props.i + props.keyName;

  let data = props.data || [];
  let toDisplayMed;
  let [isEditing, toggleEditing] = useState(false);
  let [header, updateHeader] = useState(keyName);
  let [details, updateDetails] = useState(data);

  let isEditable = false;
  let formObj = configCrawler(title, keyName);
  if (typeof details === "string" && Images.includes(header) === false) {
    isEditable = true;
  } else if (typeof details !== "object") {
    details = ifImg(
      keyName,
      details,
      i,
      props.title,
      props.editReportData,
      props.editable
    );
  } else {
    if (props.keyName === "Patient Medication Information") {
      if (details.data) {
        details = details.data;
      }

      isEditable = true;
      toDisplayMed = true;

      return (
        <MedicationProfileComponent
          details={details}
          editReportData={props.editReportData}
          header={header}
          title={props.title}
          permissionToEdit={props.editable}
          toggleValidation={props.toggleValidation}
        />
      );

      //details = ifObj(details, isEditable);
    } else {
      //process everything else where details aren't string, image, patient medication info

      return <BasicArrayRender {...props} key={"thisDab" + new Date()} />;
    }
  }

  if (props.editable === false || props.isEditableStatus === false) {
    isEditable = false;
  }

  return (
    <>
      <Typography color="primary" component={"span"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
          }}
        >
          <div
            style={{
              width: 1500,
              margin: 1,
              padding: 5,
              // flexGrow: 2,
              textAlign: "left",
              flexBasis: "20%",
              alignSelf: "flexStart",
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              fontWeight: "900",
              fontSize: 15,
            }}
          >
            {/* {isEditing ? (
            <TextField
              id="header"
              value={header}
              label="header"
              multiline
              margin="normal"
              style={{ width: "100%", padding: 0 }}
              onChange={e => {
                updateHeader(e.target.value);
              }}
            />
          ) : ( */}

            <div>
              {/* Delete button at head of row , only viewable by MC*/}
              {props.editable ? (
                <span
                  onClick={() => {
                    //console.log("hit delete header!");
                    props.editReportData(props.title, header, null, "delete");
                  }}
                >
                  <HoverText>
                    <TiDelete />
                  </HoverText>
                </span>
              ) : null}

              {header}
            </div>
            {/* )} */}
          </div>
          <div
            style={{
              width: "100%",
              fontWeight: "400",
              fontSize: 15,
              backgroundColor: "#d8d8d8",
              borderRadius: 10,
              margin: 1,
              padding: 4,
              flexGrow: 3,
              alignSelf: "flexStart",
              flexBasis: "50%",

              //overflow: "auto"
            }}
          >
            {formObj !== undefined &&
            ["Calendar", "DatePicker"].includes(formObj.formType) ? (
              <EditDate
                {...props}
                initialDate={new Date(props.data).toISOString()}
                dateOnly={true}
              />
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "5% auto" }}>
                <div
                  style={{ alignSelf: "left", fontSize: 12 }}
                  onClick={() => {
                    if (isEditable) {
                      toggleEditing(!isEditing);
                    }
                  }}
                >
                  <EditStatus
                    {...props}
                    toDisplayMed={toDisplayMed}
                    key={header + keyName}
                    editable={isEditable}
                    isEditing={isEditing}
                    addEntry={props.addEntry}
                    header={header}
                    details={details}
                    keyName={keyName}
                  />
                </div>
                <div>
                  <EditableDetails
                    {...props}
                    details={details}
                    isEditing={isEditing}
                    updateDetails={updateDetails}
                    addEntry={props.addEntry}
                    toggleEditing={toggleEditing}
                    header={header}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Typography>
    </>
  );
};

const EditableDetails = (props) => {
  let { isEditing, details } = props;
  return (
    <>
      {isEditing ? (
        <EditArray {...props} />
      ) : (
        <div style={{ textAlign: "left" }}>{details}</div>
      )}
    </>
  );
};

const EditArray = (props) => {
  let { details, updateDetails } = props;

  if (Array.isArray(details) && props.title !== "Patient Medication Profile") {
    return <div>Test</div>;
  } else {
    return (
      <TextField
        id="details"
        value={details}
        label="details"
        multiline
        margin="normal"
        style={{ width: "100%", padding: 0 }}
        onChange={async (e) => {
          await updateDetails(e.target.value);
        }}
        onBlur={() => {
          props.toggleEditing(!props.isEditing);
          props.addEntry(props.header, details, false, props.keyName);
        }}
      />
    );
  }
};

const EditStatus = (props) => {
  if (props.toDisplayMed) {
    return null;
  } else if (props.editable === false || props.isEditableStatus === false) {
    return null;
  } else if (props.isEditing === true) {
    return (
      <div
      // onBlur={() => {
      //   props.addEntry(props.header, props.details, false, props.keyName);
      // }}
      >
        Click outside to save changes
      </div>
    );
  } else if (props.isEditing === false) {
    return (
      <HoverText>
        <MdEdit />
      </HoverText>
    );
  }
};
// margintop: 20px;
const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 20px;
  textalign: left;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

export default EditableRow;
