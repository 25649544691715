import React, { useState } from "react";
import Table from "./NursingScheduleReportsTable";
import ModifyScheduleModal from "./modifyScheduleModal";
import ModifyScheduleForm from "./ModifyScheduleForm";
import ParentStyles from "../../ParentStyles.json";
import { makeStyles } from "@material-ui/core/styles";
import ModifyScheduleDenial from "./ModifyScheduleDenial";

const ModifyScheduleTableParent = (props) => {
  let { ViewState, toggleViewState } = props;
  // console.log(props);

  let [modificationForm, toggleModForm] = useState(false);

  return (
    <div>
      <BackToCal toggleViewState={toggleViewState} />
      <Table data={ViewState} toggleModForm={toggleModForm} />
      <BackToCal toggleViewState={toggleViewState} />
      <ModifyScheduleModal
        modState={modificationForm}
        toggleMod={toggleModForm}
      >
        {/* if pharmacy has set a hard block */}
        {modificationForm.isRescheduleBlocked ? (
          <ModifyScheduleDenial
            modState={modificationForm}
            toggleMod={toggleModForm}
            toggleViewState={toggleViewState}
          />
        ) : (
          <ModifyScheduleForm
            modState={modificationForm}
            toggleMod={toggleModForm}
            toggleViewState={toggleViewState}
          />
        )}
      </ModifyScheduleModal>
    </div>
  );
};

const BackToCal = (props) => {
  let { toggleViewState } = props;
  const classes = useStyles();
  return (
    <div
      onClick={() => {
        toggleViewState(false);
      }}
      className={classes.button}
    >
      &lt; Back to Calendar
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "auto",
    padding: 15,
    backgroundColor: "white",
    borderRadius: 10,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: ParentStyles["darkColor"],
    color: ParentStyles["darkColor"],
    textAlign: "center",
    width: "30%",
    fontWeight: "bold",
    fontSize: 20,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: ParentStyles["darkColor"],
      color: "white",
    },
  },
  input: {
    display: "none",
  },
}));

export default ModifyScheduleTableParent;
