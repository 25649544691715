import React, { useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import { MdAddCircle } from "react-icons/md";
import ParentStyles from "../ParentStyles.json";
import { IoIosSave } from "react-icons/io";
import styled from "styled-components";

const HoverText = styled.span`
  font-size: 20px;
  color: ${ParentStyles["darkColor"]};

  :hover {
    color: ${ParentStyles["intermediateColor"]};

    cursor: pointer;
  }
`;

const AddTo = (props) => {
  // console.log("what is props", props);
  //console.log("props in AddTo", props);
  //if true then add window will open, otherwise plus button
  let [state, toggleState] = useState(false);
  let [isError, toggleError] = useState(false);
  let { params } = props;

  if (state === true) {
    return (
      <>
        {isError ? (
          <div style={{ color: "red" }}>Cannot Submit Blank Input</div>
        ) : null}
        <div style={{ display: "grid", gridTemplateColumns: "70% 15% 15%" }}>
          <div>
            {React.cloneElement(props.children, {
              closeInputBox: () => {
                toggleState(false);
              },
            })}
          </div>
          <div
            name="SaveInput"
            style={{
              fontSize: 25,
              color: ParentStyles["darkColor"],
              alignSelf: "center",
              justifySelf: "center",
              margin: "1%",
            }}
            onClick={() => {
              // console.log("what is params", params);
              if (!params) {
                // console.log("not params");
                toggleError(true);
                return;
              }
              //edge case to prevent blank data from being added
              else if (Array.isArray(params) && !params[2]) {
                // console.log("here its blank...");
                toggleError(true);
                return;
              }
              // console.log("we have params....", params[2]);
              toggleError(false);
              toggleState(false);
              props.fn(...params);
            }}
          >
            <HoverText>
              <IoIosSave />
            </HoverText>
          </div>
          <div
            style={{
              color: ParentStyles["darkColor"],
              backgroundColor: "white",
              borderRadius: 50,
              margin: "1%",
              alignSelf: "center",
              justifySelf: "center",
            }}
            onClick={() => {
              //console.log("close window");
              toggleState(false);
            }}
          >
            <HoverText>X</HoverText>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <HoverText>
        <MdAddCircle
          style={{ fontSize: 30 }}
          onClick={() => {
            toggleState(true);
          }}
        />
      </HoverText>
    );
  }
};

export default ConsumerHOC(AddTo);
