const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Date",
  },

  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },

  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  { id: "reportStatus", numeric: false, label: "PCF Report Status" },
  { id: "billingalerts", numeric: false, label: "Billing Alerts" },
  { id: "button", numeric: false, label: "Buttons" },
];

const createData = function (
  PatientName,
  VisitDate,
  VisitType,
  NurseName,
  VisitNum,
  reportStatus,
  Note,
  AlertText,
  MRN,
  TOUCHDATE,
  policies,
  PCFReport,
  BillingStatus,
  RescheduleLock
) {
  return {
    PatientName,
    VisitDate,
    VisitType,
    NurseName,
    VisitNum,
    reportStatus,
    Note,
    AlertText,
    MRN,
    TOUCHDATE,
    policies,
    PCFReport,
    BillingStatus,
    RescheduleLock,
  };
};

const rowMapper = (incoming) => {
  // console.log("what is inomcing", incoming);
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let patientName;
  let NurseName;
  let VisitDate;
  let visitNum;
  let visitType;
  let reportStatus;
  let Note;
  let AlertText;
  let MRN;
  let TOUCHDATE;
  let policies;
  let PCFReport;
  let BillingStatus;
  let RescheduleLock;

  incoming.forEach((row, id) => {
    try {
      if (
        row.TEXT_ &&
        !row.TEXT_.includes("Refill") &&
        !row.TEXT_.includes("Pump replacement w/BHI syringe")
      ) {
        throw Error("no goodnik");
      }

      patientName = row.Patient;
      visitType = row.VisitType;
      VisitDate = row.DUEDATE;
      NurseName = row.Nurse;
      visitNum = row.VisitID;
      reportStatus = row.PCFStatus;
      Note = row.Note;
      AlertText = row.Alerts;
      MRN = row.MRN;
      TOUCHDATE = row.TOUCHDATE;
      policies = row.policy;
      PCFReport = row.PCFReport;
      BillingStatus = row.BillingStatus;
      RescheduleLock = row.RescheduleLock;

      let rowObj = createData(
        patientName ? patientName : "err",
        VisitDate ? VisitDate.split("T")[0] : "err",
        visitType ? visitType : "err",
        NurseName ? NurseName : "err",
        visitNum ? visitNum : "err",
        reportStatus ? reportStatus : "incomplete",
        Note ? Note : "No Notes Available",
        AlertText ? AlertText : null,
        MRN ? MRN : null,
        TOUCHDATE ? TOUCHDATE : null,
        policies ? policies : [],
        PCFReport ? PCFReport : {},
        BillingStatus ? BillingStatus : null,
        RescheduleLock ? RescheduleLock : null
      );
      returnArr.push(rowObj);
    } catch (err) {
      console.error(err);
    }
  });

  return returnArr;
};

//determine what color row is.
const colorRow = (type, row) => {
  if (row.reportStatus === "Not Started") {
    return "#f79292";
  } else if (row.reportStatus === "incomplete") {
    return "#f79292";
  } else {
    return "#d1f7e7";
  }
};

module.exports = { createData, headCells, rowMapper, colorRow };
