//component can remove entry from array
//add entry to array
//alter entry from array
import Styles from "../ParentStyles.json";
import React, { useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import styled from "styled-components";

const HoverText = styled.span`
  background-color: ${Styles["darkColor"]};
  font-size: 15px;
  textalign: center;
  color: white;
  border-radius: 50px;
  padding: 7px;

  :hover {
    background-color: white
    color: ${Styles["darkColor"]};
    cursor: pointer;
  }
`;

class BasicArrayRender extends React.Component {
  constructor(props) {
    super(props);

    this.state = { adder: false, data: this.props.data };
    this.header = this.props.keyName;
    this.editable = this.props.editable;
  }

  async componentDidMount() {
    await this.setState({ data: this.props.data });
  }

  editData = async (newData) => {
    await this.setState({ data: newData });
  };

  adderState = async () => {
    await this.setState({ adder: !this.state.adder });
  };

  headerStyle = {
    width: 1500,
    margin: 1,
    padding: 5,
    // flexGrow: 2,
    color: Styles["darkColor"],
    flexBasis: "20%",
    textAlign: "left",
    backgroundColor: "#d8d8d8",
    borderRadius: 10,
    fontWeight: "900",
    fontSize: 15,
  };

  // detailsStyle = {
  //   width: "100%",
  //   fontWeight: "400",
  //   fontSize: 15,
  //   backgroundColor: "#d8d8d8",
  //   borderRadius: 10,
  //   margin: 4,
  //   padding: 4,
  //   flexGrow: 3,
  //   alignSelf: "flexStart",
  //   flexBasis: "50%",
  //   color: Styles["darkColor"],
  //   maxHeight: 400,
  //   overflow: "auto",
  //   display: "grid",
  //   gridTemplateColumns: "auto auto"
  // };

  detailsStyle = {
    width: "100%",
    fontWeight: "400",
    fontSize: 15,
    backgroundColor: "#d8d8d8",
    borderRadius: 10,
    margin: 2,
    padding: 2,
    flexGrow: 2,
    alignSelf: "flexStart",
    flexBasis: "50%",
    alignItems: "center",
    color: Styles["darkColor"],
    maxHeight: 400,
    overflow: "auto",
    display: "flex",
  };

  render() {
    if (
      this.state.data &&
      Array.isArray(this.state.data) &&
      this.state.data.length > 0
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "noWrap",
            overflow: "auto",
          }}
        >
          <div style={this.headerStyle}>{this.header}</div>
          <div style={this.detailsStyle}>
            {this.state.data.map((item, id) => {
              return (
                <ArrayListData
                  {...this.props}
                  item={item}
                  editData={this.editData}
                  key={id + item}
                  state={this.state}
                />
              );
            })}
            {this.editable ? (
              <div>
                <AddData
                  adderState={this.adderState}
                  adder={this.state.adder}
                  {...this.props}
                  editData={this.editData}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      if (Array.isArray(this.state.data) && this.state.data.length < 1) {
        return null;
      } else {
        let toReturn = [];
        if (this.state.data && this.state.data.data) {
          this.state.data.data.map((x, id) => {
            let key = Object.keys(x)[0];
            if (key === "DOB") {
              x[key] = x[key].split("T")[0];
            }

            if (!["INSCPHONE", "START", "RANK"].includes(key)) {
              toReturn.push(
                <React.Fragment key={key + new Date()}>
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      padding: 3,
                    }}
                  >
                    {key}
                  </div>
                  <div style={{ padding: 3 }}>{x[key]}</div>
                </React.Fragment>
              );
            }
          });
        } else if (this.state.data && Object.keys(this.state.data).length > 0) {
          if (this.state.data.arrs || this.state.data.multipolicies) {
            toReturn.push(
              <InsuranceDisplay datapts={this.state.data} key="idthing" />
            );
          } else {
            Object.keys(this.state.data).map((key, idx) => {
              if (key !== "PH_NO") {
                toReturn.push(
                  <React.Fragment key={key + idx}>
                    <div
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        padding: 3,
                      }}
                    >
                      {key}
                    </div>
                    <div style={{ padding: 3 }}>
                      {key === "DOB" && this.state.data[key]
                        ? this.state.data[key].split("T")[0]
                        : this.state.data[key]}
                    </div>
                  </React.Fragment>
                );
              }
            });
          }
        }

        return (
          <div
            style={{
              display: "flex",
              flexBasis: "10%",
              flexDirection: "row",
              flexWrap: "noWrap",
              overflow: "auto",
              justifyContent: "center",
            }}
          >
            <div style={this.headerStyle}>{this.header}:</div>

            <div style={this.detailsStyle}>{toReturn}</div>
          </div>
        );
      }
    }
  }
}

const InsuranceDisplay = (props) => {
  let { datapts } = props;
  let toReturn = [];

  let toIterate = datapts.multipolicies ? datapts.multipolicies : datapts.arrs;
  //console.log("what is toIterate", toIterate);
  if (Array.isArray(toIterate)) {
    for (let policy of toIterate) {
      let row = [];

      Object.keys(policy).forEach((info, idx) => {
        if (info !== "GroupNumber") {
          row.push(
            <React.Fragment key={idx}>
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  padding: 3,
                }}
              >
                {info}
              </div>
              <div style={{ padding: 3 }}>{policy[info]}</div>
            </React.Fragment>
          );
        }
      });
      toReturn.push(row);
    }
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto auto auto auto auto",
      }}
    >
      {toReturn}
    </div>
  );
};

const ArrayListData = ({ ...props }) => {
  //let [data, updateData] = useState(props.state.data);
  let data = props.state.data;
  let title = props.title;
  let item = props.item;
  let header = props.keyName;
  let editReportData = props.editReportData;
  let editData = props.editData;

  const delItemArr = (toDel, data) => {
    let toReturn = data.filter((item) => {
      return item !== toDel;
    });

    return toReturn;
  };

  return (
    <>
      <div
        style={{
          padding: 5,
          margin: 3,
          marginRight: 0,
          backgroundColor: "white",
          borderRadius: 10,
        }}
      >
        <EditPriorEntry item={item} {...props} />
      </div>
      {props.editable ? (
        <div
          onClick={
            async () => {
              let res = await delItemArr(item, data);
              await editData(res);
              // setTimeout(() => {
              //   editReportData(title, header, res);
              // }, 3000);
              await editReportData(title, header, res);
            }

            //await props.updateTestVal("123");
          }
          style={{
            // padding: 2,
            // margin: 2,
            // textAlign: "center",
            // backgroundColor: props.state.color,
            // fontSize: 20,
            // fontWeight: "bold",
            marginLeft: 5,
            marginRight: 20,
          }}
        >
          <HoverText>X</HoverText>
        </div>
      ) : null}
    </>
  );
};

const AddData = ({ ...props }) => {
  let adder = props.adder;

  let [text, alterText] = useState(null);

  if (adder) {
    return (
      <div>
        <input
          autoFocus
          placeholder="New Entry Here..."
          style={{ width: "85%", margin: 5, padding: 5 }}
          onChange={(e) => {
            alterText(e.target.value);
          }}
          onBlur={async () => {
            if (text !== null) {
              let returnArr = [...props.data, text];

              await props.editData(returnArr);
              await props.editReportData(props.title, props.keyName, returnArr);
            }
            alterText(null);
            props.adderState();
          }}
        />
      </div>
    );
  } else {
    adder = false;
    return (
      <div
        onClick={() => {
          props.adderState();
        }}
        style={{ borderRadius: 5, padding: 10 }}
      >
        <HoverText>+ Add Entry</HoverText>
      </div>
    );
  }
};

const EditPriorEntry = (props) => {
  let [status, setStatus] = useState(false);
  let [text, editText] = useState(props.item);

  if (status) {
    return (
      <div
        onClick={() => {
          setStatus(!status);
        }}
      >
        <input
          autoFocus
          placeholder={text}
          style={{ width: "85%", margin: 5, padding: 5 }}
          onChange={(e) => {
            editText(e.target.value);
          }}
          onBlur={async () => {
            setStatus(false);
            let returnArr = props.data.filter((x) => {
              return x !== props.item;
            });
            returnArr.push(text);
            await props.editData(returnArr);
            await props.editReportData(props.title, props.keyName, returnArr);
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          if (props.editable) {
            setStatus(!status);
          }
        }}
      >
        {props.item}
      </div>
    );
  }
};

export default ConsumerHOC(BasicArrayRender);
