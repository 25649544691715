import React, { useState, useEffect } from "react";
import {
  ReferenceLine,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Legend,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import DatePicker from "../MatUIDatePicker";
import { Info } from "../../config.js";
import Button from "../Button";
import ReportsTable from "../ReportsTable";
const domain = Info.domain;

const NurseTotalReports = (props) => {
  //console.log("metrics props---", props);
  let [nurseData, updateNurseData] = useState([]);

  let [startDate, updateStartDate] = useState("10/1/2019");
  let [endDate, updateEndDate] = useState(null);
  let [open, toggleOpen] = useState(false);
  let [totalNum, updateTotalNum] = useState(0);

  let [TableOrGraph, toggleTableOrGraph] = useState("Graph");

  const grabNurseGenData = async () => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/analytics/basicNurse`,
      data: {
        startdate: startDate,
        enddate: endDate,
      },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });
    //console.log("what is res?", res);
    return res;
  };

  const updateData = (date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  };
  useEffect(() => {
    grabNurseGenData().then((res) => {
      //console.log("in user effect", res);
      updateNurseData(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let reconfigureData = [];
  let totalLength;

  const getTotalReports = async () => {
    let total = 0;

    await nurseData.map((x) => {
      total += x["TotalReports"];
    });
    updateTotalNum(total);
  };

  if (Array.isArray(nurseData)) {
    nurseData.forEach((dp) => {
      reconfigureData.push({
        Name: `${dp["First Name"]} ${dp["Last Name"]}`,
        "Percent Returned": Math.round(dp["percentReturned"] * 100),
        "Total Number of Reports Approved": dp["TotalReports"],
        "Number of Returns": dp["TimesReturned"],
      });
    });

    reconfigureData.sort((a, b) => {
      return (
        b["Total Number of Reports Approved"] -
        a["Total Number of Reports Approved"]
      );
    });
    totalLength = reconfigureData.length;
    getTotalReports();
  }

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Number of Reports Approved per nurse (click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Number of Reports Approved per nurse (click to close)"
            func={toggleOpen}
            data={false}
          />
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            <div style={{ flexBasis: "40%" }}>
              {" "}
              <DatePicker
                updateDate={updateData}
                dateOnly={true}
                startend={"start"}
                note={"Data accuracy best after 4/1/2020"}
              />
              <DatePicker
                updateDate={updateData}
                dateOnly={true}
                startend={"end"}
                note={"Data most reliable after 4/1/2020"}
              />
            </div>
            <div style={{ flexBasis: "50%" }}>
              <Button
                style={{ margin: 40, maxWidth: "35%" }}
                label={`Display ${
                  TableOrGraph === "Graph" ? "Table" : "Graph"
                } Version of Report`}
                func={toggleTableOrGraph}
                data={TableOrGraph === "Graph" ? "Table" : "Graph"}
              />
            </div>
            <div style={{ flexBasis: "100%" }}>
              {TableOrGraph === "Graph" ? (
                <Graph
                  totalLength={totalLength}
                  reconfigureData={reconfigureData}
                  totalNum={totalNum}
                  toggleTableOrGraph={toggleTableOrGraph}
                  {...props}
                />
              ) : (
                <Table
                  totalLength={totalLength}
                  reconfigureData={reconfigureData}
                  totalNum={totalNum}
                  toggleTableOrGraph={toggleTableOrGraph}
                  {...props}
                />
              )}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
};

const CustomTooltip = (props) => {
  let active = props.active;
  let label = props.label;
  let data =
    props.payload && props.payload[0] ? props.payload[0].payload : null;

  if (active && props.payload) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${label}`}</div>
        <div className="intro">{`Percent Returned: ${
          props.payload ? props.payload[0].value : "N/A"
        }%`}</div>
        <div className="desc">
          {`Total Number of Reports Approved : ${data["Total Number of Reports Approved"]}`}
        </div>
        <div className="desc">
          {`Total Number of Returns by Weekly's: ${data["Number of Returns"]}`}
        </div>
      </div>
    );
  } else {
    return (
      <div className="custom-tooltip">
        <p className="desc">Data problems...</p>
      </div>
    );
  }
};

const Table = (props) => {
  let { reconfigureData } = props;
  //console.log("what are Table props", props);
  return (
    <div style={{ alignItems: "center" }}>
      <ReportsTable
        type="Total Nurse Reports Approved"
        rows={reconfigureData}
      />
    </div>
  );
};

const Graph = (props) => {
  let { totalLength, reconfigureData, totalNum } = props;
  return (
    <>
      <ComposedChart
        width={1000}
        height={totalLength * 50}
        data={reconfigureData}
        margin={{ top: 50, right: 20, bottom: 100, left: 40 }}
        barGap={10}
        layout="vertical"
      >
        <Bar
          dataKey="Total Number of Reports Approved"
          barSize={20}
          fill={"gray"}
        />
        <ReferenceLine
          x={totalNum / totalLength}
          label={{
            value: `Average Number Approved- ${
              Math.round((totalNum / totalLength) * 100) / 100
            }`,
            fill: "red",
            backgroundColor: "white",
          }}
          stroke="red"
          strokeDasharray="3 3"
        />
        <CartesianGrid stroke="#ccc" />
        <YAxis dataKey="Name" interval={0} type="category"></YAxis>

        <XAxis padding={{ top: 12 }} type="number">
          <Label
            value="Total Number of Reports Approved"
            position="insideBottom"
          />
        </XAxis>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </ComposedChart>
    </>
  );
};

export default NurseTotalReports;
