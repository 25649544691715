import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      light: "#439372",
      main: "#15794F",
      dark: "#0e5473",
      contrastText: "#fff"
    }
  }
});
