import "./DemographicMap.css";
import React from "react";
import Switch from "@material-ui/core/Switch";

//controls what features are displayed at a given moment
//ex. nurses or patients or both
const MapControlColumn = (props) => {
  // console.log(props);
  let {
    inputData,
    toggleViewNurses,
    toggleViewPatients,
    viewNurses,
    viewPatients,
  } = props;
  return (
    <>
      <div className="arrangeControlSwitches">
        <div className="switchTitle"> View Nurses: </div>
        <div>
          <Switch
            checked={viewNurses}
            onChange={() => {
              toggleViewNurses(!viewNurses);
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div className="totalCount">{inputData.employees.length}</div>

        <div className="switchTitle">View Patients: </div>
        <div>
          <Switch
            checked={viewPatients}
            onChange={() => {
              toggleViewPatients(!viewPatients);
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        <div className="totalCount">{inputData.patients.length}</div>
      </div>
    </>
  );
};

export default MapControlColumn;
