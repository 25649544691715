import React, { useState, useEffect } from "react";
import ModifyScheduleForm from "../Schedule/ModifyScheduleForm";
import axios from "axios";
import { Info } from "../../config.js";
import ConsumerHOC from "../../context/ConsumerHOC";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import moment from "moment";
import "./RefillSchedule.css";

const ModifyScheduleFormWrapper = (props) => {
  let { visitID, token } = props;
  // console.log("what are props in mod", props);
  // console.log("info", Info.domain);

  let [scheduleHistory, updateScheduleHistory] = useState(false);
  let [openDialog, toggleOpenDialog] = useState(false);

  useEffect(() => {
    const getScheduleHistory = async () => {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${Info.domain}/scheduleReconciliation/wasRescheduledPreviously`,
        data: { visitID: visitID },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      updateScheduleHistory(results.data);
    };

    if (!scheduleHistory) {
      getScheduleHistory();
    }
  }, [scheduleHistory, visitID, token]);

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          toggleOpenDialog(true);
        }}
        color="primary"
      >
        View Previous Scheduling Changes
      </Button>
      <ModifyScheduleForm {...props} />
      {openDialog ? (
        <ScheduleHistoryDialog
          openDialog={openDialog}
          toggleOpenDialog={toggleOpenDialog}
          scheduleHistory={scheduleHistory}
        />
      ) : null}
    </React.Fragment>
  );
};

const ScheduleHistoryDialog = (props) => {
  let { openDialog, toggleOpenDialog, scheduleHistory } = props;
  // console.log(scheduleHistory);
  return (
    <Dialog
      open={openDialog}
      maxWidth="lg"
      // TransitionComponent={Transition}
      keepMounted
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <div className="modifiedScheduleDetails">
          {Array.isArray(scheduleHistory) && scheduleHistory.length > 0 ? (
            scheduleHistory.map((event, id) => {
              return (
                <React.Fragment key={id}>
                  {" "}
                  <div className="modifiedScheduleDetailsSection">
                    <span className="modifiedScheduleDetailsHeader">
                      New Date:{" "}
                    </span>
                    {moment(event.newVisitDate).format("MMMM DD, YYYY")}
                  </div>
                  <div className="modifiedScheduleDetailsSection">
                    <span className="modifiedScheduleDetailsHeader">
                      Original Date:{" "}
                    </span>

                    {moment(event.originalDate).format("MMMM DD, YYYY")}
                  </div>
                  <div className="modifiedScheduleDetailsSection">
                    <span className="modifiedScheduleDetailsHeader">
                      {" "}
                      Updated On:{" "}
                    </span>

                    {moment(event.updateDate).format("MMMM DD, YYYY")}
                  </div>
                  <div className="modifiedScheduleDetailsSection">
                    <span className="modifiedScheduleDetailsHeader">Note:</span>{" "}
                    {event.Note}{" "}
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div>No Schedule History to view</div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleOpenDialog(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConsumerHOC(ModifyScheduleFormWrapper);
