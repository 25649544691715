import React from "react";
import FormWizardConsumerHOC from "./FormWizardConsumerHOC";
import {
  MultipleChoices,
  YesNo,
  TextInput,
  CalendarComp,
  Reschedule,
  InsuranceRemoval,
  UncontrolledDateAndTimePicker,
} from "./componentTypes";
import { cloneDeep } from "lodash";
import "./Wizard.css";

const QuestionRenderer = (props) => {
  let { answers, questionaire, updateAnswers, type, header, question } = props;

  // console.log("question Renderer props", props);
  if (!type) {
    type = "parent";
  }

  const clickHandler = (input) => {
    let { newAnswer, header, question } = input;
    let copiedAnswers = cloneDeep(answers);
    copiedAnswers[header] = { ...copiedAnswers[header], [question]: newAnswer };

    updateAnswers(copiedAnswers);
  };

  const clearChildren = (header, question, newAnswer = "No") => {
    let copiedAnswers = cloneDeep(answers);
    copiedAnswers[header] = {};
    copiedAnswers[header] = { [question]: newAnswer };

    updateAnswers(copiedAnswers);
  };

  let renderGroup = [];

  if (type === "parent") {
    Object.keys(questionaire).forEach((header, key) => {
      let componentInfo = {
        //contains type
        ...questionaire[header],
        header,
        clickHandler,
        clearChildren,
        key,
      };
      renderGroup.push(componentPicker(componentInfo));
    });
  }
  //rendering child components
  else if (answers[header] && answers[header][question]) {
    //check to see if question's answer has a child question to render

    let section = questionaire[header];

    let existingAnswer = answers[header][question];

    let childQs = [];
    section.choices.forEach((option) => {
      //if its a set proceed here
      if (existingAnswer instanceof Set) {
        if (existingAnswer && existingAnswer.has(option.choice)) {
          if (option.childQ) {
            childQs = [...childQs, ...option.childQ];
          }
        }
      } else {
        if (existingAnswer === option.choice) {
          if (option.childQ) {
            childQs = [...childQs, ...option.childQ];
          }
        }
      }
    });

    //process info and push to array for render
    childQs.forEach((Q, key) => {
      let componentInfo = {
        ...Q,
        header,
        clickHandler,
        key,
        clearChildren,
      };
      renderGroup.push(componentPicker(componentInfo));
    });
  }

  if (renderGroup.length < 1) {
    return null;
  }

  return (
    <div
      className={type !== "parent" ? "renderGroupChild" : "renderGroupParent"}
    >
      {renderGroup}
    </div>
  );
};

export default FormWizardConsumerHOC(QuestionRenderer);

const componentPicker = (info) => {
  let { type } = info;

  switch (type) {
    case "MultipleChoices":
      return <MultipleChoices {...info} />;
    case "YesNo":
      return <YesNo {...info} />;
    case "text":
      return <TextInput {...info} />;
    case "CalendarComp":
      return <CalendarComp {...info} />;
    case "Reschedule":
      return <Reschedule {...info} />;
    case "InsuranceRemoval":
      return <InsuranceRemoval {...info} />;
    case "UncontrolledDatePicker":
      return <UncontrolledDateAndTimePicker {...info} />;
    default:
      return null;
  }
};
