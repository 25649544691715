import React, { useState } from "react";
import axios from "axios";
import { Info } from "../../config";
import Button from "./Button";
import Dialog from "./ReportsDialogue";
import ReportRender from "./ReportRender";
import "./ReportRender.css";

const domain = Info.domain;

const ReportGenerators = (props) => {
  // console.log(props);
  let { token } = props;
  let [rescheduledVisits, updateRescheduledVisits] = useState([]);
  let [viewReschedReport, updateViewReschedReport] = useState(false);

  const sortFunc = (e, list, updateState) => {
    const sortBy = e.currentTarget.title;
    let newlyOrderedList = list.sort((a, b) => {
      if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
        return 1;
      } else if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
        return -1;
      }
    });

    updateState([...newlyOrderedList]);
  };

  const getData = async () => {
    try {
      let results = await axios({
        method: "get", // you can set what request you want to be
        url: `https://${domain}/scheduleReconciliation/RescheduledVisitsInThreeWeekWindow`,

        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      // console.log(results.data);
      updateRescheduledVisits(results.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="buttonRow">
        <Button
          func={() => {
            getData();
            updateViewReschedReport(true);
          }}
          label="Rescheduled Visits Next Three Weeks"
        />
      </div>
      {viewReschedReport ? (
        <Dialog
          open={viewReschedReport}
          reportTitle="Rescheduled Visits Within Three Weeks"
          handleClose={() => {
            updateViewReschedReport(false);
          }}
        >
          <ReportRender
            data={rescheduledVisits}
            sortFunc={sortFunc}
            updateState={updateRescheduledVisits}
          />
        </Dialog>
      ) : null}
    </>
  );
};

export default ReportGenerators;
