const Images = [
  "Signature",
  "Final Nurse Signature",
  "Nurse Signature",
  "OpenSignature",
  "Patient Signature",
  "Caregiver Signature",
  "Pump Site Picture",
  "Insurance Card Front",
  "Insurance Card Back",
  "Insurance Card",
  "Skin abnormality site picture",
  "Syringe Label Picture",
  "Catheter Site Picture",
  "Pump Site Picture - concerns",
];

const drugList = {
  "Preservative Free Normal Saline": [
    "Preservative Free Normal Saline - concentration per ml",
    "Preservative Free Normal Saline - daily dose",
  ],
  "Baclofen mcg/ml": [
    "Baclofen - concentration per ml",
    "Baclofen - daily dose",
  ],
  "Lioresal mcg/ml": [
    "Lioresal - concentration per ml",
    "Lioresal - daily dose",
  ],
  "Gablofen mcg/ml": [
    "Gablofen - concentration per ml",
    "Gablofen - daily dose",
  ],
  "Infumorph mg/ml": [
    "Infumorph - concentration per ml",
    "Infumorph - daily dose",
  ],
  "Morphine_Sulfate mg/ml": [
    "Morphine_Sulfate - concentration per ml",
    "Morphine_Sulfate - daily dose",
  ],
  "Hydromorphone mg/ml": [
    "Hydromorphone - concentration per ml",

    "Hydromorphone - daily dose",
  ],
  "Dilaudid HP mg/ml": [
    "Dilaudid HP - concentration per ml",
    "Dilaudid HP - daily dose",
  ],
  "Fentanyl mcg/ml": [
    "Fentanyl - concentration per ml",
    "Fentanyl - daily dose",
  ],
  "Fentanyl_Citrate mcg/ml": [
    "Fentanyl_Citrate - concentration per ml",
    "Fentanyl_Citrate - daily dose",
  ],
  "Sufentanil mcg/ml": [
    "Sufentanil - concentration per ml",
    "Sufentanil - daily dose",
  ],
  "Bupivacaine mg/ml": [
    "Bupivacaine - concentration per ml",
    "Bupivacaine - daily dose",
  ],
  "Meperidine mg/ml": [
    "Meperidine - concentration per ml",
    "Meperidine - daily dose",
  ],
  "Clonidine mcg/ml": [
    "Clonidine - concentration per ml",
    "Clonidine - daily dose",
  ],
  "Prialt mcg/ml": ["Prialt - concentration per ml", "Prialt - daily dose"],
  "Mitigo mg/ml": ["Mitigo - concentration per ml", "Mitigo - daily dose"],
  "Other Drug": [
    "Other Drug - pump settings",
    "Other Drug - concentration per ml",
    "Other Drug - daily dose",
  ],
};

const drugChartHeaders = [
  "Initial Medtronic Pump Settings",
  "Initial Flowonix Pump Settings",
  "Medtronic Pump Status After Update",
  "Flowonix Pump Initial Settings",
  "Flowonix Pump Status After Programming",
  "Initial Non-Programmable Pump Settings",
  "New Non-Programmable Pump Status After Programming",
  "MRI Support - Medtronic Pump Settings",
  "MRI Support - Initial Flowonix Pump Settings",
  "MRI Support - Flowonix Pump Settings",
];

const drugArray = [
  "Therapy",
  "Updated Therapy",
  "Non-Programmable Pump Form - initial Pump Settings",
  "Non-Programmable Form - New Pump Settings",
  "Medtronic Current Pump Settings",
  "Flowonix Pump Drug Settings",
  "New Non-Programmable Pump Status",
];

let emailTriggers = [
  "Patient Information - Updates",
  "Patient Alert Updates",
  "Patient Alert Reviewed",
  "Any changes to insurance information?",
  "Was patient information confirmed?",
  "Insurance Information Update",
  "Initial Assessment - Allergy updates",
  "Allergy updates",
  "Allergies Confirmed",
  "Insurance Card Front",
  "Insurance Card Back",
  "Insurance Card",
  "Communication Notes",
  "Automated Reminders",
  "Communication preferences",
  "Communication Changes",
  "Email Updates",
  "Phone Number - Updates",
  "Cell Phone Number - Updates",
];

let emailHeaders = [
  "Patient Information",
  "Patient Insurance Information",
  "Patient Alerts",
  "Patient Medication Profile",
  "Patient Allergy Profile",
  "Communications",
  "Medtronic Medication Volumes",
  "Updated Medtronic Settings",
  "Medtronic Pump Status After Update",
];

let conditionalEmailConfig = {
  "Medtronic Medication Volumes": {
    trigger: "Flow Rate Error",
    condition: { value: 14, rule: ">" },
  },
  "Updated Medtronic Settings": {
    trigger: {
      "ERI Months": { condition: 12, rule: "<=" },
      "ERI 12 or less?": { condition: "Yes" },
      "Surgeon Name": { condition: "Yes" },
      "Surgeon Phone": { condition: "Yes" },
      "Surgeon Fax": { condition: "Yes" },
    },
  },
  "Medtronic Pump Status After Update": {
    trigger: {
      "ERI Months": { condition: 12, rule: "<=" },
      "ERI 12 or less?": { condition: "Yes" },
      "Surgeon Name": { condition: "Yes" },
      "Surgeon Phone": { condition: "Yes" },
      "Surgeon Fax": { condition: "Yes" },
    },
  },
};

module.exports = {
  Images,
  drugList,
  drugChartHeaders,
  drugArray,
  emailTriggers,
  emailHeaders,
  conditionalEmailConfig,
};
