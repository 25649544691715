import FormWizardContext from "./FormWizardContext";
import React, { Component } from "react";

const initialState = {
  questionaire: {},
  answers: {},
  test: "test",
  missingInput: [],
};

class MyProvider extends Component {
  state = initialState;

  render() {
    return (
      <FormWizardContext.Provider
        value={{
          missingInput: this.state.missingInput,

          updateMissingInput: async (input) => {
            // console.log("new input data", input);
            await this.setState({ missingInput: input });
          },

          miscInfo: this.state.miscInfo,

          updateMiscInfo: async (info) => {
            await this.setState({ miscInfo: info });
          },

          questionaire: this.state.questionaire,

          updateQuestionaire: async (questionaire) => {
            await this.setState({ questionaire });
          },

          answers: this.state.answers,

          updateAnswers: async (answers) => {
            this.setState({ answers });
          },
        }}
      >
        {this.props.children}
      </FormWizardContext.Provider>
    );
  }
}

export default MyProvider;
