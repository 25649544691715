import React, { useState, useEffect } from "react";
import SubmitReportBtns from "./SubmitProgressNoteBtn";
import InputView from "./AddProgressReportInput";
import ProgressContext from "../../ProgressNoteContext/ConsumerHOC";
import UploadedDocuments from "../UploadedDocuments";

const AddReportScreen = (props) => {
  //console.log(props);
  let { MRN } = props.patientData;
  let reportData = {};

  reportData["MRN"] = MRN;
  reportData["FIRST_NAME"] = props.patientData["FIRST_NAME"];
  reportData["LAST_NAME"] = props.patientData["LAST_NAME"];
  reportData["Note"] = props.newProgressNoteText;
  reportData["ReportID"] = null;
  let [id, updateID] = useState(null);
  useEffect(() => {
    if (id === null) {
      let newID = MRN + Date.now();
      updateID(newID);
    }
  }, [id, MRN, reportData]);
  reportData["ReportID"] = id;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "90%",
      }}
    >
      <div style={{ width: "25%" }}>
        {" "}
        <SubmitReportBtns reportData={reportData} />
      </div>

      <div style={{ width: "25%" }}>
        <UploadedDocuments isProgressNote={true} data={reportData} />
      </div>
      <div style={{ width: "50%" }}>
        {" "}
        <InputView />
      </div>
    </div>
  );
};

export default ProgressContext(AddReportScreen);
