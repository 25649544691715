import React from "react";
import Card from "./Card";
import ConsumerHOC from "../context/ConsumerHOC";
import "../App.css";
import "./Menu.css";
import ReportConfig from "../ReportConfig.js";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.props.location.state = { name: "Menu" };
    this.state = { permissions: null, showAdminModal: false };
  }

  async componentDidMount() {
    //indicates that user is admin
    if (Array.isArray(this.props.userInfo.permissions)) {
      await this.setState({ showAdminModal: true });
    } else {
      //user not admin so don't show permissions popup
      this.setState({ permissions: this.props.userInfo.permissions });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (Array.isArray(this.props.userInfo.permissions)) {
        this.setState({ showAdminModal: true });
      } else {
        this.setState({ permissions: this.props.userInfo.permissions });
      }
    }
  }

  render() {
    if (!this.state.permissions) {
      return <div>Nothing to show</div>;
    } else {
      return (
        <RenderButtons permissions={this.state.permissions} {...this.props} />
      );
    }
  }
}

const RenderButtons = (props) => {
  // console.log(props);
  let { permissions, history, userInfo } = props;
  let { email } = userInfo;

  return (
    <div className="menuParent">
      {ReportConfig[permissions].Menu.map((btn) =>
        email === "oncall@basichi.com" &&
        btn.title === "Performance Metrics" ? null : (
          <div key={btn.title} className="menuChild">
            <Card
              title={btn.title}
              onClick={() => {
                history.push({
                  pathname: btn.pathname,
                  state: { btn },
                });
              }}
            />
          </div>
        )
      )}
    </div>
  );
};
export default ConsumerHOC(Menu);
