//const moment = require("moment");

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "TotalNumReportsApproved",
    numeric: true,
    disablePadding: false,
    label: "Total Number of Reports Approved",
  },
  {
    id: "NumberOfReturns",

    numeric: true,
    disablePadding: false,
    label: "Number of Returns",
  },

  {
    id: "PercentReturned",
    numeric: true,
    disablePadding: false,
    label: "Percent Returned",
  },
];

const createData = function (
  Name,
  TotalNumReportsApproved,
  NumberOfReturns,
  PercentReturned
) {
  return {
    Name,
    TotalNumReportsApproved,
    NumberOfReturns,
    PercentReturned,
  };
};

const rowMapper = (incoming) => {
  let returnArr = [];

  if (!Array.isArray(incoming)) {
    incoming = [];
  }
  incoming.forEach((row, id) => {
    //console.log("what is each incoming row", row);
    let rowObj = createData(
      row["Name"],
      row["Total Number of Reports Approved"],
      row["Number of Returns"],
      row["Percent Returned"]
    );
    //console.log("what is rowObj", rowObj);
    returnArr.push(rowObj);
  });

  //console.log("what is return arr", returnArr);
  return returnArr;
};

const colorRow = (type, row) => {
  if (type === "VisitReminders") {
    if (row.PatientReply === "Reschedule") {
      return "#ff9980";
    } else if (row.PatientReply === "Confirm") {
      return "#80ff80";
    }
  }
};

module.exports = { createData, headCells, rowMapper, colorRow };
