import React from "react";
import Input from "./Input";

const rowStyle = { backgroundColor: "white" };

const AddRow = (props) => {
  let { placeholder, passToParent } = props;

  return (
    <Input
      label={placeholder}
      style={rowStyle}
      onChange={async (e) => {
        await passToParent(e.target.value);
      }}
    />
  );
};

export default AddRow;
