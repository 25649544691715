//create a list of all patients assigned to a nurse
//along with the straightline distance to nurse
//sort by farthest to closest
//round all numbers to 10th place

export const ListOfPatientsAssignedToNurse = (
  nurseID,
  nurseLat,
  nurseLng,
  inputData
) => {
  let listOfPatients = [];
  inputData.patients.forEach((patient) => {
    if (parseInt(nurseID) === parseInt(patient.NurseID)) {
      let distance = distanceCalc(
        nurseLat,
        nurseLng,
        patient.Latitude,
        patient.Longitude,
        "M"
      );
      patient.distanceToNurse = Math.round(distance * 10) / 10;
      listOfPatients.push(patient);
    }
  });

  listOfPatients.sort((a, b) => {
    return b.distanceToNurse - a.distanceToNurse;
  });
  return listOfPatients;
};

export const xClosestPatientsToNurse = (nurseLat, nurseLng, inputData) => {
  let listOfPatients = [];
  inputData.patients.forEach((patient) => {
    let distance = distanceCalc(
      nurseLat,
      nurseLng,
      patient.Latitude,
      patient.Longitude,
      "M"
    );
    patient.distanceToNurse = Math.round(distance * 10) / 10;
    listOfPatients.push(patient);
  });
  listOfPatients.sort((a, b) => {
    return a.distanceToNurse - b.distanceToNurse;
  });

  return listOfPatients.slice(0, 70);
};

//input patient coords and receive sorted list of nurses by distance
//return array of objects {nurseId, nurseName, nurseAddress, nurseCoords, nurseDistance}
export const xClosestNursesToPatient = (
  patientObject,
  inputData,
  numOfNurses = 10
) => {
  const patientLat = patientObject.geometry.coordinates[1];
  const patientLng = patientObject.geometry.coordinates[0];

  let listOfClosestNurses = [];

  inputData.employees.forEach((nurse) => {
    let distance = distanceCalc(
      parseFloat(patientLat),
      parseFloat(patientLng),
      parseFloat(nurse.Latitude),
      parseFloat(nurse.Longitude),
      "M"
    );

    nurse.distanceToNurse = Math.round(distance * 10) / 10;

    listOfClosestNurses.push(nurse);
  });

  listOfClosestNurses.sort((a, b) => {
    return a.distanceToNurse - b.distanceToNurse;
  });

  return listOfClosestNurses.slice(0, numOfNurses);
};

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function distanceCalc(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

//returns true if point within radius
export const isPtWithinXRadius = (checkPoint, centerPoint, m = 50) => {
  //must convert to km
  const km = m * 1.60934;
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
};

//returns a count of how many points are within x radius
export const checkIfCoordsArrIsInRadius = (checkPoint, arr, dist) => {
  let count = 0;
  arr.forEach((pt) => {
    let coords = { lat: pt.Latitude, lng: pt.Longitude };
    if (isPtWithinXRadius(checkPoint, coords, dist)) {
      count += 1;
    }
  });
  return count;
};
