import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import PharmacyUpdatesTable from "./PharmacyUpdatesTable";
import { Info } from "../../config";
import PharmacyBoardStatus from "./PharmacyBoardStatus";
const domain = Info.domain;

const PharmacyUpdates = (props) => {
  let { token } = props;
  const [response, setResponse] = useState([]);

  //controls status circle at top of page indicating whether socket connection is live or not.
  let [status, updateStatus] = useState(false);

  // useEffect(() => {
  //   setResponse(props.workingCopies);
  // }, [props.workingCopies]);

  // useEffect(() => {
  //   const socket = socketIOClient.connect(`https://rnexpress.net:88`, {
  //     query: { token },
  //   });

  //   socket.on("update pharmacy board", (data) => {
  //     console.log("Data Refresh recieved....", data);
  //     setResponse(data.recordset);
  //   });

  //   socket.on("FromAPI", (data) => {
  //     console.log("API connnected", data);

  //     //mark status as connected
  //     updateStatus(true);
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("Socket has disconnected");
  //     updateStatus(false);
  //   });

  //   return () => {
  //     socket.disconnect();
  //     updateStatus(false);
  //     console.log("Socket has disconnected");
  //   };
  // }, [token]);

  // if (!Array.isArray(props.workingCopies) && response.length === 0) {
  //   console.log("what is response", response);
  //   return <div>No Data To Display At This Time</div>;
  // }

  // console.log("What is response", response);
  return (
    <div>
      <PharmacyBoardStatus status={status} />
      <PharmacyUpdatesTable inputFromParent={response} />
    </div>
  );
};

export default ConsumerHOC(PharmacyUpdates);
