//This is an attempt to build a form wizard for react
//project will allow user to use config file to set up nested questionaire.

//purpose will be to take the user configured questionaire and output the answers in an object for rendering.

//user must import configuration file
import React, { useState, useEffect } from "react";
import QuestionRenderer from "./QuestionRenderer";
import FormWizardConsumerHOC from "./FormWizardConsumerHOC";
import ReviewSubmission from "./ReviewSubmission";
import "./Wizard.css";

const FormParent = (props) => {
  //renderType prop - show all parent questions at once, vs one question at a time.
  //will show all at once to begin project, second option if time permits.
  //default will true
  let {
    inputQuestionaire,
    questionaire,
    answers,
    updateAnswers,
    updateQuestionaire,
    importedAnswers,
    updateMiscInfo,
    relevantData,
    missingInput,
    updateMissingInput,
    goBackFunc,
    SubmitComponent,
    submitAPI,
  } = props;

  let [inReview, toggleReview] = useState(false);

  useEffect(() => {
    //only run once
    updateQuestionaire(inputQuestionaire);

    //only run once
    // console.log("what is relevantData", relevantData);
    updateMiscInfo(relevantData);

    //only run if no imported answers
    if (!importedAnswers) {
      //take key from each parent question and add them as an object to answers
      let sectionHeaders = {};
      Object.keys(inputQuestionaire).forEach((header) => {
        sectionHeaders[header] = {};
      });

      updateAnswers(sectionHeaders);
    } else {
      updateAnswers(importedAnswers.answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (inReview) {
    return (
      <div className="formParent">
        <ReviewSubmission />
        <SubmitComponent
          {...props}
          toggleReview={toggleReview}
          inReview={inReview}
          answers={answers}
          questionaire={questionaire}
          missingInput={missingInput}
          updateMissingInput={updateMissingInput}
          goBack={goBackFunc}
          submitAPI={submitAPI}
        />
      </div>
    );
  }

  return (
    <div className="formParent">
      <QuestionRenderer answers={answers} />

      <SubmitComponent
        {...props}
        toggleReview={toggleReview}
        inReview={inReview}
        answers={answers}
        questionaire={questionaire}
        missingInput={missingInput}
        updateMissingInput={updateMissingInput}
        goBack={goBackFunc}
      />
    </div>
  );
};

export default FormWizardConsumerHOC(FormParent);
