import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { createPDF } from "../PDFConversion/pdfConversion.js";
import ConsumerHOC from "../context/ConsumerHOC";
import axios from "axios";
import EnhancedButton from "./EnhancedButton.js";
import { progNotePDF } from "../PDFConversion/ProgNoteToPDF.js";
import ReportConfig from "../ReportConfig.js";
import { Info } from "../config.js";
import ReturnToNurseMod from "./ReturnToNurseMod";
import ReturnToNurseAction from "./ReturnToNurseAction";

const domain = Info.domain;

const useStyles = makeStyles({
  card: {
    flexBasis: "25%",
    minHeight: 500,
    margin: "1%",
    padding: 20,
    alignItems: "center",
  },
  button: { borderWidth: 5, borderColor: "black" },
  title: {
    fontSize: 14,
  },
  cardContent: {
    display: "block",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
});

//function definitions

//takes visit data and converts it to PDF depending on whether its a visit or prog note

const PDFer = async (data) => {
  if (data.visitID) {
    return await createPDF(data);
  } else {
    return await progNotePDF(data);
  }
};

//take pdf and send it to server
const submitPDFtoServer = async (d, props) => {
  return await axios.post(`https://${domain}/submission/Finalize`, d, {
    headers: { Authorization: `bearer ${props.token}` },
  });
};

//in essence this function only takes report data, converts to a pdf and sends it to server named according to protocol
//attachments are not packaged
const Finalizer = async (props, status) => {
  // delete old id because we want a new insertion
  // create priorID
  //specify updater info
  props.reportData["priorID"] = props.reportData["_id"];
  delete props.reportData["_id"];
  props.userInfo["RevisionUpdatedAt"] = new Date();
  props.reportData["updaterInfo"] = props.userInfo;

  //updates report data, not files/attachments

  if (status === "Finalized" || status === "finalized") {
    let dataToPass;

    props.type === "progress note"
      ? (dataToPass = props.reportData)
      : (dataToPass = props.currentReport);

    PDFer(dataToPass).then((res) => {
      let pdf = res;

      let data;

      //is report or progress note?
      if (props.type === "progress note") {
        let lastName = props.reportData.lastName.split(" ").join("_");

        let noteDate = new Date().toDateString().split(" ").join("_");

        data = {
          pdf,
          fileName:
            process.env.NODE_ENV === "production"
              ? `PN${lastName}@${noteDate}.pdf`
              : `TEST-PN${lastName}@${noteDate}.pdf`,
          status,
        };
        props.submitUpdate(props.reportData, status, true);
      } else {
        let visitType =
          props.currentReport.visitData["Visit Type"].data["Visit Type"];
        let lastName;
        let firstName;
        if (
          props.currentReport.visitData["Patient Information"].data[
            "Demographics"
          ].data &&
          props.currentReport.visitData["Patient Information"].data[
            "Demographics"
          ].data[1]["LAST_NAME"]
        ) {
          lastName =
            props.currentReport.visitData["Patient Information"].data[
              "Demographics"
            ].data[1]["LAST_NAME"];

          firstName =
            props.currentReport.visitData["Patient Information"].data[
              "Demographics"
            ].data[0]["FIRST_NAME"];
        } else {
          lastName =
            props.currentReport.visitData["Patient Information"].data[
              "Demographics"
            ]["LAST_NAME"];

          firstName =
            props.currentReport.visitData["Patient Information"].data[
              "Demographics"
            ]["FIRST_NAME"];

          firstName = firstName[0];
        }
        firstName = firstName.split(" ").join("_");
        lastName = lastName.split(" ").join("_");

        let visitDate = props.currentReport.visitStart;
        visitDate = new Date(visitDate);
        let vdDate = visitDate.getDate();
        let vdMonth = visitDate.getMonth();
        let visType = visitType === "RN Visit With Refill" ? "RF" : "IN";

        data = {
          visitID: props.currentReport.visitID,
          pdf,
          //set up naming convention for file
          fileName:
            process.env.NODE_ENV === "production"
              ? `${lastName},${firstName[0]}_${
                  vdMonth + 1
                }-${vdDate}_${visType}.pdf`
              : `TEST${lastName},${firstName[0]}_${
                  vdMonth + 1
                }-${vdDate}_${visType}.pdf`,

          status,
        };

        props.submitUpdate(props.currentReport, status);
      }

      //use of toggle message unclear at this point
      submitPDFtoServer(data, props)
        .then(() => {
          props.toggleMessage();
        })
        .catch((err) => {
          //console.log(err);
        });
    });
  }
};

const confirmTelem = (props) => {
  let includesPDF = false;
  props.uploadedFiles.forEach((file) => {
    file.endsWith(".pdf") ? (includesPDF = true) : console.log("nope");
  });

  return includesPDF;
};

//config file picks function from here
export const functionVar = {
  //does uploadedFiles include a pdf which most likely means its a telemetry report.
  TelemConfirm: async (props) => {
    // let includesPDF = false;
    // props.uploadedFiles.forEach(file => {
    //   file.endsWith(".pdf") ? (includesPDF = true) : //console.log("nope");
    // });
    // return includesPDF;
  },

  goBack: (props) => {
    props.history.goBack();
  },
  saveChanges: async (props) => {
    //last 'true' argument demonstrates to server not to send email
    await props.submitUpdate(
      props.currentReport,
      props.status,
      false,
      false,
      true
    );
  },

  //Nurse submitting report to weeklys
  SubmitToWeeklys: async (props) => {
    // console.log(props);
    let newStatus;
    let { history, btn, submitUpdate, currentReport } = props;
    let status = currentReport.status;
    // console.log("ok whats happening here??", btn);
    //discard report
    if (btn.status === "Discard") {
      // console.log("fire from here???");
      await submitUpdate(currentReport, "Discard");

      history.push({ pathname: "/Reports" });
      //history.goBack();
    }
    let isReady = await ConfirmBoxesChecked(currentReport.visitData);
    //if isReady is an array then boxes not all checked properly, must reject submission
    if (Array.isArray(isReady)) {
      return;
    }

    if (status === "working copy" || status === "Discard") {
      // console.log("in here??");
      newStatus = "OpenMC";
      let initialSubmitToWeeklys = true;

      await submitUpdate(
        currentReport,
        newStatus,
        false,
        initialSubmitToWeeklys
      );
    } else if (status === "Returned") {
      newStatus = "Revised";
      await submitUpdate(currentReport, newStatus, false, false);
    }
    //initial code - history.push({ pathname: "/menu" });
    history.goBack();
  },

  WeeklysStatusChange: async (props, statusUpdate) => {
    //returning to a nurse after its already finalized
    if (props.location.state.data["Status"] === "Finalized") {
      props.currentReport["ReturnToNurseAfterFinalization"] = true;
    } //Weeklys returning to nurse for corrections
    else {
      props.currentReport["Returned to Nurse: Time"] = new Date();
      props.currentReport["Times Returned"]
        ? (props.currentReport["Times Returned"] += 1)
        : (props.currentReport["Times Returned"] = 1);
      //await props.submitUpdate(props.currentReport, "Returned");
    }
    props.toggleNoteMod();
  },

  Discard: async (props) => {
    let { history, currentReport } = props;

    // console.log("fire discard!", props);
    //discard report
    if (props.btn.status === "Discard") {
      await props.submitUpdate(currentReport, "Discard");

      // let workingCopiesID = location.state.workingCopiesIndex;
      // currentReport.status = "Discard";
      // console.log(workingCopiesID, currentReport);
      // await updateWorkingCopiesFromClient(currentReport, workingCopiesID);
      //props.history.push({ pathname: "/menu" });
      history.goBack();
    }
  },

  //original function, disconnected as of 7/20
  StatusChange: async (props, statusUpdate) => {
    //console.log("what is status update", statusUpdate);
    let status = props.currentReport.status;
    //console.log("what is status", status);

    let newStatus;

    //returning to a nurse after its already finalized
    if (props.location.state.data["Status"] === "Finalized") {
      props.currentReport["ReturnToNurseAfterFinalization"] = true;
    } //Weeklys returning to nurse for corrections
    else if (statusUpdate === "Returned") {
      props.currentReport["Returned to Nurse: Time"] = new Date();
      props.currentReport["Times Returned"]
        ? (props.currentReport["Times Returned"] += 1)
        : (props.currentReport["Times Returned"] = 1);
    }

    if (Array.isArray(statusUpdate) && status === "working copy") {
      newStatus = "OpenMC";
    } else if (Array.isArray(statusUpdate)) {
      newStatus = "Revised";
    } else {
      newStatus = statusUpdate;
    }
    //confirms whether boxes all checked
    let isReady = await ConfirmBoxesChecked(props.currentReport.visitData);
    //let telemStatus = confirmTelem(props);

    if (Array.isArray(isReady) && newStatus !== "Returned") {
      return;
    }
    // else if (!telemStatus) {
    //   props.updateTelem(telemStatus);
    //   //console.log("hit updater yo");
    //   return;
    // }
    else if (statusUpdate !== "Returned") {
      let initialSubmitToWeeklys;

      if (newStatus === "OpenMC") {
        initialSubmitToWeeklys = true;
      }
      await props.submitUpdate(
        props.currentReport,
        newStatus,
        false,
        initialSubmitToWeeklys
      );
      props.history.push({ pathname: "/menu" });
    }
    props.toggleNoteMod();
    //props.history.push({ pathname: "/menu" });
  },
  Finalize: async (props, status) => {
    await Finalizer(props, status);
    props.history.push({ pathname: "/menu" });
  },

  CreateDaily: async (props) => {
    let { currentReport } = props;
    await axios.post(`https://${domain}/daily/onDemandDaily`, currentReport, {
      headers: { Authorization: `bearer ${props.token}` },
    });
  },
};

//function checks all data to ensure that the boxes have been checked to confirm that section has been reviewed
const ConfirmBoxesChecked = (data) => {
  let empty = [];
  let sectionHeaders = Object.keys(data);
  for (let header of sectionHeaders) {
    if (
      header === "updaterInfo" ||
      header === "next" ||
      header === "Final Submission"
    ) {
      continue;
    } else if (!data[header]["confirmed"]) {
      empty.push(header);
    }
  }
  if (empty.length > 0) {
    return empty;
  }
  return true;
};

function SubmitReportButton(props) {
  // console.log("submit report btn loading!");
  const classes = useStyles();

  // let PDFincluded = confirmTelem(props);
  // props.updateTelem(PDFincluded);

  let [noteModalState, toggleModal] = useState(false);

  const toggleNoteMod = () => {
    toggleModal(!noteModalState);
  };
  // let btnInfo = [];

  // ReportConfig[props.userInfo.permissions].BtnFunctions.forEach(btn => {
  //   btnInfo.push(btn.title);
  // });
  let style = { borderColor: "black", borderWidth: 5 };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContentTitle}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{
            backgroundColor: "#15794F",
            padding: 20,
            margin: 0,
            borderRadius: 5,
            color: "white",
          }}
        >
          {/* section title here */}
          Submission Options
        </Typography>
      </CardContent>

      <CardContent className={classes.cardContent}>
        {ReportConfig[props.userInfo.permissions].BtnFunctions.map(
          (btn, id) => {
            return (
              <div style={{ margin: 15 }} key={id}>
                <EnhancedButton
                  btn={btn}
                  {...props}
                  style={style}
                  key={id}
                  toggleNoteMod={toggleNoteMod}
                />
              </div>
            );
          }
        )}
      </CardContent>

      <ReturnToNurseMod
        openNote={noteModalState}
        close={toggleNoteMod}
        label="Click to Close and Cancel (Note will not be sent)"
      >
        <ReturnToNurseAction
          label="Please provide a detailed explanation to the nurse as to why report is being returned and what corrections need to be made."
          {...props}
        />
      </ReturnToNurseMod>
      {/* <Modal openStatus={false} /> */}
    </Card>
  );
}

export default ConsumerHOC(withRouter(SubmitReportButton));
