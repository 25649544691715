import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    border: "0px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4, 4),
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Mod = (props) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  //props.modalState controls whether component opens on load or not.

  let [modState, toggleMod] = React.useState(props.modalState);

  return (
    <>
      <Modal open={modState}>
        <div style={modalStyle} className={classes.paper}>
          <div>{props.children}</div>
          <div
            onClick={() => toggleMod((modState = !modState))}
            style={{ margin: 10 }}
          ></div>
        </div>
      </Modal>
    </>
  );
};

export default Mod;
