import React, { useState, useEffect } from "react";
import axios from "axios";
import domain from "../../config";
import ServiceChart from "./ServiceChart";

const Info = domain.Info;
const ServiceData = (props) => {
  let { token } = props;
  let [data, updateData] = useState([]);
  let [isErr, updateErr] = useState(null);
  const getData = async () => {
    try {
      let results = await axios({
        method: "get", // you can set what request you want to be
        url: `https://${Info.domain}/analytics/serviceRosterByStateByWeek`,

        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      // console.log(results.data);
      updateData(results.data);
    } catch (err) {
      // console.log(err);
      updateErr(true);
      return err;
    }
  };

  useEffect(() => {
    if (!data.length) {
      getData();
    }
  });

  return (
    <div style={{ margin: "20px", verticalAlign: "center" }}>
      {isErr ? "An Error occured, please contact IT" : null}
      <ServiceChart data={data} isErr={isErr} />
    </div>
  );
};

export default ServiceData;
