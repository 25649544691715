import React, { useState } from "react";
import { CircularProgress, TextField, Box } from "@mui/material";

export const PatientList = ({
  patients,
  setFirstname,
  setLastname,
  handleSearch,
  fetchPatientDocs,
  loading,
}) => {
  const [resultsSearchTerm, setResultsSearchTerm] = useState("");
  // Filter patients based on the search term
  const filteredPatients = resultsSearchTerm
    ? patients.filter((patient) =>
        patient.FIRST_NAME.toLowerCase().includes(
          resultsSearchTerm.toLowerCase()
        )
      )
    : patients;
  return (
    <div style={{ padding: "10px", fontFamily: "Arial, sans-serif" }}>
      <section
        style={{
          display: "flex",
          
          justifyContent: "flex-end",
        }}
      >
        <form onSubmit={handleSearch}>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField id="outlined-basic" size="small" label="First Name" variant="outlined"             onChange={(e) => {
                const value = e.target.value;
                setFirstname(value.charAt(0).toUpperCase() + value.slice(1));
              }}/>
          <TextField id="outlined-basic" size="small" label="Last Name" variant="outlined"            onChange={(e) => {
              const value = e.target.value;
              setLastname(value.charAt(0).toUpperCase() + value.slice(1));
            }}/>
          <button type="submit" style={styles.searchButton}>
            Search
          </button>
        </Box>
        </form>
      </section>
      <aside
        key="resultsContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          margin: "0 auto",
        }}
      >
        {loading ? (
          <section
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            <CircularProgress />
          </section>
        ) : (
          <>
            {patients.length > 0 && (
              <div>
                <TextField
                  helperText="Search by firstname"
                  id="demo-helper-text-aligned"
                  label="Search Results"
                  size="small"
                  value={resultsSearchTerm}
                  onChange={(e) => setResultsSearchTerm(e.target.value)}
                />
                {filteredPatients.map((patient, i) => {
                  return (
                    <PatientTile
                      key={i}
                      patient={patient}
                      fetchPatientDocs={fetchPatientDocs}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </aside>
    </div>
  );
};
function extractPhoneNumbers(phoneNumbersString) {
  // console.log({ phoneNumbersString });
  const phoneNumbers = {};
  if (!phoneNumbersString) return phoneNumbers;
  const entries = phoneNumbersString && phoneNumbersString.split(", ");

  entries.forEach((entry) => {
    const [title, number] = entry.split(": ").map((str) => str.trim());
    if (number) {
      phoneNumbers[title] = number;
    }
  });

  return phoneNumbers;
}
const PatientTile = ({ patient, fetchPatientDocs }) => {
  const { FIRST_NAME, LAST_NAME, DOB, PHONE_NUMBERS } = patient;
  const phoneNumbers = extractPhoneNumbers(PHONE_NUMBERS);
  const { Home, Mobile, Work } = phoneNumbers;

  return (
    <div
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "15px",
        borderRadius: "4px",
        backgroundColor: "rgb(226 226 226)",
        cursor: "pointer",
      }}
      onClick={() => {
        fetchPatientDocs(patient);
      }}
    >
      <h3 style={{ margin: "0", color: "#333", fontSize: "18px" }}>
        {FIRST_NAME} {LAST_NAME}
      </h3>
      <p style={{ margin: "5px 0", fontSize: "14px" }}>
        <b style={{ fontSize: "14px" }}>DOB:</b> {DOB.split(" ")[0]}
      </p>
      <p style={{ margin: "5px 0", fontSize: "14px" }}>
        <b style={{ fontSize: "14px" }}>Phone:</b> {Mobile}
      </p>
    </div>
  );
};

const styles = {
  searchButton: {
    padding: "10px 15px",
    backgroundColor: "rgb(67, 147, 114",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  section: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
};
