import React from "react";
import Input from "../../components/Input";
import DropDown from "../../components/DropDown";

const NewInstructions = (props) => {
  let instructionList = [
    { status: "Telemetry Only", label: "Telemetry Only" },
    { status: "RN Notes and Telemetry", label: "RN Notes and Telemetry" },
    { status: "Not Applicable", label: "Not Applicable" },
  ];
  let { PH_FIRST, PH_LAST, Instructions } = props.row;
  let { updateInstructions, instructions } = props;

  if (!Instructions) {
    Instructions = "Not Applicable";
  }

  const handleChange = (e) => {
    if (e.target && e.target.value) {
      updateInstructions(e.target.value);
    } else {
      updateInstructions(e["status"]);
    }
  };

  const section = { margin: 15 };
  return (
    <div>
      <div style={section}>
        Please specify documentation sharing instructions for Healthcare
        provider:{" "}
        <span style={{ fontWeight: "bold" }}>
          {PH_FIRST} {PH_LAST}
        </span>
      </div>
      <div style={section}>Current instructions: {Instructions} </div>
      <div style={section}>Updated Instructions: {instructions}</div>
      <Input
        label={"Please enter custom instructions"}
        onChange={handleChange}
        style={{ width: "80%", ...section }}
      />
      <div>OR</div>

      <DropDown
        onChange={handleChange}
        list={instructionList}
        value={instructions}
        color="black"
        style={{
          width: "40%",
          padding: 10,

          color: "black",
          ...section,
        }}
      />
    </div>
  );
};

export default NewInstructions;
