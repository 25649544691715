import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
import Button from "../Button";
import { Info } from "../../config.js";
import "./nurseroster.css";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
const domain = Info.domain;

const parseRosterForCSV = (roster) => {
  return roster.map((nurse) => {
    return {
      Primary_RN: nurse.Primary_RN,
      Patients: nurse.Patients,
      Date: nurse.Date.split("T")[0],
    };
  });
};

const NurseRosterByWeek = (props) => {
  let { token } = props;
  let [open, toggleOpen] = useState(false);
  let [error, toggleError] = useState(false);
  let [roster, updateRoster] = useState([]);

  useEffect(() => {
    const CallApi = async (domain) => {
      try {
        let res = await axios({
          method: "get", // you can set what request you want to be
          url: `https://${domain}/analytics/RosterByNurseByWeek`,

          headers: {
            Authorization: `bearer ${token}`,
          },
        });
        // console.log(res.data);
        updateRoster(res.data);
        toggleError(false);
      } catch (err) {
        toggleError("Error occured while retrieving data");
      }
    };
    if (open) {
      CallApi(domain);
    }
  }, [open, token]);

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Nurse Roster Per Week (Click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else {
    return (
      <Paper>
        <div className="nurse-roster-container">
          <CSVLink data={parseRosterForCSV(roster)}>
            {" "}
            <MdFileDownload className="download-button" />{" "}
          </CSVLink>
          <Button
            label="Nurse Roster Per Week (Click to close)"
            func={toggleOpen}
            data={!open}
          />
        </div>
        {error ? (
          <div
            style={{
              backgroundColor: "red",
              color: "white",
              padding: 10,
              margin: 5,
              borderRadius: "5px",
            }}
          >
            {error}
          </div>
        ) : null}
        <div className="nurseRosterParent">
          <table className="nurseRosterTable">
            <tr>
              <th>Nurse Name</th>
              <th>Roster Size</th>
              <th>Date</th>
            </tr>
            {roster.length > 0
              ? roster.map((nurse) => {
                  return (
                    <tr>
                      <td>{nurse.Primary_RN}</td>
                      <td>{nurse.Patients}</td>
                      <td>{nurse.Date.split("T")[0]}</td>
                    </tr>
                  );
                })
              : null}
          </table>
        </div>
      </Paper>
    );
  }
};

export default ConsumerHOC(NurseRosterByWeek);
