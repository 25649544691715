import React from "react";
import TextField from "@material-ui/core/TextField";
import PickPhysician from "./PickPhysician";
import PickOne from "./PickOne";

const ComponentMapper = (props) => {
  let { obj, formik } = props;

  if (obj.type === "date") {
    return (
      <div className="dateStyle">
        <TextField
          id="outlined-helperText"
          name={obj.label}
          label={obj.label}
          type={"datetime-local"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          error={
            formik.touched[obj.label] && formik.errors[obj.label]
              ? formik.errors[obj.label]
              : null
          }
          helperText={formik.errors[obj.label]}
          fullWidth={true}
        />
      </div>
    );
  } else if (obj.type === "text") {
    return (
      <TextField
        id="outlined-helperText"
        name={obj.label}
        label={obj.label}
        type={"text"}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[obj.label]}
        variant="outlined"
        multiline={true}
        error={
          formik.touched[obj.label] && formik.errors[obj.label]
            ? formik.errors[obj.label]
            : null
        }
        helperText={formik.errors[obj.label]}
        fullWidth={true}
      />
    );
  } else if (obj.type === "dropdown") {
    return (
      <PickPhysician
        {...props}
        value={formik.values[obj.label]}
        formikValues={formik.values}
        label={obj.label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        setValues={formik.setValues}
        helperText={formik.errors[obj.label]}
        error={
          formik.touched[obj.label] && formik.errors[obj.label]
            ? formik.errors[obj.label]
            : null
        }
      />
    );
  } else if (obj.type === "pickOne") {
    return (
      <PickOne
        {...props}
        value={formik.values[obj.label]}
        formikValues={formik.values}
        label={obj.label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        choices={obj.choices}
        setValues={formik.setValues}
        helperText={formik.errors[obj.label]}
        error={
          formik.touched[obj.label] && formik.errors[obj.label]
            ? formik.errors[obj.label]
            : null
        }
      />
    );
  }
};

export default ComponentMapper;
