const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Date",
    button: true,
  },
  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },
  {
    id: "DOB",
    date: true,
    numeric: true,
    disablePadding: true,
    label: "DOB ",
    button: false,
  },
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  {
    id: "reportStatus",
    numeric: false,
    label: "PCF Report Status",
    button: true,
  },
  {
    id: "MDOrderStatus",
    numeric: false,
    label: "MD Order Status",
    button: true,
  },
  {
    id: "BillingStatus",
    numeric: false,
    label: "Billing Status",
    button: true,
  },
  {
    id: "syringeWasteStatus",
    numeric: false,
    label: "Syringe Waste Status",
    button: true,
  },
  {
    id: "PharmacyReschedulingLockSet",
    numeric: false,
    label: "Pharmacy Set Rescheduling Lock",
    button: true,
  },
  // { id: "button", numeric: false, label: "Buttons" },
];

const createData = function (
  PatientName,
  VisitDate,
  VisitType,
  NurseName,
  VisitNum,
  reportStatus,
  Note,
  AlertText,
  MRN,
  TOUCHDATE,
  policies,
  PCFReport,
  BillingStatus,
  MDOrderStatus,
  PharmacyReschedulingLockSet,
  syringeWasteStatus,
  WCIndex,
  WasVisitRescheduled,
  CompoundStatus,
  DOB
) {
  return {
    PatientName,
    VisitDate,
    VisitType,
    NurseName,
    VisitNum,
    reportStatus,
    Note,
    AlertText,
    MRN,
    TOUCHDATE,
    policies,
    PCFReport,
    BillingStatus,
    MDOrderStatus,
    PharmacyReschedulingLockSet,
    syringeWasteStatus,
    WCIndex,
    WasVisitRescheduled,
    CompoundStatus,
    DOB,
  };
};

const rowMapper = (incoming) => {
  // console.log("what is inomcing", incoming);
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let patientName;
  let NurseName;
  let VisitDate;
  let visitNum;
  let visitType;
  let reportStatus;
  let Note;
  let AlertText;
  let MRN;
  let TOUCHDATE;
  let policies;
  let PCFReport;
  let BillingStatus;
  let MDOrderStatus;
  let PharmacyReschedulingLockSet;
  let syringeWasteStatus;
  let WCIndex;
  let WasVisitRescheduled;
  let CompoundStatus;
  let DOB;

  incoming.forEach((row, id) => {
    try {
      if (row.TEXT_ && !row.TEXT_.includes("Refill")) {
        throw Error("no goodnik");
      }
      BillingStatus = row.BillingStatus;
      MDOrderStatus = row.MDOrderStatus;
      patientName = row.Patient;
      visitType = row.VisitType;
      VisitDate = row.DUEDATE;
      NurseName = row.Nurse;
      visitNum = row.VisitID;
      reportStatus = row.PCFStatus;
      Note = row.Note;
      AlertText = row.Alerts;
      MRN = row.MRN;
      TOUCHDATE = row.TOUCHDATE;
      policies = row.policy;
      PCFReport = row.PCFReport;
      PharmacyReschedulingLockSet = row.RescheduleLock;
      syringeWasteStatus = row.SyringeWasted;
      WCIndex = row.WCIndex;
      WasVisitRescheduled = row.WasVisitRescheduled;
      CompoundStatus = row.CompoundStatus;
      DOB = row.DOB.split("T")[0];

      let rowObj = createData(
        patientName ? patientName : "err",
        VisitDate ? VisitDate.split("T")[0] : "err",
        visitType ? visitType : "err",
        NurseName ? NurseName : "err",
        visitNum ? visitNum : "err",
        reportStatus ? reportStatus : null,
        Note ? Note : "No Notes Available",
        AlertText ? AlertText : null,
        MRN ? MRN : null,
        TOUCHDATE ? TOUCHDATE : null,
        policies ? policies : [],
        PCFReport ? PCFReport : {},
        BillingStatus ? BillingStatus : false,
        MDOrderStatus ? MDOrderStatus : false,
        PharmacyReschedulingLockSet ? PharmacyReschedulingLockSet : false,
        syringeWasteStatus ? syringeWasteStatus : false,
        WCIndex,
        WasVisitRescheduled,
        CompoundStatus,
        DOB ? DOB : "err"
      );
      returnArr.push(rowObj);
    } catch (err) {
      // console.log("what is err");
    }
  });

  // console.log(returnArr);
  return returnArr;
};

//determine what color row is.
const colorRow = (type, row) => {
  if (row.CompoundStatus === "high" && row.PharmacyReschedulingLockSet) {
    return "#f5a6d6";
  } else if (row.CompoundStatus === "low" && row.PharmacyReschedulingLockSet) {
    return "#e8f797";
  } else if (
    row.CompoundStatus === "third party pharmacy" &&
    row.PharmacyReschedulingLockSet
  ) {
    return "#51D6FF";
    // return "#ADFC86";
  } else if (row.PharmacyReschedulingLockSet) {
    return "gray";
  }
  // } else if (row.reportStatus === "incomplete") {
  //   return "#f79292";
  // } else {
  //   return "#d1f7e7";
  // }

  return "white";
};

module.exports = { createData, headCells, rowMapper, colorRow };
