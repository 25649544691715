import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import "./Scheduler.css";
import DatePicker from "./DatePicker";
import Button from "../Button";
import Input from "../Input";
import axios from "axios";
import { Info } from "../../config.js";
import moment from "moment";
import Spin from "../Spin";
const domain = Info.domain;

const ModifyScheduleForm = (props) => {
  let {
    modState,
    toggleMod,
    token,
    updateWorkingCopiesFromServer,
    toggleViewState,
    workingCopies,
    PCF,
    SaveResultsToPCF,
    dateToParent,
  } = props;

  // console.log("what is props", props);
  let [newInput, updateNewInput] = useState({
    updatedDate: null,
    updatedNotes: null,
    VisitNum: modState.VisitNum,
    originalNotes: modState.Notes,
    originalDate: modState.VisitDate,
    visitType: modState.VisitType,
    patient: modState.PatientName,
    file: null,
    pharmacistInitials: null,
    startTime: modState.startTime,
  });

  let [submissionError, toggleSubmisionError] = useState(false);
  let [isReadAlerts, toggleIsReadAlerts] = useState(false);
  let [showSpinner, toggleSpinner] = useState(false);

  //determines next alarm date by searching for MRN in workingCopies and finds earliest alarm date if available
  //At the moment not applicable to pharmacy functionality
  const findNextAlarmDate = (mrn, allVisitsList) => {
    //return null if for some reason not an array
    if (!Array.isArray(allVisitsList) || !allVisitsList.length) {
      return null;
    }
    let nextAlarmEvent;
    const alarmList = ["Alarm Date", "Potential Alarm Date"];
    allVisitsList.forEach((visit) => {
      if (
        parseInt(visit.MRN) === parseInt(mrn) &&
        alarmList.includes(visit.TEXT_ || visit.VisitType)
      ) {
        let convertedTouchDate = visit.TOUCHDATE
          ? moment(visit.TOUCHDATE.split("T")[0]).format("MM/DD/YYYY")
          : null;
        // console.log(visit);
        if (!nextAlarmEvent) {
          visit.TOUCHDATE = convertedTouchDate;
          nextAlarmEvent = visit;
        } else if (nextAlarmEvent.TOUCHDATE < convertedTouchDate) {
          visit.TOUCHDATE = convertedTouchDate;
          nextAlarmEvent = visit;
        }
      }
    });

    return nextAlarmEvent
      ? moment(nextAlarmEvent.DUEDATE.split("T")[0]).format("MMMM, DD YYYY")
      : null;
  };

  let upcomingAlarmDate = findNextAlarmDate(
    parseInt(modState.MRN),
    workingCopies
  );

  let [nextAlarmDate, updateNextAlarmDate] = useState(
    upcomingAlarmDate ? upcomingAlarmDate : "N/A"
  );

  const submitAndClose = async () => {
    let res;

    //checks to see if patient has an alert in order to display confirmation

    if (modState.AlertText && !isReadAlerts) {
      toggleIsReadAlerts(true);
      return;
    }

    //ensure that date and notes has in fact been updated
    if (!newInput.updatedNotes || !newInput.updatedDate) {
      toggleSubmisionError(
        "User must select a new date and provide a reason for date change"
      );
      return;
    }
    let dataToSend = { ...newInput, nextAlarmDate };

    //if component used in PCF form then save results
    if (SaveResultsToPCF) {
      SaveResultsToPCF(dataToSend);
    }

    //combine original notes with new input
    // dataToSend.updatedNotes =
    //   dataToSend.originalNotes + "\n" + dataToSend.updatedNotes;

    res = await submitToServer(dataToSend, token);

    if (res !== false) {
      let res = await DownloadNewNursingSchedule(token);

      await updateWorkingCopiesFromServer(res.data);
      await toggleMod(false);
      toggleViewState(false);
    } else {
      toggleSubmisionError(
        "An error has occured, your request was unable to complete"
      );
    }

    if (dateToParent) {
      dateToParent(newInput.updatedDate);
    }
  };

  if (isReadAlerts) {
    return (
      <ConfirmAlertsRead
        isAlerts={modState.AlertText}
        submit={submitAndClose}
        offAlerts={toggleIsReadAlerts}
        toggleSpinner={toggleSpinner}
      />
    );
  } else if (showSpinner) {
    return <Spin />;
  }
  return (
    <div>
      {PCF ? null : (
        <div className="flexParent">
          <div className="flexChild">
            <div className="rowHeader"> Nurse Name:</div>
            <div>{modState.NurseName}</div>
          </div>
          <div className="flexChild">
            <div className="rowHeader">Patient Name:</div>
            {modState.PatientName}
          </div>
          <div className="flexChild">
            <div className="rowHeader">Existing Schedule Date:</div>
            {modState.VisitDate}
          </div>
          <div className="flexChild">
            <div className="rowHeader">Visit Type:</div>
            {modState.VisitType}
          </div>
          <div className="flexChild">
            <div className="rowHeader">Previous Date Modifier</div>
            {modState.Note ? modState.updatedBy : "No Modification"}
          </div>
          <div className="flexChild">
            <div className="rowHeader">Notes:</div>
            {modState.Note}
          </div>
          <div className="flexChild">
            <div className="rowHeader">Next Alarm Date:</div>
            {nextAlarmDate}
          </div>
          {modState.AlertText ? (
            <div className="flexChild flexFill">
              <div className="rowHeader">Alerts:</div>
              {modState.AlertText}
            </div>
          ) : null}
        </div>
      )}

      <div>
        <div style={{ margin: 40, padding: 10 }}>
          <DatePicker
            PCF={PCF}
            initialDate={modState.VisitDate}
            header={"Revised Visit Date"}
            updateDate={(date) => {
              updateNewInput({ ...newInput, updatedDate: date });
            }}
            nextAlarmDate={nextAlarmDate}
            workingCopies={workingCopies}
            modState={modState}
            newInput={newInput}
            updateNewInput={updateNewInput}
          />
        </div>

        <div style={{ margin: 40, padding: 10 }}>
          <Input
            label={"Notes:"}
            helperText={
              "Please provide brief explanation for scheduling change."
            }
            onChange={(e) => {
              updateNewInput({ ...newInput, updatedNotes: e.target.value });
            }}
          />
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        {submissionError ? (
          <div
            style={{
              color: "white",
              backgroundColor: "red",
              padding: 30,
              margin: 10,
              borderRadius: 10,
              fontWeight: "bold",
            }}
          >
            {submissionError}
          </div>
        ) : null}

        <Button
          label="Click to Submit"
          disabled={
            ["", null, false].includes(newInput.updatedDate) ||
            ["", null, false].includes(newInput.updatedNotes)
              ? true
              : false
          }
          func={() => submitAndClose(false)}
        />
      </div>
    </div>
  );
};

const DownloadNewNursingSchedule = async (token) => {
  return await axios({
    method: "get", // you can set what request you want to be
    url: `https://${domain}/scheduleReconciliation/`,

    headers: {
      Authorization: `bearer ${token}`,
    },
  });
};

const submitToServer = async (incoming, token) => {
  // console.log(incoming);
  let {
    updatedDate,
    updatedNotes,
    VisitNum,
    originalDate,
    file,
    visitType,
    patient,
    nextAlarmDate,
    pharmacistInitials,
    startTime,
  } = incoming;
  const data = new FormData();
  data.append("nextAlarm", nextAlarmDate);
  data.append("updatedNotes", updatedNotes);
  data.append("updatedDate", updatedDate);
  data.append("VisitNum", VisitNum);
  data.append("originalDate", originalDate);
  data.append("visitType", visitType);
  data.append("patient", patient);
  data.append("file", file);
  data.append("pharmacistInitials", pharmacistInitials);
  data.append("startTime", startTime);

  try {
    //multipart is weird, you must send text and files as below
    //they will appear serverside as req.file and req.body when using multer

    let res = await axios.post(
      `https://${domain}/scheduleReconciliation/`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      }
    );

    return res;
  } catch (err) {
    return false;
  }
};

const ConfirmAlertsRead = (props) => {
  let { isAlerts, submit, offAlerts, toggleSpinner } = props;

  const submitFunc = () => {
    toggleSpinner(true);
    offAlerts(false);
    submit();
  };

  return (
    <div className="parent">
      <div className="paragraph">
        Please be aware of the following alerts:{" "}
        <div className="alerts">{isAlerts ? isAlerts : "no alerts"}</div>
      </div>
      <div className="paragraph">
        Are you sure you still wish to proceed with rescheduling?
      </div>
      <Button
        label="Proceed with rescheduling"
        func={submitFunc}
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
};
export default ConsumerHOC(ModifyScheduleForm);
