import React from "react";
import "./App.css";
import "./styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme.js";
import Auth from "./Auth";
import Provider from "./context/Provider";

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider>
      <Auth />
    </Provider>
  </MuiThemeProvider>
);

export default App;
