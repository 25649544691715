import React, { useEffect, useState } from "react";
import axios from "axios";
import { Info } from "../config.js";
import moment from "moment";
import Button from "./Button";
import "./mileageStyles.css";
import ConsumerHOC from "../context/ConsumerHOC";

const grabWeeklyMileage = async (token, api, method = "get", data = null) => {
  let results = await axios({
    method, // you can set what request you want to be
    url: `https://${Info.domain}/daily/${api}`,
    headers: {
      Authorization: `bearer ${token}`,
    },
    data: data,
  });

  return results.data;
};

const MileageMonthlyWeeklyView = (props) => {
  //component was originally built for weekly view so there may be some holdovers
  //component is rendered from parent that decides if user is looking at month or week time frame
  let { token, showDailyWeeklyMonthly } = props;
  let [weeklyMileageReport, updateWeeklyMileageReport] = useState({
    weekly: [],
    monthly: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      let report = await grabWeeklyMileage(token, "weeklyMonthlyMileage");
      // console.log("what is report here...", report);
      if (weeklyMileageReport !== report && report.weekly.length > 0) {
        updateWeeklyMileageReport(report);
        // console.log(report);
      }
    };
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    weeklyMileageReport &&
    weeklyMileageReport.weekly &&
    weeklyMileageReport.weekly.length > 0
  ) {
    return (
      <ReportDisplay
        showDailyWeeklyMonthly={showDailyWeeklyMonthly}
        token={token}
        report={
          showDailyWeeklyMonthly === "Monthly"
            ? weeklyMileageReport.monthly
            : weeklyMileageReport.weekly
        }
      />
    );
  } else {
    return <div>No Data to Display</div>;
  }
};

const ReportDisplay = (props) => {
  //component was originally built for weekly view so there may be some holdovers
  //component is rendered from parent that decides if user is looking at month or week time frame
  let { report, showDailyWeeklyMonthly, token } = props;
  // console.log(report);
  let [viewWeekMonth, toggleViewWeekMonth] = useState(null);

  //variable created because server will return different term depending on whether we want month or weekly view
  let TimeGroupType;
  if (showDailyWeeklyMonthly === "Monthly") {
    TimeGroupType = "Monthly_Group";
  } else {
    TimeGroupType = "Weekly_Group";
  }

  return (
    <div>
      {report.map((weekMonth, idx) => {
        // console.log(weekMonth);

        let Year = weekMonth["_id"]["Year"];

        return (
          <div key={idx} style={{ margin: 5, width: "75%" }}>
            <Button
              func={() => {
                toggleViewWeekMonth(idx);
              }}
              label={
                TimeGroupType === "Weekly_Group"
                  ? moment()
                      .year(Year)
                      .week(weekMonth["_id"]["Week"] + 1)
                      .day("sunday")
                      .format("MMMM Do YYYY")
                  : moment()
                      .year(Year)
                      .month(weekMonth["_id"]["Month"] - 1)
                      .day("sunday")
                      .format("MMMM YYYY")
              }
            />
            {idx === viewWeekMonth ? (
              <ReportListForWeek
                weekMonth={weekMonth}
                key={idx}
                TimeGroupType={TimeGroupType}
                year={Year}
                token={token}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const ReportListForWeek = (props) => {
  let { weekMonth, TimeGroupType, year, token } = props;

  let mileageForTimeFrame = 0;
  let totalMileageForTimeFrame = 0;
  let rentalCarMileageForTimeFrame = 0;

  let [expand, toggleExpand] = useState(false);

  weekMonth[TimeGroupType].forEach((row) => {
    if (row["ReimburseableMileage"] > 0) {
      mileageForTimeFrame += row["ReimburseableMileage"];
    }
    if (row["TotalMileage"] > 0) {
      totalMileageForTimeFrame += row["TotalMileage"];
    }

    if (row["RentalCarMileage"] > 0) {
      rentalCarMileageForTimeFrame += row["RentalCarMileage"];
    }
  });

  return (
    <>
      <div className="WeekList">
        <table className="WeekListTable">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Total Mileage</th>
              <th>Reimburseable Mileage</th>
              <th>Rental Car Mileage</th>
            </tr>

            {weekMonth[TimeGroupType].map((row, idx) => {
              let reimburseVal = 0;
              if (row["ReimburseableMileage"] > 0) {
                reimburseVal =
                  Math.round(10 * row["ReimburseableMileage"]) / 10;
              }

              return (
                <React.Fragment key={idx}>
                  <tr
                    className="hoverRow"
                    key={idx}
                    onClick={() => {
                      if (expand !== idx) {
                        toggleExpand(idx);
                      } else {
                        toggleExpand(false);
                      }
                    }}
                  >
                    <td key={idx}>
                      {row["Fname"]} {row["Lname"]}
                    </td>
                    <td className="miles">
                      {Math.round(10 * row["TotalMileage"]) / 10} Miles
                    </td>
                    <td className="miles">{reimburseVal} Miles</td>
                    <td className="miles"> {row["RentalCarMileage"]} Miles</td>
                  </tr>
                  {expand === idx ? (
                    <ExpandedWeekView
                      weekNum={weekMonth["_id"]}
                      idx={idx}
                      year={year}
                      row={row}
                      token={token}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="weeklytotalstable">
          <div className="entireRow">
            Total Reimburseable Staff Mileage For Period:{" "}
            {Math.round(10 * mileageForTimeFrame) / 10}
          </div>
          <div className="entireRow">
            Total Staff Mileage Travel For Period:{" "}
            {Math.round(10 * totalMileageForTimeFrame) / 10}
          </div>
          <div className="entireRow">
            Total Rental Car Mileage For Period:{" "}
            {Math.round(10 * rentalCarMileageForTimeFrame) / 10}
          </div>
        </div>
      </div>
    </>
  );
};

const ExpandedWeekView = (props) => {
  // console.log(props);
  let { year, row, weekNum, token } = props;

  let [reportsList, updateReportsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let report = await grabWeeklyMileage(token, "ReportsByWeek", "post", {
        weekNum,
        year,
        submitterEmail: row.User,
        status: "Approved",
      });

      if (reportsList !== report) {
        updateReportsList(report);
        // console.log(report);
      }
    };
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportsList.length > 0) {
    return (
      <>
        {" "}
        <tr>
          <td align="center" colSpan="4">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto",
              }}
            >
              <div className="gridHeader">Date</div>
              <div className="gridHeader">Total Mileage</div>
              <div className="gridHeader">Reimburseable Mileage</div>
              <div className="gridHeader">Rental Car</div>
              {reportsList.map((report, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div>{report.date.split("T")[0]}</div>
                    <div>{report.mileageReport.totalTravel}</div>
                    <div>{report.mileageReport.reimburseableMileage}</div>
                    <div>
                      {report.mileageReport.processedTravelData[0].rentalCar
                        ? "Yes"
                        : "No"}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </td>
        </tr>
      </>
    );
  } else {
    return null;
  }
};

export default ConsumerHOC(MileageMonthlyWeeklyView);
