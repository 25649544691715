const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Date",
  },

  {
    id: "VisitType",
    numeric: false,
    disablePadding: false,
    label: "Visit Type",
  },

  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  { id: "reportStatus", numeric: false, label: "PCF Report Status" },
];

const mobileHeadCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  {
    id: "VisitDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Visit Date",
  },
];

const createData = function (
  PatientName,
  VisitDate,
  VisitType,
  NurseName,
  VisitNum,
  reportStatus,
  Note,
  AlertText,
  MRN,
  TOUCHDATE,
  policies,
  isRescheduleBlocked,
  startTime
) {
  return {
    PatientName,
    VisitDate,
    VisitType,
    NurseName,
    VisitNum,
    reportStatus,
    Note,
    AlertText,
    MRN,
    TOUCHDATE,
    policies,
    isRescheduleBlocked,
    startTime,
  };
};

const rowMapper = (incoming) => {
  // console.log("what is inomcing", incoming);
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let patientName;
  let NurseName;
  let VisitDate;
  let visitNum;
  let visitType;
  let reportStatus;
  let Note;
  let AlertText;
  let MRN;
  let TOUCHDATE;
  let policies;
  let isRescheduleBlocked;
  let startTime;

  incoming.forEach((row, id) => {
    try {
      if (
        row.TEXT_ &&
        !row.TEXT_.toLowerCase().includes("refill") &&
        !row.TEXT_.includes("Pump replacement w/BHI syringe")
      ) {
        // console.log("no goodnike", row.TEXT_);
      } else {
        patientName = row.Patient_First_Name + " " + row.Patient_Last_Name;
        visitType = row.TEXT_;
        VisitDate = row.DUEDATE;
        startTime = row.DUEDATE;
        NurseName = row.Nurse_First_Name + " " + row.Nurse_Last_Name;
        visitNum = row.CPK_CAL;
        reportStatus = row.STATUS;
        Note = row.Note;
        AlertText = row.Alerts;
        MRN = row.MRN;
        TOUCHDATE = row.TOUCHDATE;
        policies = row.policy;
        isRescheduleBlocked = row.isRescheduleBlocked;

        let rowObj = createData(
          patientName ? patientName : "err",
          VisitDate ? VisitDate.split("T")[0] : "err",
          visitType ? visitType : "err",
          NurseName ? NurseName : "err",
          visitNum ? visitNum : "err",
          reportStatus ? reportStatus : "incomplete",
          Note ? Note : "No Notes Available",
          AlertText ? AlertText : null,
          MRN ? MRN : null,
          TOUCHDATE ? TOUCHDATE : null,
          policies ? policies : [],
          isRescheduleBlocked ? isRescheduleBlocked : false,
          startTime ? startTime : "err"
        );
        returnArr.push(rowObj);
      }
    } catch (err) {
      // console.log("what is err", err);
    }
  });

  return returnArr;
};

//determine what color row is.
const colorRow = (type, row) => {
  if (row.pharmacyStatus === "unacknowledged") {
    return "#f79292";
  } else if (row.pharmacyStatus === "acknowledged") {
    return null;
  } else return "#f79292";
};

module.exports = {
  createData,
  headCells,
  rowMapper,
  colorRow,
  mobileHeadCells,
};
