import React, { useState } from "react";
import { sortClosure } from "./sortWithClosure";
import "./ReportRender.css";

const ReportRender = (props) => {
  // console.log(props);

  let [sortDir, changeSortDir] = useState("asc");
  let { data, updateState } = props;

  const lastModifiedSort = sortClosure(
    data,
    updateState,
    sortDir,
    changeSortDir
  );
  const patientNameSort = sortClosure(
    data,
    updateState,
    sortDir,
    changeSortDir
  );
  const originalVisitDateSort = sortClosure(
    data,
    updateState,
    sortDir,
    changeSortDir
  );
  const currentVisitDateSort = sortClosure(
    data,
    updateState,
    sortDir,
    changeSortDir
  );
  const nurseNameSort = sortClosure(data, updateState, sortDir, changeSortDir);

  return (
    <table className="reportRenderParent">
      <thead>
        <tr>
          <th
            className="header"
            title={"LastModifiedOn"}
            onClick={lastModifiedSort}
          >
            {" "}
            Last Modifed On
          </th>
          <th className="header" title={"Last_Name"} onClick={patientNameSort}>
            Patient Name
          </th>
          <th
            className="header"
            title={"originalDate"}
            onClick={originalVisitDateSort}
          >
            Original Visit Date
          </th>
          <th
            className="header"
            title={"DUEDATE"}
            onClick={currentVisitDateSort}
          >
            Current Scheduled Visit Date
          </th>

          <th className="header" title={"LNAME"} onClick={nurseNameSort}>
            Nurse Name
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, id) => {
          return (
            <tr key={id + row.Last_Name + row.First_Name}>
              <td>{row.LastModifiedOn.split("T")[0]}</td>
              <td>
                {row.Last_Name}, {row.First_Name}
              </td>
              <td>{row.originalDate.split("T")[0]}</td>
              <td>{row.DUEDATE.split("T")[0]}</td>

              <td>
                {row.LNAME}, {row.FNAME}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ReportRender;
