import React, { Component, useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import axios from "axios";
import jspdf from "jspdf";
import Styles from "../ParentStyles.json";
import Button from "./Button";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";
import { Info } from "../config.js";
import Dialogue from "./Dialogue";
const fileDownload = require("js-file-download");

const domain = Info.domain;

//Handles the file upload component found on Reports/Assessment view
class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedFiles: [], modal: false, uploadedFiles: [] };
  }

  static PromisifiedFileReader = (inputFile) => {
    return new Promise((resolve, reject) => {
      let temporaryFileReader = new FileReader();
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  async componentDidMount() {
    // console.log("comp mounting", this.props);
    //console.log("this state", this.state);
    await this.props.wipeFileList();

    let list = await this.props.pullFileList(
      this.props.visitID,
      this.props.token
    );
    await this.setState({ selectedFiles: list });
    await this.setState({ uploadedFiles: this.props.uploadedFiles });
  }

  async componentDidUpdate(prevProps) {
    //console.log("identifier", this.props);
    if (
      prevProps.visitID !== this.props.visitID &&
      this.props.visitID !== null
    ) {
      //console.log("first part of update");
      let list = await this.props.pullFileList(
        this.props.visitID,
        this.props.token
      );

      this.props.updateFiles(list);
    }

    if (prevProps.uploadedFiles !== this.props.uploadedFiles) {
      let list = await this.props.pullFileList(
        this.props.visitID,
        this.props.token
      );

      await this.setState({ uploadedFiles: list });
      await this.setState({ selectedFiles: list });
    }
  }

  //ensure that same file does not already exist
  checkNoDuplicates(parentArr, newObj) {
    for (let i of parentArr) {
      if (i.name === newObj.name && i.data === newObj.data) {
        return false;
      }
    }
    return true;
  }

  serverResToState = async (files) => {
    await files.forEach(async (file) => {
      let name = JSON.parse(file.config.data);
      let newFile = {
        name: name["fileName"],
        data: file.data,
        fromServer: true,
      };
      if (this.checkNoDuplicates(this.state.selectedFiles, newFile)) {
        await this.setState({
          selectedFiles: [...this.state.selectedFiles, newFile],
        });
      }
    });
  };

  toggleModal = () => {
    this.state.modal
      ? this.setState({ modal: !this.state.modal })
      : this.setState({ modal: true });
  };

  isImage = (list) => {
    let nonImages = [];
    let images = [];
    list.forEach((file) => {
      if (file.type.startsWith("image/")) {
        images.push(file);
      } else {
        nonImages.push(file);
      }
    });

    return { nonImages, images };
  };

  imageToBase64 = async (file) => {
    try {
      const content = await Upload.PromisifiedFileReader(file);
      var img = new Image();
      let dimensions = {};
      img.onload = async function () {
        var height = img.height;
        var width = img.width;

        dimensions = { height, width };
      };

      img.src = content;
      let returnPackage = { data: content, dimensions };
      return returnPackage;
    } catch (err) {
      //console.log(err);
    }
  };

  Base64ToPDF = (b64) => {
    var doc = new jspdf("p", "pt", "legal");
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.addImage(b64, "JPEG", 0, 0, width, height);
    doc.save("a4.pdf");
  };

  // uploads files to server
  sendAttachments = async (files, token, visitID) => {
    let data = new FormData();
    for (var x = 0; x < files.length; x++) {
      data.append("file", files[x]);
    }
    data.append("visitID", visitID);

    return await axios
      .post(`https://${domain}/submission/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${token}`,
        },
      })
      .then((response) => {
        let resdata = response.data;
      });
  };

  //in essence function takes newly uploaded file and passes it to
  //context api via this.props.updateFiles
  //original function also worked on allowing images to be uploaded and converted to pdf - disconnected atm
  onChangeHandler = async (event) => {
    //console.log("inside of chnage Handler", this.props);
    let newlySelected = [...event.target.files];

    //updates files in provider
    let files = [];
    newlySelected.forEach((file) => {
      files.push(file.name);
    });

    //sends new files to server
    await this.sendAttachments(
      newlySelected,
      this.props.token,
      this.props.visitID
    );

    //combine files into new unified list
    let combinedFileList = [...this.state.uploadedFiles, ...files];
    await this.props.updateFiles(combinedFileList);
    await this.setState({
      uploadedFiles: combinedFileList,
    });
  };

  //removes file from list in app
  //updates context api
  //deletes from server if previously on server
  removeFile = async (name) => {
    //console.log("delete it ????", name);
    let newList = [];
    this.props.uploadedFiles.forEach((x) => {
      if (x !== name) {
        newList.push(x);
      }
    });

    //only deletes from server it was marked as having previously been on server

    let results = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/delete/deleteFile`,
      data: { visitID: this.props.visitID, fileName: name },
      headers: {
        Authorization: `bearer ${this.props.token}`,
      },
    });

    await this.setState({ uploadedFiles: [...results.data] });
    await this.props.updateFiles([...results.data]);
  };

  DownloadFile = async (fileName, visitID, token) => {
    let headerOpts = {
      headers: {
        Authorization: `bearer ${token}`,
      },
    };

    let newData = { visitID: visitID, fileName: fileName };

    let retrievedFile = await axios.post(
      `https://${domain}/review/retrieveFile`,
      newData,
      headerOpts
    );

    return retrievedFile;
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  readMyFile = async (fileName) => {
    let visitID = this.props.visitID ? this.props.visitID : null;
    let token = this.props.token;
    let downloadedFile = await this.DownloadFile(fileName, visitID, token);

    const blob = this.b64toBlob(downloadedFile.data);

    fileDownload(blob, fileName);
  };

  render() {
    //below case may be deprecated, be on the lookout
    if (this.props.didSendFiles) {
      //console.log("check in render");
      this.props.pullFilesFromServer(
        this.serverResToState,
        this.props.visitID,
        this.props.token
      );
    }

    let { permissions } = this.props.userInfo;

    if (this.state.selectedFiles.length > 0) {
      //Documentation upload box
      //ipmservices, nurse and mc may now add documents to report.
      return (
        <div style={{ alignItems: "left" }}>
          {permissions === "ipmservices" ||
          permissions === "nurse" ||
          permissions === "callcenter" ||
          permissions.toLowerCase() === "mc" ? (
            <input
              name="uploadDocs"
              type="file"
              id="fileinput"
              className="form-control"
              accept="image/*,.pdf"
              multiple
              onChange={this.onChangeHandler}
              style={{ color: "transparent" }}
            />
          ) : null}
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto auto",
            }}
          >
            {Array.isArray(this.state.uploadedFiles) &&
            this.state.uploadedFiles.length > 0
              ? this.state.uploadedFiles.map((file, idx) => {
                  return (
                    <DocList
                      removeFile={this.removeFile}
                      key={idx + new Date()}
                      file={file}
                      readMyFile={this.readMyFile}
                      token={this.props.token}
                    />
                  );
                })
              : null}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "75%",
            alignContent: "center",
          }}
        >
          {permissions === "ipmservices" ||
          permissions === "nurse" ||
          permissions === "callcenter" ||
          permissions.toLowerCase() === "mc" ? (
            <input
              type="file"
              className="form-control"
              multiple
              onChange={this.onChangeHandler}
              style={{ color: "transparent" }}
            />
          ) : null}
        </div>
      );
    }
  }
}

const HoverText = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 50px;
  textalign: left;
  padding: 10;
  margin: 20%;
  margintop: 20px;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

const DocList = (props) => {
  let [dialogueStatus, toggleDialogue] = useState(false);

  return (
    <div
      style={{
        marginBottom: "1%",
        display: "grid",
        gridTemplateColumns: "25% 65%",
      }}
    >
      <span>
        <HoverText
          onClick={async () => {
            //await props.removeFile(props.file);
            toggleDialogue(true);
          }}
        >
          <TiDelete />
        </HoverText>
      </span>{" "}
      {/* <div
        onClick={() => {
          //console.log(props.file);
          props.readMyFile(props.file);
        }}
        style={{ padding: 20, fontSize: 15, borderWith: 2 }}
      >
        {props.file.name}
      </div> */}
      <span style={{ width: "100%" }}>
        <Button
          label={props.file}
          func={() => props.readMyFile(props.file)}
          //data={props}
          //status={btn.status}
        />
      </span>
      <Dialogue
        onConfirmAction={() => props.removeFile(props.file)}
        dialogueStatus={dialogueStatus}
        toggleDialogue={toggleDialogue}
        text={`Are you sure you wish to remove ${props.file}`}
        title={"File Removal - Confirmation"}
      />
    </div>
  );
};

export default ConsumerHOC(Upload);
