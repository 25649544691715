import React, { useState, useEffect } from "react";
import PCFAdminWeekTabs from "./PCFAdminWeekTabs";
import moment from "moment";
import "./PCFAdminFilter.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../Input";
import Button from "../Button";

const PCFAdminFilter = (props) => {
  // console.log("props in PCFAdminFilter", props);
  let { workingCopies, workingCopiesToList, updateWorkingCopiesToList } = props;

  // which week to view - contains nuumber
  let [week, toggleWeek] = useState(moment().isoWeek());

  let [workingCopiesSortedByWeek, updateworkingCopiesSortedByWeek] = useState(
    {}
  );

  useEffect(() => {
    if (workingCopies && Array.isArray(workingCopies)) {
      let objectOfArrays = {};
      workingCopies.forEach((visit, id) => {
        visit["WCIndex"] = id;
        let week = visit.Week;
        if (!objectOfArrays[week]) {
          objectOfArrays[week] = [];
        }
        objectOfArrays[week].push(visit);
      });

      updateworkingCopiesSortedByWeek(objectOfArrays);
      updateWorkingCopiesToList(objectOfArrays[week]);
    }
  }, [updateWorkingCopiesToList, week, workingCopies]);

  const ResetWeek = () => {
    // console.log("reset week", workingCopies);
    if (workingCopies && Array.isArray(workingCopies)) {
      let objectOfArrays = {};
      workingCopies.forEach((visit) => {
        let week = visit.Week;
        if (!objectOfArrays[week]) {
          objectOfArrays[week] = [];
        }
        objectOfArrays[week].push(visit);
      });

      updateworkingCopiesSortedByWeek(objectOfArrays);
      updateWorkingCopiesToList(objectOfArrays[week]);
    }
  };

  return (
    <div>
      <FilterDash
        week={week}
        workingCopiesSortedByWeek={workingCopiesSortedByWeek}
        workingCopiesToList={workingCopiesToList}
        updateWorkingCopiesToList={updateWorkingCopiesToList}
        ResetWeek={ResetWeek}
      />
      <PCFAdminWeekTabs
        toggleWeek={toggleWeek}
        week={week}
        weekNumbers={Object.keys(workingCopiesSortedByWeek)}
      />
    </div>
  );
};

const FilterDash = (props) => {
  let {
    workingCopiesSortedByWeek,
    week,
    updateWorkingCopiesToList,
    ResetWeek,
  } = props;

  const FilterFunc = (e, section) => {
    const searchTerm = e.target.value;
    let inputSection = e.target.name || section;
    // console.log(inputSection);

    let filtered = workingCopiesSortedByWeek[week].filter((row) => {
      if (inputSection === "Nurse Last Name") {
        return (
          row.Nurse.toLowerCase().slice(0, searchTerm.length) ===
          searchTerm.trim().toLowerCase()
        );
      }
      if (inputSection === "Patient Last Name") {
        return (
          row.Patient.toLowerCase().slice(0, searchTerm.length) ===
          searchTerm.trim().toLowerCase()
        );
      }
      if (inputSection === "Flags") {
        return row.PCFReport && row.PCFReport.highlight.includes(searchTerm);
      }
      if (inputSection === "Report Status") {
        return row.STATUS === searchTerm;
      }
    });
    updateWorkingCopiesToList(filtered);
  };
  //Can probably delete
  // let statusOpts = [
  //   { label: "Not Yet Begun", value: null },
  //   { label: "Incomplete", value: "incomplete" },
  //   { label: "Unable to contact patient", value: "unable to contact patient" },
  //   { label: "Completed", value: "complete" },
  // ];
  let flags = [
    "None",
    "Reschedule Request",
    "Added Insurance Policy",
    "Insurance Policy",
    "Hospice Plans",
    "Recent Hospitalizations",
  ];

  return (
    <div className="FilterRow">
      <div className="FilterTitle">Report Filters</div>
      <DropDown choices={flags} title="Flags" FilterFunc={FilterFunc} />
      <Input
        label="Patient Last Name"
        onChange={FilterFunc}
        fullWidth={false}
      />
      <Input label="Nurse Last Name" fullWidth={false} onChange={FilterFunc} />
      <div className="resetBTN">
        <Button
          label="Reset Filter"
          func={() => {
            ResetWeek();
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DropDown = (props) => {
  let { choices, title, FilterFunc } = props;
  const classes = useStyles();
  let [value, updateValue] = useState(null);

  const handleChange = (e) => {
    updateValue(e.target.value);
    FilterFunc(e, title);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
        >
          {choices.map((val) => {
            if (val.label) {
              return <MenuItem value={val.value}>{val.label}</MenuItem>;
            }
            return <MenuItem value={val}>{val}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default PCFAdminFilter;
