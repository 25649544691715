import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ViewImage from "./ViewImage";
import Button from "./Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "85%",
    width: "65%",
  };
}

const Mod = (props) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  let [modState, toggleMod] = React.useState(false);
  let Img = props.props.props.imgStr;

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "5% 5%" }}>
        <div>
          <button
            type="button"
            onClick={() => toggleMod((modState = !modState))}
          >
            {modState === true ? "Close" : "Open Picture"}
          </button>
        </div>
        <div> {props.children}</div>
      </div>
      <Modal open={modState}>
        <div style={modalStyle} className={classes.paper}>
          <div
            onClick={() => toggleMod((modState = !modState))}
            style={{ marginBottom: 10 }}
          >
            <Button label="Click to Close" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <ViewImage imgStr={Img} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Mod;
