const moment = require("moment");

const headCells = [
  {
    id: "PatientName",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
  },
  // {
  //   id: "VisitType",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Visit Type",
  // },
  {
    id: "OfficialVisitDate",
    //date: true,
    numeric: true,
    disablePadding: false,
    label: "Official Visit Date",
  },
  // {
  //   id: "VisitDateOnMessage",
  //   date: true,
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Visit Date at Time of Message",
  // },

  {
    id: "ReminderSendDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Date Reminder Send",
  },
  {
    id: "ReminderSendMethod",
    numeric: true,
    disablePadding: false,
    label: "Reminder Send Method",
  },
  {
    id: "ReminderDestination",
    numeric: true,
    disablePadding: false,
    label: "Reminder Destination",
  },
  {
    id: "SystemMsg",
    numeric: false,
    disablePadding: false,
    label: "System Message",
  },

  {
    id: "PatientReply",
    numeric: false,
    disablePadding: false,
    label: "Patient Reply",
  },
  {
    id: "PatientReplyDate",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Patient Reply Date",
  },
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  // { id: "VisitID", numeric: true, disablePadding: false, label: "Visit ID" },
];

const createData = function (
  PatientName,
  VisitType,
  OfficialVisitDate,
  VisitDateOnMessage,
  ReminderSendDate,
  ReminderSendMethod,
  ReminderDestination,
  SystemMsg,
  PatientReply,
  PatientReplyDate,
  NurseName,
  VisitID
) {
  return {
    PatientName,
    VisitType,
    OfficialVisitDate,
    VisitDateOnMessage,
    ReminderSendDate,
    ReminderSendMethod,
    ReminderDestination,
    SystemMsg,
    PatientReply,
    PatientReplyDate,
    NurseName,
    VisitID,
  };
};

const rowMapper = (incoming) => {
  if (!Array.isArray(incoming)) {
    incoming = [];
  }
  let returnArr = [];

  incoming.forEach((row, id) => {
    //console.log("what is each incoming row", row);
    let rowObj = createData(
      `${row.LAST_NAME}, ${row.FIRST_NAME}`,
      row.TEXT_,
      moment.utc(row.ActualVisitDate).format("MMMM Do YYYY"),
      row.visitDate,
      row.sendDate,
      row.Method,
      row.destination,
      row.SystemSendMessage,
      row.patientReply ? row.patientReply : "No Reply Registered",
      row.receiveDate ? row.receiveDate : "No Reply",
      `${row.NurseLastName}, ${row.NurseFirstName}`,
      row.visitID
    );
    //console.log("what is rowObj", rowObj);
    returnArr.push(rowObj);
  });

  //console.log("what is return arr", returnArr);
  return returnArr;
};

const colorRow = (type, row) => {
  if (type === "VisitReminders") {
    if (row.PatientReply === "Reschedule") {
      return "#ff9980";
    } else if (row.PatientReply === "Confirm") {
      return "#80ff80";
    }
  }
};

module.exports = { createData, headCells, rowMapper, colorRow };
