//const moment = require("moment");

const headCells = [
  {
    id: "NurseName",
    numeric: false,
    disablePadding: false,
    label: "Nurse Name",
  },
  {
    id: "Date",
    date: true,
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "Mileage",
    date: false,
    numeric: true,
    disablePadding: false,
    label: "Total Mileage",
  },
  {
    id: "ReimburseableMileage",
    date: false,
    numeric: true,
    disablePadding: false,
    label: "Reimburseable Mileage",
  },
  {
    id: "Status",
    date: false,
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Paid",
    date: false,
    numeric: false,
    disablePadding: false,
    label: "Paid",
  },
];

const createData = function (
  NurseName,
  Date,
  Mileage,
  ReimburseableMileage,
  Status,
  ID,
  Paid
) {
  return {
    NurseName,
    Date,
    Mileage,
    ReimburseableMileage,
    Status,
    ID,
    Paid,
  };
};

const rowMapper = (incoming) => {
  let returnArr = [];
  if (!Array.isArray(incoming)) {
    return returnArr;
  }

  incoming.forEach((row) => {
    if (row.mileageReport !== undefined) {
      let rowObj = createData(
        `${row.user.lastName}, ${row.user.firstName}`,
        row.date,
        row.mileageReport.totalTravel,
        row.mileageReport.reimburseableMileage <= 0
          ? 0
          : Math.round(10 * row.mileageReport.reimburseableMileage) / 10 || 0,
        row["Mileage Report Status"],
        row["_id"],
        row.Paid ? "Yes" : "No"
      );
      returnArr.push(rowObj);
    }
  });

  return returnArr;
};

module.exports = { createData, headCells, rowMapper };
