export const headCells = [
  {
    id: "ContactName",
    numeric: false,
    disablePadding: false,
    label: "Contact Name",
  },

  {
    id: "Title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "ContactStatus",
    disablePadding: false,
    label: "Contact Status",
  },
];

export const createData = function (...args) {
  // console.log(args);
  let returnObj = {};
  args.forEach((arg, id) => {
    returnObj[arg] = arg;
  });
  return returnObj;
};

export const rowMapper = (incoming) => {
  // console.log("what is incoming", incoming);
  if (!incoming || !Array.isArray(incoming)) {
    return [];
  }
  let returnArr = [];
  let ContactName;
  let Title;
  let ContactStatus;

  incoming.forEach((row, id) => {
    try {
      if (row.LastName) {
        ContactName = `${row.LastName || ""}, ${row.FirstName} ${
          row.MiddleName || ""
        }`;
        Title = row.Title || "N/A";
        ContactStatus = row.ContactStatus || "N/A";

        let rowObj = {
          ContactName,
          Title,
          ContactStatus,
          ...row,
        };
        returnArr.push(rowObj);
      }
    } catch (err) {
      console.error(err);
    }
  });

  // console.log("what is reutrn arr", returnArr);
  return returnArr;
};

//determine what color row is.
export const colorRow = (type, row) => {
  //   if (row.reportStatus === "Not Started") {
  //     return "#f79292";
  //   } else if (row.reportStatus === "incomplete") {
  //     return "#f79292";
  //   } else {
  //     return "#d1f7e7";
  //   }
  return null;
};
