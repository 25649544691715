const config = require("./config.json");

export const configCrawler = (sectionTitle, key) => {
  let sectionToSearch =
    typeof sectionTitle === "string" ? config[sectionTitle] : sectionTitle;
  if (!Array.isArray(sectionToSearch)) {
    return;
  }
  for (let x of sectionToSearch) {
    if (x["QId"] === key) {
      return x;
    } else if (x["question"] === key) {
      return x;
    } else if (x["Answers"]) {
      return configCrawler(x["Answers"], key);
    } else if (x["ifSelected"]) {
      return configCrawler(x["ifSelected"], key);
    }
  }
};
