import React, { useEffect, useState } from "react";
import "./Wizard.css";
import "./customComp.css";
import FormWizardConsumerHOC from "./FormWizardConsumerHOC";
import Button from "./Button.js";
import QuestionRenderer from "./QuestionRenderer";
import Input from "../Input.js";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ModifyScheduleForm from "../Schedule/ModifyScheduleForm";
import ConsumerHOC from "../../context/ConsumerHOC";

const ErrorMsg = (props) => {
  let { missingInput, header, question, validationError } = props;
  if (
    missingInput &&
    missingInput[header] &&
    missingInput[header].includes(question)
  ) {
    return <div className="InputError">Required*</div>;
  } else if (validationError) {
    return <div className="InputError">Error:{validationError}</div>;
  } else {
    return <div></div>;
  }
};

const MultipleChoicesBase = (props) => {
  // console.log("fire away props", props);
  let {
    choices,
    question,
    answers,
    header,
    clickHandler,
    questionaire,
    mandatory,
    missingInput,
  } = props;

  //add all mandatory questions to component automatically
  if (!answers[header][question] && mandatory) {
    clickHandler({ newAnswer: new Set(), header, question });
  }

  //process answer into set before submission
  const wrapClickHandler = (input) => {
    let { newAnswer, header, question } = input;
    let currentAnswers = answers[header][question];
    if (!currentAnswers) {
      currentAnswers = new Set();
      currentAnswers.add(newAnswer);
    } else if (currentAnswers.has(newAnswer)) {
      currentAnswers.delete(newAnswer);
    } else {
      currentAnswers.add(newAnswer);
    }
    newAnswer = currentAnswers;
    clickHandler({ newAnswer, header, question });
  };

  return (
    <div className="overallQuestion">
      <div className="QuestionTitle">{question}</div>
      <ErrorMsg
        missingInput={missingInput}
        header={header}
        question={question}
      />
      <div className="optionParent">
        {choices.map((option, idx) => {
          return (
            <div className="option" key={idx}>
              <Button
                key={idx}
                label={option.choice}
                data={{ newAnswer: option.choice, header, question }}
                func={wrapClickHandler}
              />
            </div>
          );
        })}
      </div>
      <QuestionRenderer
        answers={answers}
        question={question}
        header={header}
        questionaire={questionaire}
        type={"child"}
      />
    </div>
  );
};

const YesNoBase = (props) => {
  // console.log(props);
  let {
    choices,
    question,
    answers,
    header,
    clickHandler,
    clearChildren,
    mandatory,
    missingInput,
    defaultAnswer,
  } = props;

  let [hasChild, updateHasChild] = useState(false);
  let [value, updateValue] = useState(null);

  const hasChildQuestion = () => {
    let toReturn = false;

    choices.forEach((option) => {
      if (option.childQ) {
        toReturn = true;
      }
    });
    if (hasChild !== toReturn) {
      updateHasChild(toReturn);
    }

    return toReturn;
  };

  //add all mandatory questions to component automatically
  if (!answers[header][question] && mandatory) {
    if (defaultAnswer) {
      hasChildQuestion();

      const ourSet = new Set();
      ourSet.add(defaultAnswer);
      clickHandler({ newAnswer: ourSet, header, question });
    } else {
      clickHandler({ newAnswer: [], header, question });
    }
  }

  useEffect(() => {
    //return answer of this question => is selected or not
    const componentValue = () => {
      let questionAnswer = answers[header][question];

      updateValue(questionAnswer);

      return questionAnswer;
    };

    componentValue();
  }, [
    answers,
    clickHandler,
    header,
    mandatory,
    props,
    question,
    updateValue,
    value,
  ]);

  //process answers, determine question contains child, and clear children if answer is no before submission
  const wrapClickHandler = (input) => {
    let { newAnswer, header, question } = input;
    hasChildQuestion(newAnswer);

    clickHandler({ newAnswer, header, question });

    //if answer is no there are children, then wipe all child answers
    if (hasChild && newAnswer !== answers[header][question]) {
      //wipe all children
      clearChildren(header, question, newAnswer);
    }
  };

  const SelectedBtn = {
    backgroundColor: "white",
    color: "#15794f",
    height: "50%",
  };
  const notSelectedBtn = {
    backgroundColor: "#15794f",
    color: "white",
    height: "50%",
  };
  // console.log("well does it have a child?", hasChild, header, question);
  return (
    <div className="overallQuestion">
      <div className="QuestionTitle">{question}</div>
      <ErrorMsg
        missingInput={missingInput}
        header={header}
        question={question}
      />
      <div className="optionParent">
        {choices.map((option, idx) => {
          return (
            <div className="option" key={idx}>
              <Button
                style={value === option.choice ? SelectedBtn : notSelectedBtn}
                label={option.choice}
                data={{ newAnswer: option.choice, header, question }}
                func={wrapClickHandler}
              />
            </div>
          );
        })}
      </div>

      {hasChild ? (
        <QuestionRenderer question={question} header={header} type={"child"} />
      ) : null}
    </div>
  );
};

const TextInputBase = (props) => {
  // console.log("text box", props);
  let {
    answers,
    header,
    clickHandler,
    question,
    missingInput,
    mandatory,
    maxLength,
    type_validation,
  } = props;

  let [validationError, toggleValidationError] = useState(false);

  //add all mandatory questions to component automatically
  if (!answers[header][question] && mandatory) {
    clickHandler({ newAnswer: [], header, question });
  }

  const updateText = (e) => {
    if (e.target.value.length > maxLength) {
      toggleValidationError(
        `Field has exceeded maximum input of ${maxLength}'`
      );
    } else if (
      type_validation === "number" &&
      !Number.isInteger(parseInt(e.target.value))
    ) {
      toggleValidationError("This field requires exclusively numeric inputs");
    } else {
      toggleValidationError(false);
      clickHandler({ newAnswer: e.target.value, header, question });
    }
  };

  // let alreadyAnswered = answers[currentHeader][question]
  //   ? answers[currentHeader][question]
  //   : null;

  // let currentAnswer =

  return (
    <div className="overallQuestion">
      <ErrorMsg
        missingInput={missingInput}
        header={header}
        question={question}
      />
      <Input
        error={validationError}
        helperText={validationError}
        required={mandatory}
        default={answers[header][question]}
        multiline={true}
        label={question}
        onChange={updateText}
        type={type_validation}
        style={{
          backgroundColor: "white",
          padding: 0,
          width: "90%",
          borderRadius: 10,
          margin: 10,
        }}
      />{" "}
    </div>
  );
};

const CalendarCompBase = (props) => {
  // console.log("calendar comp", props);

  let { question, header, missingInput, answers, mandatory, clickHandler } =
    props;

  //add all mandatory questions to component automatically
  if (!answers[header][question] && mandatory) {
    clickHandler({ newAnswer: [], header, question });
  }

  return (
    <div className="overallQuestion">
      <div className="QuestionTitle">{question}</div>
      <ErrorMsg
        missingInput={missingInput}
        header={header}
        question={question}
      />
      <div className="datetimefield">
        <DateAndTimePickers {...props} />{" "}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: 30,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

const UncontrolledDateAndTimePickerBase = (props) => {
  let { header, question, clickHandler } = props;

  let [isError, toggleError] = useState(null);

  const classes = useStyles();

  return (
    <div className="overallQuestion">
      <div className="QuestionTitle">{question}</div>
      <form className={classes.container} noValidate>
        <TextField
          error={isError}
          helperText={isError ? isError : null}
          id={"date"}
          label={props.header}
          type={"date"}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            // console.log("what is it", e.target.value, header, question);
            toggleError(null);
            clickHandler({ newAnswer: e.target.value, header, question });
          }}
        />
      </form>
    </div>
  );
};

const DateAndTimePickers = (props) => {
  let { header, question, clickHandler } = props;
  // console.log("datetime", props);
  let [isError, toggleError] = useState(null);

  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        error={isError}
        helperText={isError ? isError : "Note: Date cannot be past today"}
        id={"date"}
        label={props.header}
        type={"date"}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          // console.log("changed!", e.target.value);
          if (new Date(e.target.value) >= new Date()) {
            toggleError("Date Error: Must set a date lesser than today");
          } else {
            // console.log("what is it", e.target.value, header, question);
            toggleError(null);
            clickHandler({ newAnswer: e.target.value, header, question });
          }
        }}
      />
    </form>
  );
};

const RescheduleLockedMsg = () => {
  return (
    <div>
      <p style={{ padding: "20px", color: "red" }}>
        Visit Date has been locked by the Pharmacy Department. Please call the
        pharmacy if you must reschedule.{" "}
      </p>
      <p style={{ padding: "20px", color: "red" }}>
        Note: You will not be able to complete this questionaire unless you
        revert your answer to 'NO'.
      </p>
    </div>
  );
};

const RescheduleBase = (props) => {
  // console.log(props);
  let {
    currentReport,
    clickHandler,
    question,
    header,
    answers,
    mandatory,
    missingInput,
  } = props;
  let [viewForm, toggleViewForm] = useState(true);
  let modState = currentReport;
  let [RescheduledVisitDate, modifyRescheduledVisitDate] = useState(null);

  const filler = (data) => {
    // console.log("fill funct", data);
  };

  //add all mandatory questions to component automatically
  if (!answers[header][question] && mandatory) {
    clickHandler({ newAnswer: [], header, question });
  }

  useEffect(() => {}, [viewForm, toggleViewForm]);

  const toggleForm = () => {
    toggleViewForm(!viewForm);
  };

  const SaveResultsToPCF = (val) => {
    clickHandler({ newAnswer: val.updatedDate, header, question });
  };

  //in the event that visit date is locked prevent user from changing date
  if (currentReport.isRescheduleBlocked) {
    return <RescheduleLockedMsg />;
  } else if (viewForm) {
    return (
      <>
        <ErrorMsg
          missingInput={missingInput}
          header={header}
          question={question}
        />
        <ModifyScheduleForm
          {...props}
          modState={modState}
          toggleMod={filler}
          toggleViewState={toggleForm}
          PCF={true}
          dateToParent={modifyRescheduledVisitDate}
          SaveResultsToPCF={SaveResultsToPCF}
        />
      </>
    );
  } else {
    return (
      <>
        <div>Vist Now Scheduled for: {RescheduledVisitDate}</div>
        <Button label="Reschedule" func={toggleForm} />
      </>
    );
  }
};

const InsuranceRemovalBase = (props) => {
  // console.log(props);
  let {
    question,
    miscInfo,
    answers,
    clickHandler,

    header,
    missingInput,
  } = props;
  let { policies } = miscInfo;

  //populate with policies if not already loaded
  if (
    !answers[header]["Please select all insurance policies which are inactive:"]
  ) {
    answers[header][
      "Please select all insurance policies which are inactive:"
    ] = policies;
  }

  if (policies.length > 0) {
    return (
      <div className="policyRemovalParent">
        <div className="policyRemovalTitle"> {question}</div>
        <ErrorMsg
          missingInput={missingInput}
          header={header}
          question={question}
        />
        <div>
          {policies.map((policy, idx) => {
            return (
              <IndividualPolicyInfo
                keyIDX={idx}
                key={idx}
                policies={policies}
                {...policy}
                clickHandler={clickHandler}
                header={header}
                question={question}
                answers={answers}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    // console.log("up dated?!?!?!?!?", props)
    // clickHandler({ newAnswer: ["test"], header, question });
    return (
      <div>
        <div className="policyRemovalTitle"> {question}</div>
        <div className="policyRemovalParent">
          Patient Has No Active Insurance Policies on File
        </div>
      </div>
    );
  }
};

const IndividualPolicyInfo = (props) => {
  // console.log(props);
  let {
    clickHandler,
    GROUP_NUM,
    INSCPHONE,
    INSURANCE,
    POLICY,
    START,
    header,
    question,
    answers,

    keyIDX,
  } = props;

  // let [clickStatus, toggleClickStatus] = useState(false);

  let sectionAnswers = answers[header][question];

  let clickStatus = sectionAnswers[keyIDX];

  const inscPolicyHandleClick = () => {
    // console.log("insurance handler clicked!!", props);

    //changes policy status if clicked on.....
    if (clickStatus.remove) {
      clickStatus.remove = false;
    } else {
      clickStatus.remove = true;
    }

    clickHandler({
      newAnswer: sectionAnswers,
      header,
      question,
    });
  };

  return (
    <div
      onClick={inscPolicyHandleClick}
      className={
        !clickStatus.remove
          ? "insuranceInfoParentUnclicked"
          : "insuranceInfoParentClicked"
      }
    >
      <div className="subcontainer">
        <div className="inscheader">Insurance: </div>
        <div className="inscvalue">{INSURANCE}</div>
      </div>
      <div className="subcontainer">
        {" "}
        <div className="inscheader">Policy Number:</div>
        <div className="inscvalue">{POLICY}</div>
      </div>
      <div className="subcontainer">
        {" "}
        <div className="inscheader">Group Number:</div>{" "}
        <div className="inscvalue">{GROUP_NUM}</div>
      </div>
      <div className="subcontainer">
        {" "}
        <div className="inscheader">Insurance Phone Number:</div>
        <div className="inscvalue">{INSCPHONE}</div>
      </div>
      <div className="subcontainer">
        {" "}
        <div className="inscheader">Policy Start:</div>
        <div className="inscvalue">{START ? START.split("T")[0] : "N/A"}</div>
      </div>
      {clickStatus.remove ? (
        <div className="insuranceInfoParentClicked">
          Policy To Be Removed From Records{" "}
        </div>
      ) : null}
    </div>
  );
};

const ReadOnly = (props) => {
  return (
    <div>
      If this is the case, user will not be required to submit the rest of the
      questionaire
    </div>
  );
};

const MultipleChoices = FormWizardConsumerHOC(MultipleChoicesBase);
const YesNo = FormWizardConsumerHOC(YesNoBase);
const TextInput = FormWizardConsumerHOC(TextInputBase);
const CalendarComp = FormWizardConsumerHOC(CalendarCompBase);
const InsuranceRemoval = FormWizardConsumerHOC(InsuranceRemovalBase);
const UncontrolledDateAndTimePicker = FormWizardConsumerHOC(
  UncontrolledDateAndTimePickerBase
);

//wrapped in two consumer HOCs
const Reschedule = ConsumerHOC(FormWizardConsumerHOC(RescheduleBase));
export {
  MultipleChoices,
  YesNo,
  TextInput,
  CalendarComp,
  Reschedule,
  InsuranceRemoval,
  ReadOnly,
  UncontrolledDateAndTimePicker,
};
