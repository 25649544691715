import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import ReportsFunctions from "./viewReportsFlexTableFuncs";
import ConfirmationsFunctions from "./visitConfirmationsFlexTableFuncs";
import ProgressFunctions from "./progressNotesFlexTableFuncs";
import NurseTotalReports from "./totalReportsApprovedFlexTableFunc";
import SearchOptions from "./SearchOptions";
import mileageReports from "./MileageReportsFlexTableFunc";
import ConsumerHOC from "../context/ConsumerHOC";
import NursingScheduleConfig from "./Schedule/ScheduleTableConfig";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let toReturn = stabilizedThis.map((el) => el[0]);

  return toReturn;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            //padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Typography className={classes.title} variant="h6" id="tableTitle">
      {title}
    </Typography>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "98%",
    margin: 10,
    padding: 10,

    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {
  let { type } = props;
  // console.log("what is props here", props);

  let setupFuncs;
  if (type === "VisitReminders") {
    //console.log("type is", type);
    setupFuncs = ConfirmationsFunctions;
  } else if (type === "ProgressNotes") {
    setupFuncs = ProgressFunctions;
  } else if (type === "Total Nurse Reports Approved") {
    setupFuncs = NurseTotalReports;
  } else if (type === "mileage") {
    //console.log(type);
    setupFuncs = mileageReports;
  } else if (type === "NursingSchedule") {
    setupFuncs = NursingScheduleConfig;
  } else {
    setupFuncs = ReportsFunctions;
  }

  let { rowMapper, headCells, colorRow } = setupFuncs;
  let tableTitle;
  if (props.location && props.location.state && props.location.state.type) {
    //search table
    tableTitle = props.location.state.type;
  } else {
    //reports table
    tableTitle = props.location.state.btn.title;
  }
  let [raw, updateRaw] = useState(props.workingCopies);
  let [rows, updateRows] = useState(rowMapper(raw));

  useEffect(() => {
    if (
      type === "Total Nurse Reports Approved" &&
      props.rows.length !== props.workingCopies.length
    ) {
      props.updateWorkingCopiesFromServer(props.rows);
    } else if (raw !== props.workingCopies) {
      updateRaw(props.workingCopies);
      updateRows(rowMapper(props.workingCopies));
    }
  }, [raw, props.workingCopies, rowMapper, type, props.rows, props]);

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("NurseName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(
    type === "Total Nurse Reports Approved" ? true : false
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (row) => {
    let reportType;
    //console.log("this is row clicked", row);
    rows.forEach((i, id) => {
      if (
        (i["VisitID"] && i["VisitID"] === row["VisitID"]) ||
        (i["ID"] && i["ID"] === row["ID"])
      ) {
        //console.log("what is....", props.workingCopies[id]);
        if (type === "ProgressNotes") {
          //console.log("row?", props.workingCopies[id]);
          reportType = {
            pathname: "/ViewProgressNote",
            state: {
              data: props.workingCopies[id],
              type: "Progress Note",
              title: `Progress Note: ${row["PatientName"]} - ${
                row["UploadDate"] ? row["UploadDate"].split("T")[0] : null
              }`,
            },
          };
          props.history.push(reportType);
        } else if (type === "VisitReminders") {
          //console.log("type is", type);
          toggleVisitConfirmationModal();
          passDataToModal(row);
        } else if (type === "mileage") {
          props.changeCurrentReport(cloneDeep(props.workingCopies[id]));
          reportType = {
            pathname: "/mileageReport",
            state: { data: props.workingCopies[id], type: "mileage" },
          };
          props.history.push(reportType);
        } else {
          reportType = {
            pathname: "/ViewReport",
            state: {
              data: cloneDeep(row),
              type: "Visit Report",
              workingCopiesIndex: id,
            },
          };
          props.history.push(reportType);
        }
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rows && rows.length
      ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
      : 0;

  let [VisitConfirmationModalState, updateVisitConfirmModalState] =
    useState(false);
  const toggleVisitConfirmationModal = () => {
    updateVisitConfirmModalState(!VisitConfirmationModalState);
  };
  let [modalData, passDataToModal] = useState({});

  if (!Array.isArray(rows) || !rows || rows.length < 1) {
    return (
      <div>
        {type !== "Total Nurse Reports Approved" &&
        type !== "VisitReminders" ? (
          <SearchOptions
            updateRows={updateRows}
            rows={props.rows}
            mapper={rowMapper}
            type={type}
          />
        ) : null}
        <div>No Data to Report</div>
      </div>
    );
  } else {
    return (
      <div
        className={classes.root}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          padding: 5,
        }}
      >
        <div style={{ flex: 1 }}>
          {type !== "Total Nurse Reports Approved" &&
          type !== "VisitReminders" ? (
            <SearchOptions
              noDisplayIf={
                type === "Total Nurse Reports Approved" ? true : false
              }
              updateRows={updateRows}
              rows={props.rows}
              mapper={rowMapper}
              type={type}
            />
          ) : null}

          <Paper className={classes.paper}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              title={tableTitle}
            />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  headCells={headCells}
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows && rows.length ? rows.length : 0}
                />
                <TableBody>
                  {stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let color = null;
                      if (colorRow) {
                        color = colorRow(type, row);
                      }

                      return (
                        <TableRow
                          hover
                          row={row}
                          onClick={() => {
                            handleClick(row);
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name || index}
                          selected={isItemSelected}
                          style={{
                            backgroundColor: color,
                          }}
                        >
                          {headCells.map((header, id) => {
                            return (
                              <TableCell
                                key={id + header.id}
                                component="th"
                                // /id={labelId}
                                scope="row"
                                //padding="none"
                              >
                                {header.date
                                  ? moment(row[header.id]).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )
                                  : row[header.id]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ConsumerHOC(EnhancedTable));
