import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 350,
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
}));

function Row(props) {
  //const { index, style } = props.patients;
  const style = useStyles();
  const patient = props.patient;

  return (
    <div
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        padding: "15px",
        borderRadius: "4px",
        backgroundColor: "rgb(226 226 226)",
        cursor: "pointer",
      }}
      onClick={() => {
        props.choose(patient);
      }}
    >
      <h3 style={{ margin: "0", color: "#333", fontSize: "18px" }}>
        {patient.FIRST_NAME} {patient.LAST_NAME}
      </h3>
      <p style={{ margin: "5px 0", fontSize: "14px" }}>
        <b style={{ fontSize: "14px" }}>DOB:</b> {patient.DOB}
      </p>
      <p style={{ margin: "5px 0", fontSize: "14px" }}>
        <b style={{ fontSize: "14px" }}>Phone:</b> {patient.PHONE}
      </p>
    </div>
    // <ListItem
    //   button
    //   onClick={() => {
    //     props.choose(patient);
    //   }}
    // >
    //   <ListItemText
    //     index={patient.DOB + patient.LAST_NAME}
    //     primary={`${patient.LAST_NAME}, ${patient.FIRST_NAME}  - ${
    //       patient.DOB ? patient.DOB.split("T")[0] : "No DOB listed"
    //     }`}
    //   />
    // </ListItem>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default function VirtualizedList(props) {
  const classes = useStyles();

  if (Array.isArray(props.patients) && props.patients.length > 0) {
    return (
      // <div className={classes.root}>
      <div
        className={{
          display: "flex",
          width: "100%",
        }}
      >
        {/* <List height={100} width={360} itemSize={46} itemCount={200}> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            margin: "0 auto",
          }}
        >
          {props.patients.map((patient, id) => {
            return (
              <Row
                patient={patient}
                key={patient.DOB}
                choose={props.choose}
                index={id}
              />
            );
          })}
        </div>
        {/* </List> */}
      </div>
    );
  } else {
    return <div>No Patients Available</div>;
  }
}
