import React, { useState } from "react";
import MileageReportTable from "./mileageReportTable";
import Button from "./Button";
import MileageMonthlyWeeklyView from "./MileageMonthlyWeeklyView";
import ConsumerHOC from "../context/ConsumerHOC";
import CreateNewMileageReport from "./CreateNewMileageReport";

const MileageReportsParent = (props) => {
  // console.log("mileage report parent", props);
  let { userInfo } = props;

  let [createNewReport, updateCreateNewReport] = useState(false);
  let [showDailyWeeklyMonthly, updateShowDailyWeeklyMonthly] =
    useState("Daily");
  let [canEdit, toggleCanEdit] = useState(
    userInfo.permissions === "admin" || userInfo.permissions === "mc"
      ? true
      : false
  );

  let buttonStyle = { width: "20%", margin: 20 };

  //view for nurses and others who cannot edit mileage report
  if (!canEdit) {
    return <MileageReportTable {...props} />;
  }

  //primary view for those who may edit mileage
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", margin: 5 }}>
        <Button
          disabled={showDailyWeeklyMonthly === "Monthly"}
          label="Monthly Mileage Summary"
          func={() => updateShowDailyWeeklyMonthly("Monthly")}
          fullWidth={false}
          style={buttonStyle}
        />
        <Button
          disabled={showDailyWeeklyMonthly === "Weekly"}
          label="Weekly Mileage Summary"
          func={() => updateShowDailyWeeklyMonthly("Weekly")}
          fullWidth={false}
          style={buttonStyle}
        />
        <Button
          label="Daily Mileage Reports"
          func={() => updateShowDailyWeeklyMonthly("Daily")}
          disabled={showDailyWeeklyMonthly === "Daily"}
          fullWidth={false}
          style={buttonStyle}
        />
        <Button
          label="Create Daily Mileage Report +"
          func={() => {
            updateCreateNewReport(!createNewReport);
          }}
          //func={() => updateShowDailyWeeklyMonthly("Daily")}
          //disabled={showDailyWeeklyMonthly === "Daily"}
          fullWidth={false}
          style={buttonStyle}
        />
        {createNewReport ? (
          <CreateNewMileageReport
            modState={createNewReport}
            modalData={{}}
            userInfo={userInfo}
            OpenCloseFunc={updateCreateNewReport}
            toggleModState={() => {
              updateCreateNewReport(!createNewReport);
            }}
          />
        ) : null}
      </div>
      <WhichView {...props} showDailyWeeklyMonthly={showDailyWeeklyMonthly} />
    </>
  );
};

const WhichView = (props) => {
  let { showDailyWeeklyMonthly, token } = props;
  if (showDailyWeeklyMonthly === "Daily") {
    return <MileageReportTable {...props} />;
  } else if (showDailyWeeklyMonthly === "Weekly") {
    return (
      <MileageMonthlyWeeklyView
        token={token}
        showDailyWeeklyMonthly={showDailyWeeklyMonthly}
      />
    );
  } else if (showDailyWeeklyMonthly === "Monthly") {
    return (
      <MileageMonthlyWeeklyView
        token={token}
        showDailyWeeklyMonthly={showDailyWeeklyMonthly}
      />
    );
  }
};

export default ConsumerHOC(MileageReportsParent);
