import React, { useEffect, useState } from "react";
import Button from "./Button";
import ReportConfig from "../ReportConfig.js";
import { Info } from "../config.js";
import { functionVar } from "./SubmitReportBtns.js";

//function checks all data to ensure that the boxes have been checked to confirm that section has been reviewed
const ConfirmBoxesChecked = (data) => {
  let empty = [];
  let sectionHeaders = Object.keys(data);
  for (let header of sectionHeaders) {
    if (
      header === "updaterInfo" ||
      header === "next" ||
      header === "Final Submission"
    ) {
      continue;
    } else if (data[header] && !data[header]["confirmed"]) {
      empty.push(header);
    }
  }
  if (empty.length > 0) {
    return empty;
  }
  return true;
};

const EnhancedButton = (props) => {
  let btn = props.btn;
  let disable;
  let [reviewStatus, toggleReviewStatus] = useState(false);

  useEffect(() => {
    if (
      (btn.title.includes("Submit To Weekly's") ||
        btn.title.includes("Approve and File PDF")) &&
      Object.keys(props.currentReport).length > 0
    ) {
      if (!props.currentReport.visitData) {
        toggleReviewStatus(false);
      } else {
        let isReady = ConfirmBoxesChecked(props.currentReport.visitData);

        if (Array.isArray(isReady) && isReady.length > 0) {
          toggleReviewStatus(true);
        } else {
          toggleReviewStatus(false);
        }
      }
    }
  }, [btn, props, props.currentReport.visitData]);

  {
    ReportConfig[props.userInfo.permissions].moveOrEditIf.includes(
      props.currentReport.status
    )
      ? (disable = false)
      : (disable = true);
  }

  if (
    (btn.title.includes("Submit To Weekly's") ||
      btn.title.includes("Approve and File PDF")) &&
    !props.telemAttached &&
    (!props.currentReport.TelemExplained ||
      props.currentReport.TelemExplained === null ||
      props.currentReport.TelemExplained === "")
  ) {
    disable = true;
  }

  return (
    <Button
      style={props.style}
      disabled={reviewStatus || disable}
      //regular "Are you sure you wish to do X" will not show if returning to nurse.
      modal={props.btn.title === "Return To Nurse" ? false : true}
      key={btn.title + Math.random()}
      label={btn.title}
      func={functionVar[btn.fn]}
      data={props}
      status={btn.status}
    />
  );
};

export default EnhancedButton;
