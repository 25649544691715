import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spin = (props) => {
  let { style } = props;
  return (
    <div style={{ textAlign: "center", ...style }}>
      <CircularProgress color="primary" size={70} />
      <div>Thank you for your patience</div>
    </div>
  );
};

export default Spin;
