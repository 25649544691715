import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";

// const useStyles = makeStyles((theme) => ({
//   // root: {
//   //   display: "flex",
//   //   flexWrap: "wrap"
//   // },
//   // formControl: {
//   //   margin: theme.spacing(1),
//   //   minWidth: 120
//   // },
//   // selectEmpty: {
//   //   marginTop: theme.spacing(2)
//   // }
// }));

export default function SimpleSelect(props) {
  //console.log("what is props", props);
  let { value, disable, onChange, list } = props;

  let revisedlist = [];

  if (!list) {
    return <div>No Data List To Display for Dropdown</div>;
  }

  list.forEach((val) => {
    if (val.status) {
      revisedlist.push(val);
    } else if (val.email) {
      revisedlist.push({
        label: `${val.firstName} ${val.lastName}`,
        value: val.email,
        status: true,
      });
    }
  });

  return (
    <div style={props.style ? props.style : { marginBottom: 10 }}>
      <Select
        menuPortalTarget={document.body}
        isDisabled={disable ? true : false}
        value={value}
        options={revisedlist}
        onChange={(x) => {
          //console.log(x);
          onChange(x);
        }}
        width="50%"
      />
    </div>
  );
}
