import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import {TextField, Box } from "@mui/material";
import { FetchPatientByName } from "../../network/Patients_api.js";
import moment from "moment";
import Spin from "../Spin";
import "./VO.css";

const SearchPatient = (props) => {
  let { token, choosePatient } = props;
  let [patientList, updatePatientList] = useState([]);
  let [spin, togglespin] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");


  const searchDB = async () => {
    togglespin(true);
    try {
      const results = await FetchPatientByName({token: props.token, data: { firstname, lastname }})
      console.log(results);
      updatePatientList(results);
    } catch (error) {
      console.log(error);
    }
    togglespin(false);
  };

  if (spin) {
    return <Spin />;
  }

  return (
    <div className="searchPatient">
      <div className="VOTitle">Verbal Order: Choose a patient</div>
        <section
          style={{
            display: "flex",
            
            justifyContent: "flex-end",
          }}
        >
          <form onSubmit={searchDB}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="outlined-basic" size="small" label="First Name" variant="outlined" onChange={(e) => {
                    const value = e.target.value;
                    setFirstname(value.charAt(0).toUpperCase() + value.slice(1));
                  }}/>
              <TextField id="outlined-basic" size="small" label="Last Name" variant="outlined" onChange={(e) => {
                  const value = e.target.value;
                  setLastname(value.charAt(0).toUpperCase() + value.slice(1));
                }}/>
              <button type="submit" style={styles.searchButton}>
                Search
              </button>
            </Box>
          </form>
        </section>
      <div>
        {patientList.length
          ? patientList.map((patient) => {
              return (
                <div
                  key={patient.LAST_NAME + patient.FIRST_NAME + patient.DOB}
                  className="patientMapper"
                  onClick={() => {
                    choosePatient(patient);
                  }}
                >{`${patient.LAST_NAME}, ${patient.FIRST_NAME} - ${moment(
                  patient.DOB
                ).format("MMMM DD, YYYY")}`}</div>
              );
            })
          : "No Patients to display"}
      </div>
    </div>
  );
};

const styles = {
  searchButton: {
    padding: "10px 15px",
    backgroundColor: "rgb(67, 147, 114",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  section: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

export default ConsumerHOC(SearchPatient);
