//NOTES:
//  mandatory = true => triggers 'required' in red and will prevent user from moving forward
// Notify = [email] => If user answered question then email will be sent to that party
//Highlight = true => If user answered question then section name will be highlighted in admin view

const PCFQ = {
  "Date of Patient Contact": {
    type: "CalendarComp",
    question: "When did you reach the patient?",
  },
  "Reschedule Request": {
    type: "YesNo",
    mandatory: true,
    question: "Would you like to reschedule the visit?",
    highlight: true,
    choices: [
      {
        choice: "Yes",
        childQ: [
          {
            type: "Reschedule",
            question: "Rescheduled Visit Date",
            mandatory: true,
          },
        ],
      },
      { choice: "No" },
    ],
  },
  // "Insurance Updates": {
  //   type: "MultipleChoices",
  //   question: "Have you had any changes to your health insurance?",
  //   Notify: ["intake@basichi.com", "RN"],
  //   choices: [
  //     {
  //       choice: "Added Policy",
  //       childQ: [
  //         { type: "text", question: "Name of insurance company" },
  //         { type: "text", question: "Phone number for providers" },
  //         { type: "text", question: "ID#" },
  //         { type: "text", question: "Group#" },
  //         { type: "text", question: "Effective date" },
  //         { type: "text", question: "RxBin#" },
  //         { type: "text", question: "Rx PCN#" },
  //         { type: "text", question: "Rx Group#" },
  //       ],
  //     },
  //     {
  //       choice: "Removed Policy",
  //       childQ: [
  //         {
  //           type: "InsuranceRemoval",
  //           question: "Please select all policies which are inactive:",
  //         },
  //       ],
  //     },
  //     { choice: "No Changes" },
  //   ],
  // },

  "Insurance Policy": {
    type: "YesNo",
    question: "Are current health insurance policy(s) on file still valid?",
    highlight: "No",
    mandatory: true,
    choices: [
      {
        choice: "Yes",
      },
      {
        choice: "No",
        childQ: [
          {
            type: "InsuranceRemoval",
            question:
              "Please select all insurance policies which are inactive:",
          },
        ],
      },
    ],
  },

  "Added Insurance Policy": {
    type: "YesNo",
    question: "Have you added a new health insurance policy?",
    mandatory: true,
    highlight: "Yes",

    choices: [
      {
        choice: "Yes",
        childQ: [
          {
            type: "text",
            question: "Name of insurance company",
            mandatory: true,
          },
          {
            type: "text",
            question: "Phone number for providers",
            mandatory: true,
          },
          { type: "text", question: "ID#", mandatory: true },
          { type: "text", question: "Group#", mandatory: true },
          { type: "text", question: "Effective date", mandatory: true },
          { type: "text", question: "RxBin#", mandatory: true },
          { type: "text", question: "Rx PCN#", mandatory: true },
          { type: "text", question: "Rx Group#", mandatory: true },
        ],
      },

      { choice: "No" },
    ],
  },
  // "Hospice Plans": {
  //   type: "YesNo",
  //   question: "Are you on hospice/any plans to be on hospice?",
  //   highlight: true,
  //   mandatory: true,
  //   choices: [
  //     {
  //       choice: "Yes",

  //       childQ: [
  //         {
  //           type: "text",
  //           question: "What is your hospice status/plans?",
  //           mandatory: true,
  //         },
  //         { type: "text", question: "Name of hospice company" },
  //         { type: "text", question: "Phone number" },
  //         { type: "text", question: "Case manager" },
  //         { type: "text", question: "Admission date" },
  //         { type: "text", question: "Admitting diagnosis" },
  //       ],
  //     },
  //     { choice: "No" },
  //   ],
  // },

  "Hospice Plans": {
    type: "YesNo",
    question: "Are you on hospice/any plans to be on hospice?",
    highlight: "Yes",
    mandatory: true,
    choices: [
      {
        choice: "Yes, new hospice patient",
        highlight: true,
        childQ: [
          {
            type: "text",
            question: "What is your hospice status/plans?",
            mandatory: true,
          },
          { type: "text", question: "Name of hospice company" },
          { type: "text", question: "Phone number" },
          { type: "text", question: "Case manager" },
          { type: "text", question: "Admission date" },
          { type: "text", question: "Admitting diagnosis" },
        ],
      },
      {
        choice: "Yes, hospice followup (for ongoing hospice patient)",
        highlight: true,
        childQ: [
          {
            type: "text",
            question: "What is your hospice status/plans?",
            mandatory: true,
          },
          {
            type: "text",
            question: "What is the patient's current prognosis?",
          },
          {
            type: "text",
            question: "Who did you speak with to confirm the patient's status?",
          },
          {
            type: "text",
            question: "Any clinical changes since the last visit/contact?",
          },
          {
            type: "text",
            question:
              "Did you confirm the next visit date/refill, and if so when?",
          },
        ],
      },
      { choice: "No" },
    ],
  },

  "Last MD Visit": {
    type: "YesNo",
    question:
      "Have you seen your pump management doctor since your LAST refill with BHI?",
    mandatory: true,
    Notify: ["callcenter@basichi.com"],
    choices: [
      {
        choice: "Yes",

        childQ: [
          {
            type: "YesNo",
            mandatory: true,
            question: "Did your doctor refill your pump?",
            choices: [
              {
                choice: "Yes",
              },
              { choice: "No" },
            ],
          },
          {
            type: "YesNo",
            mandatory: true,
            question: "Did your doctor make any dose changes?",
            choices: [{ choice: "Yes" }, { choice: "No" }],
          },
        ],
      },
      { choice: "No" },
    ],
  },
  "Next MD Visit": {
    type: "YesNo",
    question:
      "Will you see your pump management doctor BEFORE your scheduled refill with BHI?",
    mandatory: true,
    Notify: ["callcenter@basichi.com"],
    choices: [
      {
        choice: "Yes",

        childQ: [
          {
            type: "YesNo",
            mandatory: true,
            question:
              "Is your doctor planning to refill your pump at this appointment?",
            choices: [{ choice: "Yes" }, { choice: "No" }],
          },
          {
            type: "YesNo",
            mandatory: true,
            question:
              "Is your doctor planning to make any dose changes at this appointment?",
            choices: [{ choice: "Yes" }, { choice: "No" }],
          },
        ],
      },
      { choice: "No" },
    ],
  },
  "Recent Hospitalizations": {
    type: "YesNo",
    question:
      "Have you had any ER visits or hospital admissions since your last appointment? ",
    mandatory: true,
    highlight: "Yes",
    choices: [
      {
        choice: "Yes",

        childQ: [
          { type: "text", question: "Name of Facility", mandatory: true },
          { type: "text", question: "Admission Date", mandatory: true },
          { type: "text", question: "Reason for Admission", mandatory: true },
          { type: "text", question: "Discharge Date", mandatory: true },
        ],
      },
      { choice: "No" },
    ],
  },

  "Covid-19 Status": {
    type: "YesNo",
    mandatory: true,
    Notify: ["callcenter@basichi.com"],
    question:
      "Do you currently have any flu-like symptoms including but not limited to: fever, shortness of breath or coughing?",
    choices: [
      {
        choice: "Yes",

        childQ: [
          {
            type: "YesNo",
            mandatory: true,
            question: "Have you been tested for COVID-19?",
            choices: [{ choice: "Yes" }, { choice: "No" }],
          },
          {
            type: "YesNo",
            mandatory: true,
            question: "If yes: were your results positive",
            choices: [{ choice: "Yes" }, { choice: "No" }],
          },
        ],
      },
      { choice: "No" },
    ],
  },

  "Additional Information": {
    type: "YesNo",
    mandatory: true,
    question: "Would you like to share additional information with your nurse?",
    Notify: ["RN"],
    choices: [
      {
        choice: "Yes",

        childQ: [{ type: "text", question: "Additional Information" }],
      },
      { choice: "No" },
    ],
  },
  // SubmitForm: {
  //   type: "SubmitForm",
  //   choices: [{ choice: "Submit Form" }],
  // },
};

module.exports = PCFQ;

// Are you on hospice/any plans to be on hospice? Yes or no
// If yes: (make optional, not mandatory)
//     1. name of hospice company
//     2. Phone number
//     3. Case manager
//     4. Admission date
//     5. Admitting diagnosis
// **Email with this information goes to intake@basichi.com

// Have you had any changes to your health insurance? Yes or no
// If yes:
//     1. Name of insurance
//     2. Phone number of providers
//     3. ID#
//     4. Group #
//     5. Effective Date
//     6. RxBin#
//     7. RX PCN#
//     8. RXGroup#
// ***Email with this information goes to intake@ basichi.com

// Have you seen your doctor since your LAST refill with BHI: yes or no
// If yes:
//     1. Did your doctor refill your pump: yes or no
//     2. Did your doctor make any dose changes: yes or no
// ****Email with this information goes to the RN

// Will you see your doctor BEFORE your scheduled refill with BHI? Yes or no
// If yes:
//     1. Is your doctor planning to refill your pump at this appointment? Yes or no
//     2. Is your doctor planning to make any dose changes at this appointment? Yes or no
// ****Email with this information goes to the RN

// Have you had any ER visits or hospital admissions since your last appointment? Yes or no
// If yes:
//     1. Name of Facility
//     2. Admission Date
//     3. Reason for Admission
//     4. Discharge Date
// ****Email with this information goes to the RN

// Do you currently have any flu-like symptoms including but not limited to: fever, shortness of breath or coughing? Yes or no
// If yes:
//     1. Have you been tested for COVID-19? Yes or no
//     2. If yes: were your results positive? Yes or no
// ****Email with this information goes to the RN  mamari@basichi.com and the RN

// Additional information you would like to provide to your RN: (open text)
// ****Email with this information goes to the RN
