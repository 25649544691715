import React from "react";
import "./DemographicMap.css";
import MapControlColumn from "./MapControlColumn";
import MapDataDisplay from "./MapDataDisplay";
import { FaMapMarkedAlt } from "react-icons/fa";
import { BsGraphUp } from "react-icons/bs";

const SideBar = (props) => {
  let {
    toggleViewNurses,
    toggleViewPatients,
    viewNurses,
    viewPatients,
    sideBarView,
    updateSideBarView,
    inputData,
    updateMainComponent,
  } = props;

  // console.log(props);
  return (
    <div>
      <div className="NavIcons">
        <span>
          <FaMapMarkedAlt onClick={() => updateMainComponent("map")} />
        </span>
        <span>
          <BsGraphUp onClick={() => updateMainComponent("graph")} />
        </span>
      </div>
      <div className="projectNote">
        Note: All distances are calculated as straight lines and do not reflect
        driving distance.
      </div>
      <MapControlColumn
        toggleViewNurses={toggleViewNurses}
        toggleViewPatients={toggleViewPatients}
        viewNurses={viewNurses}
        viewPatients={viewPatients}
        inputData={inputData}
      />

      <MapDataDisplay
        sideBarView={sideBarView}
        updateSideBarView={updateSideBarView}
      />
    </div>
  );
};

export default SideBar;
