import React, { useState, useEffect } from "react";

import axios from "axios";
import ConsumerHOC from "../../context/ConsumerHOC";
//import domain from "../../config.js";
import { Info } from "../../config";

//const Info = domain.Info;
const domain = Info.domain;

const CarePlan = (props) => {
  let { token } = props;
  //let [patientList, updatePatientList] = useState({});
  let firstName = "Heather"; //This needs to be changed to be whatever first name and last name of the currently logged in nurse.
  let lastName = "Williams";

  // useEffect(() => {
  //   const getPatients = async (domain) => {
  //     try {
  //       let results = await axios({
  //         method: "post",
  //         url: `https://${domain}/carePlan/getPatients`,
  //         //data: { firstName, lastName },
  //         headers: {
  //           Authorization: `bearer ${token}`,
  //         },
  //       });
  //       console.log(results);
  //       updatePatientList(results);
  //     } catch (err) {
  //       console.log(err);
  //       return err;
  //     }
  //   };
  //   getPatients(domain);
  // }, [token, updatePatientList]);

  let [openDialog, toggleDialog] = useState(false);

  return (
    <div>
      {/* {Object.keys(patientList).map((result, idx) => {
        return (
          <React.Fragment key={idx}>
            <div>{result.LAST_NAME}</div>
            <div>{result.FIRST_NAME}</div>
            <div></div>
          </React.Fragment>
        );
      })} */}
      <div>This page is under construction</div>

      {/* Pull this one from CPR?*/}
      <br/>

      <label>Patient first name: </label>
      <input type="text" id="firstname" name="firstname"/>
      <label> Patient last name: </label>
      <input type="text" id="lastname" name="lastname"/>

      <br/><br/>
      <label>Refill Interval: (Enter numeric number) </label>
      <input type="text" id="refillinterval" name="refillinterval"/>
      
      <br/><br/>
      <label>Functional Limitation: (Check all that apply)</label><br/>
      <input type="checkbox" id="amputation" name="amputation" value="Amputation"/>
      <label for="amputation"> Amputation</label><br/>
      <input type="checkbox" id="bowelbladderincontinence" name="bowelbladderincontinence" value="Bowel/Bladder (Incontinence)"/>
      <label for="bowelbladderincontinence"> Bowel/Bladder (Incontinence)</label><br/>
      <input type="checkbox" id="contracture" name="contracture" value="Contracture"/>
      <label for="contracture"> Contracture</label><br></br>
      <input type="checkbox" id="hearing" name="hearing" value="Hearing"/>
      <label for="hearing"> Hearing</label><br></br>
      <input type="checkbox" id="paralysis" name="paralysis" value="Paralysis"/>
      <label for="paralysis"> Paralysis</label><br></br>
      <input type="checkbox" id="endurance" name="endurance" value="Endurance"/>
      <label for="endurance"> Endurance</label><br></br>
      <input type="checkbox" id="ambulation" name="ambulation" value="Ambulation"/>
      <label for="ambulation"> Ambulation</label><br></br>
      <input type="checkbox" id="speech" name="speech" value="Speech"/>
      <label for="speech"> Speech</label><br></br>
      <input type="checkbox" id="legallyblind" name="legallyblind" value="Legally Blind"/>
      <label for="legallyblind"> Legally Blind</label><br></br>
      <input type="checkbox" id="dyspnea" name="dyspnea" value="Dyspnea With Minimal Exertion"/>
      <label for="dyspnea"> Dyspnea With Minimal Exertion</label><br></br>
      <label for="otherlimitation"> Other: </label>
      <input type="text" id="otherlimitation" name="otherlimitation"/>
      

      <br/><br/>
      <label>Activities Permitted: (Check all that apply)</label><br/>
      <input type="checkbox" id="completebedrest" name="completebedrest" value="Complete Bedrest"/>
      <label for="completebedrest"> Complete Bedrest</label><br/>
      <input type="checkbox" id="bedrestBRP" name="bedrestBRP" value="Bedrest BRP"/>
      <label for="bedrestBRP"> Bedrest BRP</label><br/>
      <input type="checkbox" id="upastolerated" name="upastolerated" value="Up As Tolerated"/>
      <label for="upastolerated"> Up As Tolerated</label><br></br>
      <input type="checkbox" id="transferbedchair" name="transferbedchair" value="Transfer Bed/Chair"/>
      <label for="transferbedchair"> Transfer Bed/Chair</label><br></br>
      <input type="checkbox" id="excercisesprescribed" name="excercisesprescribed" value="Excercises Prescribed"/>
      <label for="excercisesprescribed"> Excercises Prescribed</label><br></br>
      <input type="checkbox" id="partialweightbearing" name="partialweightbearing" value="Partial Weight Bearing"/>
      <label for="partialweightbearing"> Partial Weight Bearing</label><br></br>
      <input type="checkbox" id="independentathome" name="independentathome" value="Independent At Home"/>
      <label for="independentathome"> Independent At Home</label><br></br>
      <input type="checkbox" id="crutches" name="crutches" value="Crutches"/>
      <label for="crutches"> Crutches</label><br></br>
      <input type="checkbox" id="cane" name="cane" value="Cane"/>
      <label for="cane"> Cane</label><br></br>
      <input type="checkbox" id="wheelchair" name="wheelchair" value="Wheelchair"/>
      <label for="wheelchair"> Wheelchair</label><br></br>
      <input type="checkbox" id="walker" name="walker" value="Walker"/>
      <label for="walker"> Walker</label><br></br>
      <input type="checkbox" id="norestrictions" name="norestrictions" value="No Restrictions"/>
      <label for="norestrictions"> No Restrictions</label><br></br>
      <label for="otheractivity"> Other: </label>
      <input type="text" id="otheractivity" name="otheractivity"/>
      
      <br/><br/>
      <label>Mental Status: (Check all that apply)</label><br/>
      <input type="checkbox" id="oriented" name="oriented" value="Oriented"/>
      <label for="oriented"> Oriented</label><br></br>
      <input type="checkbox" id="comatose" name="comatose" value="Comatose"/>
      <label for="comatose"> Comatose</label><br></br>
      <input type="checkbox" id="forgetful" name="forgetful" value="Forgetful"/>
      <label for="forgetful"> Forgetful</label><br></br>
      <input type="checkbox" id="depressed" name="depressed" value="Depressed"/>
      <label for="depressed"> Depressed</label><br></br>
      <input type="checkbox" id="disoriented" name="disoriented" value="Disoriented"/>
      <label for="disoriented"> Disoriented</label><br></br>
      <input type="checkbox" id="lethargic" name="lethargic" value="Lethargic"/>
      <label for="lethargic"> Lethargic</label><br></br>
      <input type="checkbox" id="agitated" name="agitated" value="Agitated"/>
      <label for="agitated"> Agitated</label><br></br>
      <label for="othermental"> Other: </label>
      <input type="text" id="othermental" name="othermental"/>
      
      <br/><br/>
      <label>Prognosis: </label><br/>
      <input type="radio" id="poor" name="Prognosis" value="Poor"/>
      <label for="poor"> Poor</label><br></br>
      <input type="radio" id="guarded" name="Prognosis" value="Guarded"/>
      <label for="guarded"> Guarded</label><br></br>
      <input type="radio" id="fair" name="Prognosis" value="Fair"/>
      <label for="fair"> Fair</label><br></br>
      <input type="radio" id="good" name="Prognosis" value="Good"/>
      <label for="good"> Good</label><br></br>
      <input type="radio" id="excellent" name="Prognosis" value="Excellent"/>
      <label for="excellent"> Excellent</label>

      <br/><br/>
      <label for="nutrition"> Nutrition Requirements: </label>
      <input type="text" id="nutrition" name="nutrition"/>
      
      <br/><br/>
      <label>In the past 60 days does patient report any significant changes to health: </label><br/>
      <input type="checkbox" id="changetohealthyes" name="changetohealthyes" value="Yes"/>
      <label for="changetohealthyes"> Yes</label><br></br>
      <input type="checkbox" id="changetohealthno" name="changetohealthno" value="No"/>
      <label for="changetohealthno"> No</label>

      <br/><br/>
      <label>Has the patient had any home intrathecal pump refills in the past 60 days: </label><br/>
      <input type="checkbox" id="recentrefillyes" name="recentrefillyes" value="Yes"/>
      <label for="recentrefillyes"> Yes</label><br></br>
      <input type="checkbox" id="recentrefillno" name="recentrefillno" value="No"/>
      <label for="recentrefillno"> No</label>
      
      <br/><br/>
      <label>Has the patient had any dose increases in the past 60 days: </label><br/>
      <input type="checkbox" id="doseincreaseyes" name="doseincreaseyes" value="Yes"/>
      <label for="doseincreaseyes"> Yes</label><br></br>
      <input type="checkbox" id="doseincreaseno" name="doseincreaseno" value="No"/>
      <label for="doseincreaseno"> No</label>

      <br/><br/>
      <label>Has the patient had any dose decreases in the past 60 days: </label><br/>
      <input type="checkbox" id="dosedecreaseyes" name="dosedecreaseyes" value="Yes"/>
      <label for="dosedecreaseyes"> Yes</label><br></br>
      <input type="checkbox" id="dosedecreaseno" name="dosedecreaseno" value="No"/>
      <label for="dosedecreaseno"> No</label>

      <br/><br/>
      <label>At time of recent assessment, what was patient's pain rating: </label><br/>
      <select name="painrating" id="painrating">
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select>

      <br/><br/>
      <label>At time of recent assessment, has the patient recently experienced muscle spasms: </label><br/>
      <br/><br/>
    </div>
  );
};

export default ConsumerHOC(CarePlan);
