import React, { useEffect, useState } from "react";

import axios from "axios";
import PrimaryConsumerHOC from "../../context/ConsumerHOC";
import ScrollableListOfPatients from "./ScrollableListOfPatients";
import { Info } from "../../config.js";

const domain = Info.domain;

const PickPatient = (props) => {
  //for use by Verbal orders component
  let { VO } = props;
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    async function fetch() {
      const results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${domain}/api/allPats`,

        headers: {
          Authorization: `bearer ${props.token}`,
        },
        data: { VO: true },
      });

      setPatients(results.data);
    }
    fetch();
  }, [props.token]);
  return <ScrollableListOfPatients patients={patients} choose={props.choose} />;
};

export default PrimaryConsumerHOC(PickPatient);
