import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Text,
} from "recharts";
import Styles from "../../ParentStyles.json";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { Info } from "../../config.js";
import DatePicker from "../MatUIDatePicker";
import Button from "../Button";
import avgTimeTilApprovedPerInterval from "./avgTimeTilApprovedPerInterval.js";
const domain = Info.domain;

const TimeStartToApproval = (props) => {
  const grabAvgReports = async () => {
    let res = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/analytics/ReportsApprovedPerDay`,
      data: {
        startInterval: startDate,
        endInterval: endDate,
      },
      headers: {
        Authorization: `bearer ${props.token}`,
      },
    });

    return res;
  };

  const updateData = (date, startend) => {
    if (startend === "start") {
      updateStartDate(date);
    } else {
      updateEndDate(date);
    }
  };

  let [open, toggleOpen] = useState(false);
  let [avgReports, updateAvgReports] = useState([]);
  let [startDate, updateStartDate] = useState("10/1/2019");
  let [endDate, updateEndDate] = useState(Date.now());
  useEffect(() => {
    grabAvgReports().then((res) => {
      updateAvgReports(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  let reconfigureData = avgTimeTilApprovedPerInterval(avgReports);
  //console.log("what is reconfigure data", reconfigureData);

  if (!open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Average Time Til Completion (click to open)"
            func={toggleOpen}
            data={true}
          />
        </div>
      </Paper>
    );
  } else if (open) {
    return (
      <Paper>
        <div style={{ margin: 30, padding: 20 }}>
          <Button
            label="Average Time Til Completion (click to close)"
            func={toggleOpen}
            data={false}
          />
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"start"}
          />
          <DatePicker
            updateDate={updateData}
            dateOnly={true}
            startend={"end"}
          />
          <LineChart
            width={1000}
            height={400}
            data={reconfigureData}
            margin={{ top: 5, right: 20, bottom: 20, left: 25 }}
          >
            <Line
              type="monotone"
              dataKey="avgTime"
              stroke={Styles["darkColor"]}
              fill={Styles["darkColor"]}
              dot={false}
              strokeWidth={4}
            />
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey="Date"
              tick={{ fontSize: 10, fill: Styles["darkColor"] }}
            >
              <Label value="Dates" offset={-10} position="insideBottom" />
            </XAxis>

            <YAxis
              padding={{ top: 12 }}
              orientation="left"
              label={
                <Text x={0} y={0} dx={50} dy={300} offset={0} angle={-90}>
                  Avg # of Days from Visit Start to Approval
                </Text>
              }
            />
            <Tooltip />
          </LineChart>
        </div>
      </Paper>
    );
  }
};

export default TimeStartToApproval;
