import React, { useState } from "react";
import Button from "../Button";
import ConsumerHOC from "../../context/ConsumerHOC";
import axios from "axios";
import { Info } from "../../config";
let domain = Info.domain;

const SyringeWasteSubmitComponent = (props) => {
  let {
    token,
    answers,
    toggleReview,
    inReview,
    updateMissingInput,
    goBack,
    relevantData,
  } = props;

  let [isError, updateError] = useState(false);

  const BeforeReview = async () => {
    let results = await CheckForMandatoryAnswers();
    // console.log("what are results", results);
    let missingInputLength = Object.keys(results).length;

    if (missingInputLength === 0) {
      toggleReview(!inReview);
    }

    return missingInputLength;
  };

  const CheckForMandatoryAnswers = () => {
    let missingQuestions = {};
    Object.keys(answers).forEach((section) => {
      let subsection = [];

      Object.keys(answers[section]).forEach((subsect) => {
        if (
          !answers[section][subsect] ||
          answers[section][subsect].length < 1 ||
          answers[section][subsect].size < 1
        ) {
          subsection.push(subsect);
        }
      });

      if (subsection.length > 0) {
        missingQuestions[section] = subsection;
      }
    });

    updateMissingInput(missingQuestions);
    return missingQuestions;
  };

  const submitData = async () => {
    // console.log("hit the submit data button", props);
    try {
      let results = await axios({
        method: "post", // you can set what request you want to be
        url: `https://${domain}/PharmacyRefill`,
        data: {
          answers,
          visitID: relevantData.VisitNum,
          headerID: relevantData.headerID,
          visitDate: relevantData.VisitDate,
          //   visitData: {
          //     PatientName: visitData.PatientName,
          //     VisitNum: visitData.VisitNum,
          //     VisitDate: visitData.VisitDate,
          //     MRN: visitData.MRN,
          //   },
          //   status,
          //   highlight,
          //   email,
        },
        headers: {
          Authorization: `bearer ${token}`,
        },
      });

      if (results.status === 200) {
        // console.log("all good");
        goBack(false);
      }
    } catch (err) {
      console.error(err);
      updateError("Error while sending form, please contact IT");
    }
  };

  //   return (
  //     <Button
  //       func={async () => {
  //         console.log(props);
  //         let results = await BeforeReview();
  //         if (results === 0 && inReview) {
  //           submitData();
  //         }
  //       }}
  //       label="Submit"
  //     />
  //   );
  // };

  return (
    <div className="ReviewBtn">
      <div className="ReviewBtnChild">
        <Button
          label={!inReview ? "Review Your Submission" : "Return to Form"}
          func={BeforeReview}
          data={!inReview}
        />
      </div>
      <div className="ReviewBtnChild">
        <Button label="Save" func={submitData} data={"incomplete"} />
      </div>
      <div className="ReviewBtnChild">
        {inReview ? (
          <Button label="Submit" func={submitData} data={"Admin Review"} />
        ) : null}
      </div>
      {isError ? <div className="errorOnSubmit">{isError} </div> : null}
    </div>
  );
};

export default ConsumerHOC(SyringeWasteSubmitComponent);
