import React, { useState } from "react";
import axios from "axios";
import "./AdminPanel.css";
import { Info } from "../../config.js";
import { ClinicianVisitCountCheck } from "./ClinicianVisitCountCheck.js";
import { CarePlans } from "./CarePlans.js";
const domain = Info.domain;

const AdminPanel = (props) => {
  const [selectedState, setSelectedState] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [state, setState] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Generate Careplan By State
  const generateCareplan = async (type = "default", data) => {
    let results;
    const headers = {
        Authorization: `bearer ${props.token}`,
      };
      switch (type) {
        case "state":
          axios
            .post(`https://${domain}/carePlan/generateCareplanReportsByState`, {
              selectedState,
            }, headers)
            .then((res) => {
              console.log("CarePlan: ", res);
            })
            .catch((error) => {
              console.error("Error generating care plan:", error);
            })
            .finally(() => {
              setIsLoading(false); // End loading whether success or fail
            });
          case "name":
            try {
              return await axios({
                  method: "post", // you can set what request you want to be
                  url: `https://${domain}/carePlan/generateCareplanReportsByName`,
                  headers: {
                    Authorization: `bearer ${props.token}`,
                  },
                  data,
                });
            } catch (error) {
              console.log(error);
            }
            break;
        case "searchPatient":
          console.log({ firstname, lastname });
          results = await axios({
            method: "post", // you can set what request you want to be
            url: `https://${domain}/patients/patientByName`,
            headers: {
              Authorization: `bearer ${props.token}`,
            },
            data: { firstname, lastname },
          });
          console.log({results});
          return(results.data)
        default:
          break;
      }
  };

  // Generate Dailies
  const generateDailies = async () => {
    setIsLoading(true); // Start loading
    const response = await axios.post(
      `https://${domain}/daily/generateDailiesByDate`,
      {},
      {
        headers: {
          Authorization: `bearer ${props.token}`,
        },
      }
    );
    console.log(response);
    setIsLoading(false); // Start loading
  }

  const fetchvisitCount = async ({startDate, endDate}) => {
   return await axios
      .post(`https://${domain}/nurses/nursingScheduleDateRange`, {
        startDate,
        endDate
      },{
        headers: {
          Authorization: `bearer ${props.token}`,
        },
      })
  }

  return (
    <div className="admin-panel">
      {/* Section: Generate Care Plans */}
      <div className="section">
        <div className="section-header">Generate Care Plans</div>
        <div className="input-group">
          <input
            type="text"
            placeholder="State"
            onChange={(e) => setSelectedState(e.target.value)}
          />
          <button onClick={() => generateCareplan("state")} disabled={false}>
            Generate
          </button>
        </div>
      </div>
      <div className="section">
        <div className="section-header">Generate Dailies</div>
        <div className="input-group">
          <button onClick={() => generateDailies()} disabled={false}>
            Generate
          </button>
        </div>
      </div>
      <ClinicianVisitCountCheck fetchvisitCount={fetchvisitCount}/>
      <CarePlans firstname={firstname} lastname={lastname} state={state} setFirstname={setFirstname} setLastname={setLastname} setState={setState} generateCareplan={generateCareplan}/>
      {/* You can add more sections below in a similar manner for other functionalities */}
    </div>
  );
};

export default AdminPanel;
