import React, { useState } from "react";
import "./DemographicMap.css";
import Button from "../Button";

const SideBarNurse = (props) => {
  let { sideBarView } = props;
  // console.log(props);
  let { FNAME, LNAME, CITY, STATE, Address } = sideBarView.data;
  let [display, toggleDisplay] = useState("assigned");

  return (
    <div className="dataDisplaySubjectParent">
      <div className="dataDisplaySubjectParentHeader">
        <div className="line">
          <div className="lineHeader">Nurse: </div>
          <div>
            {FNAME} {LNAME}
          </div>
        </div>
        <div className="line">
          <div className="lineHeader">Address: </div>
          <div>
            {" "}
            {Address} {CITY}, {STATE}
          </div>
        </div>

        <div className="line">
          <div className="lineHeader">Number of Patients: </div>
          <div> {sideBarView.patientList.length}</div>
        </div>
      </div>
      <div className="buttongrid">
        {" "}
        <Button
          label="Assigned Patients"
          func={toggleDisplay}
          data={"assigned"}
        />
        <Button
          label="70 Closest BHI Patients"
          func={toggleDisplay}
          data={"nearest"}
        />
      </div>

      <div className="dataDisplaySubjectParentHeader">
        <div className="patientsHeader">
          {display === "assigned" ? "Assigned Patients" : "Closest Patients"}
        </div>
        {display === "assigned"
          ? sideBarView.patientList.map((patient, idx) => {
              let {
                First_Name,
                Last_Name,
                Address,
                City,
                State,
                distanceToNurse,
              } = patient;
              return (
                <>
                  <div key={"patient" + idx} className="listedPatient">
                    <div className="line">
                      <div className="lineHeader"> Name:</div>
                      <div>
                        {" "}
                        {First_Name} {Last_Name}{" "}
                      </div>
                    </div>
                    <div className="line">
                      <div className="lineHeader">Address:</div>{" "}
                      <div>
                        {" "}
                        {Address} {City} {State}{" "}
                      </div>
                    </div>
                    <div className="line">
                      {" "}
                      <div className="lineHeader"> Distance: </div>
                      <div>{distanceToNurse} Miles</div>
                    </div>
                  </div>
                </>
              );
            })
          : //closest patients by distance
            sideBarView.closestPats.map((patient, idx) => {
              let {
                First_Name,
                Last_Name,
                Address,
                City,
                State,
                distanceToNurse,
                Primary_RN,
                NurseID,
              } = patient;
              return (
                <>
                  <div key={"closest" + idx} className="listedPatient">
                    <div className="line">
                      <div className="lineHeader"> Name:</div>
                      <div>
                        {" "}
                        {First_Name} {Last_Name}{" "}
                      </div>
                    </div>
                    <div className="line">
                      <div className="lineHeader">Address:</div>{" "}
                      <div>
                        {" "}
                        {Address} {City} {State}{" "}
                      </div>
                    </div>
                    <div className="line">
                      {" "}
                      <div className="lineHeader"> Distance: </div>
                      <div>{distanceToNurse} Miles</div>
                    </div>
                    <div className="line">
                      {" "}
                      <div className="lineHeader"> Assigned RN : </div>
                      <div
                        style={
                          NurseID !== sideBarView.data.NO
                            ? { color: "red" }
                            : null
                        }
                      >
                        {Primary_RN}{" "}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
    </div>
  );
};

export default SideBarNurse;
