import React, { useState } from "react";
import ParentStyles from "../ParentStyles.json";
import EditableRow from "./EditableRow.js";
import { drugArray } from "./RowDataTypes.js";
import ConsumerHOC from "../context/ConsumerHOC";
import { withRouter } from "react-router-dom";
import Toggler from "./ToggleComp";
import AddToDrugList from "./AddToDrugList.js";
import AddTo from "./AddTo";
import { TiDelete } from "react-icons/ti";
import styled from "styled-components";

const HoverText = styled.span`
  color: ${ParentStyles["darkColor"]};
  font-size: 30px;
  textalign: center;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

const HoverBtn = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const ChartMedData = (props) => {
  //console.log("hitting Druglist? - ChartMedData props", props);
  //console.log("ChartMedData props", props);
  const usedIDs = [];
  let data = props.currentReport.visitData[props.title].data;
  let addEntry = props.addEntry;

  let nameArr = [];
  let units;
  let datapts = Object.keys(data);
  let results = {};

  //create list of drugs which are in our list
  drugArray.forEach((x) => {
    if (data[x]) {
      nameArr = data[x];
      //add index of array to our list of used indexes....this allows us to
      //render all data points not necessary for our chart
      usedIDs.push(datapts.indexOf(x));
    }
  });

  //nameArr is full name, abbreviatedName is only first word

  nameArr.forEach((name) => {
    results[name.split(" ")[0]] = {
      concentration: "",
      dose: "",
      units: "",
      fullName: name,
    };
  });

  let abbreviatedName = Object.keys(results);

  datapts.forEach((pc, id) => {
    let concentration;
    let dose;

    for (let i of abbreviatedName) {
      if (pc.split(" ")[0] === i && pc.includes("concentration per ml")) {
        concentration = data[pc];

        results[i].concentration = concentration;
        usedIDs.push(id);
      }
      if (pc.split(" ")[0] === i && pc.includes("daily dose")) {
        dose = data[pc];

        results[i].dose = dose;
        usedIDs.push(id);
      }
      if (pc.split(" ")[0] === i && pc.includes("unit of measure")) {
        units = data[pc];

        results[i].units = units;
        usedIDs.push(id);
      }
    }
  });

  let noChangeCheck = false;
  //if only term in arr is "All medications, doses and concentration remain unchanged", then run following
  Object.keys(data).forEach((x) => {
    if (
      data[x] &&
      data[x][0] ===
        "All medications, doses and concentrations remain unchanged"
    ) {
      noChangeCheck = true;
    }
  });
  // if (noChangeCheck) {
  //   return (
  //     <div>All medications, doses and concentrations remain unchanged</div>
  //   );
  // }
  return (
    <>
      {/* only render if there is some data to display */}

      <MedicationDataChartRender
        {...props}
        NoDataToDisplay={noChangeCheck}
        results={results}
        data={data}
        alterParent={addEntry}
        key={props.title}
      />

      {/* Render out all data points not used in our table above */}
      <div>
        <ExtraData
          {...props}
          datapts={datapts}
          usedIDs={usedIDs}
          key={props.datapts}
        />
      </div>
    </>
  );
};

const MedicationRow = (props) => {
  //console.log("hitting Druglist medication row", props);

  let { x, id, results, data, reportID } = props;

  const setAsPrimaryDrug = (idx) => {
    let arr = data[props.IdentifyingArray];

    let newFirst = arr.splice(idx, 1);
    arr.unshift(...newFirst);

    props.editReportData(props.title, props.IdentifyingArray, arr);
  };

  const toDeleteRow = (results) => {
    //console.log("in to del row!");
    let name = results["fullName"]; //full name of drug to be removed

    let returnKey = "";
    let newRay = [];
    Object.keys(props.data).forEach((key) => {
      //drugArray is a list of array names that contain a list of meds in table
      //must remove the med name from this list
      if (Array.isArray(props.data[key]) && drugArray.includes(key)) {
        returnKey = key;

        newRay = props.data[key].filter((term) => {
          return term !== name;
        });
      }

      //use this to remove concentration, dose and measurements
      if (key.split("-")[0].trim() === name.trim()) {
        props.editReportData(props.title, key, null);
      }
    });

    return [returnKey, newRay];
  };

  return (
    <>
      <div
        style={gridCells}
        identifier={props.IdentifyingArray}
        onClick={async (event) => {
          // await props.editWorkingCopy(
          //   reportID,
          //   props.title,
          //   IdentifyingArray,
          //   "change to this thing"
          // );
        }}
      >
        {" "}
        {x === "Other"
          ? data["Other Drug - pump settings"] ||
            data["Other drug - pump settings"]
          : results[x]["fullName"]}
      </div>
      <div style={gridCells} identifier={`${x} - unit of measure`}>
        <Toggler
          key={props.title + Math.random()}
          isEditable={props.isEditableStatus}
          falseComponent={results[x]["units"]}
          reportID={reportID}
          title={props.title}
          identifier={`${x} - unit of measure`}
          editReportData={props.editReportData}
        />
        {/* {results[x]["units"]}{" "} */}
      </div>
      <div
        key={props.title + new Date()}
        style={gridCells}
        identifier={`${x} - concentration per ml`}
        // onClick={event => {
        //   openClose(event.target.getAttribute("identifier"));
        //   //console.log(event.target.getAttribute("identifier"));
        // }}
      >
        <Toggler
          editReportData={props.editReportData}
          key={props.title + Math.random()}
          isEditable={props.isEditableStatus}
          falseComponent={results[x]["concentration"]}
          reportID={reportID}
          title={props.title}
          identifier={`${x} - concentration per ml`}
        />
        {/* {results[x]["concentration"]} */}
      </div>
      <div
        key={id + Math.random()}
        style={gridCells}
        identifier={`${x} - daily dose`}
        // onClick={event => {
        //   openClose(event.target.getAttribute("identifier"));
        //   //console.log(event.target.getAttribute("identifier"));
        // }}
      >
        <Toggler
          editReportData={props.editReportData}
          key={props.title + Math.random()}
          isEditable={props.isEditableStatus}
          falseComponent={results[x]["dose"]}
          reportID={reportID}
          title={props.title}
          identifier={`${x} - daily dose`}
        />
        {/* {results[x]["dose"]}{" "} */}
      </div>
      {props.isEditableStatus ? (
        <div
          id={id}
          style={{
            backgroundColor: ParentStyles["darkColor"],
            color: "white",
            borderRadius: 5,
            padding: 5,
            textAlign: "center",
            alignContent: "center",
          }}
          onClick={() => {
            setAsPrimaryDrug(id);
          }}
        >
          <HoverBtn> Set to Primary Drug</HoverBtn>
        </div>
      ) : (
        <div></div>
      )}

      <div
        id={x}
        onClick={() => {
          if (props.isEditableStatus) {
            props.editReportData(
              props.title,
              ...toDeleteRow(results[x]),
              "deleteRow",
              results[x].fullName
            );
          }
        }}
      >
        {" "}
        {props.isEditableStatus ? (
          <HoverText>
            <TiDelete />{" "}
          </HoverText>
        ) : null}
      </div>
    </>
  );
};

// const delStyle = {
//   fontSize: 30,
//   color: ParentStyles["darkColor"]
// };

const MedicationDataChartRender = (props) => {
  //let { NoDataToDisplay } = props;

  let [state, setState] = useState({});

  let results = props.results;
  let data = props.data;
  let reportID = props.location.state.data._id;

  const openClose = (identifier) => {
    if (!state[identifier]) {
      setState({ ...state, [identifier]: true });
    } else {
      setState({ ...state, [identifier]: false });
    }
  };

  const findNameID = (abbName) => {
    let identifyingArray;
    Object.keys(data).forEach((identifier) => {
      for (let term of drugArray) {
        if (term === identifier) {
          identifyingArray = term;
        }
      }
    });

    return identifyingArray;
  };

  let resultsArr = Object.keys(results).length;
  let isempty;
  if (resultsArr > 0) {
    isempty = false;
  } else {
    isempty = true;
  }
  //console.log("what is isempty here?", isempty, resultsArr);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20% 15% 15% 15% 11% 8%",

          gridColumnGap: 5,
          gridRowGap: 5,
          textAlign: "left",
          justifyContent: "start",
          alignContent: "center",

          //borderRadius: 10,
          margin: "10, 5 10 5",
          padding: 10,

          color: ParentStyles["darkColor"],
          //borderWidth: 1,
          //borderStyle: "solid"
        }}
      >
        <div style={gridHeaderStyle}>Medication </div>
        <div style={gridHeaderStyle}>Unit of Measure </div>
        <div style={gridHeaderStyle}>Concentration (per ml)</div>
        <div style={gridHeaderStyle}>Dose (per day)</div>
        <div></div>
        <div />
        {isempty ? <div>See Below</div> : null}
        {Object.keys(results).map((x, id) => {
          let IdentifyingArray = findNameID(x);

          return (
            <MedicationRow
              key={reportID + IdentifyingArray + x}
              reportID={reportID}
              IdentifyingArray={IdentifyingArray}
              x={x}
              id={id}
              {...props}
            />
          );
        })}

        {props.isEditableStatus ? (
          <div
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 4,
              borderRadius: 5,
            }}
          >
            <AddButton>
              <AddTo
                {...props}
                style={{
                  textAlign: "center",

                  margin: 10,
                }}
              >
                <AddToDrugList {...props} />
              </AddTo>
            </AddButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

const gridHeaderStyle = {
  fontWeight: "bold",
  fontSize: "10",
  backgroundColor: "#d8d8d8",
  borderRadius: 0,
  padding: 10,
  alignContent: "center",
};

const gridCells = {
  backgroundColor: "#d8d8d8",
  borderRadius: 5,
  padding: 5,
  margin: 3,
};

const ExtraData = (props) => {
  //console.log("extra data props", props);
  let { datapts, usedIDs, data } = props;

  let toReturn = [];
  datapts.forEach((d, id) => {
    if (!usedIDs.includes(id) && data[d] !== "null" && data[d] !== null) {
      toReturn.push(
        <EditableRow
          {...props}
          key={id * new Date()}
          data={data[d]}
          keyName={d}
          alterParent={props.addEntry}
          i={123 * new Date()}
        />
      );
    }
  });
  return toReturn;
};

const AddButton = styled.span`
  color: ${ParentStyles["darkColor"]};
  font-size: 30px;
  textalign: left;
  padding: 10;
  margin: 15;
  margintop: 5px;
  z-index: 1;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;

let ChartMedDataWithRouter = withRouter(ChartMedData);
export default ConsumerHOC(ChartMedDataWithRouter);
