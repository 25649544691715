import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Button from "./Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: "none",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ReuseableModal = (props) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  let [modState, toggleMod] = React.useState(props.openStatus);

  useEffect(() => {
    if (modState !== props.openStatus) {
      toggleMod(props.openStatus);
    }
  }, [modState, props.openStatus]);

  let text = props.label
    ? `Are you sure you want to ${props.label}?`
    : props.msg;
  return (
    <>
      <Modal open={modState}>
        <div style={modalStyle} className={classes.paper}>
          <div>{text} </div>
          <div>{props.children}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ margin: 10 }}>
              <Button
                label="Submit"
                func={async () => {
                  await props.func(props.data, props.status);
                  await props.close(false);
                }}
              />
            </div>
            <div style={{ margin: 10 }}>
              <Button label="Cancel" func={() => props.close(false)} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReuseableModal;
