import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import "./Scheduler.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: 10,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

export default function DateAndTimePickers(props) {
  let {
    startend,
    note,
    initialDate,
    modState,
    nextAlarmDate,
    newInput,
    updateNewInput,
    PCF,
  } = props;

  let [isError, toggleError] = useState(null);

  let helperTextNote = note ? note : "Note: Date must be greater than today";
  const classes = useStyles();
  // console.log("pros", props);

  let [nextAlarm, updateNextAlarm] = useState(new Date(nextAlarmDate));
  //let [file, addFile] = useState(null);
  let [newDate, updateNewDate] = useState(null);

  const changeHandler = (e) => {
    //save date in state in order to use it upon file upload
    updateNewDate(e.target.value);
    let suggestedDate = new Date(e.target.value);
    // console.log(suggestedDate);
    // console.log(new Date());
    if (suggestedDate < new Date() || suggestedDate > new Date("2099-12-31")) {
      toggleError(
        "Invalid Date: Must set a date greater than today and less than 2099-12-31"
      );
      //set date value to null thereby preventing submission
      props.updateDate(null, startend);
    }
    //check to ensure that a refill visit is not being set after an alarm date
    else if (
      modState.VisitType.includes(
        "Refill" || modState.VisitType.includes("refill")
      ) &&
      suggestedDate > nextAlarm
    ) {
      toggleError("Invalid Date: Refill date must occur prior to Alarm Date");
      //set date value to null thereby preventing submission
      props.updateDate(null, startend);
    } else if (
      PCF &&
      moment(e.target.value).format() >
        moment(initialDate).add(2, "weeks").format()
    ) {
      toggleError(
        "Cannot reschedule visit more than two weeks into the future via PCF form."
      );
      props.updateDate(null, startend);
    }
    //setting conditional to question user from re-scheduling visit to be within 48 hours of the date at the moment (the current date at time of rescheduling)
    //ex. today is tuesday, and visit is set for Saturday. Error will be triggered if user wants to reschedule visit to Wednesday or Thursday.
    //user will then have to contact pharmacy and ask for permission. User will need to input pharmacy contact's initials.
    else if (
      moment(e.target.value).diff(moment(), "days") + 1 <= 2 &&
      (!newInput.pharmacistInitials || newInput.pharmacistInitials.length <= 2)
    ) {
      toggleError(
        "If user requests to move a visit within 2 days of current date, user must contact pharmacy."
      );
      props.updateDate(null, startend);
    } else {
      toggleError(null);
      props.updateDate(e.target.value, startend);
    }
  };

  return (
    <form className={classes.container} noValidate>
      {startend ? `${startend} of Interval` : null}
      <TextField
        error={isError}
        helperText={isError ? isError : helperTextNote}
        id="date"
        label={props.header}
        type="date"
        defaultValue={initialDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          //console.log("changed!", new Date(e.target.value));
          changeHandler(e);
        }}
      />

      {isError ===
        "If user requests to move a visit within 2 days of current date, user must contact pharmacy." ||
      newInput.pharmacistInitials ? (
        <TextField
          error={isError}
          helperText={isError ? isError : helperTextNote}
          // id="date"
          label={"Name of pharmacy representative contacted"}
          // type="date"

          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            updateNewInput({
              ...newInput,
              pharmacistInitials: e.target.value,
              updatedDate: newDate,
            });
            if (
              newInput.pharmacistInitials &&
              newInput.pharmacistInitials.length > 1
            ) {
              toggleError(false);
            } else {
              toggleError(
                "If user requests to move a visit within 2 days of current date, user must contact pharmacy."
              );
            }
          }}
        />
      ) : null}

      {isError === "Invalid Date: Refill date must occur prior to Alarm Date" ||
      newInput.file ? (
        <UploadAFile
          toggleError={toggleError}
          newInput={newInput}
          updateNewInput={updateNewInput}
          newDate={newDate}
          nextAlarm={nextAlarm}
        />
      ) : null}
    </form>
  );
}

const UploadAFile = (props) => {
  let { toggleError, newInput, updateNewInput, newDate } = props;
  const onFileChange = (e) => {
    let fileData = e.target.files[0];
    // let fileData = new FormData();

    // fileData.append(
    //   "New Alarm Date Picture",
    //   e.target.files[0],
    //   e.target.files[0].name
    // );

    // for (var pair of fileData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    updateNewInput({
      ...newInput,
      file: fileData,
      updatedDate: newDate,
    });
    toggleError(false);
  };

  return (
    <div className="fileInput">
      <div style={{ color: "red", width: "75%" }}>
        {!newInput.file
          ? "In order to reschedule the upcoming refill date past the alarm date, you must submit a picture of PTM/PTC"
          : "File Selected"}
      </div>
      <input type="file" name="name" onChange={onFileChange} />
    </div>
  );
};
