import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import FormWizard from "../FormWizard/FormWizard";
import "./marketing.css";
import SubmitComponent from "../FormWizard/SubmitReviewButton";
import marketingAddAccountQuestionaire from "./marketingAddAccountQuestionaire.json";

import Button from "../Button";
import MarketingViewNarrativeByAccount from "./MarketingViewNarrativeByAccount";

const MarketingParent = (props) => {
  let { token } = props;

  let buttonTypes = [
    { data: "viewNarrative", label: "View Narrative By Account" },
    // { data: "updateNarrative", label: "Add New Narrative" },
    { data: "addAccount", label: "Add New Account" },
  ];

  let [currentView, toggleCurrentView] = useState("viewNarrative");
  let [pickedAccount, updatePickedAccount] = useState(false);

  return (
    <div>
      {buttonTypes.map((btn, id) => {
        return (
          <Button
            data={btn.data}
            label={btn.label}
            func={toggleCurrentView}
            fullWidth={false}
            style={{ maxWidth: "30%", margin: "10px" }}
            disabled={currentView === btn.data ? true : false}
          />
        );
      })}

      <MarketingComponentSwitch
        currentView={currentView}
        toggleCurrentView={toggleCurrentView}
        token={token}
        pickedAccount={pickedAccount}
        updatePickedAccount={updatePickedAccount}
      />
    </div>
  );
};

const MarketingComponentSwitch = (props) => {
  let {
    currentView,
    toggleCurrentView,
    token,
    pickedAccount,
    updatePickedAccount,
  } = props;

  switch (currentView) {
    case "addAccount":
      return (
        <MarketingAddAccountForm
          toggleCurrentView={toggleCurrentView}
          pickedAccount={pickedAccount}
          updatePickedAccount={updatePickedAccount}
        />
      );

    case "viewNarrative":
      return (
        <MarketingViewNarrativeByAccount
          toggleCurrentView={toggleCurrentView}
          token={token}
          pickedAccount={pickedAccount}
          updatePickedAccount={updatePickedAccount}
        />
      );

    // case "updateNarrative":
    //   return (
    //     <MarketingNarrativeUpdate
    //       SubmitComponent={SubmitComponent}
    //       toggleCurrentView={toggleCurrentView}
    //       token={token}
    //       pickedAccount={pickedAccount}
    //       updatePickedAccount={updatePickedAccount}
    //     />
    //   );

    default:
      return null;
    // code block
  }
};

const MarketingAddAccountForm = (props) => {
  let { toggleCurrentView } = props;
  // console.log(props);
  return (
    <div className="initialProspectParent">
      <div className="ReviewTitle">Marketing: Add New Account</div>
      <FormWizard
        inputQuestionaire={marketingAddAccountQuestionaire}
        goBack={() => {
          toggleCurrentView("viewNarrative");
        }}
        SubmitComponent={SubmitComponent}
        submitAPI="marketing/Contacts"
      />
    </div>
  );
};

export default ConsumerHOC(MarketingParent);
