import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    // minWidth: 275,
    // maxWidth: "40%",
    // margin: "1%",
    padding: 20,
    textAlign: "center",
  },

  title: {
    fontSize: 14,
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();
  //console.log("what is props", props);
  return (
    <Card className={classes.card} onClick={props.onClick}>
      <Button size="large">
        <CardContent>
          <CardActions>
            <Typography variant="h5" component="h2" color="primary">
              {props.title}
            </Typography>
          </CardActions>
        </CardContent>
      </Button>
    </Card>
  );
}
