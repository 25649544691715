import React, {useState} from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CircularProgress } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}

export const CarePlans = ({state, setState, generateCareplan}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [patients, setPatients] = useState([]);
    const [generatedStatus, setGeneratedStatus] = useState()
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");

    const [radioValue, setRadioValue] = useState('name');

    const searchPatient = async () => {
        console.log({firstname, lastname});
        if(!firstname && !lastname) return;
        setLoading(true)
        let data = {
            firstname: firstname.trim(),
            lastname: lastname.trim(),
        };
        const results = generateCareplan('searchPatient', data);
        results.then(res => {
            setPatients(res)
            setLoading(false)
        })
        results.catch(err => {
            console.log(err);
            setLoading(false)
        })
        return;
    }

    const generatePOC = async (data) => {
        if(radioValue === 'name'){
            const {LAST_NAME, FIRST_NAME} = data;
            if(!FIRST_NAME && !LAST_NAME) return;
            setLoading(true)
            const results = generateCareplan(radioValue, data);
            results.then(res => {
                console.log({res});
                {!res ? setGeneratedStatus(false) : setGeneratedStatus(true)};
                setLoading(false)
            })

            results.catch(err => {
                console.log(err)
                setGeneratedStatus(false)
                setLoading(false)
            })
            return;
        };
        if(radioValue === 'state'){
            if(!state) return;
            setLoading(true)
            const results = generateCareplan(radioValue);
            console.log(results)
            // setLoading(false)
            return;
        };
    }
    
    return (
        <div>
            {loading ? 
                <section
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10% auto",
                }}
                >
                <CircularProgress />
                </section>
            :(
                <Card variant="outlined" sx={{ maxWidth: '70%' }}>
                    <Box sx={{ p: 2 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography gutterBottom variant="h5" component="div">
                                Generate Plan Of Cares
                            </Typography>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="female" control={<Radio onChange={() => setRadioValue('name')}/>} label="Name" />
                                    <FormControlLabel value="male" control={<Radio onChange={() => setRadioValue('state')}/>} label="State" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Typography color="text.secondary" variant="body2">
                            Enter the patient's name
                        </Typography>
                    </Box>
                    <Divider />
                    {radioValue === 'name' ? (
                        <Box sx={{ p: 2 }}>
                            {error && <TextField error id="standard-error" label={error} defaultValue="Error" variant="standard"/>}
                            <Stack direction="row" spacing={4}>
                                <TextField id="outlined-basic" 
                                    size="small" 
                                    label="First Name" 
                                    variant="outlined"             
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFirstname(value.charAt(0).toUpperCase() + value.slice(1));
                                    }}
                                />
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    label="Last Name" 
                                    variant="outlined"            
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setLastname(value.charAt(0).toUpperCase() + value.slice(1));
                                    }}
                                />
                                <Button variant="contained" color="success" size="large" sx={{width: "200px"}} onClick={searchPatient}>
                                    Submit
                                </Button>
                            </Stack>
                            <List
                                sx={{ width: '100%', maxWidth: 650, bgcolor: 'background.paper' }}
                                subheader={<ListSubheader>Patients</ListSubheader>}
                                >
                                    <Divider />
                                    {patients && patients.map(patient => {
                                        const {LAST_NAME, FIRST_NAME, DOB} = patient;
                                        return(
                                            <ListItem>
                                                <ListItemText id="switch-list-label-wifi" primary={`${LAST_NAME}, ${FIRST_NAME} -  ${formatDate(DOB)}`} />
                                                <Button variant="contained" onClick={() => generatePOC(patient)}>
                                                    Generate
                                                </Button>
                                                <ListItemText primary={generatedStatus && "Success" }/>
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </Box> 
                    ) : (
                        <Box sx={{ p: 2 }}>
                            {error && <TextField error id="standard-error" label={error} defaultValue="Error" variant="standard"/>}
                            <Stack direction="row" spacing={4}>
                                <TextField id="outlined-basic" 
                                    size="small" 
                                    label="State Name" 
                                    variant="outlined"             
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setState(value.toLowerCase());
                                    }}
                                />
                                <Button variant="contained" color="success" disabled={true} size="large" sx={{width: "200px"}} onClick={generatePOC}>
                                    Submit
                                </Button>
                            </Stack>
                        </Box> 
                    )}
                </Card>
            )}
        </div>
    )
}