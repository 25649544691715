import React, { useState } from "react";

import AddReportScreen from "./AddReportScreen";
import ProgressReportProvider from "../../ProgressNoteContext/Provider";
import PickPatient from "./PickPatient";

const primaryColor = "#439372";

const AddProgressReportParent = (props) => {
  let [screen, whichScreen] = useState("Pick Patient");
  let [patientData, setPatientData] = useState({});

  const toggleScreen = () => {
    screen === "Pick Patient"
      ? whichScreen("Progress Report")
      : whichScreen("Pick Patient");
  };

  const choosePatient = (data) => {
    setPatientData(data);
    whichScreen("Progress Report");
    //console.log("check data", data);
    //console.log("patientDAta", patientData);
  };

  let patHeader;

  if (screen !== "Pick Patient" && patientData) {
    patHeader = `${patientData.LAST_NAME}, ${patientData.FIRST_NAME} - DOB: ${
      patientData.DOB ? patientData.DOB.split("T")[0] : "No DOB listed"
    } - MRN ${patientData.MRN}`;
  } else {
    patHeader = null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: primaryColor,
          height: "5%",
          flexDirection: "row",
          margin: 20,
          padding: 20,
          color: "white",
          borderRadius: 5,
          fontSize: 30,
          fontWeight: "500",
        }}
      >
        <div onClick={toggleScreen} style={{ float: "left", fontSize: 15 }}>
          {screen !== "Pick Patient" ? "Select New Patient" : null}
        </div>
        <div style={{ textAlign: "center" }}>
          {" "}
          {screen}: {patHeader}{" "}
        </div>
      </div>

      {screen === "Pick Patient" ? (
        <PickPatient choose={choosePatient} />
      ) : (
        <AddReportScreen patientData={patientData} />
      )}
    </div>
  );
};

const AddProgressReportWProvider = () => {
  return (
    <ProgressReportProvider>
      <AddProgressReportParent />
    </ProgressReportProvider>
  );
};

export default AddProgressReportWProvider;
