import React, { useState } from "react";
import ConsumerHOC from "../../context/ConsumerHOC";
import axios from "axios";
import Button from "../../components/Button";
import { Info } from "../../config.js";
const domain = Info.domain;

const SystemTools = (props) => {
  let { token } = props;
  let callApi = async (route) => {
    //console.log("what is route", route);
    let status = await axios({
      method: "get", // you can set what request you want to be
      url: `https://${domain}/system/${route}`,

      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    //console.log(status);
    updateDisplayData(status.data);
  };

  let [displayData, updateDisplayData] = useState("No Updates");

  return (
    <div style={{ margin: 30 }}>
      <Button
        label="Get System Status"
        style={{ width: "50%" }}
        func={callApi}
        data={"status"}
      />
      <Button
        label="Restart System"
        style={{ width: "50%" }}
        func={callApi}
        data={"reset"}
      />
      <div style={{ margin: 20 }}>Date Display Here: {displayData}</div>
    </div>
  );
};

export default ConsumerHOC(SystemTools);
//export default SystemTools;
