import React from "react";
import "./DemographicMap.css";

const SideBarPatient = (props) => {
  // console.log(props);
  let { FNAME, LNAME, City, State, Address, nurse } =
    props.sideBarView.data.properties;

  let closestNurses = props.sideBarView.closestNurses;

  return (
    <>
      <div className="dataDisplaySubjectParent">
        <div className="dataDisplaySubjectParentHeader">
          <div className="line">
            <div className="lineHeader">Patient: </div>
            <div>
              {FNAME} {LNAME}
            </div>
          </div>
          <div className="line">
            <div className="lineHeader">Address: </div>
            <div>
              {" "}
              {Address} {City}, {State}
            </div>
          </div>

          <div className="line">
            <div className="lineHeader">Assigned Nurse: </div>
            <div> {nurse}</div>
          </div>
        </div>
      </div>

      <div className="dataDisplaySubjectParentHeader">
        <div className="patientsHeader">10 Closest Nurses</div>
        {closestNurses.map((nurse, idx) => {
          let { FNAME, LNAME, Address, CITY, STATE, distanceToNurse } = nurse;
          return (
            <div key={"patient" + idx} className="listedPatient">
              <div className="line">
                <div className="lineHeader"> Name:</div>
                <div>
                  {" "}
                  {FNAME} {LNAME}{" "}
                </div>
              </div>
              <div className="line">
                <div className="lineHeader"> Address:</div>
                <div>
                  {" "}
                  {Address} {CITY}, {STATE}
                </div>
              </div>
              <div className="line">
                <div className="lineHeader"> Distance:</div>
                <div> {distanceToNurse} Miles</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SideBarPatient;
