import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import ConsumerHOC from "../context/ConsumerHOC";
import moment from "moment";
import ProgressNoteRow from "./ProgressNoteRow";
import UploadedDocuments from "./UploadedDocuments.js";
import { Info } from "../config.js";

const domain = Info.domain;

// const submitUpdate = async (token, data, status = null) => {
//   //console.log("hit here");
//   if (status !== null) {
//     data["status"] = status;
//   }

//   try {
//     let results = await axios({
//       method: "post", // you can set what request you want to be
//       url: `https://${domain}/api/progressNotes`,
//       data: {
//         data
//       },
//       headers: {
//         Authorization: `bearer ${token}`
//       }
//     });
//     //console.log("submitting data");
//     return results.data;
//   } catch (err) {
//     //console.log("hit an error in submitting update", err);
//   }
// };

const useStyles = makeStyles({
  card: {
    // flex: 1,
    // minWidth: "60%",
    // minHeight: 500,

    // margin: "1%",
    // padding: 10,
    // alignItems: "center",

    minWidth: "25%",

    minHeight: 500,

    margin: 20,
    padding: 20,
  },

  title: {
    fontSize: 14,
  },
  cardContent: {
    padding: 5,
    //width: "50%",
    alignItems: "center",
    margin: "auto",
  },
});

const ViewReport = (props) => {
  //console.log("what is props", props);

  let reportData = props.location.state.data;
  const classes = useStyles();
  return (
    <>
      <div style={{ marginBottom: "5%" }}>
        <div
          style={{
            display: "flex",
            flexBasis: "20%",
            flexDirection: "row",

            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flexStart",
            alignContent: "space-between",
            margin: "auto",
          }}
        >
          {/* submit button */}

          <UploadedDocuments isProgressNote={true} />

          {/* <UploadDocs /> */}
          <div>
            <Card className={classes.card}>
              <CardContent className={classes.cardContentTitle}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{
                    backgroundColor: "#15794F",
                    padding: 20,
                    margin: 0,
                    borderRadius: 5,
                  }}
                >
                  {/* section title here */}
                  Progress Note
                </Typography>
              </CardContent>
              <CardContent>
                <ProgressNoteRow
                  header="Uploaded by"
                  details={`${reportData.submitterData.firstName} ${reportData.submitterData.lastName}`}
                />
                <ProgressNoteRow
                  header="Uploaded At"
                  details={moment(reportData.uploadedAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                />
                <ProgressNoteRow
                  header="Patient"
                  details={`${reportData.firstName} ${reportData.lastName}`}
                />
                <ProgressNoteRow
                  header="Progress Note"
                  details={reportData.Note}
                />
                <ProgressNoteRow
                  header="Relevant Parties"
                  details={reportData["Email"].join(", ")}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

let ViewReportWithRouter = withRouter(ViewReport);

export default ConsumerHOC(ViewReportWithRouter);
