import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "../Button";
import { withRouter } from "react-router-dom";
import { createPDF } from "../../PDFConversion/pdfConversion.js";
import ConsumerHOC from "../../context/ConsumerHOC";
import ProgressContext from "../../ProgressNoteContext/ConsumerHOC";
import axios from "axios";
import { Info } from "../../config.js";

const domain = Info.domain;

const useStyles = makeStyles({
  card: {
    // flexBasis: "50%",
    // flex: 1,
    // minWidth: "30%",
    // minHeight: 500,
    // maxWidth: "40%",
    margin: 20,
    padding: 20,
    alignItems: "center",
    height: 500,
  },

  title: {
    fontSize: 14,
  },
  cardContent: {
    padding: 5,
    width: "50%",
    alignItems: "center",
    margin: "auto",
  },
});

const submitFn = async (token, data, status = null, email = null) => {
  //console.log("submitFn", token);
  if (status !== null) {
    data["status"] = status;
  }

  if (email !== null) {
    data["email"] = email;
  }

  try {
    //console.log("send note?");
    let results = await axios({
      method: "post", // you can set what request you want to be
      url: `https://${domain}/api/progressNotes`,
      data: {
        data,
      },
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
    //console.log("submitting data");
    return results.data;
  } catch (err) {
    //console.log("hit an error in submitting update", err);
  }
};

function SubmitReportButtons(props) {
  //console.log("props in submit report butn", props);
  const classes = useStyles();
  // let [modalState, toggleModal] = useState(false);

  const submitToServer = () => {
    //console.log("what is props in submit ot server", props);
    //delete old id because we want a new insertion
    //create priorID

    submitFn(props.token, props.reportData, "working copy", [
      ...props.recipientEmails,
      props.userInfo.email,
      "monitoringcenter@basichi.com",
    ]);

    if (props.status === "finalized") {
      createPDF(props.reportData);
    }

    goBack(props);
  };

  const goBack = (props) => {
    props.history.goBack();
  };
  //console.log("props in submit report butn", props);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContentTitle}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{
            backgroundColor: "#15794F",
            padding: 20,
            margin: 0,
            borderRadius: 5,
          }}
        >
          {/* section title here */}
          Submission Options
        </Typography>
      </CardContent>

      <CardContent className={classes.cardContent}>
        <div style={{ padding: 5 }}>
          {" "}
          <Button
            label="Submit"
            func={() => {
              //toggleModal(!modalState);
              submitToServer();
            }}
          />
        </div>

        <div style={{ padding: 5 }}>
          {" "}
          <Button
            label="Discard Changes"
            func={() => {
              props.history.goBack();
            }}
          />
        </div>

        {/* <Modal
          modalState={modalState}
          close={toggleModal}
          func={submitToServer}
          data={{
            ...props,
            status: "working copy",
            emailList: props.recipientEmails,
          }}
        >
          <EmailList />
        </Modal> */}
      </CardContent>
    </Card>
  );
}

export default ConsumerHOC(ProgressContext(withRouter(SubmitReportButtons)));
