import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ConsumerHOC from "../context/ConsumerHOC";
import AddEntry from "./EditableData";
import EditableRow from "./EditableRow";
import ReportConfig from "../ReportConfig.js";
import ParentStyles from "../ParentStyles.json";
import Druglist from "./DrugList";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import RadioButtonUncheckedSharpIcon from "@material-ui/icons/RadioButtonUncheckedSharp";
import WithCalculator from "./WithCalculator";

import { drugChartHeaders } from "./RowDataTypes.js";

const useStyles = makeStyles({
  card: {
    flexBasis: "99%",
    flex: 1,
    flexWrap: "wrap",
    padding: 1,
    margin: 2,
  },

  minCard: {
    flexBasis: "99%",
    flex: 1,
    flexWrap: "wrap",

    // minHeight: 500,
    // maxWidth: "40%",
    margin: 1,
    padding: 5,
  },

  title: {
    fontSize: 10,
  },
  cardContent: { padding: 2, overflow: "auto" },
  cardContentTitleMin: { padding: 1 },
});

function DataCard(props) {
  //console.log("data card props", props);
  let permissions = props.userInfo.permissions;

  let { editEntireHeaderReportData, confirmReview } = props;

  let isEditableStatus = ReportConfig[permissions].edit;

  let [newInput, toggleNewInput] = useState(false);

  const toggleForm = () => {
    toggleNewInput(!newInput);
  };

  let [minView, toggleMinView] = useState(false);
  let [entries, editEntries] = useState(props);
  let [selected, toggleSelected] = useState(
    props.currentReport.visitData[props.title]["confirmed"]
  );

  let [invalid, toggleInvalid] = useState(true);

  // const validation = val => {
  //   toggleInvalid(!invalid);
  // };

  // useEffect(() => {
  //   if (props.currentReport.visitData[props.title].confirmed === false) {
  //     toggleInvalid(false);
  //     //console.log("fire!!");
  //   }
  // }, [props.currentReport.visitData, props.title]);

  //Should only toggle "ADD FIELD" if working on added field, and not preexisting
  const addEntry = (header, detail, toggle = false, toDelete = null) => {
    //removed the below code in order to prevent change in the order upon edit

    // if (toDelete !== null) {
    //   delete entries["data"][toDelete];
    // }

    entries["data"][header] = detail;

    editEntries(entries);

    props.editReportData(entries.title, header, detail);

    if (toggle) {
      toggleForm();
    }
  };

  const classes = useStyles();

  if (
    entries.title === "undefined" ||
    entries.title === "next" ||
    entries.title === "Final Submission"
  ) {
    return null;
  } else if (minView) {
    return (
      <DataCardMinView
        title={entries.title}
        toggleMinView={toggleMinView}
        minView={minView}
        invalid={invalid}
      />
    );
  } else {
    return (
      <Card
        className={classes.card}
        style={
          selected ? { backgroundColor: ParentStyles["lightColor"] } : null
        }
      >
        <SectionTitle
          entries={entries}
          invalid={invalid}
          minView={minView}
          toggleMinView={toggleMinView}
        />
        <CardContent className={classes.cardContent}>
          {/* If entire component needs to be specialized then it goes in below component */}
          <SpecialtyComponentSwitch
            editEntireHeaderReportData={editEntireHeaderReportData}
            entries={entries}
            isEditableStatus={isEditableStatus}
            addEntry={addEntry}
            {...props}
          />
        </CardContent>
        {newInput ? <AddEntry addEntry={addEntry} /> : null}
        {/* component will not render if permissions do not allow for editing */}
        {isEditableStatus ? (
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                toggleForm();
              }}
            >
              {newInput ? <div>Remove Field </div> : <div>Add Field</div>}
            </Button>
          </CardActions>
        ) : null}
        <ConfirmSectionReviewed
          selected={selected}
          confirmReview={confirmReview}
          toggleSelected={toggleSelected}
          entries={entries}
        />
      </Card>
    );
  }
}

const SectionTitle = (props) => {
  let { toggleMinView, minView, invalid, entries } = props;
  const classes = useStyles();

  return (
    <CardContent
      className={classes.cardContentTitle}
      onClick={() => {
        toggleMinView(!minView);
      }}
    >
      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        color="white"
        style={{
          backgroundColor: !invalid ? "red" : "#15794F",
          padding: 5,
          margin: 0,
          borderRadius: 5,
          color: "white",
        }}
      >
        {/* section title here */}
        {entries.title}
      </Typography>
    </CardContent>
  );
};

const ConfirmSectionReviewed = (props) => {
  let { selected, confirmReview, toggleSelected, entries } = props;
  return (
    <CardActions>
      <Button
        size="small"
        color="primary"
        onClick={async () => {
          await confirmReview(entries.title);
          await toggleSelected(!selected);
        }}
      >
        Section Reviewed
        {selected ? (
          <CheckCircleOutlineSharpIcon />
        ) : (
          <RadioButtonUncheckedSharpIcon />
        )}
      </Button>
    </CardActions>
  );
};

const DataCardMinView = (props) => {
  const classes = useStyles();
  let { minView, toggleMinView } = props;

  return (
    <div
      className={classes.minCard}
      onClick={() => {
        toggleMinView(!minView);
      }}
    >
      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        color="white"
        style={{
          width: "30%",
          minWidth: 300,
          backgroundColor: !props.invalid ? "red" : "#15794F",
          padding: 1,
          margin: 0,
          borderRadius: 5,
        }}
      >
        {/* section title here */}
        {props.title}
      </Typography>
    </div>
    //   </CardContent>
    // </Card>
  );
};

const MapEditableRow = (props) => {
  const entries = props.entries;
  if (entries.data) {
    return Object.keys(entries.data).map((keyName, i) => {
      const data = entries.data[keyName];
      const parentData = entries.data;
      const title = entries.title;

      return (
        <EditableRow
          editReportData={props.editReportData}
          editable={props.isEditableStatus}
          key={i + keyName}
          keyName={keyName}
          data={data}
          parentData={parentData}
          title={title}
          i={i}
          addEntry={props.addEntry}
          invalidData={props.invalidData}
        />
      );
    });
  } else {
    return null;
  }
};

//if non-default component necessary for entire section then place in this switch
const SpecialtyComponentSwitch = (props) => {
  const { entries, isEditableStatus, addEntry } = props;
  //console.log("Specialty Components Switch", props);

  if (
    props.userInfo.permissions === "ipmservices" &&
    (entries.title === "Medtronic Medication Volumes" ||
      entries.title === "Flowonix Medication Volumes")
  ) {
    return <WithCalculator {...props} />;
  } else if (!drugChartHeaders.includes(entries.title)) {
    return (
      <MapEditableRow
        editReportData={props.editReportData}
        entries={entries}
        isEditableStatus={isEditableStatus}
        addEntry={addEntry}
        key={entries.title}
        invalidData={props.invalidData}
      />
    );
  } else {
    //console.log("hitting Druglist??", entries);
    return (
      <Druglist
        editReportData={props.editReportData}
        key={entries.title}
        title={entries.title}
        data={entries.data}
        addEntry={addEntry}
        isEditableStatus={isEditableStatus}
      />
    );
  }
};

export default ConsumerHOC(DataCard);
