import React from "react";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";

const isChecked = (props) => {
  let { checked, checkedClass, unCheckedClass, onClick, label } = props;

  return (
    <>
      {checked ? (
        <FaRegCheckCircle className={checkedClass} onClick={onClick} />
      ) : (
        <FaRegCircle className={unCheckedClass} onClick={onClick} />
      )}
      {label}
    </>
  );
};

export default isChecked;
