import React, { useState } from "react";
import ConsumerHOC from "../context/ConsumerHOC";
import Styles from "../ParentStyles.json";
import Button from "./Button";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";
import Input from "./Input.js";
import Switch from "@material-ui/core/Switch";
import { IoIosAddCircle } from "react-icons/io";

import DatePicker from "./MatUIDatePicker";
import MileageReportWorkSheetAddressSection from "./mileageReportWorkSheetAddressSection";

export const EditableYesNo = (props) => {
  let { data, update, report, canEdit } = props;
  //console.log(props);
  let yesNo = data ? "Yes" : "No";

  const handleChange = () => {
    update({ ...report, [data]: !data });
  };

  if (canEdit) {
    return (
      <>
        <Switch
          checked={data}
          onChange={() => {
            handleChange();
          }}
          color="primary"
          name="checkedB"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        {yesNo}
      </>
    );
  } else if (!canEdit) {
    return <>{yesNo}</>;
  }
};

export const AddRow = (props) => {
  let { integrateNewRow } = props;
  let [openEdit, toggleOpenEdit] = useState(false);

  if (!openEdit) {
    return <AddRowBtn onClick={() => toggleOpenEdit(!openEdit)} />;
  } else {
    return (
      <WrappedAddRowEditInputs
        toggle={() => toggleOpenEdit(!openEdit)}
        integrateNewRow={integrateNewRow}
      />
    );
  }
};

//format data being sent to menuList in order to properly display
const dataFormatter = (data) => {
  let toReturn = data.map((addr) => {
    return { name: addr["formatted_address"], coords: addr.geometry.location };
  });

  return toReturn;
};

const AddRowEditInputs = (props) => {
  let { toggle, token, integrateNewRow } = props;

  let [rowData, updateRowData] = useState({
    type: null,
    time: null,
    address: "Address",
  });

  let [addressList, updateAddressList] = useState(null);

  const buttonStyle = { margin: 20 };

  const selectAddressFromList = (addressdata) => {
    //console.log("what is addressdata", addressdata);
    updateRowData({
      ...rowData,
      address: addressdata.name,
      coords: addressdata.coords,
    });

    updateAddressList(null);
  };

  const integrateAndCloseInput = () => {
    integrateNewRow(rowData);
    toggle();
  };

  return (
    <div
      style={{
        margin: 40,
        padding: 20,
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 5,
        borderColor: Styles["darkColor"],
        display: "grid",
        gridTemplateColumns: "auto auto",
        width: "60%",
      }}
    >
      <div>Type</div>
      <div>
        <Input
          onChange={(e) => updateRowData({ ...rowData, type: e.target.value })}
        />
      </div>
      <div>Time</div>
      <div>
        <DatePicker
          updateDate={(time) => {
            updateRowData({ ...rowData, time });
          }}
        />
      </div>
      <div>Address</div>
      <MileageReportWorkSheetAddressSection
        addressList={addressList}
        updateRowData={updateRowData}
        rowData={rowData}
        token={token}
        updateAddressList={updateAddressList}
        dataFormatter={dataFormatter}
        selectAddressFromList={selectAddressFromList}
      />
      <div style={{ ...buttonStyle }}>
        <Button
          label={"Add New Row"}
          func={integrateAndCloseInput}
          data={rowData}
          disabled={
            !rowData["address"] ||
            !rowData["type"] ||
            !rowData["time"] ||
            !rowData["coords"]
              ? true
              : false
          }
        />
      </div>

      <div style={{ ...buttonStyle }}>
        {" "}
        <Button label={"Cancel"} func={toggle} />
      </div>
    </div>
  );
};

const WrappedAddRowEditInputs = ConsumerHOC(AddRowEditInputs);

const AddRowBtn = (props) => {
  let { onClick } = props;
  return (
    <div
      style={{
        //   padding: 5,
        //   margin: 40,
        //   width: 150,
        //   height: 150,
        //   borderColor: Styles["darkColor"],
        //   borderWidth: 10,
        //   color: Styles["darkColor"],
        //   fontWeight: "bold",
        //   fontSize: 60,
        //   borderRadius: "50px",
        //   textAlign: "center",
        //   borderStyle: "solid",
        color: Styles["darkColor"],
        padding: 10,
        margin: 20,

        width: 50,
        height: 50,
        fontSize: 60,
        fontWeight: "bold",
        verticalAlign: "center",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <HoverAddRow>
        {" "}
        <IoIosAddCircle />
      </HoverAddRow>
    </div>
  );
};

const EditBtn = (props) => {
  let { toggleInput } = props;
  return (
    <HoverAddRow
      onClick={() => {
        toggleInput(true);
      }}
    >
      <MdEdit />
    </HoverAddRow>
  );
};

const HoverAddRow = styled.span`
  color: ${Styles["darkColor"]};
  font-size: 50px;
  textalign: left;
  padding: 10;

  :hover {
    color: #439372;
    cursor: pointer;
  }
`;
