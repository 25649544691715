import FormWizardContext from "./FormWizardContext";
import React from "react";

const Wrapper = (ChildComp) => (props) => (
  <FormWizardContext.Consumer>
    {(context) => {
      return (
        <ChildComp
          {...props}
          questionaire={context.questionaire}
          updateQuestionaire={context.updateQuestionaire}
          answers={context.answers}
          updateAnswers={context.updateAnswers}
          miscInfo={context.miscInfo}
          updateMiscInfo={context.updateMiscInfo}
          missingInput={context.missingInput}
          updateMissingInput={context.updateMissingInput}
        />
      );
    }}
  </FormWizardContext.Consumer>
);

export default Wrapper;
