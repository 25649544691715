//input text box for adding new progress notes
//called by AddProgressReportInput

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ConsumerHOC from "../../ProgressNoteContext/ConsumerHOC";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

function OutlinedTextFields(props) {
  const classes = useStyles();
  const [text, setText] = useState("");

  // const handleChange = name => event => {
  //     setValues({ ...values, [name]: event.target.value });
  // };

  const updateField = async (e) => {
    // await setText(
    //     [e.target.value]
    // );
    props.updateNewProgressNoteText(e.target.value);
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        id="outlined-multiline-static"
        label="new progress note"
        multiline
        rows="15"
        placeholder="A long long time ago ......."
        className={classes.textField}
        margin="normal"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={updateField}
      />
    </form>
  );
}

export default ConsumerHOC(OutlinedTextFields);
