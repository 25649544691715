import React from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";

function CustomAutocomplete({ options, onChange, defaultValue }) {
  return (
    <Autocomplete
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      getOptionLabel={(option) =>
        `${option.PH_LAST.trim()} ${option.PROFDESIG.trim()}, ${option.PH_FIRST.trim()}`
      }
      renderInput={(props) => (
        <TextField
          {...props}
          fullWidth
          label="Pick Provider"
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
}

export default function FreeSolo(props) {
  let { input, onChange, onBlur, setValues, formikValues, label } = props;

  const handleChange = (e, value) => {
    value = {
      ...value,
      label: `${value.PH_FIRST.trim()} ${value.PH_LAST.trim()} ${value.PROFDESIG.trim()}`,
    };

    let newState = { ...formikValues, [label]: value };

    setValues(newState);

    // console.log("What is e", e);
    // console.log("What is e", e.target);
    // onChange(e, value);
  };

  const handleBlur = (input1, input2) => {
    // console.log(input1, input2);
    onBlur(input1);
  };
  return (
    <div style={{ width: 300 }}>
      <div className="App">
        <CustomAutocomplete
          onChange={handleChange}
          options={input}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}
