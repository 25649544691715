import React, { useState, useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Bar,
  Text,
  BarChart,
} from "recharts";
import Styles from "../../ParentStyles.json";
import Paper from "@material-ui/core/Paper";

const massageData = (data) => {
  return data.map((week) => {
    return { ...week, InsertDate: week.InsertDate.split("T")[0] };
  });
};

const findMaxMin = (data, keyName) => {
  let maxMin = [Infinity, 0];

  data.forEach((obj) => {
    if (obj[keyName] > maxMin[1]) {
      maxMin[1] = obj[keyName];
    } else if (obj[keyName] < maxMin[0]) {
      maxMin[0] = obj[keyName];
    }
  });

  // console.log(maxMin);
  return maxMin;
};

const ServiceChart = (props) => {
  let { data, isErr } = props;

  let [displayData, updateData] = useState(data);
  let [maxMinTotalRange, updateMaxMinTotalRange] = useState([]);

  useEffect(() => {
    if (data && !isErr) {
      const newData = massageData(data);
      const maxMin = findMaxMin(data, "Total");
      updateMaxMinTotalRange(maxMin);
      updateData(newData);
    }
  }, [data, isErr]);

  if (!data.length) {
    return <div>No data to display at this time.</div>;
  }
  return (
    <Paper>
      <div style={{ margin: 30, padding: 20 }}>
        <div> Service Roster Total By Week </div>
        {/* <ResponsiveContainer> */}
        <BarChart
          width={1000}
          height={400}
          data={displayData}
          margin={{ top: 5, right: 20, bottom: 20, left: 20 }}
          barGap={10}
        >
          {/* <Bar dataKey="ApprovedToday" barSize={20} fill={"gray"} /> */}
          {/* <Line
            type="monotone"
            dataKey="Total"
            stroke={Styles["darkColor"]}
            fill={Styles["darkColor"]}
            dot={false}
            strokeWidth={4}
          /> */}
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey="InsertDate"
            // tick={{ fontSize: 10, fill: Styles["darkColor"] }}
          >
            <Label value="Date" offset={-10} position="insideBottom" />
          </XAxis>

          <YAxis
            dataKey="Total"
            padding={{ top: 12 }}
            //type="number"
            domain={[maxMinTotalRange[0] - 10, maxMinTotalRange[1] + 10]}
            //yAxisId="hours"
            orientation="left"
            label={
              <Text x={0} y={0} dx={30} dy={300} offset={0} angle={-90}>
                Number Of Patients In Service
              </Text>
            }
          />
          <Bar dataKey="Total" fill={Styles["darkColor"]} />
          <Tooltip cursor={false} />
        </BarChart>
        {/* </ResponsiveContainer> */}
      </div>
    </Paper>
  );
};

export default ServiceChart;

//Original Line Chart

//  <Paper>
// <div style={{ margin: 30, padding: 20 }}>
//   <div> Service Roster By State By Week </div>

//   <LineChart
//     width={1000}
//     height={400}
//     data={displayData}
//     margin={{ top: 5, right: 20, bottom: 20, left: 20 }}
//     barGap={10}
//   >
//     <Bar dataKey="ApprovedToday" barSize={20} fill={"gray"} />
//     <Line
//       type="monotone"
//       dataKey="Total"
//       stroke={Styles["darkColor"]}
//       fill={Styles["darkColor"]}
//       dot={false}
//       strokeWidth={4}
//     />
//     <CartesianGrid stroke="#ccc" />
//     <XAxis
//       dataKey="InsertDate"
//       tick={{ fontSize: 10, fill: Styles["darkColor"] }}
//     >
//       <Label value="Date" offset={-10} position="insideBottom" />
//     </XAxis>

//     <YAxis
//       padding={{ top: 12 }}
//       //type="number"
//       domain={[maxMinTotalRange[0] - 10, maxMinTotalRange[1] + 10]}
//       //yAxisId="hours"
//       orientation="left"
//       label={
//         <Text x={0} y={0} dx={30} dy={300} offset={0} angle={-90}>
//           Number Of Patients By State
//         </Text>
//       }
//     />
//     <Tooltip />
//   </LineChart>

// </div>
// </Paper>
