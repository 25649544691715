import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/Send";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";
import config from "../ReportConfig.js";
import ConsumerHOC from "../context/ConsumerHOC";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MenuComponent = (props) => {
  let userPermission = props.userInfo.permissions;
  let navigationInfo =
    config[userPermission] && config[userPermission].Menu
      ? config[userPermission].Menu
      : [];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    //console.log(props);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuIcon
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        //color="secondary"
        onClick={handleClick}
      >
        Open Menu
      </MenuIcon>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navigationInfo
          ? navigationInfo.map((btn) => {
              return (
                <StyledMenuItem
                  key={btn.title}
                  onClick={() =>
                    props.history.push({
                      pathname: btn.pathname,
                      state: { btn },
                    })
                  }
                >
                  <ListItemIcon>
                    <SendIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={btn["title"]} />
                </StyledMenuItem>
              );
            })
          : null}
      </StyledMenu>
    </div>
  );
};

export default ConsumerHOC(withRouter(MenuComponent));
