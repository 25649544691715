import { makeRequest } from "./Requests";

export const GenerateCarePlanByName = async ({token, data}) => {
    const headers = {
        Authorization: `bearer ${token}`,
    };

    return makeRequest({
        method: 'POST', 
        url: 'carePlan/generateCareplanReportsByName', 
        data,
        config:{headers: headers}
    }).then(response => response.data )
}

export const GenerateCarePlanByState = async ({token, data}) => {
    const headers = {
        Authorization: `bearer ${token}`,
    };

    return makeRequest({
        method: 'POST', 
        url: 'carePlan/generateCareplanReportsByState', 
        data,
        config:{headers: headers}
    }).then(response => response.data )
}