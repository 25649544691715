import React, { useState, useEffect } from 'react';
import axios from "axios";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination  } from '@mui/material';
import { Info } from "../../config.js";
import {TextField} from "@mui/material";
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import { CircularProgress } from "@mui/material";

const domain = Info.domain;

// Splitting out the fetch function
const fetchAssessments = async (token, domain, startDate = "2024/01/01", endDate = "2024/12/31") => {
  let newStart = new Date(startDate).toISOString().split('T')[0]
  let newEnd = new Date(endDate).toISOString().split('T')[0]

  try {
    const response = await axios.post(
      `https://${domain}/patients/fetchFallAssessmentData`, 
      { startDate: newStart, endDate: newEnd },
      { headers: { Authorization: `bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    return null; 
  };
}

export default function FallAssessments(props) {
  const [value, setValue] = useState(0);
  const [assessmentsData, setAssessmentsData] = useState(null);
  const [resultsSearchTerm, setResultsSearchTerm] = useState("");
  // const [rnSearchTerm, setRnSearchTerm] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 10;
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);

  useEffect(() => {
    fetchAssessments(props.token, domain).then(data => setAssessmentsData(data));
  }, [props.token, domain]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Always reset to first page
  };

  const renderAssessmentData = (data, type) => {
    if (!data) return null;
    // console.log({rnSearchTerm, resultsSearchTerm});
    let filteredPatients;
    if(type === "outstandingAssessments"){
      filteredPatients = resultsSearchTerm
       ? data[type].filter((patient) =>
           patient.FIRST_NAME.toLowerCase().includes(
             resultsSearchTerm.toLowerCase()
           )
         )
       : data[type];
    } else {
      
      filteredPatients = resultsSearchTerm
      ? data[type].filter((patient) =>
          patient.firstName.toLowerCase().includes(
            resultsSearchTerm.toLowerCase()
          )
        )
      : data[type];
    }

    const selectedData = filteredPatients?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="simple table">
            {type === "outstandingAssessments" ? (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>MRN</TableCell>
                    {type === "outstandingAssessments" ? (
                      <>
                        <TableCell align="right">Primary RN</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">MRN</TableCell>
                      </>
                    )}
                    <TableCell align="right">FirstName</TableCell>
                    <TableCell align="right">LastName</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedData && selectedData.map((assessment, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {assessment.MRN}
                      </TableCell>
                      {type === "outstandingAssessments" ? (
                        <>
                          <TableCell align="right">{assessment.PRIMARY_RN}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align="right">{assessment.MRN}</TableCell>
                        </>
                      )}
                      <TableCell align="right">{assessment.FIRST_NAME}</TableCell>
                      <TableCell align="right">{assessment.LAST_NAME}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>MRN</TableCell>
                    {type === "outstandingAssessments" ? (
                      <>
                        <TableCell align="right">Primary RN</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">Date</TableCell>
                      </>
                    )}
                    <TableCell align="right">FirstName</TableCell>
                    <TableCell align="right">LastName</TableCell>
                    <TableCell align="right">Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedData && selectedData.map((assessment, i) => {
                    const formattedDate = assessment && assessment?.Date ? assessment?.Date.split('T')[0] : 'n/a';
                    return (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {assessment.MRN}
                        </TableCell>
                        {type === "outstandingAssessments" ? (
                          <>
                            <TableCell align="right">{assessment.PRIMARY_RN}</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right">{formattedDate}</TableCell>
                          </>
                        )}
                        <TableCell align="right">{assessment.firstName}</TableCell>
                        <TableCell align="right">{assessment.lastName}</TableCell>
                        <TableCell align="right">{assessment.total}</TableCell>
                      </TableRow>
                    )
                  } )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data[type]?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  const handleSubmit = async () => {
    if(endDate < startDate) {
        setError('Invalid dates, End < Start');
        return;
    }
    if(!endDate || !startDate) return;
    setError(null)
    setLoading(true)
    await fetchAssessments(props.token, domain, startDate, endDate).then(data => setAssessmentsData(data));
    setLoading(false)
}

  const totalCompleted = assessmentsData && assessmentsData.completedAssessmentsMRN ? assessmentsData.completedAssessmentsMRN.length : 0;
  const totalActive = assessmentsData ? assessmentsData.activePatientCount : 0;
  const completionPercentage = totalActive ? (totalCompleted / totalActive) * 100 : 0;
  const outstandingAssessments = assessmentsData && assessmentsData.patients
  ? assessmentsData.patients.filter(pat => !assessmentsData.completedAssessmentsMRN.includes(pat.MRN))
  : [];
  if(assessmentsData && typeof assessmentsData !== 'string')
    assessmentsData['outstandingAssessments'] = outstandingAssessments;
  return (
    <Card variant="outlined" sx={{ maxWidth: '85%' }}>
      <Box sx={{ p: 2 }}>
          <Stack direction="row" spacing={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Date"
                    onChange={(newValue) => setStartDate(newValue.$d)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="End Date"
                    onChange={(newValue) => setEndDate(newValue.$d)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <Button variant="contained" color="success" size="large" sx={{width: "200px"}} 
              onClick={handleSubmit}
            >
                Submit
            </Button>
          </Stack>
      </Box>
      <Divider />
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <p><b>Percentage Complete: </b>{completionPercentage.toFixed(2)}%</p>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            style={{backgroundColor: '#307447'}}
          >
            <Tab label="Outstanding Annual Assessments" />
            <Tab label="Completed Annual Assessments" />
            <Tab label="Non Annual Assessments" />
          </Tabs>
        </AppBar>
        <div style={{display: 'flex', padding:'10px 0 0 10px'}}>
          <TextField
            // helperText="Search by firstname"
            id="demo-helper-text-aligned"
            label="Search by firstname"
            size="small"
            value={resultsSearchTerm}
            onChange={(e) => {
              setResultsSearchTerm(e.target.value)
              // setRnSearchTerm("")
            }}
          />
          {/* {(value === 0) && ( // Assuming tab indexes 1 and 2 correspond to "Completed" and "Non Annual"
            <>
              <Switch  defaultChecked />
              <TextField
                  id="search-primary-rn"
                  label="Search by Primary RN"
                  size="small"
                  value={rnSearchTerm}
                  onChange={(e) => {
                    setRnSearchTerm(e.target.value)
                    setResultsSearchTerm("")
                  }}
                  style={{ marginLeft: '10px' }}
              />
            </>
          )} */}
        </div>
        {loading ? 
          <section
            style={{
                display: "flex",
                justifyContent: "center",
                margin: "10% auto",
            }}
            >
            <CircularProgress />
          </section>
        : (
          <div>
            {value === 0 && renderAssessmentData(assessmentsData, 'outstandingAssessments')}
            {value === 1 && renderAssessmentData(assessmentsData, 'annualAssessments')}
            {value === 2 && renderAssessmentData(assessmentsData, 'nonAnnualAssessments')}
          </div>

        )}
      </Box>
    </Card>
  )
}

